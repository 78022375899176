<template>
  <div>
    <VTextField label="タイトル" v-model="article.title[lang]" outlined :dense="false"/>
    <v-checkbox :label="`非公開にする`" v-model="article.is_hidden"/>
    <VueCtkDateTimePicker label="予約投稿日時" v-model="article.publish_at" :disabled="article.is_hidden" :format="'YYYY-MM-DD HH:mm'" :overlay="true"/>
    <p style="font-size: 1.3vh;">※今より前の日時を入力すると即時公開されます</p>
    <VTextarea label="ログイン前でも見られる内容" v-model="article.content[lang]" outlined rows="6"/>
    <VTextarea label="ログイン後のみの内容" v-model="article.after_signup_content[lang]" outlined rows="6"/>
    <VTextarea label="Call To Action" v-model="article.call_to_action[lang]" outlined rows="6"/>
  </div>
</template>

<script>
import { HfMemberRegisterArticleCommandService } from "hfapi"
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
  components: {
    VueCtkDateTimePicker,
  },
  props: {
    articleParams: {
      type: HfMemberRegisterArticleCommandService.Params,
    },
    lang: {
      type: String
    },
  },
  computed: {
    article() {
      return this.articleParams.article
    },
  },
  methods: {
    initializeParams(){
      this.article.is_hidden ||= false
    },
  },
  mounted() {
    this.initializeParams()
  }
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset{
  border-color: rgba(0,0,0,.2);
}
</style>