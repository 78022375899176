<template lang="">
  <VContainer>
    <h2>退会処理</h2>
    <p>
      マニュアルは以下にあります <br>
      <a href="https://www.notion.so/hf-jtj/379ad352095c4adcac7ba57a702cb6d7" target="_blank">ユーザーの退会処理/データ削除/メール配信停止周りの現状と実務(Notion)</a>
    </p>
    <VRow>
      <VCol cols="6">
        <VCard>
          <VCardTitle>元々のユーザーデータ</VCardTitle>
          <VContainer>
            <VTextField label="id" v-model="user.id" disabled/>
            <VTextField label="first_name" v-model="user.first_name" disabled/>
            <VTextField label="last_name" v-model="user.last_name" disabled/>
            <VTextField label="middle_name" v-model="user.middle_name" disabled/>
            <VTextField label="first_name_kana" v-model="user.first_name_kana" disabled/>
            <VTextField label="last_name_kana" v-model="user.last_name_kana" disabled/>
            <VTextField label="middle_name_kana" v-model="user.middle_name_kana" disabled/>
            <VTextField label="phone_num" v-model="user.phone_num" disabled/>
            <VTextField label="address" v-model="user.address" disabled/>
            <VTextField label="past_email" v-model="user.past_email" disabled/>
          </VContainer>
        </VCard>
      </VCol>
      <VCol cols="6">
        <VCard>
          <VContainer>
            <VCardTitle>更新後のユーザーデータ</VCardTitle>
            <VTextField label="id" v-model="params.user.id" disabled/>
            <VTextField label="first_name" v-model="params.user.first_name"/>
            <VTextField label="last_name" v-model="params.user.last_name"/>
            <VTextField label="middle_name" v-model="params.user.middle_name"/>
            <VTextField label="first_name_kana" v-model="params.user.first_name_kana"/>
            <VTextField label="last_name_kana" v-model="params.user.last_name_kana"/>
            <VTextField label="middle_name_kana" v-model="params.user.middle_name_kana"/>
            <VTextField label="phone_num" v-model="params.user.phone_num"/>
            <VTextField label="address" v-model="params.user.address"/>
            <VTextField label="past_email" :value="params.user.past_email" disabled/>
          </VContainer>
        </VCard>
      </VCol>
      <VCol cols="12" class="text-right">
        <VBtn color="success" @click="update">更新</VBtn>
      </VCol>
    </VRow>

  </VContainer>
</template>
<script>
import { HfMemberOverwriteUserPersonalDataCommandService } from "hfapi/jtj"
import User from "@/aggregates/user";


export default {
  data(){
    return {
      user: new User(this.$route.params.id),
      params: new HfMemberOverwriteUserPersonalDataCommandService.BuildableParams()
    }
  },
  mounted(){
    this.params.user.id = this.$route.params.id
    this.params.user.first_name = "-"
    this.params.user.last_name = "-"
    this.params.user.middle_name = "-"
    this.params.user.first_name_kana = "-"
    this.params.user.last_name_kana = "-"
    this.params.user.middle_name_kana = "-"
    this.params.user.phone_num = 0
    this.params.user.address = "-"
    this.params.user.past_email = this.user.email
  },
  methods: {
    update(){
      const check = window.confirm("ユーザーのデータが上書きされますが本当に大丈夫ですか？\n＊マニュアルをよく読んだ上で実行ください。");
      if (check){
        HfMemberOverwriteUserPersonalDataCommandService.execute(this.params).then(()=>{
          this.flash("ユーザーのデータの上書きが完了しました", "success")
        }).catch(e=>{
          console.log(e)
          this.flash("エラーが起きました")
        })
      }
    }
  },
  watch: {
    "user.isFetched": function(){
      this.params.user.past_email = this.user.email
    }
  }
}
</script>
<style lang="">
  
</style>