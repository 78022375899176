<template>
  <div>
    <VCard>
      <VCardTitle>画像のアップロード</VCardTitle>
      <VCardText>
        <VFileInput :label="label" accept="image/png, image/jpeg, image/svg" v-model="file"/>
        <VBtn @click="upload" :disabled="disableUpload">Upload</VBtn>
      </VCardText>
    </VCard>
    <VOverlay v-model="loading">
      <VProgressCircular indeterminate/>
    </VOverlay>
  </div>
</template>

<script>
import axios from 'axios'
import { HfMemberGetPresignedUrlQueryService } from "hfapi"
export default {
  props: {
    label: { type: String, default: "Upload" },
    dir: { type: String },
    value: { type: String },
  },
  data(){
    return {
      file: null,
      loading: false,
    }
  },
  methods: {
    async upload(){
      this.loading = true
      // presignedObjectの取得
      const params = new HfMemberGetPresignedUrlQueryService.Params()
      params.key = this.dir + this.file.name
      const { data: presignedObject } = await HfMemberGetPresignedUrlQueryService.execute(params)

      // 送信するデータの用意
      const formData = new FormData
      for (const key in presignedObject.fields) {
        formData.append(key, presignedObject.fields[key])
      }
      formData.append('file', this.file)

      axios.post(presignedObject.url, formData, { headers: { 'content-type': 'multipart/form-data', } }).then(() => {
        this.dialog = false
        this.file = null
        this.$emit('input', params.key)
        this.flash("アップロードに成功しました。\n最後に保存ボタンを押してください。", "success")
      }).catch(e => console.log(e.message))
        .finally(() => this.loading = false)
    }
  },
  computed: {
    disableUpload() {
      return this.file == null 
    }
  },
}
</script>

<style>

</style>