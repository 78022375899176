<template>
  <VForm ref="form">
    <h4 class="mb-3">{{t('english_level')}}</h4>
    <VChipGroup
      column
      class="mb-5"
      active-class="primary"
      v-model="englishSkill.general_level_id"
    >
      <VChip v-for="generalLevel in master('general_language_levels')" :key="generalLevel.id" :value="generalLevel.id">
        {{generalLevel.name}}
      </VChip>
      <ValidationViewer v-model="englishSkill.general_level_id" :rules="[validation.required]"></ValidationViewer>
    </VChipGroup>

    <h4>{{t('english_examination')}}</h4>
    <VChipGroup
      column
      class="mb-5"
      active-class="primary"
      v-model="englishExamination.language_examination_id"
    >
      <VChip v-for="languageExamination in englishExaminations" :key="languageExamination.id" :value="languageExamination.id">
        {{languageExamination.name}}
      </VChip>
    </VChipGroup>
    <VTextField :label="t('result')" v-model="englishExamination.result"/>

    <!-- <h3 class="mt-4 mb-n4">{{t('speaking_level', {lang_name: t('japanese')} ) }}</h3>
    <VRadioGroup v-model="selectedLevels.speaking" @change="change()" :rules="[validation.required]">
      <VRadio
        v-for="speakingLevel in speakingLevels"
        :key="speakingLevel.id"
        :value="speakingLevel.id"
        :label="speakingLevel.name"
      />
    </VRadioGroup> -->
  </VForm>
</template>

<script>
import { UserUpdateProfileCommandService } from "hfapi";
import { LANGUAGE_SYMBOL_2_ID } from "@@/config/constants.js"

export default {
  props: ["value"],
  data() {
    return {
    };
  },
  computed: {
    englishSkill(){
      return this.value.englishSkill()
    },
    englishExamination(){
      return this.value.englishExamination()
    },
    englishExaminations(){
      return this.master('language_examinations').filter(lE => lE.language_id == LANGUAGE_SYMBOL_2_ID["en"])
    }
  },
  methods: {
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      return this.$refs.form.validate()
    },
    defaultEnglishSkill(){
      const englishSkill = (new UserUpdateProfileCommandService.Params).user.user_language_skills[0]
      englishSkill.language_id = LANGUAGE_SYMBOL_2_ID["en"]
      return englishSkill
    },
    defaultEnglishExamination(){
      const englishExamination = (new UserUpdateProfileCommandService.Params).user.user_language_examinations[0]
      Object.assign(englishExamination, { language_examination: { language_id: LANGUAGE_SYMBOL_2_ID["en"] } } )
      return englishExamination
    }
  },
  created() {
    if ( !this.value.englishSkill() ){
      this.value.user_language_skills.push(this.defaultEnglishSkill())
    }
    if ( !this.value.englishExamination() ){
      this.value.user_language_examinations.push(this.defaultEnglishExamination())
    }
  },
};
</script>

<style>
</style>