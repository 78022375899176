<template lang="">
  <div v-if="recruitment_company_offices !== undefined">
    <VCard v-for="recruitment_company_office in recruitment_company_offices" :key="recruitment_company_office.id" class="mb-2">
      <VContainer>
        <VRow v-if="!recruitment_company_office._destroy">
          <VCol cols="12">
            <VSelect
              label="オフィス"
              v-model="recruitment_company_office.company_office_id"
              :items="company_offices"
              item-value="id"
              item-text="name"
              hint="企業側で登録したオフィスと連携しています"
              :rules="[ validation.required ]"
              append-icon="mdi-sync"
              @click:append="syncCompanyOfficeOptions"
            />
            <VTextarea label="説明" v-model="recruitment_company_office.description[lang]" hint="勤務地に変化がある場合などは入力してください(転勤があることや最初だけなど)"/>
          </VCol>
          <VCol>
            <VBtn class="error" @click="remove(recruitment_company_offices, recruitment_company_office)"><VIcon>mdi-trash</VIcon>削除</VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VRow>
      <VCol cols="12">
        <VBtn @click="add" class="ma-3">勤務地追加</VBtn>
        <VBtn small @click="window.open(`/#/companies/${recruitment.company_id}/edit`)" class="ma-3">企業を編集してオフィスを追加する</VBtn>
      </VCol>
    </VRow>
  </div>
</template>
<script>
import { HfMemberRegisterSimpleRecruitmentCommandService, HfMemberGetCompanyOfficeOptionsQueryService } from "hfapi/jtj"

export default {
  props: {
    recruitmentParams: { type: Object },
    lang: { type: String, required: true },
  },
  data: () => ({
    company_offices: [],
  }),
  computed: {
    recruitment_company_offices(){
      return this.recruitmentParams?.recruitment?.recruitment_company_offices
    },
    recruitment(){
      return this.recruitmentParams?.recruitment
    },
  },
  methods: {
    add(){
      const item = (new HfMemberRegisterSimpleRecruitmentCommandService.BuildableParams).build("recruitment_company_offices")
      this.recruitment_company_offices.push(item)
    },
    remove(ary, object){
      if (object.id){
        this.$set(object, "_destroy", true)
      } else {
        ary.remove(object)
      }
    },
    syncCompanyOfficeOptions(){
      HfMemberGetCompanyOfficeOptionsQueryService.execute({ company_id: this.recruitment.company_id }).then(resp => {
        this.company_offices = resp.data
      })
    },
  },
  watch: {
    "recruitment.company_id": function(){
      if (this.recruitment.company_id) this.syncCompanyOfficeOptions()
    },
  }
}
</script>
<style lang="">

</style>
