<template>
  <VContainer>
    <VSelect :items="sheetTypes" v-model="sheetType" hint="こちらで吐き出し用途を変更できます。" persistent-hint class="mb-2"/>
    <!-- 検索カードs -->
    <VCard v-for="(searchUserQueryParam, index) in searchUserQueryParams" :key="index">
      <VContainer>
        <h2 class="my-2 accent--text">ユーザー吐き出し</h2>
        <component :is ="currentSearchRecommendFrom" v-model="searchUserQueryParams[index]"/>
      </VContainer>
      <VCardActions>
        <VSpacer></VSpacer>
        
        <VBtn color="error" @click="deleteUserSearchQuery(index)">
          Delete
        </VBtn>
      </VCardActions>
    </VCard>
    <!-- 吐き出し追加ボタン -->
    <VRow>
      <VCol>
        <VBtn @click="addUserSearchQuery()" color="accent">吐き出し情報の追加</VBtn>
      </VCol>
    </VRow>
    <!-- 吐き出しボタン -->
    <VRow>
      <VCol>
        <VBtn @click="saveUserSearchQueriesAndSearchUsers()" color="primary" :loading="loading">吐き出して確認</VBtn>
      </VCol>
    </VRow>

    <!-- スプレッドシートへ吐き出し -->
    <VRow justify="end">
      <VBtn @click="showInquiryModal = true" class="my-5 mr-3" color="success">SpreadSheetへ吐き出し</VBtn>
      <SheetCreateDialog :showModal="showInquiryModal" :searchUserQueryIds="searchUserQueryIds" @close="showInquiryModal = false" :sheet_type="sheetType"></SheetCreateDialog>
    </VRow>
    <!-- データのテーブル -->
    <DataTableFor2dArray :data="searchUsersData"/>
    
  </VContainer>
</template>

<script>
import SearchRecommendedUsersForSalesForm from "@@/components/organisms/hf_members/SearchRecommendedUsersForSalesForm.vue"
import SearchRecommendedUsersForm from "@@/components/organisms/hf_members/SearchRecommendedUsersForm.vue"
import { HfMemberRegisterSearchUserQueryCommandService, HfMemberSearchRecommendedUsersQueryService } from "hfapi"
import SheetCreateDialog from "@@/components/organisms/hf_members/SheetCreateDialog.vue"
import DataTableFor2dArray from "@@/components/molecules/DataTableFor2dArray.vue"


export default {
   metaInfo() {
    return {
      title: `ユーザー吐き出しページ`,
    }
  },
  components: {
    SearchRecommendedUsersForm,
    SearchRecommendedUsersForSalesForm,
    SheetCreateDialog,
    DataTableFor2dArray,
  },
  data() {
    return {
      searchUsersData: [[]],
      searchUserQueryParams: [new HfMemberRegisterSearchUserQueryCommandService.Params()],
      showInquiryModal: false,
      loading: false,
      sheetType: "matching",
      sheetTypes: [
        { value: "matching", text: 'マッチングチーム向け'},
        { value: "sales", text: '営業向け'},
      ]
    }
  },
  computed: {
    searchUserQueryIds() {
      return this.searchUserQueryParams.map(params=>params.search_user_query.id)
    },
    currentSearchRecommendFrom() {
      return {
        "sales": "SearchRecommendedUsersForSalesForm",
        "matching": "SearchRecommendedUsersForm",
      }[this.sheetType]
    }
  },
  methods: {
    saved(result) {
      this.flash(`吐き出し情報が ${result.users.length}件 追加されました`,"success")
      if (result.users.length) {
        this.searchUsersDatum.push(result)
        this.$refs.table.updateItems()
      }
    },
    defaultSearchUserQuery(){
      return new HfMemberRegisterSearchUserQueryCommandService.Params()
    },
    addUserSearchQuery(){
      this.searchUserQueryParams.push(this.defaultSearchUserQuery())
    },
    deleteUserSearchQuery(index){
      this.searchUserQueryParams.splice(index,1)
    },
    async saveUserSearchQueriesAndSearchUsers(){
      // try-catchで括らないと、awaitでエラーが起こってもそのまま続けてしまう
      try {
        this.loading = true
        await this.saveUserSearchQueries()
        await this.searchUsers()
      } catch(e) {
        this.flash(e.response.data, "error")
      } finally {
        this.loading = false
      }
    },
    // すべてのsearchUserQueryを保存するPromise関数
    async saveUserSearchQueries(){
      await Promise.all(
        this.searchUserQueryParams.map(
          (searchUserQueryParam)=>{ return this.saveUserSearchQuery(searchUserQueryParam) }
        )
      )
    },
    // searchUserQueryを保存して、idをセットするPromise関数
    async saveUserSearchQuery(searchUserQueryParam){
      return HfMemberRegisterSearchUserQueryCommandService.execute(searchUserQueryParam).then(resp=>{
        console.log("saveUserSearchQuery", "成功")
        searchUserQueryParam.search_user_query.id = resp.data.id
      })
    },
    async searchUsers() {
      console.log(this.searchUserQueryParams[0].search_user_query.id);
      let params = new HfMemberSearchRecommendedUsersQueryService.Params
      params.q.search_user_query_ids = this.searchUserQueryParams.map((searchUserQueryParam)=>{
        return searchUserQueryParam.search_user_query.id
      })
      console.log(params)
      await HfMemberSearchRecommendedUsersQueryService.execute(params).then(resp=>{
        this.searchUsersData = resp.data.users
      }).catch((e)=>{
        this.loading = false
        this.flash(e.response.data, "error")
      })
    }
  },
}
</script>

<style>

</style>