<template>
  <div>
    <MasterSelectField :label="t('prefecture_id')" v-model="company_office.prefecture_id" :items="master('prefectures')"></MasterSelectField>
    <VTextField :label="t('name')" v-model="company_office.name"/>
    <VTextField :label="t('postal_code')" v-model="company_office.postal_code" :rules="[validation.required]"/>
    <VTextField :label="t('address_ja')" v-model="company_office.address_ja" :rules="[validation.required]"/>
    <VTextField :label="t('address_en')" v-model="company_office.address_en"/>
    <CheckboxDefaultFalse :label="t('is_main_office')" v-model="company_office.is_main_office" :rules="[validation.required]"/>
    <h4>通勤手段</h4>
    <VCard v-for="company_office_transport_mean in company_office.company_office_transport_means" :key="company_office_transport_mean.id" class="mb-3">
      <VContainer>
        <CompanyOfficeTransportMean :company_office_transport_mean="company_office_transport_mean" :lang="lang"/>
        <VBtn class="error" @click="remove(company_office.company_office_transport_means, company_office_transport_mean)"><VIcon>mdi-trash</VIcon>移動手段を削除</VBtn>
      </VContainer>
    </VCard>
    <VBtn @click="add('company_office_transport_means')">移動手段を追加</VBtn>
  </div>
</template>

<script>
import CompanyOfficeTransportMean from "@@/components/organisms/companies/CompanyRegisterForms/CompanyOfficeTransportMean"
import { HfMemberRegisterCompanyCommandService } from "hfapi"

export default {
  components: {
    CompanyOfficeTransportMean,
  },
  props: {
    company_office: {
      type: Object
    },
    lang: {
      type: String
    }
  },
  methods: {
    add(key){
      let pushed = (new HfMemberRegisterCompanyCommandService.BuildableParams).build(key)
      this.company_office[key].push(pushed)
    },
    remove(ary, object){
      if (object.id){
        this.$set(object, "_destroy", true)
      } else {
        ary.remove(object)
      }
    },
    t(key) {
      return this.$t(`aggregates.CompanyColumnNames.${key}`)
    }
  }
}

</script>

<style>

</style>