<template>
  <div>
    <VDataTable :items="data" :headers="headers" @click:row="showDetail" >
      <template v-slot:[`item.user_id`]="{ value }">
        <a @click.stop="$router.push({name: 'UserDetailPage', params: { id: value }})">{{value}}</a>
      </template>
      <template v-slot:[`item.application_id`]="{ value }">
        <a @click.stop="$router.push({name: 'ApplicationShowPage', params: { id: value }})">{{value}}</a>
      </template>
      <template v-slot:[`item.user.created_at`]="{ value }">
        {{moment(value).format("YYYY-MM-DD")}}
      </template>
    </VDataTable>
    <VDialog v-model="dialog">
      <VCard>
        <VCardTitle>Campaign情報</VCardTitle>
        <VCardText>
          <VSimpleTable>
            <tbody>
              <tr v-for="c in campaigns" :key="c.text">
                <th>{{c.text}}</th>
                <td>{{dialogData[c.value]}}</td>
              </tr>
            </tbody>
          </VSimpleTable>
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import {HfMemberGetRecruitmentSignUpUsersQueryService} from "hfapi"

export default {
  props: {
    recruitment_id: { type: String, required: true},
  },
  data(){
    return {
      data: [],
      headers: [
        { text: 'UserID', value: 'user_id' },
        { text: '名前', value: 'user.full_name' },
        { text: '出身国', value: 'user.home_country_name' },
        { text: '日付', value: 'user.created_at' },
        { text: '応募', value: 'application_id' },
      ],
      campaigns: [
        { text: "name", value: "sign_up_campaign_name" },
        { text: "source", value: "sign_up_campaign_source" },
        { text: "term", value: "sign_up_campaign_term" },
        { text: "medium", value: "sign_up_campaign_medium" },
        { text: "content", value: "sign_up_campaign_content" },
      ],
      dialog: false,
      dialogData: {},
    }
  },
  methods: {
    showDetail(e){
      this.dialogData = e
      this.dialog = true
    }
  },
  created(){
    console.log(HfMemberGetRecruitmentSignUpUsersQueryService)
    const params = new HfMemberGetRecruitmentSignUpUsersQueryService.Params
    params.recruitment_id = this.recruitment_id
    HfMemberGetRecruitmentSignUpUsersQueryService.execute(params).then(resp => {
      this.data = resp.data
    })
  }
}
</script>

<style>

</style>