import { hfapiAxios } from "hfapi"

export default {
  namespaced: true,
  state: {
    client: '',
    uid: '',
    token: '',
    tokenType: '',
    expiry: '',
  },
  mutations: {
    create(state, headers) {
      state.client = headers["client"]
      state.token = headers["access-token"]
      state.uid = headers["uid"]
      state.tokenType = headers["token-type"]
      state.expiry = headers["expiry"]
    },
    destroy (state) {
      state.client = ''
      state.uid = ''
      state.token = ''
      state.tokenType = ''
      state.expiry = ''
      this.dispatch('user/destroy', null, {root: true})
    },
    updateWithToken(state, headers) {
      state.client = headers["client"];
      state.token = headers["access-token"];
      state.uid = headers["uid"];
      state.tokenType = headers["token-type"];
      state.expiry = headers["expiry"];
    }
  },
  actions: {
    signIn({ commit }, user) {
      return hfapiAxios.post('/jtj/user/sign_in', user).then(resp => {
        commit('create', resp.headers)
      })
    },
    confirmAndSignIn({ commit }, confirmationToken) {
      return hfapiAxios.post('/jtj/user/confirm_and_log_in', { confirmation_token: confirmationToken }).then(resp => {
        commit('create', resp.headers)
      })
    },
    signOut({ commit }) {
      return hfapiAxios.delete('/jtj/user/sign_out').finally(() => {
        commit('destroy') //とりあえず削除すればログアウトはできるので
      })
    },
    updateWithToken({ commit }, headers) {
      if (headers['access-token']) { // undefined または 空文字列のときは弾く
        commit('updateWithToken', headers)
      }
    }
  },
  getters: {
    isSignIn: (state) => {
      return !!state.token
    },
  }
}
