import Query from "../../lib/queries_services/aggregates__mail_template";
import BaseAggregate from "../../../base_aggregate";
class MailTemplate extends BaseAggregate {
    container_hash() {
        return { "id": null, "mail_template_group_id": null, "name": null, "template_subject": null, "template_body": null };
    }
    query() {
        return Query;
    }
}
export default MailTemplate;
