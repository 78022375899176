<template>
  <VContainer>
    <h2>求人情報を登録できます。</h2>
    <VChip>* 注意: 日本語と英語のタイトル、内容それぞれ全てが入力されていないと登録ができないのでお気をつけを！</VChip>
    <VChip class="mt-3" v-if="recruitmentParams.recruitment.id">このページは簡易版です．正規版編集ページは<RouterLink :to="{name: 'RecruitmentEditPage'}">こちら</RouterLink></VChip>
    <VRow>
      <VCol cols="6">
        <VCard>
          <VToolbar>
            <VTabs v-model="tab">
              <VTab href="#ja">日本語</VTab>
              <VTab href="#en">English</VTab>
            </VTabs>
          </VToolbar>
          <VTabsItems v-model="tab" class="ma-5">
            <VTabItem v-for="lang in ['ja', 'en']" :key="lang" :value="lang" eager>
              <RecruitmentSimpleRegisterLangForm
                :recruitmentParams.sync="recruitmentParams"
                :resumeQuestions="resumeQuestions"
                :companies="companies"
                @onResumeQuestionRegister="updateResumeQuestionOptions"
                :lang="lang" :ref="`${lang}Form`"
                @registerCompany="updateCompanyOptions"
              />
            </VTabItem>
          </VTabsItems>
        </VCard>
      </VCol>
      <VCol cols="6">
        <VCard>
          <VToolbar>
            <VTabs v-model="previewTab">
              <VTab href="#recruitment">プレビュー</VTab>
              <VTab href="#question">質問フォーム</VTab>
            </VTabs>
          </VToolbar>
          <VTabsItems v-model="previewTab" class="ma-5">
            <VTabItem value="recruitment" eager>
              <div style="overflow-y: scroll; height: 73vh;">
                <h1 class="my-5 text-center"> {{ recruitmentParams.recruitment.title[tab] }} </h1>
                <VueMarkdown :source="recruitmentParams.recruitment.simple_content[tab] || ''" />
              </div>
            </VTabItem>
            <VTabItem value="question" eager>
              <RecruitmentResumeQuestionsPreview :resumeQuestions="selectedResumeQuestions" :resumeQuestionsLocale="resumeQuestionsLocale" :recruitmentResumeQuestions="recruitmentParams.recruitment.recruitment_resume_questions" />
            </VTabItem>
          </VTabsItems>
        </VCard>
      </VCol>
    </VRow>
    <VRow dense>
      <VCol>
        <VBtn @click="submit" color="success">Submit</VBtn>
        <VBtn :to="{name: 'RecruitmentBuildApplyLinkPage', params: {id: $route.params.id}}" class="ml-3" v-if="$route.params.id">Build Apply Link</VBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { HfMemberRegisterSimpleRecruitmentCommandService, HfMemberGetCompanyOptionsQueryService, HfMemberGetResumeQuestionsQueryService } from "hfapi/jtj"
import Recruitment from "@/aggregates/recruitment"
import RecruitmentSimpleRegisterLangForm from '../../organisms/recruitments/RecruitmentSimpleRegisterLangForm.vue'
import VueMarkdown from 'vue-markdown'
import RecruitmentResumeQuestionsPreview from '../../organisms/recruitments/RecruitmentResumeQuestionsPreview.vue'

export default {
   metaInfo() {
    return {
      title: `簡易求人作成ページ`,
    }
  },
  components: {
    RecruitmentSimpleRegisterLangForm,
    VueMarkdown,
    RecruitmentResumeQuestionsPreview,
  },
  data(){
    return {
      recruitmentParams: new HfMemberRegisterSimpleRecruitmentCommandService.BuildableParams(),
      tab: "tab",
      previewTab: "recruitment",
      resumeQuestionOptions: [],
      companies: [],
      resumeQuestionsLocale: this.$i18n.locale,
    }
  },
  methods: {
    submit(){
      if ( !this.$refs.jaForm[0].validate() || !this.$refs.enForm[0].validate() ) {
        this.flash("入力していない項目があります。", "error")
        return
      }
      this.register()
    },
    register(){
      this.recruitmentParams.recruitment.is_simple = true
      HfMemberRegisterSimpleRecruitmentCommandService.execute(this.recruitmentParams).then(resp=>{
        // this.recruitment.fetch(resp.data.id)
        this.$router.push({ name: "RecruitmentShowPage", params: {id: resp.data.id } })
        this.flash("保存に成功しました", "success")
      }).catch(e=>{
        this.flash(e.response.data, "error")
      })
    },
    updateResumeQuestionOptions(){
      HfMemberGetResumeQuestionsQueryService.execute({ all: false }).then(resp => {
        this.resumeQuestionOptions = resp.data
      })
    },
    updateCompanyOptions(){
      HfMemberGetCompanyOptionsQueryService.execute().then(resp=>{
        this.companies = resp.data
      })
    },
  },
  computed: {
    resumeQuestions(){
      return this.resumeQuestionOptions.map(rq => {
        this.$set(rq, 'recruitment_resume_question', this.recruitmentParams.recruitment.recruitment_resume_questions.find(rrq => rq.id == rrq.resume_question_id))
        return rq
      })
    },
    selectedResumeQuestions(){
      return this.resumeQuestions.filter(rq => !!rq.recruitment_resume_question && !rq.recruitment_resume_question._destroy).sort((a, b) => a.recruitment_resume_question.index - b.recruitment_resume_question.index)
    },
  },
  mounted(){
    this.updateCompanyOptions()
    this.updateResumeQuestionOptions()
    const recruitment_id = this.$route.params.id
    if(recruitment_id){
      const recruitment = new Recruitment(this.$route.params.id)
      recruitment.after_assign = () => {
        Object.assign(this.recruitmentParams.recruitment, recruitment)
      }
    }
  },
  watch: {
    'recruitmentParams.recruitment.is_japanese_only_for_resume_questions': function(val){
      if(val){
        this.resumeQuestionsLocale = 'ja'
      }else{
        this.resumeQuestionsLocale = this.$i18n.locale
      }
    }
  }
}
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10%;
  right: 5%;
  z-index: 1000;
}

</style>
