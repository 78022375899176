<template>
  <VDialog v-model="showModal" persistent>
    <VCard elevation="2">
      <VCardTitle>{{ message }}</VCardTitle>
      <VCardActions>
        <VBtn @click="confirm()" :color="btnColor">{{ btnText }}</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: ""
    },
    btnText: {
      type: String,
      default: ""
    },
    btnColor: {
      type: String,
      default: ""
    },
    showModal: {
      type: Boolean,
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style>

</style>