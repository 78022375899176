<template>
  <VContainer>
    <VTextField label="企業・求人名" v-model="value.search_user_query.filter_name" outlined class="mb-n3"></VTextField>
    <h3 class="my-2 primary--text">フィルタ</h3>
    <VRow>
      <VCol cols="6">
        <h4>日本に住んでいるか</h4>
        <VCheckbox label="住んでいる" v-model="value.search_user_query.is_living_in_japan" :true-value="true" :false-value="null"/>
      </VCol>
      <VCol cols="6">
        <h4>年齢が入力値以下である</h4>
        <VTextField label="年齢" v-model.number="value.search_user_query.maximum_age" type="number"/>
      </VCol>
      <VCol cols="6">
        <h4>以下の国どれかが母国</h4>
        <CheckboxDefaultFalse v-model="value.search_user_query.home_country_null" label="未入力も含める" />
        <SelectFieldCountryRich v-model="value.search_user_query.home_country_ids"/>
      </VCol>
      <VCol cols="6">
        <h4>以下の学部のどれかに所属</h4>
        <CheckboxDefaultFalse v-model="value.search_user_query.study_department_category_null" label="未入力も含める" />
        <MasterSelectField label="学部" :items="master('study_department_categories')" v-model="value.search_user_query.study_department_category_ids" multiple chips deletable-chips/>
      </VCol>
      <VCol cols="6">
        <h4>日本語レベルがN？以上の可能性あり</h4>
        <VSelect label="数字" :items="[1,2,3,4,5]" v-model="value.search_user_query.minimum_japanese_level" clearable/>
      </VCol>
      <VCol cols="6">
        <h4>以下の職種に興味がある(空白の人は吐き出されません)</h4>
        <CheckboxDefaultFalse v-model="value.search_user_query.interesting_job_category_null" label="未入力も含める" />
        <MasterSelectField label="職種" :items="master('job_categories')" v-model="value.search_user_query.interesting_job_category_ids" multiple chips deletable-chips/>
      </VCol>
      <VCol cols="6">
        <h4>以下のスキルを持っている(空白の人は吐き出されません)</h4>
        <CheckboxDefaultFalse v-model="value.search_user_query.skill_null" label="未入力も含める" />
        <MasterSelectField label="スキル" :items="master('skills')" v-model="value.search_user_query.skill_ids" multiple chips deletable-chips/>
      </VCol>
      <VCol cols="6">
        <h4>性別</h4>
        <VSelect label="性別" :items="genders" v-model="value.search_user_query.genders" multiple chips deletable-chips/>
      </VCol>
      <VCol cols="6">
        <h4>フリーテキストで検索(カンマ(,)区切りで入力してください)</h4>
        <VTextField label="テキスト" hint="Python,AI" v-model="value.search_user_query.free_texts" multiple chips deletable-chips/>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { HfMemberRegisterSearchUserQueryCommandService } from "hfapi"
import CheckboxDefaultFalse from "@/components/atoms/checkboxes/CheckboxDefaultFalse.vue"
export default {
  components: { CheckboxDefaultFalse },
  data: () => ({
    genders: [
      { text: "男性", value: "man" },
      { text: "女性", value: "woman" },
      { text: "その他", value: "other" }
    ]
  }),
  props: {
    value: {
      type: HfMemberRegisterSearchUserQueryCommandService.Params,
      default: new HfMemberRegisterSearchUserQueryCommandService.Params()
    },
  },
}
</script>

<style>

</style>