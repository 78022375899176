<template>
  <VContainer>
    <VRow>
      <VCol cols="12">
        <VForm @submit.prevent="search">
          <VTextField v-model="searchParams.query" label="検索ワード" />
          <VBtn type="submit">検索</VBtn>
        </VForm>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <VDataTable :headers="headers" :items="companies" @click:row="$event => $router.push({name: 'CompanyEditPage', params: {id: $event.id }})"/>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { UserFullTextSearchCompaniesQueryService } from "hfapi/jtj"

export default {
  data() {
    return {
      searchParams: new UserFullTextSearchCompaniesQueryService.BuildableParams(),
      companies: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Official Name", value: "official_name" },
      ]
    }
  },
  methods: {
    search() {
      if(!this.searchParams.query){
        this.flash("検索ワードを入力してください", "error")
        return
      }

      if(this.$route.query.search != this.searchParams.query) this.$router.push({query: {search: this.searchParams.query}})

      UserFullTextSearchCompaniesQueryService.execute(this.searchParams).then(resp=>{
        this.companies = resp.data || []
      }).catch(e =>{
        this.flash(`検索に失敗しました\n ${e.message}`, "error")
      })
    }
  },
  mounted() {
    this.searchParams.query = this.$route.query.search
    if(this.searchParams.query){ this.search() }
  },
}
</script>

<style>

</style>
