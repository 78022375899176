
<template>
  <VContainer>
    <h2>会社情報を登録できます。</h2>
    <VChip v-if="simple" color="warning">簡易版：正式名称だけが必須項目になっています．</VChip>
    <div class="btns">
      <VBtn @click="submit" class="success mb-3">全て保存</VBtn>
      <VBtn @click="submitja">日本語だけ保存</VBtn>
    </div>
    <VRow>
      <VCol cols="6">
        <h3>日本語</h3>
        <CompanyRegisterLangForm :companyParams="companyParams" lang="ja" ref="jaForm" :simple="simple"/>
      </VCol>
      <VCol cols="6">
        <h3>英語</h3>
        <CompanyRegisterLangForm :companyParams="companyParams" lang="en" ref="enForm" :simple="simple"/>
      </VCol>
    </VRow>
    <VBtn @click="submit" class="success mb-3">全て保存</VBtn>
    <VBtn @click="submitja">日本語だけ保存</VBtn> <br>

  </VContainer>
</template>

<script>
import { HfMemberRegisterCompanyCommandService } from "hfapi"
import Company from "@/aggregates/company"
import CompanyRegisterLangForm from "@@/components/organisms/companies/CompanyRegisterLangForm"

export default {
   metaInfo() {
    return {
      title: `${this.company.official_name}-企業編集ページ`,
    }
  },
  components: {
    CompanyRegisterLangForm
  },
  data(){
    return {
      company: new Company(this.$route.params.id),
      // companyParams: new HfMemberRegisterCompanyCommandService.Params(),
      companyParams: new HfMemberRegisterCompanyCommandService.BuildableParams(),
    }
  },
  methods: {
    submit(){
      if ( !this.$refs.jaForm.validate() || !this.$refs.enForm.validate() ) {
        this.flash("入力していない項目があります。", "error")
        return
      }
      this.register()
    },
    submitja(){
      if ( !this.$refs.jaForm.validate() ) {
        this.flash("入力していない項目があります。", "error")
        return
      }
      this.register()
    },
    register(){
      this.companyParams.company.id = this.$route.params.id
      if (this.companyParams.company.id == null){
        this.companyParams.company.images = []
        this.companyParams.company.logo = null
      }
      if (this.companyParams.company.logo?.key == null){
        this.companyParams.company.logo = null
      }
      HfMemberRegisterCompanyCommandService.execute(this.companyParams).then(resp=>{
        this.company.fetch(resp.data.id)
        this.$router.push({ name: "CompanyEditPage", params: {id: resp.data.id } })
        this.flash("保存に成功しました", "success")
        this.company.fetch()
        Object.assign(this.companyParams.company, this.company)
      }).catch(e=>{
        this.flash(e.response.data, "error")
      })
    },
  },
  computed: {

    simple(){
      return !!this.$route.query.simple
    }
  },
  mounted(){
    this.company.after_assign = ()=>{
      Object.assign(this.companyParams.company, this.company)
      this.$refs.enForm.initializeParams()
      this.$refs.jaForm.initializeParams()
    }
  },
  // beforeRouteUpdate(to, from , next){
  //   console.log("あいう");
  //   this.$set(this.companyParams, new HfMemberRegisterCompanyCommandService.Params())
  //   this.company.fetch(to.params.id)
  //   next()
  // }
}
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10%;
  right: 5%;
  z-index: 1000;
}
</style>
