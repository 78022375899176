<template>
  <div>
    <div v-for="recruitment in recruitments" :key="recruitment.id" class="mb-5">
      <RecruitmentCard :recruitment="recruitment" @click="didClickRecruitmentCard(recruitment)"/>
    </div>
  </div>
</template>

<script>
import RecruitmentCard from "./RecruitmentCard"
export default {
  components: {
    RecruitmentCard
  },
  props: {
    recruitments: {
      type: Array,
      default: ()=>[]
    }
  },
  methods: {
    didClickRecruitmentCard(recruitment){
      this.$emit("clickRecruitmentCard", recruitment)
    }
  }
}
</script>

<style scoped>
</style>
