<template>
  <div>
    <p class="warning--text">Gmailの送信画面へ移ります</p>
    <VBtn @click="send" color="primary">履歴書を催促するメールを送信する</VBtn>
  </div>
</template>

<script>
import { HfMemberGetResumeRequestForUserMailQueryService, HfMemberGetGmailUrlCommandService } from 'hfapi/jtj'

export default {
  props: {
    application: {
      type: Object
    }
  },
  explanation: "履歴書を催促するメールを送る．",
  methods: {
    async send(){
      const mailParams = new HfMemberGetResumeRequestForUserMailQueryService.Params
      mailParams.application_id = this.application.id
      const resp = await HfMemberGetResumeRequestForUserMailQueryService.execute(mailParams)
      const gmailUrlParams = new HfMemberGetGmailUrlCommandService.Params
      gmailUrlParams.mail = resp.data
      const gmailUrlResp = await HfMemberGetGmailUrlCommandService.execute(gmailUrlParams)
      window.open(gmailUrlResp.data, '_blank')
    }
  }
}
</script>

<style>

</style>