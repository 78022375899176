import { Aggregates } from "hfapi";
import moment from "moment"

class Article extends Aggregates.Article {
  publishAtText() {
    return moment(this.publish_at).format("YYYY年MM月DD日")
  }
  titleMarkdown() {
    return "# " + this.title_text
  }
  isPublished() {
    return new Date(this.publish_at) > Date.now()
  }
}

export default Article;
