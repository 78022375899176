import { render, staticRenderFns } from "./ResumeQuestionRegister.vue?vue&type=template&id=2f821dc8&scoped=true"
import script from "./ResumeQuestionRegister.vue?vue&type=script&lang=js"
export * from "./ResumeQuestionRegister.vue?vue&type=script&lang=js"
import style0 from "./ResumeQuestionRegister.vue?vue&type=style&index=0&id=2f821dc8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_ejs@3.1.10_vue-template-compiler@2.6.11_webpack@5.90.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f821dc8",
  null
  
)

export default component.exports