<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px" >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field :value="value" :label="label" readonly v-bind="attrs" v-on="on" :rules="rules" clearable @click:clear="clear"/> <br>
    </template>
    <v-date-picker ref="picker" :value="value" :max="moment().add(10, 'Y').format('YYYY-MM-DD')" :min="moment().subtract(100, 'Y').format('YYYY-MM-DD')" @change="$emit('change', $event)" />
  </v-menu>
</template>

<script>

export default {
  props: {
    label: { default: "Date" },
    value: {},
    rules: {
      type: Array,
      default: ()=>[]
    }
  },
  model: {
    event: 'change'
  },
  data() {
    return {
      // date: null,
      menu: false,
    };
  },
  methods: {
    clear(){
      this.$emit('change', null)
    },
    save(date) {
      this.$refs.menu.save(date);
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
};
</script>

<style>
</style>