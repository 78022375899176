"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "search_user_query": { "id": null, "filter_name": null, "is_living_in_japan": null, "minimum_japanese_level": null, "maximum_age": null, "study_department_category_ids": null, "study_department_category_null": null, "home_country_ids": null, "home_country_null": null, "interesting_job_category_ids": null, "interesting_job_category_null": null, "skill_ids": null, "skill_null": null, "graduate_nendo_years": null, "free_texts": null, "genders": null } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "search_user_query": { "id": null, "filter_name": null, "is_living_in_japan": null, "minimum_japanese_level": null, "maximum_age": null, "study_department_category_ids": [], "study_department_category_null": null, "home_country_ids": [], "home_country_null": null, "interesting_job_category_ids": [], "interesting_job_category_null": null, "skill_ids": [], "skill_null": null, "graduate_nendo_years": null, "free_texts": null, "genders": [] } });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/hf_member__register_search_user_query", { params });
    }
};
