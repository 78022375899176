import { render, staticRenderFns } from "./RecruitmentShowSimpleContent.vue?vue&type=template&id=ade06498"
import script from "./RecruitmentShowSimpleContent.vue?vue&type=script&lang=js"
export * from "./RecruitmentShowSimpleContent.vue?vue&type=script&lang=js"
import style0 from "./RecruitmentShowSimpleContent.vue?vue&type=style&index=0&id=ade06498&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_ejs@3.1.10_vue-template-compiler@2.6.11_webpack@5.90.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports