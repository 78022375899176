<template>
  <div>
    <RecruitmentSearch @clickRecruitmentCard="open"/>
  </div>
</template>

<script>
import RecruitmentSearch from "@@/components/organisms/recruitments/RecruitmentSearch.vue"
export default {
   metaInfo() {
    return {
      title: `求人検索ページ`,
    }
  },
  components: {
    RecruitmentSearch,
  },
  methods: {
    open(recruitment){
      window.open(`/#/recruitments/${recruitment.id}`, "_blank")
    }
  }
}
</script>

<style>

</style>