<template>
  <div>
    <VTextField label="email" v-model="hfMember.email"/>
    <VTextField label="phone_num" v-model="hfMember.phone_num"/>
    <VTextField label="first_name_ja" v-model="hfMember.first_name_ja"/>
    <VTextField label="last_name_ja" v-model="hfMember.last_name_ja"/>
    <VTextField label="first_name_en" v-model="hfMember.first_name_en"/>
    <VTextField label="last_name_en" v-model="hfMember.last_name_en"/>
    <VTextField label="nick_name_ja" v-model="hfMember.nick_name_ja"/>
    <VTextField label="nick_name_en" v-model="hfMember.nick_name_en"/>
    <CheckboxDefaultFalse label="is_manager_of_applications" v-model="hfMember.is_manager_of_applications"/>
    <VTextField label="trello_id" v-model="hfMember.trello_id"/>
    <VTextField label="notion_id" v-model="hfMember.notion_id" hint="ハイフンは消してください"/>
    <VTextField label="slack_id" v-model="hfMember.slack_id"/>
    <VTextField label="available_times_google_calendar_id" v-model="hfMember.available_times_google_calendar_id"/>
    <VTextField label="password" v-model="hfMember.password" :disabled="!isEditablePassword"/>
  </div>
</template>

<script>
export default {
  props: {
    hfMemberParams: {
      type: Object,
    },
  },
  computed: {
    isEditablePassword(){
      if(this.hf_member.id == this.hfMember.id || this.hfMember.id == null) return true
      if(Number(this.hf_member.id) == 2) return true
      return false
    },
    hfMember() {
      return this.hfMemberParams.hf_member
    }
  },
  created () {
    this.hfMemberParams.hf_member.is_manager_of_applications = false;
  },
}
</script>

<style>

</style>