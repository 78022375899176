<template>
  <VContainer>
    <h2>{{t('upload_resume_face_image')}}</h2>
    <VRow>
      <VCol cols="4" md="2">
        <h4>Example</h4>
        <VImg src="https://hfapi-production.s3-ap-northeast-1.amazonaws.com/manual_public/cv_face_image_example1.jpg"/>
      </VCol>
      <VCol cols="8" md="10">
        <h4>Required</h4>
        <ul>
          <li>Background color should be white, gray, or light blue.</li>
          <li>Don't wear a hat.</li>
        </ul>
      </VCol>
    </VRow>
    <VRow>
      <VCol>
        <ResumeFaceImage v-model="imageFile" ref="form"/>
        <VBtn @click="upload" class="mt-2">Upload</VBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import ResumeFaceImage from "@@/components/organisms/users/forms/ResumeFaceImage"
import {UserUploadResumeFaceImageCommandService} from "hfapi"

export default {
  components: {
    ResumeFaceImage,
  },
  props: {
    user_id: { },
  },
  data(){
    return {
      imageFile: null,
    }
  },
  methods: {
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      return this.$refs.form.validate()
    },
    upload(){
      if (this.validate()){
        const reader = new FileReader();
        reader.readAsDataURL(this.imageFile)
        reader.addEventListener("load", ()=>{
          const params = new UserUploadResumeFaceImageCommandService.Params
          params.user.id = this.user_id
          params.user.resume_face_image = reader.result
          UserUploadResumeFaceImageCommandService.execute(params).then(()=>{
            this.emit()
          })
        }, false)
      }
    },
    emit(){
      this.$emit("uploaded")
    }
  }
}
</script>

<style>

</style>