<template>
  <VCheckbox :input-value="value" v-on="$listeners" v-bind="$attrs" @change="change"/>
</template>

<script>
export default {
  props: [ "value" ],
  methods: {
    change(value) {
      this.$emit('input', value)
    }
  },
  created(){
    this.$emit('input', this.value || false)
  }
}
</script>

<style>

</style>
