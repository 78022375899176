<template>
  <div>
    <MasterSelectField :label="t('country_id')" v-model="company_annually_foreigner_datum.country_id" :items="master('countries')" :rules="[validation.required]"/>
    <VTextField :label="t('nendo_year')" v-model="company_annually_foreigner_datum.nendo_year" type="number" :rules="[validation.required]"/>
    <VTextField :label="t('num_of_technical_trainee')" v-model="company_annually_foreigner_datum.num_of_technical_trainee" type="number"/>
    <VTextField :label="t('num_of_full_time')" v-model="company_annually_foreigner_datum.num_of_full_time" type="number"/>
    <VTextField :label="t('num_of_former_intl_students')" v-model="company_annually_foreigner_datum.num_of_former_intl_students" type="number"/>
    <CheckboxDefaultFalse :label="t('have_employed_intl_student')" v-model="company_annually_foreigner_datum.have_employed_intl_student"/>
  </div>
</template>

<script>
export default {
  props: {
    company_annually_foreigner_datum: {
      type: Object
    }
  },
  methods: {
    t(key) {
      return this.$t(`aggregates.CompanyColumnNames.${key}`)
    }
  },
}
</script>

<style>

</style>