<template lang="">
  <div>
    ＊翻訳項目で入れられないものがあれば「-」などを登録ください。
    <VForm ref="form">
      <VTextField label="id" v-model="university.id" disabled />
      <VTextField label="local_name" v-model="university.local_name" />
      <VTextField label="english_name" v-model="university.english_name" />
      <MasterSelectField label="country_id" v-model="university.country_id" :items="master('countries')" />
      <MasterSelectField label="prefecture_id" v-model="university.prefecture_id" :items="master('prefectures')" />
      <VTextField label="world_ranking" v-model="university.world_ranking" type="number" />
      <VTextField label="uni_rank_id" v-model="university.uni_rank_id" />
      <CheckboxDefaultFalse label="is_apporved_by_hf_member" v-model="university.is_apporved_by_hf_member" />
      <CheckboxDefaultFalse label="is_created_by_user" v-model="university.is_created_by_user" />
      <VTextField label="local_ranking" v-model="university.local_ranking" type="number" />
      <VTextField label="description" v-model="university.description" />
      <MasterSelectField label="university_control_type_id" v-model="university.university_control_type_id" :items="master('university_control_types')" />
    </VForm>
    <VBtn @click="register" color="success">保存</VBtn>
  </div>
</template>
<script>
import { HfMemberUpdateUniversityCommandService, GetUniversitiesQueryService } from "hfapi/jtj"

export default {

  data() {
    return {
      params: new HfMemberUpdateUniversityCommandService.BuildableParams()
    }
  },

  computed: {
    id(){
      return this.$route.params.id
    },
    university(){
      return this.params.university
    }
  },
  methods: {
    initialize_university() {
      if(!this.id){ return } // idがなければ不要
      let queryParams = new GetUniversitiesQueryService.BuildableParams()
      queryParams.q.id_eq = this.id
      this.loading = true
      GetUniversitiesQueryService.execute(queryParams).then(resp=>{
        Object.assign(this.params.university, resp.data[0])
        this.loading = false
      })
    },
    register(){
      if(!this.$refs.form.validate()){ return }

      this.loading = true
      HfMemberUpdateUniversityCommandService.execute(this.params).then(resp=>{
        Object.assign(this.params.university, resp.data)
        this.flash("保存に成功しました", "success")
        this.$router.push({name: 'MasterUniversityEditPage', params: { table_name: "universities", id: this.university.id } })
      }).catch(e=>{
        console.log(e)
      }).finally(()=>{
        this.loading = false 
      })
    }
  },
  created() {
    this.initialize_university()
  },
}
</script>
<style lang="">
  
</style>