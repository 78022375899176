"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "resume_question": { "id": null, "title_for_hf_member": null, "title": { "ja": null, "en": null }, "purpose": { "ja": null, "en": null }, "explanation": { "ja": null, "en": null }, "default_template_id": null, "is_allow_reuse": null, "disabled": null, "using_frequency": null, "default_is_display_in_resume": null, "default_num_of_approximate_characters_ja": null, "default_num_of_approximate_words_en": null, "resume_question_templates": [{ "id": null, "template_name": { "ja": null, "en": null }, "template_text": null, "resume_question_id": null, "_destroy": null }] } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "resume_question": { "id": null, "title_for_hf_member": null, "title": { "ja": null, "en": null }, "purpose": { "ja": null, "en": null }, "explanation": { "ja": null, "en": null }, "default_template_id": null, "is_allow_reuse": null, "disabled": null, "using_frequency": null, "default_is_display_in_resume": null, "default_num_of_approximate_characters_ja": null, "default_num_of_approximate_words_en": null, "resume_question_templates": [] } });
    }
    build(key) { return { "resume_question_templates": { "id": null, "template_name": { "ja": null, "en": null }, "template_text": null, "resume_question_id": null, "_destroy": null } }[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/hf_member__register_resume_question", { params });
    }
};
