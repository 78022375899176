<template>
  <div>
    <ResumeQuestionRegister :resumeQuestionId="$route.params.id" @register="onRegister"></ResumeQuestionRegister>
    <ResumeQuestionRecruitments :resumeQuestionId="$route.params.id" />
  </div>
</template>

<script>
import ResumeQuestionRegister from '../../organisms/resumeQuestions/ResumeQuestionRegister.vue'
import ResumeQuestionRecruitments from '../../organisms/resumeQuestions/ResumeQuestionRecruitments.vue'

export default {
  metaInfo() {
    return {
      title: `履歴書用質問新規作成ページ`,
    }
  },
  components: {
    ResumeQuestionRegister,
    ResumeQuestionRecruitments,
  },
  methods: {
    onRegister(resumeQuestion){
      this.$router.push({ name: "ResumeQuestionEditPage", params: {id: resumeQuestion.id } })
    }
  }
}

</script>

<style scoped>
</style>