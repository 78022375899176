<template lang="">
  <VContainer style="background-color: white;">
    <v-progress-circular v-if="loading" indeterminate color="primary" ></v-progress-circular>
    <template v-if="!loading">
    <VTextField v-model="from" label="From / 送信元" disabled/>
    <VTextField v-model="to" label="To / 宛先" disabled/>
    <VTextField v-model="subject" label="Subject / 件名"/>
    <VTextarea v-model="body" label="Body / 本文" rows="13"/>
    <Base64FileInput label="Attachments / 添付ファイル" v-model="attachments" multiple counter chips/>
    <div class="text-right mt-5">
      <VBtn @click="sendMail" color="primary" width="100px" :loading="sending">送信</VBtn>
      <p v-if="hint" class="my-3 primary--text">{{hint}}</p>
    </div>
    </template>
  </VContainer>
</template>

<script>
import Base64FileInput from "@/components/atoms/filefields/Base64FileInput.vue"
import { HfMemberSendMailCommandService } from "hfapi"

export default {
  components: {
    Base64FileInput
  },
  props: {
    // メールの諸々
    defaultTo: {type: Array, default: ()=>[]},
    defaultFrom: {type: String},
    defaultSubject: {type: String, default: ""},
    defaultBody: {type: String, default: ""},
    defaultAttachments: {type: Array, default: ()=>[]},
    loading: {type: Boolean, default: false},
    hint: {type: String, default: ""}
    // その他
    // mailTypeName: {type: String}, // イベントをemitするときにつけておく?
  },
  data(){
    return {
      to: this.defaultTo,
      from: this.defaultFrom,
      subject: this.defaultSubject,
      body: this.defaultBody,
      attachments: this.defaultAttachments,
      sending: false,
    }
  },
  methods: {
    sendMail(){
      this.sending = true
      let params = new HfMemberSendMailCommandService.Params()
      params.mail = {
        subject: this.subject,
        body: this.body,
        to: this.to,
        attachments: this.attachments,
      }
      HfMemberSendMailCommandService.execute(params).then((resp)=>{
        this.flash("メールの送信に成功しました", "success")
        this.$emit("sent", resp)
      }).catch(e=>{
        this.flash("メールの送信に失敗しました\n"+e.message, "error")
        this.$emit("sendError", e)
      }).finally(()=>{
        this.sending = false
      })
    },
    setDefault(){
      this.to = this.defaultTo
      this.from = this.defaultFrom
      this.subject = this.defaultSubject
      this.body = this.defaultBody
      this.attachments = this.defaultAttachments
    },
  },
  watch: {
    loading(newValue) {
      // 読み込み終了時にdefault値を再度いれる
      if(newValue == false) { this.setDefault() }
    }
  },
}
</script>
<style lang="">
  
</style>