<template>
  <VCard @click="$emit('click')">
    <VImg :src="event.thumbnail_url" class="d-md-none"></VImg>
    <VContainer class="pa-0 pa-md-3">
      <VRow>
        <VCol cols="12" md="4" class="d-none d-md-flex">
          <VImg :src="event.thumbnail_url"></VImg>
        </VCol>
        <VCol cols="12" md="8">
          <VCardTitle>{{event.title[lang]}}</VCardTitle>
          <VCardSubtitle>{{event.startAtStr(lang)}}</VCardSubtitle>
        </VCol>
      </VRow>
    </VContainer>
    <VCardActions>
      <VBtn @click="$router.push({name: 'EventShowPage', params: {id: event.id}})" text color="primary" class="font-weight-bold">Show Detail</VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import Event from "@/aggregates/event"

export default {
  props: {
    event: {
      type: Event
    },
    lang: String,
  }
}
</script>

<style>

</style>