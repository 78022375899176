function arrayToCSVText(twoDiArray) {
  //  Modified from: http://stackoverflow.com/questions/17836273/
  //  export-javascript-data-to-csv-file-without-server-interaction
  var csvRows = [];
  for (var i = 0; i < twoDiArray.length; ++i) {
    for (var j = 0; j < twoDiArray[i].length; ++j) {
      twoDiArray[i][j] = '"' + twoDiArray[i][j] + '"'; // Handle elements that contain commas
    }
    csvRows.push(twoDiArray[i].join(","));
  }

  var csvString = csvRows.join("\r\n");
  return csvString
}

const csvDownload = function (data, filename) {
  // data to csv text
  var csvText = arrayToCSVText(data)
  // UTF BOM
  var bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  // リンククリエイト
  var downloadLink = document.createElement("a");
  downloadLink.download = filename + ".csv";
  // ファイル情報設定
  downloadLink.href = URL.createObjectURL(
    new Blob([bom, csvText], { type: "text/csv" })
  );
  downloadLink.dataset.downloadurl = [
    "text/csv",
    downloadLink.download,
    downloadLink.href,
  ].join(":");
  // イベント実行
  downloadLink.click();
};

export default csvDownload;
