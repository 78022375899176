<template>
  <div>
    <VContainer>
    Hello world.
    This is the top page.
    <p v-if="hf_member">
      Now you are loggging in as {{ hf_member.last_name_ja }}.
    </p>
      <VRow>
        <VCol cols="4">
          <VCard @click="$router.push({name: 'RecommendMailIndexPage'})">
            <VCardTitle>レコメンド一覧へ</VCardTitle>
            <VCardText>レコメンドメールを送れます。</VCardText>
          </VCard>
        </VCol>
        <VCol cols="4">
          <VCard @click="$router.push({name: 'ApplicationsIndexPage'})">
            <VCardTitle>応募一覧へ</VCardTitle>
            <VCardText>応募状況を管理できます。</VCardText>
          </VCard>
        </VCol>
        <VCol cols="4">
          <VCard @click="console.log('ごめんなさい')">
            <VCardTitle>ユーザー一覧へ</VCardTitle>
            <VCardText>(coming soon...)</VCardText>
          </VCard>
        </VCol>
        <VCol cols="4">
          <VCard @click="$router.push({name: 'AvailableDateCopyPage'})">
            <VCardTitle>面談可能日程コピー</VCardTitle>
            <VCardText>空いている日時がコピペできます。 <br> 日程調整に便利。</VCardText>
          </VCard>
        </VCol>
        <VCol cols="4">
          <VCard @click="$router.push({name: 'ArticlesIndexPage'})">
            <VCardTitle>記事一覧へ</VCardTitle>
            <VCardText>記事作成、編集もこちらから</VCardText>
          </VCard>
        </VCol>
        <VCol cols="4">
          <VCard @click="$router.push({name: 'RecruitmentSearchPage'})">
            <VCardTitle>求人検索ページ</VCardTitle>
            <VCardText>お探しの求人を検索できます。</VCardText>
          </VCard>
        </VCol>
        <VCol cols="4">
          <VCard @click="$router.push({name: 'EventsIndexPage'})">
            <VCardTitle>イベント一覧へ</VCardTitle>
            <VCardText>イベント作成、編集もこちらから</VCardText>
          </VCard>
        </VCol>
        <VCol cols="4">
          <VCard @click="$router.push({name: 'SearchRecommendedUsersPage'})">
            <VCardTitle>ユーザー吐き出しページへ</VCardTitle>
            <VCardText>企業にあったユーザーを検索、SpreadSheetへ吐き出しができます。【営業用】もこちらに移行されました。</VCardText>
          </VCard>
        </VCol>
        <VCol cols="4">
          <VCard>
            <VCardTitle>履歴書用質問</VCardTitle>
            <VCardText>履歴書のための質問を管理できます．</VCardText>
            <VCardActions>
              <VBtn :to="{name: 'ResumeQuestionIndexPage'}">一覧</VBtn>
              <VBtn :to="{name: 'ResumeQuestionRegisterPage'}">新規作成</VBtn>
            </VCardActions>
          </VCard>
        </VCol>
        <VCol cols="4">
          <VCard>
            <VCardTitle>HFMember</VCardTitle>
            <VCardText>HfMemberの確認と新規登録が出来ます</VCardText>
            <VCardActions>
              <VBtn :to="{name: 'HfMemberPage'}">一覧</VBtn>
              <VBtn :to="{name: 'HfMemberRegisterPage'}">新規作成</VBtn>
            </VCardActions>
          </VCard>
        </VCol>
        <VCol cols="4">
          <VCard @click="$router.push({name: 'RecruitmentSimpleRegisterPage'})">
            <VCardTitle>簡易求人作成</VCardTitle>
            <VCardText>簡易で求人を作成できます．</VCardText>
          </VCard>
        </VCol>
        <VCol cols="4">
          <VCard>
            <VCardTitle>フォームの作成</VCardTitle>
            <VCardText>フォームが作成できます。</VCardText>
            <VCardActions>
              <VBtn :to="{name: 'HfFormsIndexPage'}">一覧</VBtn>
              <VBtn :to="{name: 'HfFormRegisterPage'}">新規作成</VBtn>
            </VCardActions>
          </VCard>
        </VCol>
        <VCol cols="4">
          <VCard>
            <VCardTitle>Company</VCardTitle>
            <VCardActions>
              <VBtn :to="{name: 'CompanySearchPage'}" >全文検索</VBtn>
              <VBtn :to="{ name: 'CompanyRegisterPage' }" >新規作成</VBtn>
            </VCardActions>
          </VCard>
        </VCol>
      </VRow>
      <VDivider/>
      <VRow>
        <VCol>
          <h3 class="my-4">{{hf_member.last_name_ja}}さんの担当ユーザー一覧</h3>
          <HfMemberUsersList :_hf_member="hf_member"/>
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<script>
import HfMemberUsersList from "@@/components/organisms/hf_members/HfMemberUsersList.vue"

export default {
  components: {
    HfMemberUsersList,
  },
   metaInfo() {
    return {
      title: `トップページ`,
    }
  },
}
</script>

<style>

</style>
