export default {
  title: "【Important things to get a job in Japan / 日本で仕事を得るために大事なこと】",
  body: `*日本語は下にあります

Dear <%= user.first_name %>

This is <%= hf_member.nick_name_en %> who is supporting job-hunting for international students!
Thank you for your registration yesterday.

Today I tried to find a company from your registration information.
However at the moment I couldn't find companies because actually there is no jobs available for people who don't have a bachelor's degree.
So terribly sorry about this.

If you have a bachelor's degree from your home country, please let me know and I will be happy to help you.
In that case, I may have a job that I can refer you to.

I hope that this information is helpful to you.
<%= hf_member.nick_name_en %>

*日本語
<%= user.first_name %> さん

留学生の就職支援をしているJob Tree Japanの<%= hf_member.nick_name_en %>です！

昨日は登録ありがとうございました。

今日は予告していた「<%= user.first_name %> さんにオススメの仕事リスト」をお届けしようと思ったのですが、現状Job Tree Japanでは学士を持っていない方へ紹介できる仕事がありませんでした。
期待させてしまったのにオススメができなくてごめんなさい。

母国で大学を卒業している場合は、ご連絡ください。
その場合は、紹介できる仕事があるかもしれません。

以上となります。
<%= user.first_name %> さんの夢を応援しています！

<%= hf_member.nick_name_en %>`,
};