<template>
  <div>
    <VContainer>
      <!-- 説明部分 -->
      <h1>{{t("notice1")}}</h1>
      <h2 class="error--text">{{t("notice2")}}</h2>
      <p>
        {{t("notice3")}}
      </p>
      <!-- フォーム -->
      <VForm class="mt-8" ref="form">
        <div v-for="field in fields" :key="field.paramKey">
          <TextFieldWithToggleExplanation :header="field.header" :toggleText="field.toggleText" :toggleContents="field.toggleContents" v-model="resumeParams.application.resume[field.paramKey]" :rules="[validation.required]" />
        </div>
      </VForm>

      <div class="text-sm-center">
        <VBtn @click="save" class="primary" x-large>保存</VBtn>
      </div>
    </VContainer>
  </div>
</template>

<script>
import TextFieldWithToggleExplanation from "@@/components/molecules/TextFieldWithToggleExplanation"
import { UserSaveResumeForApplicationCommandService } from "hfapi"
import Application from "@/aggregates/application"

export default {
  components: {
    TextFieldWithToggleExplanation
  },
  props: {
    application: {
      type: Application
    }
  },
  data(){
    return {
      resumeParams: new UserSaveResumeForApplicationCommandService.Params,
      fields: [
        { paramKey: "self_pr", header: this.t("h4_1"), toggleText: this.t("toggle_1"), toggleContents: [ { header: this.t("h5_1_1"), text: this.t("content_1_1")}, { header: this.t("h5_1_2"), text: this.t("content_1_2")}, { header: this.t("h5_1_4"), text: this.t("content_1_4")} ] },
        { paramKey: "skill_pr", header: this.t("h4_2"), toggleText: this.t("toggle_2"), toggleContents: [ { header: this.t("h5_2_1"), text: this.t("content_2_1")}, { header: this.t("h5_2_2"), text: this.t("content_2_2")}, { header: this.t("h5_2_3"), text: this.t("content_2_3")} ] },
        { paramKey: "why_select_company", header: this.t("h4_3"), toggleText: this.t("toggle_3"), toggleContents: [ { header: this.t("h5_3_1"), text: this.t("content_3_1")}, { header: this.t("h5_3_2"), text: this.t("content_3_2")}, { header: this.t("h5_3_3"), text: this.t("content_3_3")}, { header: this.t("h5_3_4"), text: this.t("content_3_4")} ] },
        // { paramKey: "why_select_career", header: this.t("h4_4"), toggleText: this.t("toggle_4"), toggleContents: [ { header: this.t("h5_4_1"), text: this.t("content_4_1")}, { header: this.t("h5_4_2"), text: this.t("content_4_2")}, { header: this.t("h5_4_3"), text: this.t("content_4_3")} ] },
        { paramKey: "why_good_at_job", header: this.t("h4_5"), toggleText: this.t("toggle_5"), toggleContents: [ { header: this.t("h5_5_1"), text: this.t("content_5_1")}, { header: this.t("h5_5_2"), text: this.t("content_5_2")} ] },
        // { paramKey: "about_special_skill", header: this.t("h4_6"), toggleText: this.t("toggle_6"), toggleContents: [ { header: this.t("h5_6_1"), text: this.t("content_6_1")}, { header: this.t("h5_6_2"), text: this.t("content_6_2")}, { header: this.t("h5_6_3"), text: this.t("content_6_3")} ] },
      ]
    }
  },
  methods: {
    t(key, options){
      return this.$t(`pages.applications.ApplicationResumeEditPage.${key}`, options)
    },
    save(){
      if(this.$refs.form.validate()){
        UserSaveResumeForApplicationCommandService.execute( this.resumeParams ).then( () => {
          this.$emit('save')
        } )
      } else {
        this.flash("未入力の項目があります。", "error")
      }
    },
    initializeResumeParams(){
      this.resumeParams.application.id = this.$route.params.id
      Object.keys(this.resumeParams.application.resume).forEach( key => {
        console.log(this.application.resume[key]);
        this.resumeParams.application.resume[key] = this.application.resume[key] || ""
      } )
    }
  },
  created(){
    this.application.after_assign = ()=>{
      this.initializeResumeParams()
    }
  },
}
</script>

<style scoped>
</style>
