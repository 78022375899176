<template>
  <VForm ref="form">
    <h4 class="mt-8 primary--text">企業情報</h4>
    <VAutocomplete
      :label="t('company_id')"
      v-model="recruitment.company_id"
      :items="companies"
      item-text="official_name_ja"
      item-value="id"
      :rules="[ validation.required ]"
    />
    <VBtn small @click="companyRegisterModal=true">企業を簡易作成する</VBtn>
    <p class="caption">＊正式名称，Notion URLだけで作成できます</p>
    <VDialog v-model="companyRegisterModal">
      <VCard>
        <VContainer>
          <CompanySimpleRegisterForm @register="onRegisterCompany"/>
        </VContainer>
      </VCard>
    </VDialog>

    <h4 class="mt-8 primary--text">求人ニーズのNotion URL</h4>
    <NotionUrlField v-model="recruitment.notion_id" :rules="[ validation.required ]" />
    <VBtn v-if="!recruitment.id" small @click="retreieveDataFromNotion" :loading="loading">Notionからデータを入力</VBtn>

    <h4 class="mt-8 primary--text">求人情報</h4>
    <VTextField :label="t('title_id')" v-model="recruitment.title[lang]" :rules="[ validation.required ]" />
    <VTextarea label="内容(markdown)" v-model="recruitment.simple_content[lang]" :rules="[ validation.required ]"/>
    <template v-if="recruitment.id != null">
      <h5>求人イメージ画像</h5>
      <ImagesUpload label="images" :dir="`companies/${recruitment.company_id}/recruitments/${recruitment.id}/`" v-model="recruitment.images"/>
      <p class="caption">＊アップロード後に「SUBMIT」を必ず押してください。</p>
    </template>

    <h4 class="mt-8 primary--text">求人検索のための情報</h4>
    <h5>学部学科</h5>
    <MasterSelectField :label="t('study_department_category_id')" v-model="recruitment.target_study_department_category_ids" :items="master('study_department_categories')" :hint="t('recruitment_target_study_department_categories_hint')" multiple deletable-chips chips persistent-hint/>
    <h5>日本語レベル</h5>
    <VSelect :label="t('required_jlpt')" v-model="recruitment.required_jlpt" :items="['N1', 'N2', 'N3', 'N4', 'N5']"/>
    <MasterSelectField :label="t('speaking_level_id')" v-model="recruitment.required_japanese_level.speaking_level_id" :items="master('speaking_levels')"/>
    <MasterSelectField :label="t('listening_level_id')" v-model="recruitment.required_japanese_level.listening_level_id" :items="master('listening_levels')"/>
    <MasterSelectField :label="t('writing_level_id')" v-model="recruitment.required_japanese_level.writing_level_id" :items="master('writing_levels')"/>
    <MasterSelectField :label="t('reading_level_id')" v-model="recruitment.required_japanese_level.reading_level_id" :items="master('reading_levels')"/>
    <CheckboxDefaultFalse :label="t('is_must')" v-model="recruitment.required_japanese_level.is_must"/>
    <h5>職種</h5>
    <MasterSelectField :label="t('job_category_id')" v-model="recruitment.job_category_ids" multiple deletable-chips chips :items="master('job_categories')" />
    <h5>勤務地</h5>
    <RecruitmentCompanyOfficeForm :recruitmentParams="recruitmentParams" :lang="lang"/>
    <h5>将来の勤務地</h5>
    <RecruitmentCareersForm :recruitmentParams="recruitmentParams" :hiddenFields="['title_id', 'is_work_globaly', 'years_in_first_country', 'description_id']"/>

    <h5>仕事で関わる国</h5>
    <MasterSelectField label="仕事で関わる国" v-model="recruitment.related_country_ids" :items="master('countries')" multiple deletable-chips chips/>

    <h4 class="mt-8 primary--text">履歴書作成用質問</h4>
    <RecruitmentResumeQuestionForm :recruitmentParams="recruitmentParams" @update:recruitmentParams="$emit('update:recruitmentParams', $event)" :resumeQuestions="resumeQuestions" @onResumeQuestionRegister="$emit('onResumeQuestionRegister')"/>


    <h4 class="mt-8 primary--text">公開設定</h4>
    <CheckboxDefaultFalse :label="t('is_released')" v-model="recruitment.is_released"/>

    <h4 class="mt-8 primary--text">トップページへ表示</h4>
    <CheckboxDefaultFalse label="トップページへ表示" v-model="recruitment.is_shown_on_top"/>

    <template v-if="recruitment.is_shown_on_top">
    <h4 class="mt-8">給料について</h4>
    <p>
      ＊トップページに表示する場合は「給料の単位」と最低限1つの「給料の項目」を入力する必要があります。 <br>
      ＊給料が範囲指定の場合は「最大」と「最小」にそれぞれ数値を入れてください。 <br>
      ＊給料が固定の場合は「最大」と「最小」に同じ数値を入れてください。 <br>
    </p>
    <VSelect :label="t('salary_unit')" v-model="recruitment.recruitment_detail.salary_unit" :items="[{text: '月収', value: 'month'}, {text: '年収', value: 'year'}]"/>
    <VTextField :label="t('salary_min_for_professional_school_students')" v-model="recruitment.recruitment_detail.salary_min_for_professional_school_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_max_for_professional_school_students')" v-model="recruitment.recruitment_detail.salary_max_for_professional_school_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_min_for_bachelor_students')" v-model="recruitment.recruitment_detail.salary_min_for_bachelor_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_max_for_bachelor_students')" v-model="recruitment.recruitment_detail.salary_max_for_bachelor_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_min_for_master_students')" v-model="recruitment.recruitment_detail.salary_min_for_master_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_max_for_master_students')" v-model="recruitment.recruitment_detail.salary_max_for_master_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_min_for_doctor_students')" v-model="recruitment.recruitment_detail.salary_min_for_doctor_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_max_for_doctor_students')" v-model="recruitment.recruitment_detail.salary_max_for_doctor_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_min_for_mid_career')" v-model="recruitment.recruitment_detail.salary_min_for_mid_career" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_max_for_mid_career')" v-model="recruitment.recruitment_detail.salary_max_for_mid_career" type="number" hint="単位は円で。'100000' と数字だけで入力"/>

    </template>
  </VForm>
</template>

<script>
import { HfMemberRegisterSimpleRecruitmentCommandService } from "hfapi/jtj"
import NotionRecruitment from "@@/lib/notion_models/recruitment.js"
import NotionUrlField from '../../molecules/NotionUrlField.vue'
import RecruitmentResumeQuestionForm from './RecruitmentResumeQuestionForm.vue'
import CompanySimpleRegisterForm from '@@/components/organisms/companies/CompanySimpleRegisterForm.vue'
import RecruitmentCompanyOfficeForm from '@@/components/organisms/recruitments/RecruitmentRegisterForms/RecruitmentCompanyOfficeForm'
import RecruitmentCareersForm from '@@/components/organisms/recruitments/RecruitmentRegisterForms/RecruitmentCareersForm'
import ImagesUpload from '@@/components/organisms/shared/ImagesUpload.vue'

export default {
  components: {
    NotionUrlField,
    RecruitmentResumeQuestionForm,
    CompanySimpleRegisterForm,
    RecruitmentCompanyOfficeForm,
    RecruitmentCareersForm,
    ImagesUpload,
  },
  props: {
    recruitmentParams: {
      type: [HfMemberRegisterSimpleRecruitmentCommandService.Params, HfMemberRegisterSimpleRecruitmentCommandService.BuildableParams],
    },
    lang: {
      type: String,
    },
    resumeQuestions: {
      type: Array,
      required: true,
    },
    companies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      companyRegisterModal: false,
      loading: false,
    }
  },
  methods: {
    t(key){
      return this.$t(`aggregates.RecruitmentColumnNames.${key}`)
    },
    validate(){
      return this.$refs.form.validate()
    },
    onRegisterCompany(){
      this.companyRegisterModal = false
      this.$emit("registerCompany")
    },
    retreieveDataFromNotion(){
      this.loading = true
      const notionRecruitment = new NotionRecruitment(this.recruitment.notion_id)
      notionRecruitment.addCallback("assigned", ()=>{
        notionRecruitment.setDataToJTJ(this.recruitment, ["title", "simple_content", "target_study_department_category_ids", "required_jlpt", "required_japanese_level", "job_category_ids", "recruitment_company_offices", "related_country_ids", "recruitment_careers"])
        console.log("assigned", this.recruitment)
        this.loading = false
        this.$forceUpdate()
      })
    }
  },
  computed: {
    recruitment(){ return this.recruitmentParams.recruitment },
  }
}


</script>

<style>

</style>
