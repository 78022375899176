<template>
  <VForm ref="form">
    <h3>{{t('name')}}</h3>
    <VRow>
      <VCol v-if="!hiddenFields.includes('first_name')" cols="6">
        <VTextField
          dense
          outlined
          hide-details
          :rules="[validation.required]" v-model="value.first_name"
          label="First name"
        />
      </VCol>
      <VCol v-if="!hiddenFields.includes('last_name')" cols="6">
        <VTextField
          dense
          outlined
          hide-details
          v-model="value.last_name"
          label="Last name"
        />
      </VCol>
      <VCol v-if="!hiddenFields.includes('first_name_kana')" cols="6">
        <VTextField
          dense
          outlined
          hide-details
          :rules="[validation.required]" v-model="value.first_name_kana"
          label="めい"
        />
      </VCol>
      <VCol v-if="!hiddenFields.includes('last_name_kana')" cols="6">
        <VTextField
          dense
          outlined
          hide-details
          :rules="[validation.required]" v-model="value.last_name_kana"
          label="せい"
        />
      </VCol>
    </VRow>
    <VRow>
      <VCol v-if="!hiddenFields.includes('gender')">
        <h3 class="mb-3">{{t('gender')}}</h3>
        <VRadioGroup mandatory :rules="[validation.required]" v-model="value.gender">
          <VRadio value="man" :label="t('man')" />
          <VRadio value="woman" :label="t('woman')" />
          <VRadio value="other" :label="t('other')" />
        </VRadioGroup>
      </VCol>
    </VRow>
    <VRow>
      <VCol v-if="!hiddenFields.includes('phone_num')">
        <h3 class="mb-3">{{t('phone_num')}}</h3>
        <VTextField dense outlined :rules="[validation.required]" v-model="value.phone_num" type="number" />
      </VCol>
    </VRow>
    <VRow>
      <VCol v-if="!hiddenFields.includes('birth_day')">
        <h3 class="mb-3">{{t('birth_day')}}</h3>
        <SteppedDatePicker :rules="[validation.required]" v-model="value.birth_day" :label="t('birth_day')"/>
      </VCol>
    </VRow>
    <VRow>
      <VCol v-if="!hiddenFields.includes('has_driver_license')">
        <h3 class="mb-3">{{t('has_driver_license')}}</h3>
        <VCheckbox :rules="[validation.required]" v-model="value.has_driver_license" :label="t('yes')" />
      </VCol>
    </VRow>
    <VRow>
      <VCol v-if="!hiddenFields.includes('living_prefecture_id')">
        <LivingPrefectureOrCountry :rules="[validation.required]" v-model="value"/>
      </VCol>
    </VRow>
    <VRow>
      <VCol v-if="!hiddenFields.includes('address')">
        <h3>{{t('living_place')}}</h3>
        <VTextField :label="t('address')" v-model="value.address" :rules="[validation.required]"></VTextField>
      </VCol>
    </VRow>
    <VRow>
      <VCol v-if="!hiddenFields.includes('home_country')">
        <h3 class="mb-3">{{t('home_country')}}</h3>
        <MasterSelectField :label="t('home_country')" v-model="value.home_country_id" :items="master('countries')" />
      </VCol>
    </VRow>
  </VForm>
</template>

<script>
import SteppedDatePicker from '@@/components/molecules/SteppedDatePicker'
import LivingPrefectureOrCountry from "@@/components/organisms/users/forms/LivingPrefectureOrCountry"
export default {
  props: {
    value: {},
    hiddenFields: {
      type: Array,
      default: ()=>[]
    }
  },
  components: { SteppedDatePicker, LivingPrefectureOrCountry },
  methods: {
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      return this.$refs.form.validate()
    },
  },
  created(){
    this.value.has_driver_license = this.value.has_driver_license || false
  }
};
</script>

<style>
</style>