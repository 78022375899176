<template>
  <VForm ref="form">
    <MasterSelectField
      :rules="[validation.required]"
      v-if="!hiddenFields.includes('schoolCountryId')"
      dense
      outlined
      :label="t('university_country')"
      :disabled="disabled"
      :items="master('countries')"
      v-model="schoolCountryId"
      @change="getUniversities"
    />
    <UniversitySelect
      :rules="[validation.required]"
      v-if="!hiddenFields.includes('university_id')"
      dense
      outlined
      :items="masterData.universities"
      v-model="value"
      :disabled="!schoolCountryId || disabled"
      :label="t('university')"
    />
    <VAutocomplete
      :rules="[validation.required]"
      v-if="!hiddenFields.includes('study_department_category_id')"
      dense
      outlined
      :items="master('study_department_categories')"
      item-value="id"
      item-text="name"
      v-model="value.study_department_category_id"
      :label="t('study_department_category')"
      :disabled="disabled"
    />
    <VSelect
      :rules="[validation.required]"
      v-if="!hiddenFields.includes('university_academic_degree_id')"
      dense
      outlined
      :items="master('university_academic_degrees')"
      :label="t('university_academic_degree')"
      :disabled="disabled"
      item-text="name"
      item-value="id"
      v-model="value.university_academic_degree_id"
    />
    <VSelect
      :rules="[validation.required]"
      v-if="!hiddenFields.includes('university_student_category_id')"
      dense
      outlined
      :items="master('university_student_categories')"
      :label="t('university_student_category')"
      :disabled="disabled"
      item-text="name"
      item-value="id"
      v-model="value.university_student_category_id"
    />
    <SteppedDatePicker
      :rules="[validation.required]"
      v-if="!hiddenFields.includes('enroll_at')"
      v-model="value.enroll_at"
      :label="t('enroll_at')"
      :disabled="disabled"
    />
    <SteppedDatePicker
      :rules="[validation.required]"
      v-if="!hiddenFields.includes('graduate_at')"
      v-model="value.graduate_at"
      :label="t('graduate_at')"
      :disabled="disabled"
    />
  </VForm>
</template>

<script>
import {
  GetUniversitiesQueryService,
  UserUpdateProfileCommandService,
} from "hfapi";
import { JAPAN_COUNTRY_ID } from "@@/config/constants";
import MasterSelectField from "@@/components/organisms/shared/MasterSelectField";
import SteppedDatePicker from "@@/components/molecules/SteppedDatePicker";
import UniversitySelect from "@@/components/organisms/users/forms/school/UniversitySelect.vue"

let p = new UserUpdateProfileCommandService.Params();
export default {
  props: {
    value: {
      default: () => Object.assign({}, p.user.user_universities[0]),
    },
    hiddenFields: {
      type: Array,
      default: ()=>[]
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MasterSelectField,
    SteppedDatePicker,
    UniversitySelect,
  },
  data() {
    return {
      masterData: {
        universities: [],
      },
      selectedSchoolCategoryId: 1,
      schoolCountryId: null,
    };
  },
  methods: {
    getUniversities() {
      let params = new GetUniversitiesQueryService.Params();
      params.q.country_id_eq = this.schoolCountryId;

      GetUniversitiesQueryService.execute(params).then((resp) => {
        this.masterData.universities = resp.data;
      });
    },
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      return this.$refs.form.validate()
    },
  },
  created() {
    console.log("UniversityForm created");
    this.schoolCountryId = this.value.university?.country_id || JAPAN_COUNTRY_ID
    this.getUniversities();
  },
};
</script>

<style>
</style>
