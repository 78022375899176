<template>
  <VForm ref="form">
    <h4 class="mt-8 mb-2">基本情報</h4>
    <VTextField :label="t('official_name_ja')" v-model="company.official_name_ja" :rules="[validation.required]"/>
    <VTextField :label="t('official_name_en')" v-model="company.official_name_en" :rules="[validation.required]"/>
    <NotionUrlField v-model="company.notion_id" :rules="[validation.required]" hint="(将来的に自動でNotionからプロパティを取得するようにします)"/>
    <template v-if="company.id != null">
      <h5>アップロード後に提出が必要です</h5>
      <ImageUpload label="logo" :dir="`companies/${company.id}/`" v-model="company.logo.key" class="mb-3"/>
      <ImagesUpload label="company images" :dir="`companies/${company.id}/`" v-model="company.images" class="mb-3"/>
    </template>
    <MasterSelectField :label="t('company_industry_sector')" v-model="company.industry_sector_ids" multiple deletable-chips chips :items="master('industry_sectors')" ref="master_select" :rules="[validation.required]"></MasterSelectField>
    <VTextField :label="t('establishment_nendo_year')" v-model="company.establishment_nendo_year" type="number" :rules="simple ? [] : [validation.required]"/>
    <VTextarea :label="t('business_description_id')" v-model="company.business_description[lang]" :rules="simple ? [] : [validation.required]"/>
    <VTextarea :label="t('appeal_point_id')" v-model="company.appeal_point[lang]" :rules="simple ? [] : [validation.required]"/>
    <VTextarea :label="t('vision_id')" v-model="company.vision[lang]"/>

    <h4 class="mt-8 mb-2">企業担当者情報</h4>
    <VCard v-for="company_employee in company.company_employees" :key="company_employee.id" class="mb-2">
      <VContainer>
        <VRow v-if="!company_employee._destroy">
          <VCol cols="12">
            <CompanyEmployeeForm :company_employee="company_employee"/>
          </VCol>
          <VCol cols="2">
            <VBtn class="error" @click="remove(company.company_employees, company_employee)"><VIcon>mdi-trash</VIcon>担当者情報を削除</VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VBtn @click="add('company_employees')">担当者情報追加</VBtn>
    <h4 class="mt-8 mb-2">CC連絡先</h4>
    <VCard v-for="company_cc_email in company.company_cc_emails" :key="company_cc_email.id" class="mb-2">
      <VContainer>
        <VRow v-if="!company_cc_email._destroy">
          <VCol cols="12">
            <VTextField :label="t('email')" v-model="company_cc_email.email" :rules="[validation.required]"/>
          </VCol>
          <VCol cols="2">
            <VBtn class="error" @click="remove(company.company_cc_emails, company_cc_email)"><VIcon>mdi-trash</VIcon>CCを削除</VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VBtn @click="add('company_cc_emails')">追加</VBtn>


    <h4 class="mt-8 mb-2">年度毎に更新される情報</h4>
    <VCard v-for="company_annually_datum in company.company_annually_data" :key="company_annually_datum.id" class="mb-2">
      <VContainer>
        <VRow v-if="!company_annually_datum._destroy">
          <VCol cols="12">
            <CompanyAnnuallyDatum :company_annually_datum="company_annually_datum"/>
          </VCol>
          <VCol cols="2">
            <VBtn class="error" @click="remove(company.company_annually_data, company_annually_datum)"><VIcon>mdi-trash</VIcon>年度情報を削除</VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VBtn @click="add('company_annually_data')">年度情報を追加</VBtn>

    <h4 class="mt-8 mb-2">外国人雇用データ</h4>
    <VCard v-for="company_annually_foreigner_datum in company.company_annually_foreigner_data" :key="company_annually_foreigner_datum.id" class="mb-2">
      <VContainer>
        <VRow v-if="!company_annually_foreigner_datum._destroy">
          <VCol cols="12">
            <CompanyAnnuallyForeignerDatum :company_annually_foreigner_datum="company_annually_foreigner_datum"/>
          </VCol>
          <VCol>
            <VBtn class="error" @click="remove(company.company_annually_foreigner_data, company_annually_foreigner_datum)"><VIcon>mdi-trash</VIcon>削除</VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VBtn @click="add('company_annually_foreigner_data')">他の国を追加</VBtn>

    <h4 class="mt-8 mb-2">ボーナスについて</h4>
    <VTextField :label="t('bonus_months_worth')" v-model="company.bonus_months_worth" :hint="t('bonus_months_worth_hint')" persistent-hint/>
    <VSelect :label="t('bonus_paid_months')" v-model="bonus_paid_months" :items="[1,2,3,4,5,6,7,8,9,10,11,12]" multiple deletable-chips chips></VSelect>

    <h4 class="mt-8 mb-2">休日について</h4>
    <VTextField :label="t('holiday_description_id')" v-model="company.holiday_description[lang]"/>
    <VTextField :label="t('paid_leave_description_id')" v-model="company.paid_leave_description[lang]"/>

    <h4 class="mt-8 mb-2">宗教への配慮</h4>
    <MasterSelectField :label="t('company_religious_consideration')" v-model="company.religious_consideration_ids" :items="master('religious_considerations')" ref="master_select" multiple deletable-chips chips></MasterSelectField>
    
    <h4 class="mt-8 mb-2">住宅サポート</h4>
    <MasterSelectField :label="t('company_housing_support')" v-model="company.housing_support_ids" :items="master('housing_supports')" :hint="t('company_housing_support_hint')" persistent-hint multiple deletable-chips chips ref="master_select"></MasterSelectField>


    <h4 class="mt-8 mb-2">企業と国の関係性</h4>
    <VCard v-for="company_country_relation in company.company_country_relations" :key="company_country_relation.id" class="mb-2">
      <VContainer>
        <VRow v-if="!company_country_relation._destroy">
          <VCol cols="12">
            <CompanyCountryRelation :company_country_relation="company_country_relation"/>
          </VCol>
          <VCol cols="2">
            <VBtn class="error" @click="remove(company.company_country_relations, company_country_relation)"><VIcon>mdi-trash</VIcon>削除</VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VBtn @click="add('company_country_relations')">国との関係を追加</VBtn>

    <h4 class="mt-8 mb-2">事業所一覧</h4>
    <VCard v-for="company_office in company.company_offices" :key="company_office.id" class="mb-2">
      <VContainer>
        <VRow v-if="!company_office._destroy">
          <VCol cols="12">
            <CompanyOffice :company_office="company_office" :lang="lang"/>
          </VCol>
          <VCol cols="2">
            <VBtn class="error" @click="remove(company.company_offices, company_office)"><VIcon>mdi-trash</VIcon>事業所を削除</VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VBtn @click="add('company_offices')">事業所を追加</VBtn>


    <h4 class="mt-8 mb-2">福利厚生について</h4>
    <VTextarea :label="t('employee_welfare_description_id')" v-model="company.employee_welfare_description[lang]"/>

    <h4 class="mt-8 mb-2">保険について</h4>
    <MasterSelectField :label="t('company_insurance')" v-model="company.insurance_ids" :items="master('insurances')" ref="master_select" multiple deletable-chips chips></MasterSelectField>
    
  </VForm>
</template>

<script>
import { HfMemberRegisterCompanyCommandService } from "hfapi"
import CompanyEmployeeForm from "@@/components/organisms/companies/CompanyRegisterForms/CompanyEmployeeForm.vue"
import CompanyOffice from "@@/components/organisms/companies/CompanyRegisterForms/CompanyOffice"
import CompanyAnnuallyDatum from "@@/components/organisms/companies/CompanyRegisterForms/CompanyAnnuallyDatum"
import CompanyAnnuallyForeignerDatum from "@@/components/organisms/companies/CompanyRegisterForms/CompanyAnnuallyForeignerDatum"
import CompanyCountryRelation from "@@/components/organisms/companies/CompanyRegisterForms/CompanyCountryRelation"
import ImageUpload from '@@/components/organisms/shared/ImageUpload.vue'
import ImagesUpload from '@@/components/organisms/shared/ImagesUpload.vue'
import NotionUrlField from '../../molecules/NotionUrlField.vue'

export default {
  components: {
    CompanyEmployeeForm,
    CompanyOffice,
    CompanyAnnuallyDatum,
    CompanyAnnuallyForeignerDatum,
    CompanyCountryRelation,
    ImageUpload,
    ImagesUpload,
    NotionUrlField
  },
  props: {
    companyParams: {
      type: [HfMemberRegisterCompanyCommandService.Params, HfMemberRegisterCompanyCommandService.BuildableParams],
    },
    lang: {
      type: String
    },
    simple: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return {
      company: this.companyParams.company
    }
  },
  computed: {
    bonus_paid_months: {
      get(){
        return JSON.parse(this.company.bonus_paid_months)
      },
      set(value){
        console.log(value);
        this.company.bonus_paid_months = "[" + String(value) + "]"
      }
    }
  },
  methods: {
    initializeParams(){
      if (!this.company.company_appliable_terms?.at(0)?.id) { this.company.company_appliable_terms = [] }
      if (!this.company.company_joinable_months?.at(0)?.id) { this.company.company_joinable_months = [] }
    },
    add(key){
      let pushed = this.companyParams.build(key)
      this.company[key].push(pushed)
    },
    remove(ary, object){
      if (object.id){
        this.$set(object, "_destroy", true)
      } else {
        ary.remove(object)
      }
    },
    validate(){
      return this.$refs.form.validate()
    },
    t(key){
      return this.$t(`aggregates.CompanyColumnNames.${key}`)
    }
  },
  mounted(){
    this.initializeParams()
    this.$store.dispatch("master/fetch", "countries")
  }
}

</script>

<style>

</style>