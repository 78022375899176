"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "form": { "id": null, "title_for_hf": null, "memo_for_hf": null, "form_questions": [{ "id": null, "index": null, "title_texts": { "ja": null, "en": null }, "description_texts": { "ja": null, "en": null }, "option_texts": { "ja": null, "en": null }, "question_type": null, "required": null, "_destroy": null }] } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "form": { "id": null, "title_for_hf": null, "memo_for_hf": null, "form_questions": [] } });
    }
    build(key) { return { "form_questions": { "id": null, "index": null, "title_texts": { "ja": null, "en": null }, "description_texts": { "ja": null, "en": null }, "option_texts": { "ja": null, "en": null }, "question_type": null, "required": null, "_destroy": null } }[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/hff/commands/register_form", { params });
    }
};
