"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "event": { "id": null, "title": { "ja": null, "en": null }, "body": { "ja": null, "en": null }, "start_at": null, "apply_url": null, "deadline": null, "form_id": null, "apply_complete_message": { "ja": null, "en": null }, "is_need_resume_file": null, "is_released": null, "slack_notify_channel_id": null, "notion_id": null, "thumbnail": { "id": null, "key": null, "description": null, "_destroy": null } } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "event": { "id": null, "title": { "ja": null, "en": null }, "body": { "ja": null, "en": null }, "start_at": null, "apply_url": null, "deadline": null, "form_id": null, "apply_complete_message": { "ja": null, "en": null }, "is_need_resume_file": null, "is_released": null, "slack_notify_channel_id": null, "notion_id": null, "thumbnail": { "id": null, "key": null, "description": null, "_destroy": null } } });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/hf_member__register_event", { params });
    }
};
