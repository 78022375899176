<template>
  <VForm ref="form" class="mb-8">
    <VChipGroup column>
      <VChip v-for="name in selectedSkillNames" :key="name" color="primary">
        {{name}}
      </VChip>
    </VChipGroup>
    <VList>
      <VListGroup v-for="item in items" :key="item.id" no-action>
        <template v-slot:activator>
          <VListItemContent>
            <VListItemTitle v-text="item.name" />
          </VListItemContent>
        </template>
        <div v-if="item.id!=5">
          <VChipGroup column multiple :value="selected">
            <div class="ma-2">
              <VChip v-for="child in item.children" :key="child.id" :value="child.id" @input="change(child, $event)" active-class="primary">
                {{ child.name }}
              </VChip>
            </div>
          </VChipGroup>
        </div>
        <div v-else>
          <VRow justify="center">
            <VCol cols="11">
              <VTextarea :label="t('feel_free_to_write')" dense outlined v-model="value.skill_description"/>
            </VCol>
          </VRow>
        </div>
      </VListGroup>
    </VList>
  </VForm>
</template>

<script>
import { UserUpdateProfileCommandService } from "hfapi/jtj";
import { GetMasterDataQueryService } from "hfapi/master";

let p = new UserUpdateProfileCommandService.Params
let userSKill = p.user.user_skills[0]
export default {
  props: ["value"],
  data() {
    return {
      skillCategories: [],
      skills: [],
      openCategories: {},
    };
  },
  methods: {
    getSkills() {
      let params = new GetMasterDataQueryService.Params();
      params.target.skill_categories = true;
      params.target.skills = true;

      GetMasterDataQueryService.execute(params).then((resp) => {
        this.skillCategories = resp.data.skill_categories;
        this.skills = resp.data.skills;
      });
    },
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    // change() {
    //   this.value.user_skills = this.selected.map(id => {
    //     let us = this.user_skill()
    //     us.skill_id = id
    //     return us
    //   })
    // },
    change(skill,event) {
      let index = this.value.user_skills.findIndex(p => p.skill_id == skill.id)
      if(index == -1){
        if(event){
          let newULS = Object.assign({},userSKill)
          newULS.skill_id = skill.id
          newULS.skill = skill
          this.value.user_skills.push(newULS)
        }
      }else{
        if(event){
          this.$set(this.value.user_skills[index], '_destroy', false )
        }else{
          this.$set(this.value.user_skills[index], '_destroy', true )
        }
      }
    },
    validate(){
      return this.$refs.form.validate()
    },
  },
  computed: {
    items() {
      return this.skillCategories.map((sc) => {
        const filteredSkills = this.skills.filter(
          (s) => s.skill_category_id == sc.id
        );
        return {
          id: sc.id,
          name: sc.name,
          children: filteredSkills.map((fs) => {
            return fs;
          }),
        };
      });
    },
    selectedSkillNames(){
      if(this.skills.length==0){return}
      return this.selected.map(id =>{
        return this.skills.find(s=>s.id==id)?.name
      })
    },
    selected(){
      return this.value.user_skills.filter(us => !us._destroy).map(us => us.skill_id)
    }
  },
  created() {
    this.getSkills();
    this.value.user_skills = this.value.user_skills.filter((uS)=> uS.skill_id)
  },
};
</script>

<style>
</style>