<template>
  <div>
    <VAppBar app color="primary accent-4" dark shouldExtend>

      <VToolbarTitle>
        <VBtn depressed dark color ="primary accent-4" small router class="px-1" @click="$router.push({name: 'TopPage'})">
          <span class="display-1 text-capitalize" style="line-height: 1.5rem;">Job Tree Japan</span>
        </VBtn>
      </VToolbarTitle>

      <VSpacer></VSpacer>

      <div class="d-none d-md-flex">
        <div v-for="item in items" :key="item.title">
          <VBtn depressed color="primary accent-4" v-if="isShowItem(item)" :to="item.route" >
            <span>{{item.title}}</span>
          </VBtn>
        </div>
      </div>

      <div class="d-flex d-md-none">
        <VAppBarNavIcon @click="drawer = !drawer"></VAppBarNavIcon>
        <VNavigationDrawer right v-model="drawer" app class="white darken-1" hide-overlay>
          <VList>
            <div v-for="item in items" :key="item.title" >
              <VListItem @click="$router.push(item.route)" v-if="isShowItem(item)">
                <VListItemContent class="black--text">
                    <span>{{item.title}}</span>
                </VListItemContent>
              </VListItem>
            </div>
          </VList>
        </VNavigationDrawer>
      </div>

      <VMenu offset-y>
        <template v-slot:activator="{ on }">
          <VBtn text v-on="on">
            <VIcon>mdi-web</VIcon> <span class="d-none d-md-block">Language</span>
          </VBtn>
        </template>
        <VList>
          <VListItem v-for="lang in languages" @click="changePageLang(lang.symbol)" :key="lang.symbol">
            <VListItemTitle>{{ lang.emoji + lang.name }} <span v-if="$i18n.locale==lang.symbol">✔︎</span></VListItemTitle>
          </VListItem>
        </VList>
      </VMenu>

    </VAppBar>
  </div>
</template>

<script>
export default {
  data(){
    return {
      drawer: false,
      items: [
        { title: 'Login', route: '/log_in', showType: "notLogin" },
        { title: 'LogOut', route: '/log_out', showType: "login" },
      ],
      languages: [
        { name: "Japanese", symbol: "ja", emoji: "🇯🇵" },
        { name: "English", symbol: "en", emoji: "🇺🇸" }
      ],
    }
  },
  computed: {
  },
  methods: {
    isShowItem(item){
      switch (item.showType) {
        case "both":
          return true
        case "login":
          return this.isSignIn
        case "notLogin":
          return !this.isSignIn
        default:
          return false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
