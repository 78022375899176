<script>
export default {
  inheritAttrs: false,
  name: "HfMemberSelectField",
  render(h){
    const children = Object.keys(this.$slots).map(slot =>
      h("template", { slot }, this.$slots[slot])
    );
    return h("VAutocomplete", {
      props: {
        ...this.$attrs,
        ...this.$props,
        "item-text": "last_name_ja",
        "item-value": "id",
      },
      on: this.$listeners,
      scopedSlots: this.$scopedSlots
    }, children);
  }
}
</script>
