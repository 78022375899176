<template>
  <VTextField :label="label" :hint="hint" :placeholder="placeholder" v-model="notion_url" :rules="rules" />
</template>

<script>
import {ID2URL, URL2ID} from "@@/lib/notion"

export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      default: "Notion URL"
    },
    hint: {
      default: ""
    },
    placeholder: {
      default: "https://www.notion.so/..."
    },
    rules: {
      default: ()=>[]
    }
  },
  computed: {
    notion_url: {
      get() {
        return ID2URL(this.value)
      },
      set(value){
        this.$emit('input', URL2ID(value))
      }
    }
  }
}
</script>

<style>

</style>