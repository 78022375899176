<template>
  <VContainer>
    <h1>HfForm一覧</h1>
    <VBtn :to="{name: 'HfFormRegisterPage'}" color="success" class="mb-3">新規作成</VBtn>
    <VCard>
      <VTextField v-model="search" label="search" outlined dense class="ml-4 pa-4"/>
      <VCardText>
        <VDataTable :items="hfForms" :headers="headers" :search="search" @click:row="$router.push({name: 'HfFormEditPage', params: {id: $event.id}})">
          <template v-slot:item.action={item}>
            <VBtn @click="$router.push({name: 'HfFormCopyPage', params: {id: item.id}})" @click.stop>コピー</VBtn>
            <VBtn @click="$router.push({name: 'HfFormEditPage', params: {id: item.id}})" @click.stop>編集</VBtn>
            <VBtn @click="destroyForm(item)" class="error" @click.stop>削除</VBtn>
          </template>
        </VDataTable>
      </VCardText>
    </VCard>
  </VContainer>
</template>

<script>
import { GetFormsQueryService, DestroyFormCommandService } from "hfapi/hff"
import HfForm from "@/aggregates/hf_form"

export default {
  data(){
    return {
      hfForms: [],
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Title', value: 'title_for_hf', sortable: true },
        { text: "Action", value: "action", sortable: false }
      ],
      search: "",
    }
  },
  methods: {
    destroyForm(item){
      let checkFlg = window.confirm(`本当に削除しますか？\n削除するフォーム: ${item.title_for_hf}`);
      if(checkFlg){
        DestroyFormCommandService.execute({form: {id: item.id}}).then(resp=>{
          console.log(resp)
          this.getForms()
        }).catch((e)=>{
          const message = e.response.data
          if(message.startsWith("ActiveRecord::InvalidForeignKey")){
            const eventIds = message.match(/Key \(id\)=\((.+)\)/)[1]
            console.log(message)
            this.flash(`IDが${eventIds}番のイベントで使われているため削除ができません。`, "error")
          } else {
            this.flash(e.response.data, "error")
          }
        })
      }
    },
    async getForms(){
      const resp = await GetFormsQueryService.execute()
      this.hfForms = resp.data.map(d => new HfForm(d))
    }
  },
  metaInfo() {
    return {
      title: `HfForm一覧`,
    }
  },
  created(){
    this.getForms()
  },
}

</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10%;
  right: 5%;
  z-index: 1000;
}

</style>