<template>
  <div>
    <h3 class="mb-3">{{header}}</h3>
    <details open="true"><summary>{{toggleText}}</summary><div>

      <div v-for="content in toggleContents" :key="content.header">
        <h4>{{content.header}}</h4>
        <p>
          {{content.text}}
        </p>
      </div>
    </div></details>

    <VTextarea background-color="white" outlined :value="textValue" @input="$emit('input', $event)" :rules="rules" />
  </div>
</template>

<script>
// import { UsecaseServiceName } from "hfapi"
export default {
  name: "TextFieldWithToggleExplanation",
  model: {
    prop: "textValue",
    event: "input",
  },
  props: {
    header: {
      type: String,
      default: "header"
    },
    toggleText: {
      type: String
    },
    // { header: , text:  }
    toggleContents: {
      type: Array
    },
    textValue: {
      type: String
    },
    rules: {
      type: Array,
      default: ()=>[]
    }
  },
  data(){
    return {

    }
  },
}
</script>

<style scoped>
</style>
