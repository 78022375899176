import HfMember from '@/aggregates/hf_member'
import { HfMemberGetOwnDataQueryService, HfMemberGetFullOwnDataQueryService } from "hfapi";

export default {
  namespaced: true,
  state() {
    return new HfMember()
  } ,
  mutations: {
    update(state, hf_member){
      Object.assign(state, hf_member)
    },
    destroy (state) {
      Object.assign(state, new HfMember())
    },
  },
  actions: {
    update({ commit }, callback) {
      HfMemberGetOwnDataQueryService.execute({}).then(resp => {
        commit( "update", new HfMember(resp.data) )
      }).catch(error=>{
        console.error(error);
        commit('destroy')
        commit('auth/destroy', null, { root: true })
      }).finally(() => {
        if (callback) { callback() }
      })
    },
    updateFullData({ commit }, callback) {
      HfMemberGetFullOwnDataQueryService.execute({}).then(resp => {
        commit( "update", new HfMember(resp.data) )
      }).catch(error=>{
        console.error(error.response);
        commit('destroy')
      }).finally(() => {
        if (callback) { callback() }
      })
    },
    destroy({ commit }) {
      commit('destroy')
    },
  },
}
