<template>
  <div>
    <VTextField label="Email" v-model="loginData.email" id="email"></VTextField>
    <VTextField label="Password" v-model="loginData.password" type="password" id="password"></VTextField>
    <VBtn @click="login">Log in</VBtn>
  </div>
</template>

<script>
export default {
  props: {
    loginData: {
    }
  },
  methods: {
    login(){
      this.$emit("login")
    }
  },
}
</script>

<style>

</style>