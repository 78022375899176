<template>
  <VForm ref="form">
    <NotionUrlField v-model="userParams.notion_id" :rules="[validation.required]" />
    <VBtn @click="save">保存</VBtn>
  </VForm>
</template>

<script>
import NotionUrlField from '../../../molecules/NotionUrlField.vue'
import { HfMemberUpdateUserNotionIdCommandService } from "hfapi/jtj"

export default {
  components: {
    NotionUrlField,
  },
  props: {
    userParams: {
    },
  },
  methods: {
    save(){
      HfMemberUpdateUserNotionIdCommandService.execute({user: this.userParams}).then(resp=>{
        console.log(resp)
        this.$emit("save")
      })
    }
  }
};
</script>

<style>
</style>