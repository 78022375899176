<template>
  <VContainer>
    <h2>求人情報を登録できます。</h2>
    <VChip v-if="recruitment.id" class="mt-3" color="success">このページは{{ recruitment.is_simple ? "簡易版" : "通常版" }}で作成されています．簡易版編集ページは<router-link
        :to="{ name: 'RecruitmentSimpleEditPage' }">こちら</router-link></VChip>
    <VChip v-if="!recruitment.is_simple && !recruitment.id" class="mt-3" color="success">このページは正規版です．簡易版作成ページは<RouterLink
        :to="{ name: 'RecruitmentSimpleRegisterPage' }">こちら</RouterLink>
    </VChip>
    <div class="btns">
      <VBtn @click="submit" class="success mb-3">全て保存</VBtn>
      <VBtn @click="submitja">日本語だけ保存</VBtn>
    </div>
    <VRow>
      <VCol cols="6">
        <h3>日本語</h3>
        <RecruitmentRegisterLangForm
          :recruitmentParams.sync="recruitmentParams"
          :resumeQuestions="resumeQuestions"
          :companies="companies"
          @onResumeQuestionRegister="updateResumeQuestionOptions()"
          lang="ja"
          ref="jaForm"
        />
      </VCol>
      <VCol cols="6">
        <h3>英語</h3>
        <RecruitmentRegisterLangForm
          :recruitmentParams.sync="recruitmentParams"
          :resumeQuestions="resumeQuestions"
          :companies="companies"
          @onResumeQuestionRegister="updateResumeQuestionOptions()"
          lang="en"
          ref="enForm"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { HfMemberRegisterRecruitmentCommandService, HfMemberGetCompanyOptionsQueryService, HfMemberGetResumeQuestionsQueryService } from "hfapi/jtj"
import Recruitment from "@/aggregates/recruitment"
import RecruitmentRegisterLangForm from "@@/components/organisms/recruitments/RecruitmentRegisterLangForm"

export default {
  metaInfo() {
    return {
      title: `${this.recruitment.full_name}-求人登録ページ`,
    }
  },
  components: {
    RecruitmentRegisterLangForm
  },
  data() {
    return {
      recruitment: new Recruitment(this.$route.params.id),
      recruitmentParams: new HfMemberRegisterRecruitmentCommandService.BuildableParams(),
      companies: [],
      resumeQuestionOptions: [],
    }
  },
  methods: {
    submit() {
      if (!this.$refs.jaForm.validate() || !this.$refs.enForm.validate()) {
        this.flash("入力していない項目があります。", "error")
        return
      }
      this.register()
    },
    submitja() {
      if (!this.$refs.jaForm.validate()) {
        this.flash("入力していない項目があります。", "error")
        return
      }
      this.register()
    },
    register() {
      this.recruitmentParams.recruitment.is_simple = false
      if (this.recruitmentParams.recruitment.id == null) {
        this.recruitmentParams.recruitment.images = []
      }
      HfMemberRegisterRecruitmentCommandService.execute(this.recruitmentParams).then(resp => {
        this.recruitment.fetch(resp.data.id)
        this.$router.push({ name: "RecruitmentEditPage", params: { id: resp.data.id } })
        this.flash("保存に成功しました", "success")
      }).catch(e => {
        this.flash(e.response.data, "error")
      })
    },
    removeIdForCopy() {
      this.recruitmentParams.recruitment.id = null
      this.recruitmentParams.recruitment.recruitment_detail.id = null
      if (this.recruitment.required_japanese_level.id) { this.recruitment.required_japanese_level.id = null }
      if (this.recruitment.required_english_level.id) { this.recruitment.required_english_level.id = null }
      this.recruitmentParams.recruitment.required_other_language_levels?.forEach(r => r.id = null)
      this.recruitmentParams.recruitment.recruitment_required_language_examinations?.forEach(r => r.id = null)
      this.recruitmentParams.recruitment.recruitment_company_offices?.forEach(r => r.id = null)
      this.recruitmentParams.recruitment.recruitment_annually_data?.forEach(r => r.id = null)
      this.recruitmentParams.recruitment.recruitment_target_study_department_categories?.forEach(r => r.id = null)
      this.recruitmentParams.recruitment.recruitment_required_personalities?.forEach(r => r.id = null)
      this.recruitmentParams.recruitment.recruitment_careers?.forEach(r => r.id = null)
      this.recruitmentParams.recruitment.recruitment_selections?.forEach(r => { r.id = null; r.recruitment_selection_languages.forEach(r => { r.id = null }) })
      this.recruitmentParams.recruitment.recruitment_country_relations?.forEach(r => r.id = null)
      this.recruitmentParams.recruitment.recruitment_required_skills?.forEach(r => r.id = null)
      this.recruitmentParams.recruitment.recruitment_job_categories?.forEach(r => r.id = null)
      this.recruitmentParams.recruitment.recruitment_allowances?.forEach(r => r.id = null)
      this.recruitmentParams.recruitment.recruitment_internal_datum.id = null
    },
    updateResumeQuestionOptions() {
      HfMemberGetResumeQuestionsQueryService.execute({ all: false }).then(resp => {
        this.resumeQuestionOptions = resp.data
      })
    }
  },
  mounted() {
    this.recruitment.after_assign = () => {
      Object.assign(this.recruitmentParams.recruitment, this.recruitment)
      if (this.$route.name == "RecruitmentCopyPage") {
        this.removeIdForCopy()
      }
    }
    HfMemberGetCompanyOptionsQueryService.execute().then(resp => {
      this.companies = resp.data
    })
    this.updateResumeQuestionOptions()
  },
  computed: {
    resumeQuestions() {
      return this.resumeQuestionOptions.map(rq => {
        this.$set(rq, 'recruitment_resume_question', this.recruitmentParams.recruitment.recruitment_resume_questions.find(rrq => rq.id == rrq.resume_question_id))
        return rq
      })
    },
  }
  // beforeRouteUpdate(to, from , next){
  //   console.log("あいう");
  //   this.$set(this.recruitmentParams, new HfMemberRegisterRecruitmentCommandService.Params())
  //   this.recruitment.fetch(to.params.id)
  //   next()
  // }
}
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10%;
  right: 5%;
  z-index: 1000;
}
</style>
