<template lang="">
  <div>
    ＊翻訳項目で入れられないものがあれば「-」などを登録ください。
    <VForm ref="form">
      <div v-for="key in keys" :key="key">

        <!-- id -->
        <template v-if="key_to_component_type(key)=='id'">
          <VTextField :label="key" v-model="instance[key]" disabled/>
        </template>

        <!-- string -->
        <template v-if="key_to_component_type(key)=='string'">
          <VTextField :label="key" v-model="instance[key]" :rules="[validation.required]"/>
        </template>
        
        <!-- number -->
        <template v-if="key_to_component_type(key)=='number'">
          <VTextField :label="key" v-model="instance[key]" type="number" :rules="[validation.required]"/>
        </template>

        <!-- boolean -->
        <template v-if="key_to_component_type(key)=='boolean'">
          <CheckboxDefaultFalse :label="key" v-model="instance[key]" :rules="[validation.required]"/>
        </template>
        
        <!-- reference -->
        <template v-if="key_to_component_type(key)=='reference'">
          <MasterSelectField :label="key" v-model="instance[key]" :items="master(key_to_reference_table_name(key))" :rules="[validation.required]"/>
        </template>

      </div>
    </VForm>
    <VBtn @click="register" color="success">保存</VBtn>
  </div>
</template>
<script>
import { UpdateMasterDataCommandService } from "hfapi/master"

export default {

  data() {
    return {
      string_keys: [
        "name_ja",
        "name_en",
        "name_vi",
        "name_zh_CN",
        "sentence_ja",
        "sentence_en",
        "sentence_vi",
        "sentence_zh_CN",
      ],
      number_keys: [
        "level",
      ]
    }
  },

  computed: {
    other_table_names(){
      return Object.keys(new UpdateMasterDataCommandService.BuildableParams()).filter(key=>key!="class_name")
    },
    table_name() {
      return this.$route.params.table_name
    },
    id(){
      return this.$route.params.id
    },
    master_data(){
      return this.master(this.table_name)
    },
    instance(){
      if(this.id){
        return this.master_data.find(data=>data.id == this.id)
      } else {
        return new UpdateMasterDataCommandService.BuildableParams()[this.table_name]
      }
    },
    keys(){
      if(!this.instance) {return []}
      return Object.keys(this.instance)
    },
  },
  methods: {
    key_to_reference_table_name(key){
      if(key.endsWith("_id")){
        const reference_nam = key.slice(0, -4)
        return this.other_table_names.find(table_name=>table_name.startsWith(reference_nam))
      } else {
        return null
      }
    },
    key_to_component_type(key){
      switch (true) {
        case key == "id":
          return "id"

        case !!this.key_to_reference_table_name(key):
          return "reference"

        case RegExp(this.string_keys.join("|")).test(key):
          return "string"

        case RegExp(this.number_keys.join("|")).test(key):
          return "number"

        case key.startsWith("is_"):
          return "boolean"
      
        default:
          break;
      }
    },
    register(){
      if(!this.$refs.form.validate()){ return }

      this.loading = true
      const params = new UpdateMasterDataCommandService.BuildableParams()
      params.table_name = this.table_name
      params[this.table_name] = this.instance
      UpdateMasterDataCommandService.execute(params).then(resp=>{
        Object.assign(this.instance, resp.data)
        this.flash("保存に成功しました", "success")
        this.$router.push({name: 'MasterTableEditPage', params: { table_name: this.table_name, id: this.instance.id } })
      }).catch(e=>{
        console.log(e)
      }).finally(()=>{
        this.loading = false 
      })
    }
  },
}
</script>
<style lang="">
  
</style>