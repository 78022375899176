import { hfapiAxios } from "hfapi";
import store from "../store";
import moment from "moment";

const host = process.env.VUE_APP_HFAPI_HOST;

export default function() {
  hfapiAxios.defaults.baseURL = host;

  hfapiAxios.interceptors.request.use((request) => {
    // const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    // console.log(`token ${store.state.auth.token}`)
    request.headers = {
      "X-Requested-With": "XMLHttpRequest",
      // "X-CSRF-Token": csrf_token,
      "access-token": store.state.auth.token,
      client: store.state.auth.client,
      uid: store.state.auth.uid,
      "token-type": store.state.auth.tokenType,
      "page-language-id": store.state.page_language_id
    };
    if (
      !!store.state.auth.expiry &&
      moment.unix(store.state.auth.expiry).isBefore()
    ) {
      //トークンの有効期限が切れていたら
      store.dispatch("auth/signOut");
    }
    return request;
  });

  hfapiAxios.interceptors.response.use((response) => {
    if(!response){ return response }
    store.dispatch("auth/updateWithToken", response.headers);
    return response;
  }, (error) => {
    console.log(error);
    if (error.response.status == 403) {
      store.dispatch("auth/signOut", () => {
        // リクエストが複数送られる時に全部403で返されてしまい、このerror interceptorが複数回呼ばれてしまうのでifでネストしている
        if (!store.state.auth.showExpirationDialog) store.commit("auth/openExpirationDialog")
      });
    }
    return Promise.reject(error);
  });
}
