"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "key": null });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "key": null });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.get("/jtj/queries/hf_member__get_presigned_url", { params });
    }
};
