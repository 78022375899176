export default function deepSeal(o) {
    // Object.seal(o);
    if (o === undefined) {
        return o;
    }
    Object.getOwnPropertyNames(o).forEach(function (prop) {
        if (o[prop] !== null
            && (typeof o[prop] === "object" || typeof o[prop] === "function")
            && Object.isExtensible(o[prop])) {
            deepSeal(o[prop]);
        }
    });
    return o;
}
