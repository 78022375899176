<template>
  <div id="pdf-viewer" class="Viewer">
  </div>
</template> 

<script>
import 'pdfjs-dist/web/pdf_viewer.css';
const pdfjsLib = require("pdfjs-dist/build/pdf.min.js");

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

export default {
  props: {
    data: { type: String }
  },
  data(){
    return {
      pdfViewer: null,
    }
  },
  methods: {
    renderPage(pdf, pageNum){
      pdf.getPage(pageNum).then((page) => {
        // canvasの横幅とpdfの横幅を合わせる
        let clientWidth = document.getElementById("pdf-viewer").getBoundingClientRect().width // #pdf-viewerの横幅
        let viewportWidth = page.getViewport({ scale: 1.0 }).width
        const newScale = clientWidth / viewportWidth
        const viewport = page.getViewport({ scale: newScale });
        // #pdf-viewrにchild(canvas)を追加
        let canvas = document.createElement("canvas")
        let pdfViewer = document.getElementById("pdf-viewer")
        pdfViewer.appendChild(canvas)
        canvas.id = `pdf-canvas-page-${pageNum}`
        canvas.height = viewport.height
        canvas.width = viewport.width

        let renderContext = {
          canvasContext: canvas.getContext("2d"),
          viewport: viewport,
        };
        // render page
        page.render(renderContext)
      });
    },
    renderPDF(pdfData){
      let loadingTask = pdfjsLib.getDocument({data: atob(pdfData)})
      loadingTask.promise.then((pdf) => {
        // render all pades
        for (let i = 1; i <= pdf.numPages; ++i) {
          this.renderPage(pdf, i)
        }
      }, (reason) => {
        console.error(`Error during ${this.data} loading: ${reason}`)
      });
    },
    resetPDF(){
      let pdfViewer = document.getElementById("pdf-viewer")
      pdfViewer.innerHTML = ""
    }
  },
  watch: {
    data: function(newData) {
      this.resetPDF()
      this.renderPDF(newData)
    }
  },
  mounted(){
    if (this.data) {
      this.renderPDF(this.data)
    }
  }
}
</script>

<style scoped>
.Viewer {
    background-color: #444;
    height: 800px;
    position: relative;
    overflow: scroll;
}
</style>