<template>
  <VForm ref="form">        
    <div v-for="us in programingUserSkills" :key="us.skill_id">
      <h3>{{ t("skill_description_programming", { skill_name: us.skill.name }) }}</h3>
      <VTextarea outlined v-model="us.description" :rules="[validation.required]"/>
    </div>

    <div v-for="us in designUserSkills" :key="us.skill_id">
      <h3>{{ t("skill_description_design") }}</h3>
      <VTextarea outlined v-model="us.description" :rules="[validation.required]"/>
    </div>

    <div v-for="us in CADUserSkills" :key="us.skill_id">
      <h3>{{ t("skill_description_cad") }}</h3>
      <VTextarea outlined v-model="us.description" :rules="[validation.required]"/>
    </div>
  </VForm>
</template>

<script>
import { GetMasterDataQueryService } from "hfapi/master";
import { SKILL_CATEGORY_IDS } from "@@/config/constants.js"
export default {
  props: ["value"],
  data() {
    return {
      skills: [],
    };
  },
  methods: {
    getSkills() {
      let params = new GetMasterDataQueryService.Params();
      params.target.skills = true;

      GetMasterDataQueryService.execute(params).then((resp) => {
        this.skills = resp.data.skills;
      });
    },
    skillName(id) {
      if (this.skills.length == 0) {
        return;
      }
      return this.skills.find((s) => s.id == id).name;
    },
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      return this.$refs.form.validate()
    },
  },
  computed: {
    programingUserSkills() {
      return this.value.user_skills.filter( (us) => us.skill.skill_category_id == SKILL_CATEGORY_IDS["programming"] );
    },
    designUserSkills() {
      return this.value.user_skills.filter( (us) => us.skill.skill_category_id == SKILL_CATEGORY_IDS["design"] );
    },
    CADUserSkills() {
      return this.value.user_skills.filter( (us) => us.skill.skill_category_id == SKILL_CATEGORY_IDS["cad"] );
    },
  },
  created() {
    this.getSkills();
  },
};
</script>

<style>
</style>