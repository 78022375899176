<template>
  <div>
    <div v-for="userJobHistory in value.userJobHistories()" :key="userJobHistory.id || userJobHistory.key">
      <JobHistory :value="userJobHistory" :ref="userJobHistory.id || userJobHistory.key"/>
      <VBtn @click="remove(userJobHistory)" class="mb-7 error">削除</VBtn>
      <VDivider></VDivider>
    </div>
    <VBtn @click="add" class="mt-3">職歴を追加</VBtn>
  </div>
</template>

<script>
import JobHistory from "@@/components/organisms/users/forms/JobHistory.vue"
import User from '@/aggregates/user'
import { UserUpdateProfileCommandService } from "hfapi";

export default {
  components: {
    JobHistory
  },
  props: {
    value: {
      type: User
    }
  },
  methods: {
    defaultUserJobHistory(){
      const defaultParams = (new UserUpdateProfileCommandService.Params).user.user_job_histories[0]
      defaultParams.user_job_history_quit_reasons = null
      defaultParams.key = this.randHash()
      return defaultParams
    },
    add(){
      this.value.user_job_histories.push(this.defaultUserJobHistory())
    },
    remove(userJobHistory){
      userJobHistory._destroy = true
    },
    validate(){
      let valid = true
      this.value.userJobHistories().forEach(userJobHistory => {
        const key = userJobHistory.id || userJobHistory.key
        if (!this.$refs[key][0].validate()) { valid = false }
      });
      return valid
    }
  },
  created(){
    if ( this.value.userJobHistories().length == 0 ){
      // this.add()
    }
  }
}
</script>

<style>

</style>