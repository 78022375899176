<template>
  <div>
    <!-- <VTextarea rows="3" label="聞きたいこと" v-model="questions" /> Gmail側で編集すればいいので凍結 -->
    <p class="warning--text">Gmailの送信画面へ移ります</p>
    <VBtn @click="send" color="primary">質問メールの送信へ移る</VBtn>
  </div>
</template>

<script>
import { HfMemberGetAskQuestionsMailQueryService, HfMemberGetGmailUrlCommandService } from 'hfapi/jtj'

export default {
  explanation: "ユーザーに質問をするためのメールを送る",
  props: {
    application: {
      type: Object
    }
  },
  data(){
    return {
      questions: ""
    }
  },
  methods: {
    async send(){
      const mailParams = new HfMemberGetAskQuestionsMailQueryService.Params
      mailParams.application_id = this.application.id
      mailParams.questions = this.questions.replace("\n", "<br/>")
      const resp = await HfMemberGetAskQuestionsMailQueryService.execute(mailParams)
      const gmailUrlParams = new HfMemberGetGmailUrlCommandService.Params
      gmailUrlParams.mail = resp.data
      const gmailUrlResp = await HfMemberGetGmailUrlCommandService.execute(gmailUrlParams)
      window.open(gmailUrlResp.data, '_blank')
    }
  }
}
</script>

<style>

</style>