<template>
  <VContainer fluid>
    <VRow>
      <VCol>
        <h3>レコメンドメール送信ページ</h3>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="6" style="max-height: 70vh; overflow-y: scroll;">
        <UserTable :user="user" />
      </VCol>
      <VCol cols="6" style="max-height: 70vh; overflow-y: scroll;">
        <RecruitmentSearch :queryParams="recruitmentsSearchParams" @clickRecruitmentCard="select"/>
      </VCol>
    </VRow>
    <VRow>
      <!-- おすすめ企業 -->
      <VCol v-for="remind_mail_recruitment in this.setRecommendMailParams.recommend_mail.active_remind_mail_recruitments" :key="remind_mail_recruitment.recruitment_id">
        <h4>{{ remind_mail_recruitment.recruitment.company.official_name_ja }}</h4>
        <VTextarea label="おすすめ理由日本語" v-model="remind_mail_recruitment.reason_ja"/>
        <VTextarea label="おすすめ理由英語" v-model="remind_mail_recruitment.reason_en"/>
        <VBtn class="error" @click="select(remind_mail_recruitment.recruitment)"><VIcon>mdi-delete</VIcon>削除</VBtn>
        <VDivider class="mt-2"></VDivider>
      </VCol>
      <!-- typeとか諸々 -->
      <VCol cols="12">
        <VSelect label="レコメンドメールのタイプ" :items="recommend_mail_types" v-model="setRecommendMailParams.recommend_mail.recommend_mail_type"/>
        <VSwitch label="面接日程調整をするか？" v-model="setRecommendMailParams.recommend_mail.is_including_meeting_schedule"></VSwitch>
        <HfMemberSelectField label="担当者" :items="master('hf_members')" v-model="setRecommendMailParams.recommend_mail.hf_member_id"></HfMemberSelectField>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <VBtn @click="setRecommendMail">メールを生成</VBtn>
      </VCol>
    </VRow>

    <!-- 送信 -->
    <VRow v-if="showMailGenerator">
      <VCol>
        <h3>メール送信</h3>
        <RecommendMailGenerator
        ref="RecommendMailGenerator"
        :recommendMail="recommendMail"
        @send="send"
        :sendding="sendding"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import UserTable from "@@/components/organisms/users/UserTable"
import RecruitmentSearch from "@@/components/organisms/recruitments/RecruitmentSearch"
import RecommendMail from "@/aggregates/recommendMail.js"
import RecommendMailGenerator from "@@/components/organisms/recommendMails/RecommendMailGenerator.vue"
import User from "@/aggregates/user.js"
import { HfMemberSearchRecruitmentsQueryService, HfMemberSetRecommendMailCommandService, HfMemberSendRecommendMailCommandService } from "hfapi/jtj"
import { GetMasterDataQueryService } from "hfapi/master"


export default {
   metaInfo() {
    return {
      title: `レコメンドメール設定ページ`,
    }
  },
  components: {
    UserTable,
    RecruitmentSearch,
    RecommendMailGenerator,
  },
  data(){
    return {
      user: new User(),
      recommendMail: new RecommendMail(this.$route.params.id),
      recruitmentsSearchParams: new HfMemberSearchRecruitmentsQueryService.Params(),
      setRecommendMailParams: new HfMemberSetRecommendMailCommandService.Params(),
      recommend_mail_types: ["recommend", "follow_improve_japanese", "follow_expand_vision", "follow_not_have_gakushi", "nothing"],
      showMailGenerator: false,
      sendding: false,
    }
  },
  methods: {
    select(recruitment){
      console.log(this.setRecommendMailParams);
      let remind_mail_recruitment = this.setRecommendMailParams.recommend_mail.active_remind_mail_recruitments.find((rmr)=>{ return rmr.recruitment_id == recruitment.id })
      if (!remind_mail_recruitment){ 
        // 要素追加
        this.appendRecruitment(recruitment)
      }else{
        // 要素削除
        const index = this.setRecommendMailParams.recommend_mail.active_remind_mail_recruitments.indexOf(remind_mail_recruitment);
        this.setRecommendMailParams.recommend_mail.active_remind_mail_recruitments.splice(index, 1);
      }
    },
    appendRecruitment(recruitment){
      this.setRecommendMailParams.recommend_mail.active_remind_mail_recruitments.push({
        recruitment_id: recruitment.id,
        reason_en: "",
        reason_ja: "",
        recruitment: recruitment
      })
    },
    setRecommendMail(){
      HfMemberSetRecommendMailCommandService.execute(this.setRecommendMailParams).then(resp=>{
        console.log(resp);
        // fetchした後の処理を書く
        this.recommendMail.after_assign = ()=>{ 
          if (this.recommendMail.recommend_mail_type != "nothing"){
            this.showMailGenerator = true
            this.$refs["RecommendMailGenerator"].$forceUpdate()
          }else{
            this.$router.push({name: "RecommendMailIndexPage"})
          }
        }
        // fetchする
        this.showMailGenerator = false
        this.recommendMail.fetch()
      })
    },
    send(sendedMail){
      if(this.sendding) { return }
      this.sendding = true
      let params = new HfMemberSendRecommendMailCommandService.Params()
      params.recommend_mail_id = this.recommendMail.id
      params.mail.title = sendedMail.title
      params.mail.body = sendedMail.body
      HfMemberSendRecommendMailCommandService.execute(params).then(()=>{
        this.flash('送れました〜', 'success')
        this.$router.push({name: "RecommendMailIndexPage"})
      }).catch(e=>{
        this.flash(e.response.data, "error")
      })
    }
  },
  computed: {
  },
  created(){
    this.recruitmentsSearchParams.q.is_released_true = true
    this.setRecommendMailParams.recommend_mail.active_remind_mail_recruitments = []
    this.recommendMail.after_assign = ()=>{
      this.user.fetch(this.recommendMail.user_id)
      Object.assign(this.setRecommendMailParams.recommend_mail, this.recommendMail)
    }
    this.user.after_assign = ()=>{
      this.recruitmentsSearchParams.q.study_department_categories_id_eq_any = this.user.study_department_category_ids?.distinct()
      this.recruitmentsSearchParams.q.related_countries_id_eq_any = [this.user.home_country.id]
    }
    GetMasterDataQueryService.execute({target: {hf_members: true}}).then(resp=>{
      console.log(resp.data.hf_members);
      this.hf_members = resp.data.hf_members
    })
  }
}
</script>

<style>

</style>