<template>
  <div>
    <h3>{{t('living_place')}}</h3>
    <VCheckbox v-model="notInJapan" :label="t('not_living_in_japan')" @change="checkNotInJapan" />
    <div v-if="!notInJapan">
      <MasterSelectField :items="master('prefectures')" v-model="value.living_prefecture_id" :label="t('living_prefecture_id')" />
    </div>
    <div v-else>
      <MasterSelectField :items="master('countries')" v-model="value.living_country_id" :label="t('living_country')" />
    </div>
  </div>
</template>

<script>
import { JAPAN_COUNTRY_ID } from "@@/config/constants";

export default {
  props: ["value"],
  data() {
    return {
      notInJapan: false,
      selectedPrefecture: null,
      selectedCountry: null,
    };
  },
  methods: {
    checkNotInJapan() {
      if (this.notInJapan) {
        this.value.living_prefecture_id = null;
        this.value.living_country_id = null
      } else {
        this.value.living_country_id = JAPAN_COUNTRY_ID;
      }
    },
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
  },
  created() {
    this.notInJapan =
      this.value.living_country_id != null &&
      this.value.living_country_id != JAPAN_COUNTRY_ID;
  },
};
</script>

<style>
</style>