<template>
  <div>
    <VChipGroup>
      <VChip v-for="rq in selectedResumeQuestions" :key="rq.id" v-text="rq.title_for_hf_member" />
    </VChipGroup>
    <VDialog v-model="dialog" max-width="80vw">
      <template v-slot:activator="{on, attrs}">
        <VChip color="primary" v-bind="attrs" v-on="on"><VIcon>mdi-pencil</VIcon></VChip>
      </template>
      <VCard>
        <VCardTitle>クリックして確認・編集することができます．</VCardTitle>
        <VCardActions>
          <VTextField label="Search" v-model="search" clearable class="ma-2"/>
          <VBtn class="ma-2" @click="create" ><VIcon>mdi-plus</VIcon>質問を追加する</VBtn>
          <VBtn @click="$emit('onResumeQuestionRegister')"><VIcon>mdi-sync</VIcon>一覧を更新する</VBtn>
        </VCardActions>
        <VDataTable @click:row="routerWithBlank({ name: 'ResumeQuestionEditPage', params: { id: $event.id }})" :items="resumeQuestions" :headers="headers" item-key="id" return-value="id" :search="search" :custom-sort="sort" >
          <template v-slot:[`item.checkbox`]="{item}">
            <VCheckbox @click.stop="toggle(item)" :input-value="isSelected(item)"/>
          </template>
          <template v-slot:[`item.recruitment_resume_question.is_display_in_resume`]="{item}">
            <VCheckbox v-if="isSelected(item)" @click.stop v-model="findRecruitmentResumeQuestionByResumeQuestionId(item.id).is_display_in_resume"/>
          </template>
          <template v-slot:[`item.recruitment_resume_question.num_of_approximate_characters_ja`]="{item}">
            <!-- {{findRecruitmentResumeQuestionByResumeQuestionId(item.id)}} -->
            <VTextField v-if="isSelected(item)" @click.stop v-model="findRecruitmentResumeQuestionByResumeQuestionId(item.id).num_of_approximate_characters_ja" type="number"/>
          </template>
          <template v-slot:[`item.recruitment_resume_question.num_of_approximate_words_en`]="{item}">
            <VTextField v-if="isSelected(item)" @click.stop v-model="findRecruitmentResumeQuestionByResumeQuestionId(item.id).num_of_approximate_words_en" type="number"/>
          </template>
          <template v-slot:[`item.content`]="{ item }">
            {{item.title.ja}}<br/>
            {{item.purpose.ja}}<br/>
          </template>
          <template v-slot:[`item.up_down`]="{ item }">
            <VBtn icon @click.stop="up(item)"><VIcon>mdi-arrow-up</VIcon></VBtn>
            <VBtn icon @click.stop="down(item)"><VIcon>mdi-arrow-down</VIcon></VBtn>
          </template>
          <template v-slot:[`item.using_frequency`]="{ item }">
            {{ "★".repeat(item.using_frequency_rating_number) }}
          </template>
        </VDataTable>
      </VCard>
    </VDialog>
    <VDialog v-model="isShowResumeQuestionForm" max-width="1200px" v-if="isShowResumeQuestionForm">
      <VCard>
        <ResumeQuestionRegister @register="onResumeQuestionRegister" ref="ResumeQuestionRegister"></ResumeQuestionRegister>
      </VCard>
    </VDialog>
    <CheckboxDefaultFalse :label="t('is_japanese_only_for_resume_questions')" v-model="recruitment.is_japanese_only_for_resume_questions"/>
  </div>
</template>

<script>
import { HfMemberRegisterSimpleRecruitmentCommandService } from 'hfapi/jtj'
import ResumeQuestionRegister from '../resumeQuestions/ResumeQuestionRegister.vue'
export default {
  components: { ResumeQuestionRegister },
  props: {
    recruitmentParams: {
      type: [HfMemberRegisterSimpleRecruitmentCommandService.Params, HfMemberRegisterSimpleRecruitmentCommandService.BuildableParams],
    },
    resumeQuestions: {
      type: Array,
      required: true,
    },
  },
  data(){
    return {
      headers: [
        { text: 'タイトル', value: 'title_for_hf_member', sortable: false },
        { text: '選択', value: 'checkbox', width: 40, sortable: false, filterable: false, align: 'center' },
        { text: '履歴書に表示', width: 80, value: 'recruitment_resume_question.is_display_in_resume', sortable: false, filterable: false, align: 'center' },
        { text: '目安文字数(日本語)', width: 80, value: "recruitment_resume_question.num_of_approximate_characters_ja", filtable: false, align: 'center' },
        { text: '目安ワード数(英語)', width: 80, value: "recruitment_resume_question.num_of_approximate_words_en", filtable: false, align: 'center' },
        { text: '順番入れ替え', value: 'up_down', width: 80, sortable: false, filterable: false, align: 'center' },
        { text: '順番', value: 'recruitment_resume_question.index', sortable: false, width: 80, align: 'center' },
        // { text: "利用頻度", value: 'using_frequency', sortable: false }
      ],
      dialog: false,
      search: "",
      isShowResumeQuestionForm: false,
    }
  },
  methods:{
    create(){
      this.isShowResumeQuestionForm = true
    },
    down(item){
      const ids = this.resume_question_ids
      ids.splice(item.recruitment_resume_question.index, 1)
      ids.splice(item.recruitment_resume_question.index+1, 0, item.id)
      this.resume_question_ids = ids
    },
    up(item){
      const ids = this.resume_question_ids
      ids.splice(item.recruitment_resume_question.index, 1)
      ids.splice(item.recruitment_resume_question.index-1, 0, item.id)
      this.resume_question_ids = ids
    },
    toggle(item){
      if(this.isSelected(item)){
        this.resume_question_ids = this.resume_question_ids.filter(id => String(id) != String(item.id))
      }else{
        this.resume_question_ids = [...this.resume_question_ids, item.id]
      }
      console.log(this.resume_question_ids)
    },
    isSelected(item){
      return this.resume_question_ids.includes(item.id)
    },
    onResumeQuestionRegister(){
      this.isShowResumeQuestionForm = false
      this.$emit('onResumeQuestionRegister')
    },
    sort(items){
      return items.sort((a, b) => {
        return b.using_frequency_rating_number - a.using_frequency_rating_number
      }).sort((a, b) => {
        return this.isSelected(b) - this.isSelected(a)
      }).sort((a, b) => {
        return a.recruitment_resume_question?.index - b.recruitment_resume_question?.index
      })
    },
    findResumeQuestion(id){
      return this.resumeQuestions.filter(resumeQuestion=>resumeQuestion.id==id)[0]
    },
    findRecruitmentResumeQuestionByResumeQuestionId(resume_question_id){
      return this.recruitmentResumeQuestions.filter(recruitmentResumeQuestion=>recruitmentResumeQuestion.resume_question_id==resume_question_id)[0]
    },
    t(key){
      return this.$t(`aggregates.RecruitmentColumnNames.${key}`)
    },
    setDefaultValues(){
      this.recruitmentResumeQuestions.forEach(recruitment_resume_question=>{
        const resume_question = this.findResumeQuestion(recruitment_resume_question.resume_question_id)
        if(resume_question){
          // mixin定義↓ falseの時に代入されたくないので。
          this.setIfEmpty(recruitment_resume_question, "is_display_in_resume", resume_question.default_is_display_in_resume)
          this.setIfEmpty(recruitment_resume_question, "num_of_approximate_characters_ja", resume_question.default_num_of_approximate_characters_ja)
          this.setIfEmpty(recruitment_resume_question, "num_of_approximate_words_en", resume_question.default_num_of_approximate_words_en)
        }
      })
    }
  },
  computed: {
    recruitment(){ return this.recruitmentParams.recruitment },
    recruitmentResumeQuestions(){ return this.recruitment.recruitment_resume_questions.filter(rrq => !rrq._destroy) },
    selectedResumeQuestions(){ return this.resumeQuestions.filter(rq => !!rq.recruitment_resume_question && !rq.recruitment_resume_question._destroy) },
    resume_question_ids: {
      get(){ return this.recruitmentResumeQuestions.filter(rrq => !rrq._destroy).sort((a, b) => a.index - b.index).map((rrq => rrq.resume_question_id)) },
      set(ids){
        const old_rrqs = this.recruitment.recruitment_resume_questions
        // 一回全部_destroyフラグを立てる
        this.recruitment.recruitment_resume_questions.forEach(rrq => {
          this.$set(rrq, '_destroy', true)
          this.$set(rrq, 'index', undefined)
        })
        // その後作成
        ids.forEach((id, index) => {
          const old_rrq = old_rrqs.find(rrq => rrq.resume_question_id == id)
          if(old_rrq){
            // すでにある時
            this.$set(old_rrq, '_destroy', false)
            this.$set(old_rrq, 'index', index)
          }else{
            // 新規の時
            const resume_question = this.findResumeQuestion(id)
            const new_rrq = this.recruitmentParams.build('recruitment_resume_questions')
            new_rrq.is_display_in_resume = resume_question.default_is_display_in_resume
            new_rrq.num_of_approximate_characters_ja = resume_question.default_num_of_approximate_characters_ja
            new_rrq.num_of_approximate_words_en = resume_question.default_num_of_approximate_words_en
            new_rrq.resume_question_id = id
            new_rrq.index = index
            this.recruitment.recruitment_resume_questions.push(new_rrq)
          }
        })
        this.recruitment.recruitment_resume_questions.sort((a, b) => a.index - b.index)
        this.$emit('update:recruitmentParams', this.recruitmentParams)
      }
    }
  },
  watch: {
    resumeQuestions: function(){
      this.setDefaultValues()
    },
    // recruitments: ()=>{
    //   this.setDefaultValues()
    // }
  }
}
</script>

<style>

</style>
