"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "uuid": null, "domain": null, "table": null });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "uuid": null, "domain": null, "table": null });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.get("/hfw/queries/get_notion_data", { params });
    }
};
