<template>
<div>
  <VDataTable
    :headers="header"
    :items="items"
  />
</div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array
    }
  },
  computed: {
    header() {
      return this.data[0].map((column, index)=>{
        return { text: column, value: String(index) }
      })
    },
    items(){
      return this.data.slice(1).map((row)=>{
        // if (ri == 0 ) { continue; }
        let rtval = {}
        row.forEach((columnValue, ci)=>{
          rtval[ci] = columnValue
        })
        return rtval
      })
    }
  },
}
</script>

<style>

</style>