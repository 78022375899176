"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "hf_member": { "id": null, "email": null, "phone_num": null, "first_name_ja": null, "last_name_ja": null, "first_name_en": null, "last_name_en": null, "nick_name_ja": null, "nick_name_en": null, "is_manager_of_applications": null, "trello_id": null, "notion_id": null, "slack_id": null, "available_times_google_calendar_id": null, "password": null } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "hf_member": { "id": null, "email": null, "phone_num": null, "first_name_ja": null, "last_name_ja": null, "first_name_en": null, "last_name_en": null, "nick_name_ja": null, "nick_name_en": null, "is_manager_of_applications": null, "trello_id": null, "notion_id": null, "slack_id": null, "available_times_google_calendar_id": null, "password": null } });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/hf_member__register_hf_member", { params });
    }
};
