<template>
  <VCard>
    <VContainer>
      <VForm ref="template_form">
        <VTextField label="テンプレート名" v-model="resumeQuestionTemplate.template_name[lang]"/>
        <VTextarea label="テンプレート文章" v-model="resumeQuestionTemplate.template_text"/>
        <template v-if="!persisted">
          <VChip class="warning">未保存</VChip> <br>
          <span>＊保存後にデフォルトテンプレートとして選択できます。</span>
        </template>
      </VForm>
    </VContainer>
  </VCard>
</template>

<script>
export default {
  props: {
    resumeQuestionTemplate: {
      type: Object,
    },
    lang: {
      type: String,
    }
  },
  methods: {
    t(key){
      return this.$t(`aggregates.ResumeQuestionColumnNames.${key}`)
    },
    validate(){
      return this.$refs.template_form.validate()
    }
  },
  computed: {
    persisted(){ return !!this.resumeQuestionTemplate.id }
  }
}


</script>

<style>
</style>