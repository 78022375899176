<template>
  <div>
    <InformationRow v-for="rowDatum in rowData" :key="rowDatum.name" :rowDatum="rowDatum" :darken="darken"></InformationRow>
  </div>
</template>

<script>
import InformationRow from "./InformationRow"

export default {
  name: "InformationContainer",
  props: {
    //[{ name: , text: , tableData: [ name: , text:  ] }]
    rowData: {
      type: Array
    },
    darken: {
      type: Boolean
    },
  },
  components: {
    InformationRow
  }
}
</script>

<style>

</style>