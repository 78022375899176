<template>
  <div>
    <h1>記事一覧</h1>
    <VContainer fluid>
      <VRow>
        <VCol cols="12" class="section" v-for="article in articles" :key="article.id">
          <h2>{{ article.title[lang] }}</h2>
          <p> 投稿予約日時: {{ article.publishAtText() }} </p>
          <VBtn class="error--text" elevation="0" @click="$router.push({name: 'ArticleShowPage', params: { id: article.id }})">
            詳しく見てみる
            <VIcon right color="error">mdi-arrow-right</VIcon>
          </VBtn>
          <VChip v-if="article.is_hidden" ><VIcon small left>mdi-cancel</VIcon>非公開中</VChip>
          <VChip v-else-if="article.isPublished()" color="error"><VIcon small left>mdi-checkbox-marked-circle</VIcon>公開予定</VChip>
          <VChip v-else color="success"><VIcon small left>mdi-checkbox-marked-circle</VIcon>公開中</VChip>
        </VCol>
      </VRow>
    </VContainer>
    <div class="btns">
      <VBtn class="my-1" @click="$router.push({name: 'ArticleRegisterPage'})">新規作成</VBtn>
    </div>
  </div>
  
</template>

<script>
import Article from "@/aggregates/article"
import { GetArticlesQueryService } from "hfapi";

export default {
  metaInfo() {
    return {
      title: `記事一覧ページ`,
    }
  },
  data(){
    return {
      articles: [],
    }
  },
  methods: {
    getArticles() {
      let params = new GetArticlesQueryService.Params;

      GetArticlesQueryService.execute(params).then((resp) => {
        this.articles = resp.data.map(a => new Article(a));
      });
    },
  },
  created() {
    this.getArticles();
  },
  computed: {
    lang(){
      return this.currentLocale
    }
  }
}
</script>

<style scoped>
.section{
  border-bottom: solid 2px #cccccc;
}
.btns {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 15%;
  right: 5%;
}
</style>