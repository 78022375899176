<template>
  <div>
    <VCheckbox v-model="neverBeenBachelor" label="I don't have academic background on bachelor."/>
    <UniversityForm :value="mainBachelorUserUniversity" :hiddenFields="hiddenFields.concat('university_academic_degree_id')" ref="form" :disabled="neverBeenBachelor"/>
  </div>
</template>

<script>
import { UserUpdateProfileCommandService, } from "hfapi";
import UniversityForm from './UniversityForm'
import { BACHELOR_ID } from '@@/config/constants'

export default {
  components: {
    UniversityForm,
  },
  props: {
    "value": {},
    hiddenFields: {
      type: Array,
      default: ()=>[]
    }
  },
  data(){
    return {
      neverBeenBachelor: false
    }
  },
  computed: {
    mainBachelorUserUniversity: {
      get(){
        return this.value.mainBachelorUserUniversity()
      },
      set(val){
        Object.assign(this.value.mainBachelorUserUniversity(), val)
      } 
    }
  },
  methods: {
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      if (this.neverBeenBachelor) { return true }
      else { return this.$refs.form.validate() }
    },
    defaultUniversityState(){
      const plainParams = new UserUpdateProfileCommandService.Params
      return Object.assign( plainParams.user.user_universities[0], { university_academic_degree_id: BACHELOR_ID, is_main_bachelor: true, is_latest: false } )
    }

  },
  created(){
    if (!this.value.mainBachelorUserUniversity()){
      const pushed = this.defaultUniversityState()
      this.value.user_universities.push(pushed)
    }
  }
};
</script>

<style>
</style>