<template lang="">
  <div>
    <VDataTable :headers="headers" :items="items" :items-per-page="-1">
      <template v-slot:item.actions="{ item }">
        <VBtn @click="goIndex(item.table_name)">一覧へ</VBtn>
      </template>
    </VDataTable>
  </div>
</template>
<script>
import { UpdateMasterDataCommandService } from "hfapi/master"

export default {
  data() {
    return {
      headers: [
        { text: "テーブル名", value: "table_name" },
        { text: "アクション", value: "actions" },
      ]
    }
  },
  computed: {
    table_names(){
      return Object.keys(new UpdateMasterDataCommandService.BuildableParams()).filter(key=>key!="table_name").concat(["universities"])
    },
    items() {
      return this.table_names.map(table_name=>{
        return {
          table_name: table_name,
        }
      })
    }
  },
  methods: {
    goIndex(table_name) {
      if(table_name=="universities"){
        this.$router.push({name: 'MasterUniversitiesIndexPage' })
      } else {
        this.$router.push({name: 'MasterTableIndexPage', params: { table_name: table_name } })
      }
    }
  },
}
</script>
<style lang="">
  
</style>