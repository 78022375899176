import Axios from "axios";
import Qs from "qs";
const axios = Axios.create({
    paramsSerializer: (params) => {
        // Qs is already included in the Axios package
        return Qs.stringify(params, {
            arrayFormat: "brackets",
            encode: false,
        });
    },
});
export default axios;
