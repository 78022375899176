import { Aggregates } from "hfapi";
import moment from "moment"

class Event extends Aggregates.Event {
  startAtStr(locale){
    return (locale == "ja" ? moment(this.start_at).format("YYYY年MM月DD日 HH:mm〜") : moment(this.start_at).format("DD MMM YYYY HH:mm〜"))
  }
  applyURL(campaign_name){
    if (this.apply_url) {
      return this.apply_url.replace("$Campaign", campaign_name);
    } else {
      return `https://docs.google.com/forms/d/e/1FAIpQLSeQAIOiBmNqWIToc9Gt9dJU39yL58559oD8_LrHv7V0FM1b-Q/viewform?usp=pp_url&entry.1264806093=${this.id}&entry.9800588=${this.title_text}&entry.818371017=${campaign_name}`
    }
  }
  startAtDate(){
    return new Date(this.start_at)
  }
  deadlineDate(){
    return new Date(this.deadline)
  }
  isInApplyTerm(){
    return this.deadline == null ? 
      new Date() < this.startAtDate() : 
      new Date() < this.deadlineDate()
  }
}
export default Event;
