import router from "@@/config/routes";
import store from "@@/store";

router.beforeEach((to, from, next) => {
  switch (to.meta.auth) {
    case "public":
      next();
      break;

    case "log_in":
      if (store.getters["auth/isSignIn"]) next();
      else next("/log_in");
      break;

    case "not_log_in":
      if (!store.getters["auth/isSignIn"]) next();
      else next("/");
      break;

    // サインインしてないとそもそも使えないからこれでいい
    default:
      if (store.getters["auth/isSignIn"]) next();
      else next("/log_in");
      break;
  }
});

export default router
