export const JAPAN_COUNTRY_ID = 109
export const UNIVERSITY_SCHOOL_CATEGORY_ID = 1
export const HIGH_SCHOOL_CATEGORY_ID = 2
export const BACHELOR_ID = 1
export const MASTER_ID = 2
export const DOCTOR_ID = 3
export const JAPANESE_LANGUAGE_ID = 1
export const ENGLISH_LANGUAGE_ID = 2;
export const LANGUAGE_ID_2_SYMBOL = { "2": "en", "1": "ja" };
export const LANGUAGE_SYMBOL_2_ID = { "en": "2", "ja": "1" };
export const STUDY_DEPARTMENT_CATEGORY_ID = {
  "other_arts": 15,
  "other_sciences": 37,
}
export const SKILL_CATEGORY_IDS = {
  "programming": 1,
  "cad": 2,
  "general skills": 3,
  "design": 4,
  "other": 5,
}