import moment from "moment";
import { LANGUAGE_ID_2_SYMBOL } from "@/config/constants.js";

export default {
  data(){
    return {
      moment: moment,
      validation: {
        required: value => !!value || typeof value == "boolean" || this.$t('shared.Validations.required'),
        notBlank: value => (!!value && value.length > 0) || this.$t('shared.Validations.required'),
      },
      VUE_APP_JTJ_HOST: process.env.VUE_APP_JTJ_HOST,
    }
  },
  computed: {
    isSignIn(){
      return this.$store.getters["auth/isSignIn"]
    },
    window(){ return window },
    hf_member() { return this.$store.state.hf_member },
    document(){ return document },
    console(){ return console },
    currentLocale(){
      if(!this.$store){ return 'ja' }
      if ( this.$store.state.page_language_id ){
        return LANGUAGE_ID_2_SYMBOL[this.$store.state.page_language_id]
      } else {
        // 初めて訪れた時
        this.$store.commit("setPageLang", "ja");
        return LANGUAGE_ID_2_SYMBOL[this.$store.state.page_language_id]
      }
    },
  },
  methods: {
    flash(message, type) {
      this.$store.dispatch('flash/show', {message: message, type: type})
    },
    assign(target, source){
      return Object.assign({}, target, source)
    },
    randHash(){
      const length = 16
      var result = "";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    copy(obj){
      return JSON.parse(JSON.stringify(obj))
    },
    master(key){
      this.$store.dispatch("master/fetch", key)
      return this.$store.state.master[key]
    },
    changePageLang(symbol) {
      this.$store.commit("setPageLang", symbol)
    },
    redirectOrPush(path) {
      const to = this.$route.query.redirect
      if (to && to != this.$route.path) this.$router.push(this.$route.query.redirect)
      else this.$router.push(path)
    },
    getYYYYMMDD(date){
      const offset = date.getTimezoneOffset()
      date = new Date(date.getTime() - (offset*60*1000))
      return date.toISOString().split('T')[0]
    },
    routerWithBlank(route){
      window.open(this.$router.resolve(route).href, '_blank');
    },
    setIfEmpty(object, key, value){
      if(object[key]===undefined || object[key]===null){
        object[key] = value
      }
    }
  },
    watch: {
    currentLocale: {
      handler: function(){
        this.$i18n.locale = this.currentLocale
        this.window.location.reload()
      }
    }
  },
};
