<template>
  <VRow>
    <VCol cols="12" class="pb-0">
      <MasterSelectField label="School Category" v-model="selectedSchoolCategoryId" :items="master('school_categories')" dense outlined/>
      <!-- <VChipGroup mandatory column v-model="selectedSchoolCategoryId" active-class="primary--text">
        <VChip
          v-for="schoolCategory in master('school_categories')"
          :key="schoolCategory.id" :value="schoolCategory.id"
        >
          {{ schoolCategory.name }}
        </VChip>
      </VChipGroup> -->
    </VCol>
    <VCol cols="12" class="py-0">
      <div v-if="selectedSchoolCategoryId == UNIVERSITY_SCHOOL_CATEGORY_ID">
        <UniversityForm v-model="latestUserPolymorphicSchool" :hiddenFields="hiddenFields" ref="universityForm"/>
      </div>
      <div v-else>
        <OtherSchoolForm v-model="latestUserPolymorphicSchool" :schoolCategoryId="selectedSchoolCategoryId" :hiddenFields="hiddenFields" ref="schoolForm"/>
      </div>
    </VCol>
  </VRow>
</template>

<script>
import { UserUpdateProfileCommandService } from "hfapi";
import { JAPAN_COUNTRY_ID, UNIVERSITY_SCHOOL_CATEGORY_ID } from '@@/config/constants'
import UniversityForm from './UniversityForm'
import OtherSchoolForm from './OtherSchoolForm'

export default {
  props: {
    "value": {},
    universityIndex: { default: 0 },
    otherSchoolIndex: { default: 0 },
    hiddenFields: {
      type: Array,
      default: () => []
    },
  },
  components: {
    UniversityForm,
    OtherSchoolForm,
  },
  data() {
    let p = new UserUpdateProfileCommandService.Params
    return {
      defaultStates: {
        userUniversity: () => Object.assign({}, p.user.user_universities[0]),
        userSchool: () => Object.assign({}, p.user.user_schools[0]),
      },
      masterData: {
        universities: [],
        departmentCategories: [],
      },
      selectedSchoolCategoryId: UNIVERSITY_SCHOOL_CATEGORY_ID,
      schoolCountryId: JAPAN_COUNTRY_ID, //日本のID
      UNIVERSITY_SCHOOL_CATEGORY_ID: UNIVERSITY_SCHOOL_CATEGORY_ID,
    };
  },
  computed: {
    latestUserPolymorphicSchool() {
      return this.value.latestUserPolymorphicSchool()
    }
  },
  methods: {
    validate(){
      if ( this.selectedSchoolCategoryId == UNIVERSITY_SCHOOL_CATEGORY_ID ) { return this.$refs.universityForm.validate() }
      else{ return this.$refs.schoolForm.validate() }
    },
  },
  watch: {
    "selectedSchoolCategoryId": {
      handler: function(){
        // 存在していたら削除する
        if( this.value.latestUserPolymorphicSchool() && this.value.latestUserPolymorphicSchool()?.school_category_id != this.selectedSchoolCategoryId ) {
          this.value.removeUserPolymorphicSchool( this.value.latestUserPolymorphicSchool() )
        }
        if (!this.value.latestUserPolymorphicSchool()){
          // 削除されて空になっているはずなので追加する
          if (this.selectedSchoolCategoryId == UNIVERSITY_SCHOOL_CATEGORY_ID){
            const haveDestroyedUU = this.value.user_universities.find( uU => uU.is_latest && uU._destroy )
            const pushed = haveDestroyedUU || this.defaultStates.userUniversity()
            Object.assign(pushed, { is_latest: true, _destroy: false, school_category_id: UNIVERSITY_SCHOOL_CATEGORY_ID, is_main_bachelor: false })
            this.value.appendUserPolymorphicSchool( pushed )
          }else{
            const haveDestroyedUS = this.value.user_schools.find( uS => uS.is_latest && uS._destroy )
            const pushed = haveDestroyedUS || this.defaultStates.userSchool()
            Object.assign(pushed, { is_latest: true, _destroy: false, school_category_id: this.selectedSchoolCategoryId, is_main_bachelor: false })
            this.value.appendUserPolymorphicSchool( pushed )
          }
        }
        this._computedWatchers.latestUserPolymorphicSchool.run()
      }
    },
  },
  created(){
    this.selectedSchoolCategoryId = this.value.latestUserPolymorphicSchool()?.school_category_id || UNIVERSITY_SCHOOL_CATEGORY_ID
    if ( !this.value.latestUserPolymorphicSchool() ){
      // 存在しなかったら作成する
      const haveDestroyedUU = this.value.user_universities.find( uU => uU.is_latest && uU._destroy )
      const pushed = haveDestroyedUU || this.defaultStates.userUniversity()
      Object.assign(pushed, { is_latest: true, _destroy: false, school_category_id: UNIVERSITY_SCHOOL_CATEGORY_ID, is_main_bachelor: false })
      this.value.appendUserPolymorphicSchool( pushed )
    }
  }
};
</script>

<style>
</style>
