<template>
  <VSimpleTable>
    <template v-slot:default>
      <thead>
        <tr>
          <th>Member</th>
          <th>作られたタイミング</th>
          <th>締め切り</th>
          <th>担当者</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="recommendMail in recommendMails" :key="recommendMail.id">
          <td> {{recommendMail.user.display_for_hf}} </td>
          <td> {{recommendMail.created_type}} </td>
          <td> <template v-if="recommendMail.due_date">{{ moment(recommendMail.due_date).format("MM月DD日")}}</template> </td>
          <td> <template v-if="recommendMail.hf_member">{{ recommendMail.hf_member.last_name_ja }} </template></td>
          <td> <VBtn @click="$router.push( { name: 'RecommendMailSetPage', params: { id: recommendMail.id } } )">設定ページ</VBtn> </td>
        </tr>
      </tbody>
    </template>
  </VSimpleTable>
</template>

<script>

export default {
  props: {
    recommendMails: {
      type: Array
    },
  }
}
</script>

<style>

</style>