import { Aggregates } from "hfapi";
import numberFormatter from "@/lib/numberFormatter";
import i18n from "@/config/i18n";
import moment from "moment";


class Company extends Aggregates.Company {
  employeesNationalities(){
    return this.company_annually_foreigner_data.map(company_annually_foreigner_datum => {
      return company_annually_foreigner_datum.country.name
    }).join(", ")
  }
  religiousConsiderationsText(){
    return this.company_religious_considerations.map(company_religious_consideration => {
      return company_religious_consideration.religious_consideration.name;
    }).join(", ")
  }
  relationCountriesText(type){
    const types = { "overseas_expansion_country": 1, "branch": 2, "client": 3}
    return this.company_country_relations
      ?.filter((company_country_relation)=>{ return company_country_relation.company_country_relation_type_id == types[type] })
      ?.map(company_country_relation => { return company_country_relation.country.name; })
      ?.join(", ") || ''
  }
  allowancesText(){
    return this.company_allowances.map( company_allowance=>{
      return company_allowance.allowance.name
    } ).join("\n")
  }
  insurancesText(){
    return this.company_insurances.map((company_insurance) => {
      return company_insurance.insurance.name;
    }).join("\n");
  }
  numOfEmployees(){
    return this.latest_company_annually_datum.num_of_employees;
  }
  capitalText(){
    return this.latest_company_annually_datum.capital
  }
  salesText(){
    if (!this.latest_company_annually_datum.sales_in_last_year) { return }
    return numberFormatter(this.latest_company_annually_datum.sales_in_last_year) + " yen"
  }
  numOfForeigners(){
    return this.latest_company_annually_datum?.num_of_foreigners || "";
  }
  numOfForeignersBreakdown(){
    const latest_nendo_year = Math.max(...(this.company_annually_foreigner_data.map((d) => { return d.nendo_year })))
    return this.company_annually_foreigner_data.filter((d) => {
      return d.nendo_year == latest_nendo_year
    }).map((ld) => {
      return ld.country.name
    }).join(", ")
  }
  numOfForeignersText(){
    let text = this.numOfForeigners()
    if (this.numOfForeignersBreakdown()) {
      text += " (" + this.numOfForeignersBreakdown() + ")"
    }
    return text
  }
  mainOfficeAddressText(){
    const mainOffice = this.company_offices.find((co) => co.is_main_office);
    if (!mainOffice) { return }
    return `${mainOffice.postal_code}
    ${mainOffice.address}
    `;
  }
  industrySectorsText(){
    return this.company_industry_sectors.map(company_industry_sector => {
      return company_industry_sector.industry_sector.name;
    }).join(", ")
  }
  get numberOfEmployees(){
    return this.latest_company_annually_data.num_of_employees
  }
  mainPerson(){
    return this.company_employees.find((company_employee)=>{
      return company_employee.is_main_person
    })
  }
  hfMember(){
    return this.company_hf_members[0].hf_member
  }
  emailsText(){
    return this.company_employees.map(company_employee=>{
      return `${company_employee.last_name}\n${company_employee.email}`
    }).join("\n")
  }
  housingSupportText(){
    if (!Array.isArray(this.housing_supports)) { return }
    return this.housing_supports.map((housing_support)=>{
      return `・${housing_support.name}`
    }).join("\n")
  }
  bonusPaidMonthText(){
    let months = JSON.parse(this.bonus_paid_months)
    if (!Array.isArray(months)) { return }
    return months.map((num)=>{
      return moment(new Date(0, num, 0)).locale(i18n.locale).format("MMM")
    }).join(", ")
  }
}

function t(key, options){
  return i18n.t(`aggregates.Recruitments.${key}`, options);
}


export default Company;
