<template lang="">
  <div>
    <VContainer>
      <h3>検索</h3>
      <MasterSelectField label="国" :items="master('countries')" v-model="params.q.country_id_eq" @change="retrieve_universities" />
      <VTextField label="大学名(ローカル名)" v-model="params.q.local_name_cont" @keydown="retrieve_universities_with_enter" />
      <VTextField label="大学名(英語)" v-model="params.q.english_name_cont" @keydown="retrieve_universities_with_enter" />
    </VContainer>
    <VContainer>
      <VBtn @click="$router.push({name: 'MasterUniversityCreatePage' })">新規作成</VBtn>
      <VBtn @click="downloadCsv()">CSVダウンロード</VBtn>
    </VContainer>
    <VDataTable :headers="headers" :items="items" :items-per-page="-1" @loading="loading">
      <template v-slot:item.actions="{ item }">
        <VBtn @click="$router.push({name: 'MasterUniversityEditPage', params: { id: item.id } })">編集</VBtn>
      </template>
    </VDataTable>
  </div>
</template>
<script>

import csvDownload from "@@/lib/csvDownload"
import { GetUniversitiesQueryService } from "hfapi"
import { JAPAN_COUNTRY_ID } from "@@/config/constants";

export default {
  data() {
    return {
      universities: [],
      params: new GetUniversitiesQueryService.BuildableParams(),
      loading: false,
      table_name: "universities",
    }
  },
  computed: {
    keys(){
      if (!this.universities[0]) { return [] }
      return Object.keys(this.universities[0])
    },
    headers(){
      return this.keys.map(key=>{
        return { text: key, value: key }
      }).concat([{ text: "Actions", value: "actions" }])
    },
    items(){
      return this.universities
    },
    twoDArray(){
      let array = []
      array.push(this.keys)
      this.universities.forEach(data => {
        array.push(this.keys.map(key=>data[key]))
      });
      return array
    }
  },
  methods: {
    retrieve_universities() {
      this.loading = true
      GetUniversitiesQueryService.execute(this.params).then(resp=>{
        this.universities = resp.data
        this.loading = false
      })
    },
    downloadCsv(){
      csvDownload(this.twoDArray , this.table_name)
    },
    retrieve_universities_with_enter(val){
      if(val.key == "Enter"){
        console.log(val)
        this.retrieve_universities()
      }
    }
  },
  created(){
    this.params.q.country_id_eq = JAPAN_COUNTRY_ID
    this.retrieve_universities()
  }
}
</script>
<style lang="">
  
</style>