<template>
  <div>
    <HfMemberSelectField v-model="hf_member_id" :items="master('hf_members')"/>
    <VBtn color="primary" @click="change">変更</VBtn>
  </div>
</template>

<script>
import { HfMemberAssignHfMemberToApplicationCommandService } from "hfapi"
export default {
  props: {
    application: {
      type: Object
    }
  },
  data() {
    return {
      hf_member_id: null
    }
  },
  methods: {
    change(){
      let params = new HfMemberAssignHfMemberToApplicationCommandService.Params()
      params.application.id = this.application.id
      params.hf_member.id = this.hf_member_id
      HfMemberAssignHfMemberToApplicationCommandService.execute(params).then(resp=>{
        this.application.assign(resp.data)
        this.flash("保存されました", "success")
      })
    }
  },
  watch: {
    "application.isFetched": {
      immediate: true,
      handler: function(){
        this.hf_member_id = this.application.hf_member?.id
      }
    }
  }
}
</script>
