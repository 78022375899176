<template>
  <div>
    <div v-if="success">
      <h3>You successfully Logged out.</h3>
    </div>
  </div>
</template>

<script>
export default {
   metaInfo() {
    return {
      title: `ログアウト`,
    }
  },
  computed: {
    success(){
      return !this.isSignIn
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit("auth/destroy")
    })
  }
}
</script>

<style>

</style>