<template>
  <VCard>
    <VCardText>
      <h3 v-text="userUniversity.university.local_name" class="mb-2"/>
      <h4 class="mt-3">ユーザー情報</h4>
      <VSimpleTable>
        <table>
          <tr> <td class="pr-3"><strong>学科系統</strong></td><td>{{ userUniversity.study_department_category.name }}</td> </tr>
          <tr> <td class="pr-3"><strong>卒業</strong></td><td>{{ moment(userUniversity.graduate_at).format("YYYY年M月") }}</td> </tr>
          <tr> <td class="pr-3"><strong>Grade</strong></td><td>{{userUniversity.university_academic_degree.name }}</td> </tr>
          <tr> <td class="pr-3"><strong>学生種別</strong></td><td>{{ userUniversity.university_student_category.name }}</td> </tr>
        </table>
      </VSimpleTable>
      <h4 class="mt-3">大学詳細</h4>
      <VSimpleTable>
        <table>
          <tr> <td class="pr-3"><strong>大学ランキング</strong></td><td>国内: {{userUniversity.university.local_ranking_text }}( 世界: {{ userUniversity.university.world_ranking }}位 )</td> </tr>
          <tr> <td class="pr-3"><strong>区分</strong></td><td>{{ userUniversity.university.university_control_type.name }}</td> </tr>
        </table>
      </VSimpleTable>
    </VCardText>
    <VCardActions class="mt-n10">
      <VSpacer/>
      <VBtn @click="show = !show" >
        大学情報編集<VIcon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</VIcon>
      </VBtn>
    </VCardActions>

    <VExpandTransition>
      <div v-show="show">
        <VDivider/>
        <VCardText>
          <h4>以下の内容はユーザーには表示されません</h4>
          <p>＊これらの情報はユーザーではなく、<strong>大学に紐づいて登録</strong>されます。</p>
          <MasterSelectField :items="master('university_control_types')" v-model="userUniversity.university.university_control_type_id" item-text="name_ja" label="区分"/>
          <VTextarea v-model="userUniversity.university.description" label="メモ"/>
        </VCardText>
        <VCardActions class="mt-n7">
          <VSpacer />
          <VBtn @click="submitDetail">保存</VBtn>
        </VCardActions>
      </div>
    </VExpandTransition>
  </VCard>
</template>

<script>
import {HfMemberRegisterUniversityDetailsCommandService} from "hfapi"
export default {
  props: {
    userUniversity: {required: true}
  },
  data: () => ({
    show: false
  }),
  methods: {
    submitDetail(){
      const params = new HfMemberRegisterUniversityDetailsCommandService.Params
      Object.assign(params.university, this.userUniversity.university)
      HfMemberRegisterUniversityDetailsCommandService.execute(params).then(() => {
        this.flash("保存しました．", 'notice')
      })
    }
  }
}
</script>

<style>

</style>