<template>
  <div>
    <InformationRow v-for="rowDatum in rowData" :key="rowDatum.name" :rowDatum="rowDatum" :darken="darken" ref="informationRow"></InformationRow>
  </div>
</template>

<script>
import InformationRow from "./InformationRow"

export default {
  name: "InformationContainer",
  props: {
    //[{ name: , text: , tableData: [ name: , text:  ] }]
    rowData: {
      type: Array
    },
    darken: {
      type: Boolean
    },
  },
  data() {
    return {
      isMounted: false
    }
  },
  computed: {
    show() {
      if(this.isMounted){
        this.$refs.informationRow?.some(ir=>{
          return ir.show
        })
      }
      return false;
    }
  },
  components: {
    InformationRow
  },
  mounted(){
    this.isMounted = true;
  }
}
</script>

<style>

</style>