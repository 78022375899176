import Query from "../../lib/queries_services/aggregates__event_application";
import BaseAggregate from "../../../base_aggregate";
class EventApplication extends BaseAggregate {
    container_hash() {
        return { "apply_at": null, "id": null, "event_id": null, "form_answer": { "form_id": null, "form_question_answers": [{ "form_question_id": null, "answer_text": null }] } };
    }
    query() {
        return Query;
    }
}
export default EventApplication;
