<template>
  <VContainer v-if="user.isFetched">
    <div v-for="viewName in Object.keys(views)" :key="viewName" class="mb-8">
      <h2 class="accent--text mb-3">{{views[viewName].header}}</h2>
      <Component
        :ref="viewName"
        :is="views[viewName].formClass"
        :value="user"
        :hiddenFields="views[viewName].absolutelyHiddenFields"
      />
    </div>
    <VRow>
      <VCol class="text-center">
        <VBtn @click="submit" class="primary">保存</VBtn>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { UserUpdateProfileCommandService } from "hfapi";
import User from "@/aggregates/user.js"

import JapaneseLevel from "@@/components/organisms/users/forms/JapaneseLevel";
import EnglishSkill from "@@/components/organisms/users/forms/EnglishSkill";
import JLPTLevel from "@@/components/organisms/users/forms/JLPTLevel";
import Language from "@@/components/organisms/users/forms/Language";
import Skill from "@@/components/organisms/users/forms/Skill";
import SkillDetail from "@@/components/organisms/users/forms/SkillDetail";
import School from "@@/components/organisms/users/forms/school/Main";
import AddSchools from "@@/components/organisms/users/forms/school/AddSchools";
import GetBachelor from "@@/components/organisms/users/forms/school/GetBachelor";
import HighSchool from "@@/components/organisms/users/forms/school/HighSchool";
import BasicProfile from "@@/components/organisms/users/forms/BasicProfile";
import JobHistories from "@@/components/organisms/users/forms/JobHistories";

export default {
  props: {
    user_id: {
    }
  },
  data(){
    return {
      user: new User(this.user_id),
      views: {
        BasicProfile: {
          header: this.t('basic_profile'),
          formClass: BasicProfile,
          absolutelyHiddenFields: ["is_hope_phone_consulting"],
          shouldHiddenFields: []
        },
        School: {
          header: this.t('latest_academic_background'),
          formClass: School,
        },
        GetBachelor: {
          header: this.t('bachelor_info'),
          formClass: GetBachelor,
        },
        HighSchool: {
          header: this.t('high_school_info'),
          formClass: HighSchool,
        },
        AddSchools: {
          header: "学校追加",
          formClass: AddSchools,          
        },
        JobHistories: {
          header: this.t('job_history'),
          formClass: JobHistories,
        },
        JapaneseLevel: {
          header: this.t('japanese_level'),
          formClass: JapaneseLevel,
        },
        JLPTLevel: {
          formClass: JLPTLevel,
        },
        EnglishSkill: {
          header: this.t('english_level'),
          formClass: EnglishSkill
        },
        Language: {
          header: this.t('language_abilities'),
          formClass: Language,
        },
        Skill: {
          header: this.t('skill'),
          formClass: Skill,
        },
        SkillDetail: {
          formClass: SkillDetail,
        },
      },
    }
  },
  methods: {
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    submit(){
      if ( this.validate() ){
        UserUpdateProfileCommandService.execute({user: this.user}).then(()=>{
          this.$emit('save')
          this.user.fetch()
          this.flash("保存されました")
        })
      } else {
        this.flash( "未入力の項目があります", "error" )
      }
    },
    validate(){
      let value = true
      Object.keys(this.views).forEach((viewName)=>{
        if ( !this.$refs[viewName][0].validate() ) { value = false; console.log(viewName); }
      })
      return value
    }
  },
}
</script>

<style scoped>
</style>
