<template>
  <div>
    <VBtn class="mb-4" @click="save">保存</VBtn>
    <DatePickerInMenu label="キャリア面談日程" v-model="params.user.career_meeting_at"></DatePickerInMenu>
    <VAutocomplete label="担当者" v-model="params.user.user_hf_member.hf_member_id" :items="hf_members" item-text="last_name_ja" item-value="id"></VAutocomplete>
    <h3>{{ user.status }}</h3>
    <VSwitch label="サポート可能！" v-model="params.user.is_able_to_be_supported" hide-details></VSwitch>
    <template v-if="!params.user.is_able_to_be_supported">
      <VCheckbox label="すでに内定を持っている" v-model="params.user.has_job_offer" hide-details></VCheckbox>
      <VCheckbox label="進学予定" v-model="params.user.will_go_next_education" hide-details></VCheckbox>
      <VCheckbox label="就職を諦めている" v-model="params.user.has_gived_up_jh" hide-details></VCheckbox>
    </template>
    <VTextarea label="めも" v-model="params.user.user_appraisal.memo" rows="20"></VTextarea>
    <VBtn @click="save">保存</VBtn>
  </div>
</template>

<script>
import { HfMemberSaveUserAppraisalCommandService } from "hfapi/jtj"
import { GetMasterDataQueryService } from "hfapi/master"
import DatePickerInMenu from "@@/components/molecules/DatePickerInMenu.vue"


export default {
  components: {
    DatePickerInMenu
  },
  props: {
    user: {
      type: Object
    }
  },
  data(){
    return {
      params: new HfMemberSaveUserAppraisalCommandService.Params(),
      hf_members: []
    }
  },
  methods: {
    save(){
      HfMemberSaveUserAppraisalCommandService.execute(this.params).then(resp=>{
        this.flash("保存されました", "success")
        console.log(resp);
        this.user.fetch()
      }).catch(e=>{
        console.log(e);
        this.flash(e.response.data, "error")
      })
    }
  },
  watch: {
    "user.isFetched": {
      handler: function(){
        if (this.params.user.id) { return }
        this.params.user.id = this.user.id
        this.params.user.career_meeting_at = new Date(this.user.career_meeting_at)
        this.params.user.is_able_to_be_supported = this.user.status==`サポート対象`
        this.params.user.has_job_offer = this.user.has_job_offer
        this.params.user.will_go_next_education = this.user.will_go_next_education
        this.params.user.has_gived_up_jh = this.user.has_gived_up_jh
        this.params.user.user_hf_member.hf_member_id = this.user.hf_member?.id
        this.params.user.user_appraisal.memo = this.user.user_appraisal?.memo
      }
    }
  },
  mounted(){
    console.log(this.user);
    GetMasterDataQueryService.execute({target: {hf_members: true}}).then(resp=>{
      this.hf_members = resp.data.hf_members
    })
  }
}
</script>

<style>

</style>