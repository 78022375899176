import Vue from 'vue'
import Vuex from 'vuex'

import PersistedState from 'vuex-persistedstate'

import auth from "@@/store/modules/auth";
import flash from "./modules/flash";
import hf_member from "./modules/hf_member";
import master from "./modules/master";
import { LANGUAGE_SYMBOL_2_ID } from "@/config/constants.js";

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    auth, flash, master, hf_member,
  },
  plugins: [PersistedState({
    key: 'jtj',
    paths: ['auth', 'hf_member', `page_language_id`],
    storage: window.localStorage
  })],
  state: {
    page_language_id: ""
  }, mutations: {
    setPageLang(state, symbol) {
      state.page_language_id = LANGUAGE_SYMBOL_2_ID[symbol];
    }
  }
})
