<template>
  <div>
    <VContainer fluid>
    <VChip>* 注意: 日本語と英語のタイトル、内容それぞれ全てが入力されていないと登録ができないのでお気をつけを！</VChip>
    <VRow>
      <VCol cols="6">
        <VCard>
          <VToolbar>
            <VTabs v-model="tab">
              <VTab href="#tab-ja">日本語</VTab>
              <VTab href="#tab-en">English</VTab>          
            </VTabs>
          </VToolbar>
          <VTabsItems v-model="tab" class="ma-5">
            <VTabItem value="tab-ja" >
              <ArticleForm :articleParams="articleParams" lang="ja"></ArticleForm>
            </VTabItem>
            <VTabItem value="tab-en" >
              <ArticleForm :articleParams="articleParams" lang="en"></ArticleForm>
            </VTabItem>
          </VTabsItems>
        </VCard>
      </VCol>
      <VCol cols="6">
        <h3>プレビュー</h3>
        <div style="overflow-y: scroll; height: 73vh;">
          <ArticlePreview :articleParams="articleParams" :lang="tab.split('-')[1]" ></ArticlePreview>
        </div>
      </VCol>
    </VRow>
    <VRow dense>
      <VCol>
        <VBtn @click="submit">Submit</VBtn>
      </VCol>
    </VRow>
  </VContainer>
  </div>
  
</template>

<script>
import ArticleForm from "@@/components/organisms/articles/ArticleForm";
import ArticlePreview from "@@/components/organisms/articles/ArticlePreview";
import { HfMemberRegisterArticleCommandService } from "hfapi"

export default {
  metaInfo() {
    return {
      title: `記事新規作成ページ`,
    }
  },
  components: {
    ArticleForm,
    ArticlePreview,
  },
  data(){
    return {
      articleParams: new HfMemberRegisterArticleCommandService.Params(),
      tab: "tab-ja",
    }
  },
  methods: {
    submit(){
      HfMemberRegisterArticleCommandService.execute(this.articleParams).then(resp=>{
          console.log(resp);
          this.$router.push({ name: "ArticleEditPage", params: {id: resp.data.id } })
          this.flash("保存に成功しました", "success")
      }).catch(e=>{
        console.log(e.response.data);
        this.flash(e.response.data, "error")
      })
    },
  },
}
</script>

<style>

</style>