<template>
  <v-snackbar :color="$store.state.flash.type" top :timeout="-1" v-model="$store.state.flash.isShown">
    <p @click="close">
      {{message}}
    </p>
    <div class="text-right">
      <VIcon @click="close">mdi-close</VIcon>
    </div>
  </v-snackbar>
</template>

<script>
export default {
  computed: {
    message(){
      return this.$store.state.flash.message.replaceAll(",", ",\n").replace(":", ":\n")
    }
  },
  methods: {
    close(){
      this.$store.commit('flash/close')
    }
  }
}
</script>

<style>

</style>