<template>
  <div>
    <VDataTable
      :headers="headers"
      :items="behaviors"
      :items-per-page="50"
      class="elevation-1"
    ></VDataTable>
  </div>
</template>

<script>
import Application from "@/aggregates/application"

export default {
  data() {
    return {
      application: new Application(this.$route.params.id),
      headers: [
        { text: "時刻", value: "at" },
        { text: "イベントタイプ", value: "event_type" },
        { text: "値", value: "value" },
      ]
    }
  },
  computed: {
    behaviors(){
      return this.application.getBehavior()?.map((behavior)=>{
        return {
          ...behavior,
          at: new Date(behavior.at).toLocaleString(),
        }
      })
    }
  },
  watch: {
    "application.isFetched": {
      handler: function(){
        this._computedWatchers.behaviors.run()
        this.$forceUpdate()
      }
    }
  },
}
</script>

<style lang="">
  
</style>