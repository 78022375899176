<template>
<div>
  <VContainer style="max-width: 800px;">
    <VImg :src="event.thumbnail_url" class="mb-3"></VImg>
    <h2>{{event.title[lang]}}</h2>
    <div class="mt-5">
      <VueMarkdown :source="event.body[lang]" v-if="event.body[lang]" class="eventdetail-markdown"/>
      <div class="text-center mt-8">
        <VBtn @click="window.open(event.applyURL(campaignText()), '_blank')">Apply</VBtn>
      </div>
    </div>
  </VContainer>
  <div class="btns">
      <VBtn :href="`${VUE_APP_JTJ_HOST}/#/events/${event.id}`" target="_blank" color="#33C8B2" class="my-1">JTJのページを開く</VBtn>
      <VBtn @click="$router.push({name: 'EventEditPage', params: { id: event.id }})" class="my-1">編集する</VBtn>
      <VBtn v-if="eventApplicationsSpreadsheetUrl" :href="eventApplicationsSpreadsheetUrl" target="_blank" color="#33C8B2" class="my-1">応募一覧(Spreadsheet)</VBtn>
      ＊現在参加者{{event.event_applications_count}}人
  </div>
</div>
</template>

<script>
import Event from "@/aggregates/event"
import VueMarkdown from 'vue-markdown'
import {GetSpreadSheetUrlCommandService} from "hfapi/hfw"

export default {
   metaInfo() {
    return {
      title: `${this.event.title.ja}-イベント詳細ページ`,
    }
  },
  components: {
    VueMarkdown,
  },
  data(){
    return {
      event: new Event(this.$route.params.id),
      eventApplicationsSpreadsheetUrl: "",
    }
  },
  methods: {
    campaignText() {
      return "TEST CAMPAIGN"
    },
  },
  computed: {
    lang(){
      console.log(this.event)
      return this.currentLocale
    }
  },
  watch: {
    "event.isFetched": function(){
      const params = new GetSpreadSheetUrlCommandService.Params()
      params.sheet_id = "jtj_event_applications"
      params.sheet_name = this.event.event_application_sheet_name_regexp
      GetSpreadSheetUrlCommandService.execute(params).then(resp=>{
        this.eventApplicationsSpreadsheetUrl = resp.data.url
      }).catch(err=>{
        console.log(err)
      })
    }
  }
}
</script>

<style>
  .eventdetail-markdown p {
    margin-bottom: 0px !important;
    line-height: 2.0;
    white-space: normal !important;
  }
  .eventdetail-markdown .table {
      width: 100%;
      margin-bottom: 1rem;
      color: #212529;
      border: 1px solid #dee2e6;
  }

  .eventdetail-markdown .table td,.eventdetail-markdown .table th {
      padding: .75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
      border: 1px solid #dee2e6;
  }

  .eventdetail-markdown .table thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
      border-bottom-width: 2px;
  }

  .eventdetail-markdown .table tbody+tbody {
      border-top: 2px solid #dee2e6
  }
  .eventdetail-markdown .table tbody tr:nth-of-type(odd) {
    background-color: #f5f5f5;
  }
  .eventdetail-markdown img {
    margin-top: 12px;
    width: 100%;
  }
  .eventdetail-markdown .table {
    border-collapse: collapse;
    empty-cells: hide;
  }
  .eventdetail-markdown h3 {
    margin-bottom: 8px;
  }

  .btns {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10%;
  right: 5%;
}

</style>
