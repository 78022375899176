"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "recruitment": { "id": null, "company_id": null, "title": { "ja": null, "en": null }, "simple_content": { "ja": null, "en": null }, "is_released": null, "notion_id": null, "is_japanese_only_for_resume_questions": null, "job_category_ids": null, "required_jlpt": null, "related_country_ids": null, "target_study_department_category_ids": null, "is_shown_on_top": null, "recruitment_detail": { "id": null, "salary_unit": null, "salary_min_for_professional_school_students": null, "salary_min_for_bachelor_students": null, "salary_min_for_master_students": null, "salary_min_for_doctor_students": null, "salary_min_for_mid_career": null, "salary_max_for_professional_school_students": null, "salary_max_for_bachelor_students": null, "salary_max_for_master_students": null, "salary_max_for_doctor_students": null, "salary_max_for_mid_career": null, "_destroy": null }, "required_japanese_level": { "speaking_level_id": null, "listening_level_id": null, "writing_level_id": null, "reading_level_id": null, "is_must": null }, "recruitment_company_offices": [{ "id": null, "description": { "ja": null, "en": null }, "_destroy": null, "company_office_id": null }], "recruitment_careers": [{ "id": null, "title": { "ja": null, "en": null }, "first_living_country_id": null, "last_living_country_id": null, "description": { "ja": null, "en": null }, "_destroy": null }], "recruitment_resume_questions": [{ "id": null, "resume_question_id": null, "index": null, "is_display_in_resume": null, "num_of_approximate_characters_ja": null, "num_of_approximate_words_en": null, "_destroy": null }], "images": [{ "id": null, "key": null, "description": null, "_destroy": null }] } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "recruitment": { "id": null, "company_id": null, "title": { "ja": null, "en": null }, "simple_content": { "ja": null, "en": null }, "is_released": null, "notion_id": null, "is_japanese_only_for_resume_questions": null, "job_category_ids": [], "required_jlpt": null, "related_country_ids": [], "target_study_department_category_ids": [], "is_shown_on_top": null, "recruitment_detail": { "id": null, "salary_unit": null, "salary_min_for_professional_school_students": null, "salary_min_for_bachelor_students": null, "salary_min_for_master_students": null, "salary_min_for_doctor_students": null, "salary_min_for_mid_career": null, "salary_max_for_professional_school_students": null, "salary_max_for_bachelor_students": null, "salary_max_for_master_students": null, "salary_max_for_doctor_students": null, "salary_max_for_mid_career": null, "_destroy": null }, "required_japanese_level": { "speaking_level_id": null, "listening_level_id": null, "writing_level_id": null, "reading_level_id": null, "is_must": null }, "recruitment_company_offices": [], "recruitment_careers": [], "recruitment_resume_questions": [], "images": [] } });
    }
    build(key) { return { "recruitment_company_offices": { "id": null, "description": { "ja": null, "en": null }, "_destroy": null, "company_office_id": null }, "recruitment_careers": { "id": null, "title": { "ja": null, "en": null }, "first_living_country_id": null, "last_living_country_id": null, "description": { "ja": null, "en": null }, "_destroy": null }, "recruitment_resume_questions": { "id": null, "resume_question_id": null, "index": null, "is_display_in_resume": null, "num_of_approximate_characters_ja": null, "num_of_approximate_words_en": null, "_destroy": null }, "images": { "id": null, "key": null, "description": null, "_destroy": null } }[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/hf_member__register_simple_recruitment", { params });
    }
};
