"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "full_text_search_query": null, "q": { "id_eq_any": null, "target_study_department_categories_id_eq_any": null, "company_office_prefectures_id_eq_any": null, "related_countries_id_eq_any": null, "required_jlpt_gteq": null, "is_available_from_overseas_true": null, "is_available_by_mid_career_true": null, "job_categories_id_eq_any": null, "recruitment_careers_first_living_country_id_eq_any": null, "recruitment_careers_last_living_country_id_eq_any": null, "recruitment_careers_is_work_globaly_true": null, "required_skills_id_eq_any": null, "is_released_true": null, "company_company_channel_id_eq_any": null, "resume_questions_id_eq_any": null } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "full_text_search_query": null, "q": { "id_eq_any": [], "target_study_department_categories_id_eq_any": [], "company_office_prefectures_id_eq_any": [], "related_countries_id_eq_any": [], "required_jlpt_gteq": null, "is_available_from_overseas_true": null, "is_available_by_mid_career_true": null, "job_categories_id_eq_any": [], "recruitment_careers_first_living_country_id_eq_any": [], "recruitment_careers_last_living_country_id_eq_any": [], "recruitment_careers_is_work_globaly_true": null, "required_skills_id_eq_any": [], "is_released_true": null, "company_company_channel_id_eq_any": [], "resume_questions_id_eq_any": [] } });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.get("/jtj/queries/hf_member__search_recruitments", { params });
    }
};
