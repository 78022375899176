<template lang="">
  <div>
    <VTextField v-model="formQuestion.title_texts[lang]" label="質問タイトル"/>
    <VTextarea v-model="formQuestion.description_texts[lang]" label="詳細説明"/>
    <VSelect v-model="formQuestion.question_type" :items="questionTypes" label="タイプ"/>
    <VSwitch v-model="formQuestion.required" label="必須"/>
    <VCard v-if="shouldShowOptionsForm">
      <VContainer>
        <h5>オプション</h5>
        <VRow v-for="(option) in options" :key="option.key" class="px-2">
          <VCol cols="1"><Component :is='optionHeadComponent' disable class="py-1 my-0" hide-details/></VCol>
          <VCol cols="10"><VTextarea v-bind:value="option.text" class="pt-0 mt-0" hide-details @input="onOptionChange($event, option)" rows="1" no-resize style="white-space: nowrap;" :ref="option.key" wrap="off"/></VCol>
          <VCol cols="1" class="pl-0"><VIcon @click="removeOption(option)" class="pt-1">mdi-delete</VIcon></VCol>
        </VRow>
        <VBtn @click="addBlankOption">オプション追加</VBtn>
      </VContainer>
    </VCard>
  </div>
</template>
<script>
export default {
  props: {
    formQuestion: { type: Object },
    lang: { type: String }
  },
  data() {
    return {
      questionTypes: [
        {value: "radio_button", text: "ラジオボタン"}, 
        {value: "check_box", text: "チェックボックス"}, 
        {value: "long_text", text: "長文"}, 
        {value: "short_text", text: "短分"}, 
        {value: "single_select", text: "単数選択式"}, 
        {value: "multi_select", text: "複数選択式"}
      ],
      options: [{text: "", key: "init"}]
    }
  },
  methods: {
    addBlankOption(){
      this.options.push({text: "", key: this.randHash()})
    },
    removeOption(option){
      this.options.remove(option)
    },
    onOptionChange(val, option){
      const optionTexts = val.split("\n")
      option.text = val
      if (optionTexts.length > 1){
        optionTexts.forEach((text, index)=>{
          if(index==0) {
            this.$nextTick(()=>{ option.text = text })
          } else {
            if(!this.options.map(option=>option.text).isInclude(text)){
              this.options.push({text: text, key: this.randHash()})
            }
          }
        })
        this.$nextTick(()=>{
          const inputEl = this.$refs[this.options.last().key][0].$el
          const input = inputEl.querySelector('input:not([type=hidden]),textarea:not([type=hidden])')
          input.focus()
        })
      }
    },
  },
  computed: {
    shouldShowOptionsForm(){
      const dontHaveToShowOptionsQuestionType = ["long_text", "short_text"]
      return !dontHaveToShowOptionsQuestionType.isInclude(this.formQuestion.question_type)
    },
    optionHeadComponent(){
      const obj = { radio_button: "VRadio", check_box: "VCheckbox" }
      return obj[this.formQuestion.question_type]
    }
  },
  watch: {
    options: {
      deep: true,
      handler: function(newValue) {
        this.formQuestion.option_texts[this.lang] = newValue?.map(obj=>obj.text)?.join(",")
      },
    },
  },
  mounted(){
    this.options = this.formQuestion.option_texts[this.lang]?.split(",")?.map(text=>{return {text: text, key: this.randHash()}}) || []
  }
}
</script>
<style lang="">
  
</style>