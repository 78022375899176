import { GetMasterDataQueryService } from "hfapi/master";

export default {
  namespaced: true,
  state: {
    countries: [],
    study_faculty_categories: [],
    study_department_categories: [],
    languages: [],
    prefectures: [],
    job_categories: [],
    industries: [],
    industry_sectors: [],
    university_academic_degrees: [],
    university_student_categories: [],
    school_categories: [],
    general_language_levels: [],
    speaking_levels: [],
    listening_levels: [],
    reading_levels: [],
    writing_levels: [],
    language_examinations: [],
    transport_means: [],
    company_country_relation_types: [],
    company_cultures: [],
    dresses: [],
    allowances: [],
    insurances: [],
    housing_supports: [],
    religious_considerations: [],
    personalities: [],
    selection_types: [],
    skill_categories: [],
    skills: [],
    skill_levels: [],
    user_surveys: [],
    job_history_quit_reasons: [],
    recruit_intl_students_reasons: [],
    hf_members: [],
    company_channels: [],
    academic_major_categories: [],
    academic_majors: [],
    loading: {
      countries: false,
      study_faculty_categories: false,
      study_department_categories: false,
      languages: false,
      prefectures: false,
      job_categories: false,
      industries: false,
      industry_sectors: false,
      university_academic_degrees: false,
      university_student_categories: false,
      school_categories: false,
      general_language_levels: false,
      speaking_levels: false,
      listening_levels: false,
      reading_levels: false,
      writing_levels: false,
      language_examinations: false,
      transport_means: false,
      company_country_relation_types: false,
      company_cultures: false,
      dresses: false,
      allowances: false,
      insurances: false,
      housing_supports: false,
      religious_considerations: false,
      personalities: false,
      selection_types: false,
      skill_categories: false,
      skills: false,
      user_surveys: false,
      job_history_quit_reasons: false,
      recruit_intl_students_reasons: false,
      hf_members: false,
      company_channels: false,
      academic_major_categories: false,
      academic_majors: false,
    }
  },
  mutations: {
    create(state, arg) {
      let key = arg.key
      let data = arg.data
      state[key] = data
    },
    loading(state, key){
      state.loading[key] = true
    },
    finishLoad(state, key){
      state.loading[key] = true
    }
  },
  actions: {
    fetch({ state, commit }, key) {
      if ( state.loading[key] || state[key].length > 0 ) return; // ロードされている場合は呼ばないで済むようにする
      let params = new GetMasterDataQueryService.Params
      params["target"][key] = true
      commit("loading", key)
      GetMasterDataQueryService.execute(params).then(resp=>{
        console.log(`${key} was fetched`);
        commit("create", { key: key, data: resp.data[key] } );
        commit("finishLoad", key)
      })
    },
  },
};
