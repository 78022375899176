<template>
  <div>
    <div v-if="company.company_internal_datum.resume_submit_alert">
      <h1 class="error--text">注意！！！！！！！！</h1>
      <h3 class="error--text">{{company.company_internal_datum.resume_submit_alert}}</h3>
    </div>
    <template v-if="company.mainPerson()">
      <MailGenerator
        v-if="isSetUpObjects"
        @send="send"
        :templateTitle="mailTemplate.title"
        :templateBody="mailTemplate.body"
        :locals="{application: application, company: company, user: user, recruitment: recruitment}"
        :address="company.mainPerson().email"
        :disableAddress="true"
        :sendding="sendding"
      />
    </template>
    <template v-else>
      <h3 class="error--text">エラー</h3>
      担当者の情報が登録されていません。
    </template>
  </div>
</template>

<script>
import MailGenerator from "@@/components/molecules/MailGenerator"
import { HfMemberSendResumeToCompanyCommandService } from "hfapi"

export default {
  components: {
    MailGenerator
  },
  props: {
    application: {
      type: Object
    },
    company: {
      type: Object
    },
    user: {
      type: Object
    },
    recruitment: {
      type: Object
    },
  },
  data(){
    return {
      mailTemplate: {
        title: templateTitle(),
        body: templateBody()
      },
      sendding: false
    }
  },
  computed: {
    isSetUpObjects(){
      if ( !(this.recruitment.id && this.application.id && this.company.id && this.user.id)  ) { return false  }
      return true
    }
  },
  methods: {
    send(sendedMail){
      let params = new HfMemberSendResumeToCompanyCommandService.Params
      params.application_id = this.application.id
      params.mail.title = sendedMail.title
      params.mail.body = sendedMail.body
      params.mail.as_hf_member_id = this.application.hfMember().id
      this.sendding = true
      HfMemberSendResumeToCompanyCommandService.execute(params).then(()=>{
        this.application.fetch()
        this.flash('送信が完了いたしました', 'success')
        this.sendding = false
      }).catch((e)=>{
        this.flash(e.response.data, 'error')
      })
    }
  }
}
function templateTitle(){
  return "【新規応募】書類選考のお願い (期限：<%=moment().subtract(7, 'days').format('MM月DD日')%>)（株式会社Harmony For）"
}
function templateBody(){
  return `
<%=company.official_name_ja%>
<%=company.mainPerson().last_name%>様

お世話になっております。
名古屋大学Tongaliプロジェクトの支援を受け外国人留学生の就職サポートをしております
株式会社Harmony Forの<%=application.hfMember().last_name_ja%>です。

貴社の<%=recruitment.job_category_name_of_company_text%>の求人に応募がございました。
履歴書を添付させていただきましたので、
お忙しいところ恐縮ですがご確認ください。

下記の応募者の概要です。
名前：<%=user.first_name%> <%=user.last_name%>
出身国：<%=user.home_country.name%>
大学名：<%=user.mainUniversity().name%>
卒業年月：<%=moment( user.mainUserUniversity().graduate_at ).format("YYYY年MM月")%>

以上となります。
どうぞご検討のほどよろしくお願いいたします。

<%=application.hfMember().last_name_ja%>
--------------------------------------------
株式会社Harmony For
〒450-6321
愛知県名古屋市中村区名駅1-1-1
JPタワー名古屋 21F
SPACES JPタワー名古屋
E-Mail <%=application.hfMember().email%>
--------------------------------------------
`
}
</script>