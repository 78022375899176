import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

// localeの設定ファイルを一括セット
const messages = {
  "ja": {},
  "en": {},
  "vi": {},
}

var requireComponent = require.context(
  "./locales",
  true,
  /[A-Z]\w+\.json$/
);

requireComponent.keys().forEach(function(fileName) {
  var json = requireComponent(fileName);

  Object.keys(messages).forEach( langKey => {
    // 今からアサインするオブジェクト
    var tmpObject = messages[langKey];
    // fileのNamespaceを取っていく
    var nameSpaceKeys = fileName.replace(/.json/, "").split("/")
    // assignしていく
    nameSpaceKeys.forEach((nsKey, index) => {
      if (nsKey == "."){ return; } // "." お断り
      tmpObject[nsKey] = tmpObject[nsKey] || {}; // tmpObjectに中身が入っていなかったらinitialize
      // 最後だったら値を入れる
      if (nameSpaceKeys.length == index + 1) {
        tmpObject[nsKey] = json[langKey];
      }else{
      // 最後じゃなければ、次へ繋ぐ
        tmpObject = tmpObject[nsKey]
      }
    });
  } )
});
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: "en", // set locale
  fallbackLocale: "en",
  messages, // set locale messages
});

export { messages }
export default i18n