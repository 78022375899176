<template>
  <VSimpleTable>
    <template v-slot:default>
      <thead>
        <tr>
          <th>Member</th>
          <th>作られたタイミング</th>
          <th>レコメンドメールを送った日</th>
          <th>何通目のリマインドか</th>
          <th>担当者</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="recommendMail in recommendMails" :key="recommendMail.id">
          <td> {{recommendMail.user.display_for_hf}} {{recommendMail.id}} </td>
          <td> {{recommendMail.created_type}} </td>
          <td> {{ moment(recommendMail.sent_recommend_at).format("MM月DD日")}} </td>
          <td> {{ recommendMail.num_of_sent_remind_mails }} </td>
          <td> {{ recommendMail.hf_member.last_name_ja }} </td>
          <td> <VBtn class="error" @click="stopRemind(recommendMail)">停止</VBtn> </td>
          <td> <VBtn @click="$router.push({name: 'RecommendMailSetRemindPage', params: { id: recommendMail.id }})">送信ページへ</VBtn> </td>
        </tr>
      </tbody>
    </template>
  </VSimpleTable>
</template>

<script>
import { HfMemberStopRecommendRemindCommandService } from "hfapi"

export default {
  props: {
    recommendMails: {
      type: Array
    }
  },
  methods: {
    stopRemind(recommendMail){
      HfMemberStopRecommendRemindCommandService.execute({recommend_mail: { id: recommendMail.id }}).then(()=>{
        this.flash("停止しました", "success")
        this.recommendMails.remove(recommendMail)
      })
    }
  }
}
</script>

<style>

</style>