<template>
  <div class="user-list">
    <VDataTable
    :headers="headers"
    :items="items"
    item-key="id"
    :single-expand="true"
    show-expand
    :expanded.sync="expanded"
    >
    <template v-slot:item.display_for_hf="{ item }">
      <h4><a @click="$router.push({name: 'UserDetailPage', params: { id: item.id }})">{{item.display_for_hf}} <VIcon>mdi-link</VIcon></a></h4>
    </template>
    <template v-slot:item.action_memo="{ item }">
      <ActionMemoForm :user="item.user" />
    </template>
    <template v-slot:item.applicationText="{ item }">
      <VContainer v-for="application in item.applications" :key="item.id+'__'+application.id">
        <h4><a @click="$router.push({name: 'ApplicationShowPage', params: {id: application.id}})">{{application.recruitmentExpression()}} <VIcon>mdi-link</VIcon></a></h4>
        <p>
          {{application.state_ja}}
        </p>
      </VContainer>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" v-if="expanded[0] == item">
        <VContainer>
          <p> {{item.user.user_appraisal.memo}} </p>
          <VBtn @click="expanded=[]">閉じる</VBtn>
        </VContainer>
      </td>
    </template>
    </VDataTable>
  </div>
</template>

<script>
import Application from "@/aggregates/application"
import ActionMemoForm from "@@/components/organisms/users/forms/ActionMemoForm.vue"
export default {
  components: {
    ActionMemoForm
  },
  props: {
    users: {
      type: Array
    }
  },
  data(){
    return {
      headers: [
        {text: "ユーザーid", value: "id",},
        {text: "ユーザー", value: "display_for_hf", width: "100px"},
        // {text: "ステータス", value: "status"},
        {text: "応募状況", value: "applicationText"},
        {text: "アクションめも", value: "action_memo", width: "360px"},
      ],
      expanded: []
    }
  },
  computed: {
    items(){
      return this.users.map(user=>{
        return {
          id: user.id,
          display_for_hf: user.display_for_hf,
          link: user.link,
          status: user.status,
          applications: user.active_applications.map(a=>new Application(a)),
          action_memo: user.user_appraisal?.action_memo,
          user: user,
        }
      })
    }
  },
  methods: {
    // kibelaLinks(item){
    //   if(item.user.)
    // }
  }
}
</script>

<style>
</style>