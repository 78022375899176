<template>
  <div>
    <ApplicationsList :applications="applications"></ApplicationsList>
  </div>
</template>

<script>
import ApplicationsList from "@@/components/organisms/applications/ApplicationsList.vue"
import {HfMemberSearchApplicationsQueryService} from "hfapi"

export default {
  components: {
    ApplicationsList
  },
  props: {
    user: {
      type: Object
    }
  },
  data(){
    return {
      applications: [],
      params: new HfMemberSearchApplicationsQueryService.Params()
    }
  },
  watch: {
    user: {
      deep: true,
      handler: function(){
        if ( this.params.q.user_id_eq_any ) { return }
        this.params.q.user_id_eq_any = [this.user.id]
        HfMemberSearchApplicationsQueryService.execute(this.params).then(resp=>{
          this.applications = resp.data
        })
      }
    }
  }
}
</script>

<style>

</style>