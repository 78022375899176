var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VDataTable',{attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"page":_vm.paginate.page_index,"items-per-page":_vm.paginate.rows_per_page,"server-items-length":_vm.paginate.server_item_length,"hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.paginate, "page_index", $event)}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'ApplicationShowPage', params: {id: item.id} },"target":"_blank"}},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"margin-bottom":"0"}},[_vm._v(_vm._s(item.user_name))]),_c('router-link',{attrs:{"to":{ name: 'UserDetailPage', params: {id: item.user_id} },"target":"_blank"}},[_vm._v(" ユーザー詳細へ ")])]}},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'CompanyEditPage', params: {id: item.company_id} },"target":"_blank"}},[_vm._v(" "+_vm._s(item.company_name)+" ")])]}},{key:"item.recruitment_title",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"margin-bottom":"0"}},[_vm._v(_vm._s(item.recruitment_title))]),_c('router-link',{attrs:{"to":{ name: 'RecruitmentShowPage', params: {id: item.recruitment_id} },"target":"_blank"}},[_vm._v(" 求人詳細へ ")])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('ApplicationStateAction',{attrs:{"application":item}})]}}],null,true)}),_c('VPagination',{attrs:{"length":_vm.paginate.page_length,"total-visible":7},model:{value:(_vm.paginate.page_index),callback:function ($$v) {_vm.$set(_vm.paginate, "page_index", $$v)},expression:"paginate.page_index"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }