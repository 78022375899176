<template>
  <VForm ref="form">
    <h4 class="mt-8 mb-2">基本情報</h4>
    <VTextField :label="t('official_name_ja')" v-model="company.official_name_ja" :rules="[validation.required]"/>
    <VTextField :label="t('official_name_en')" v-model="company.official_name_en" :rules="[validation.required]"/>
    <NotionUrlField v-model="company.notion_id" :rules="[validation.required]"/>
    <VBtn class="success" @click="register" :loading="loading">登録</VBtn>
  </VForm>
</template>

<script>
import { HfMemberRegisterCompanyCommandService } from "hfapi"
import NotionUrlField from '../../molecules/NotionUrlField.vue'

export default {
  components: {
    NotionUrlField
  },
  data(){
    return {
      company: (new HfMemberRegisterCompanyCommandService.BuildableParams()).company,
      loading: false
    }
  },
  methods: {
    validate(){
      return this.$refs.form.validate()
    },
    t(key){
      return this.$t(`aggregates.CompanyColumnNames.${key}`)
    },
    register(){
      this.company.logo = null
      this.loading = true
      HfMemberRegisterCompanyCommandService.execute({company: this.company}).then(resp=>{
        this.flash("保存に成功しました。", "success")
        this.$emit("register")
        console.log(resp)
      }).catch(e=>{
        console.log(e)
      }).fainally(()=>{
        this.loading = false
      })
    }
  },
}

</script>

<style>

</style>