<template>
  <div>
    <VSwitch v-model="showAfterSignup" label="サインアップ後の内容も表示する．"/>
    <h1 class="my-5 text-center"> {{ article.title[lang] }} </h1>
    <VueMarkdown :source="content" v-if="content" class="articledetail-markdown"/>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import { HfMemberRegisterArticleCommandService } from "hfapi"

export default {
  components: {
    VueMarkdown
  },
  props: {
    articleParams: {
      type: HfMemberRegisterArticleCommandService.Params,
    },
    lang: {
      type: String
    }
  },
  data(){
    return {
      showAfterSignup: true
    }
  },
  computed: {
    article() {
      return this.articleParams.article
    },
    content(){
      return this.article.content[this.lang] + (this.showAfterSignup ? "\n" + this.article.after_signup_content[this.lang] : "")
    }
  },
}
</script>

<style>
.articledetail-markdown p {
  margin-bottom: 0px !important;
  line-height: 2.2;
  white-space: normal !important;
}
.articledetail-markdown .table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    border: 1px solid #dee2e6;
}

.articledetail-markdown .table td,.articledetail-markdown .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
    border: 1px solid #dee2e6;
}

.articledetail-markdown .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    border-bottom-width: 2px;
}

.articledetail-markdown .table tbody+tbody {
    border-top: 2px solid #dee2e6
}
.articledetail-markdown .table tbody tr:nth-of-type(odd) {
  background-color: #f5f5f5;
}
.articledetail-markdown img {
  margin-top: 12px;
  width: 100%;
}
.articledetail-markdown .table {
  border-collapse: collapse;
  empty-cells: hide;
}
.articledetail-markdown h2 {
  color: #1d566b;
  border-left: 10px solid #1d566b;
  padding-left: 8px;
  margin-top: 20px;
  margin-bottom: 12px;
}
.articledetail-markdown h3 {
  color: #1d566b;
  margin-top: 16px;
  margin-bottom: 8px;
}
.articledetail-markdown li {
  line-height: 2.2;
}
</style>