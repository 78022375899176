<template>
  <VRow v-if="show">
    <VCol>
      <h4 v-if="rowDatum.name" class="mb-2" :class="{'primary--text': !darken, linkClass: true}" @click="openLink">{{rowDatum.name}}</h4>
      <p v-if="rowDatum.text"> {{ rowDatum.text }} </p>
      <VRow v-for="tableDatum in rowDatum.tableData" :key="tableDatum.name">
        <template v-if="showTable(tableDatum)">
          <VCol :cols="headerColsNum(tableDatum)" :md="headerMdNum(tableDatum)">
            <h4 class="grey--text text--darken-2">
              {{tableDatum.name}}
            </h4>
          </VCol>
          <VCol :cols="paragraphColsNum(tableDatum)" :md="paragraphMdNum(tableDatum)">
            <p> {{tableDatum.text}} </p>
          </VCol>
          <template v-if="tableDatum.hint">
            <VCol cols="12">
              <p class="font-italic"> * {{ tableDatum.hint }} </p>
            </VCol>
          </template>
        </template>
      </VRow>
      <p v-if="rowDatum.hint" class="font-italic"> * {{ rowDatum.hint }} </p>
    <v-divider></v-divider>
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: "InformationRow",
  props: {
    // { name: , text: , link: , tableData: [ name: , text:  ] }
    rowDatum: {
      type: Object
    },
    darken: {
      type: Boolean
    },
  },
  computed: {
    show(){
      if (this.rowDatum.text){ return true  }
      if ( this.rowDatum.tableData?.filter((data)=>{ return data.text})?.length >= 1 ){ return true }
      return false
    },
    openLink(){
      // return ()=>{
      //   console.log("aaa");
      // }
      if ( this.rowDatum.link ){
        return ()=>{
          this.window.open(this.rowDatum.link, "_blank")
        }
      } else {
        return ()=>{}
      }
    },
    linkClass(){
      return this.rowDatum.link ? "text-decoration-underline" : ""
    }
  },
  methods: {
    showTable(tableDatum){
      if (tableDatum.text) { return true }
      return false
    },
    headerColsNum(tableDatum){
      if (tableDatum.horizontal) { return 12 }
      else { return 6 }
    },
    paragraphColsNum(tableDatum){
      if (tableDatum.horizontal) { return 12 }
      else { return 6 }
    },
    headerMdNum(tableDatum){
      if (tableDatum.horizontal) { return 12 }
      else { return 4 }
    },
    paragraphMdNum(tableDatum){
      if (tableDatum.horizontal) { return 12 }
      else { return 8 }
    },
  }
}
</script>

<style>

</style>