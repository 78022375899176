<template>
  <VContainer>
    <h2 class="my-6">応募用のキャンペーンリンクが作れます</h2>
    <UtmLinkBuilder
      :defaultBaseUrl="defaultBaseUrl"
      defaultCampaignName="share_recruitment"
      defaultCampaignSource="lists"
      defaultCampaignMedium="email"
      :defaultCampaignContent="getYYYYMMDD(new Date())"
      defaultCampaignTerm=""
    />
  </VContainer>
</template>

<script>
import UtmLinkBuilder from "@@/components/organisms/UtmLinkBuilder.vue"
export default {
  components: {
    UtmLinkBuilder,
  },
  computed: {
    defaultBaseUrl(){
      return `${this.VUE_APP_JTJ_HOST}/#/recruitments/${this.$route.params.id}/apply`
    }
  }
}
</script>
