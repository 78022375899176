<template>
  <div>
    <VTextField :label="t('nendo_year')" v-model="company_annually_datum.nendo_year" type="number" :rules="[validation.required]"/>
    <VTextField :label="t('sales_in_last_year')" v-model="company_annually_datum.sales_in_last_year" type="number"/>
    <VTextField :label="t('capital')" v-model="company_annually_datum.capital" type="number"/>
    <VTextField :label="t('num_of_employees')" v-model="company_annually_datum.num_of_employees" type="number"/>
    <VTextField :label="t('num_of_employees_with_consolidated_companies')" v-model="company_annually_datum.num_of_employees_with_consolidated_companies" type="number"/>
    <VTextField :label="t('num_of_foreigners')" v-model="company_annually_datum.num_of_foreigners" type="number"/>
  </div>
</template>

<script>
export default {
  props: {
    company_annually_datum: {
      type: Object
    }
  },
  methods: {
    t(key) {
      return this.$t(`aggregates.CompanyColumnNames.${key}`)
    }
  },
}
</script>

<style>

</style>