"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "mail": { "subject": null, "body": null, "to": null, "attachments": [{ "file_name": null, "mime_type": null, "content": null, "encoding": null }] } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "mail": { "subject": null, "body": null, "to": [], "attachments": [] } });
    }
    build(key) { return { "attachments": { "file_name": null, "mime_type": null, "content": null, "encoding": null } }[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/hf_member__send_mail", { params });
    }
};
