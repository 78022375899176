export default function(){
  // 配列周り
  Array.prototype.remove = function(item) {
    const index = this.indexOf(item);
    if (index > -1) {
      this.splice(index, 1);
    }
    return this
  };
  Array.prototype.distinct = function() {
    return [...new Set(this)]
  };
  Array.prototype.isInclude = function(item) {
    const index = this.indexOf(item);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  };
  Array.prototype.compact = function(){
    return this.filter((value)=>{
      return !!value
    })
  }
  Array.prototype.last = function(){
    return this[this.length-1]
  }
}

