<template>
  <div>
    <VFileInput
    v-bind="$attrs"
    v-model="files"
    @click:clear="onClear"
    :counter-string="`ファイル数: ${base64Files.length}`"
    />
  </div>
</template>
<script>
/*
全体の更新の流れとして
1. VFileInputで@changeが発火される
2. filesのsetが呼ばれる
3. filesのsetの中でbase64Filesの新しい値をemitする
4. 親コンポーネントでv-modelの値が更新される
5. bindされているbase64Filesの値が更新される(このコンポーネント内)
6. filesが更新されて、新しくgetされる

一番怖いのは「間違ったファイルを添付して消したけど、裏で残ってた」みたいなのが怖いのでここをいじる人はそれを注意。
履歴書とか他の人のを添付してしまっていて、消したつもりだったが、送ってしまった。とか問題レベル大なので。
*/

export default {
  model: {
    prop: 'base64Files',
    event: 'change'
  },
  props: {
    base64Files: { type: Array, default: ()=>[] }, // base64エンコードされているcontentを持ったオブジェクトが格納されている(bindされている)
  },
  computed: {
    // Fileオブジェクトが格納されている。
    files: {
      get() {
        return this.base64Files.map(file=>new File([file.content], file.file_name))
      },
      set(files){
        // filesには新規で登録されたファイルが入ってくる
        // 過去に登録されていたファイルはbase64Filesにしかない
        files.forEach(this.setBase64File)
      }
    },
    fileNames(){
      return this.base64Files.map(file=>file.file_name)
    }
  },
  methods: {
    setBase64File(file){
      if(this.fileNames.isInclude(file.name)) { return } // 同じファイル名がすでにアップロードされていたらスキップ
      const reader = new FileReader();
      reader.addEventListener("load", ()=>{
        const base64File = {
          file_name: file.name,
          mime_type: file.type,
          content: reader.result,
          encoding: "base64"
        }
        this.$emit("change", [...this.base64Files, base64File])
      }, false)
      reader.readAsDataURL(file)
    },
    onClear(){
      this.$emit("change", [])
    },
  },
}
</script>
<style>
  
</style>