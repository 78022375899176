<template>
  <div class="resume-question-form">
    <h3 class="accent--text">◇ {{resumeQuestion.title[resumeQuestionsLocale]}}</h3>
    <div class="mt-3 mb-3">
      <template v-if="!!resumeQuestion.purpose[resumeQuestionsLocale]">
        <VueMarkdown :source="resumeQuestion.purpose[resumeQuestionsLocale]"/>
      </template>
      <template v-if="!!resumeQuestion.explanation[resumeQuestionsLocale]">
        <VueMarkdown :source="resumeQuestion.explanation[resumeQuestionsLocale]" />
      </template>
    </div>
    <!-- <VSelect :items="resumeQuestion.resume_question_templates" v-model="templateID" item-text="template_name_text" item-value="id" :label="t('resume_question_template', resumeQuestionsLocale)" v-if="false"/> -->
    <VSelect :items="reusableAnswers" @input="reuse" item-text="answer" return-object :label="t('reusable_answers', resumeQuestionsLocale)" v-show="isShownReusableAnswers" />
    <VTextarea background-color="white" outlined v-bind="$attrs" v-model="resumeQuestionAnswer.answer" :rules="[validation.required]" :hint="resumeQuestionsLocale != 'ja' ? this.t('english_ok') : this.t('japanese_only')" :counter="counterNum" :counter-value="counterValueFunction">
      <template v-slot:counter="slotProps">
        {{slotProps.props.value}}/{{slotProps.props.max}} {{counterUnit}}
      </template>
    </VTextarea>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
export default {
  components:{
    VueMarkdown
  },
  props: {
    recruitmentResumeQuestion: {
      type: Object,
      required: true,
    },
    resumeQuestionAnswer: {
      type: Object,
      required: true,
    },
    resumeQuestionsLocale: {
      type: String,
      required: true,
    }
  },
  methods: {
    reuse(e){
      if(confirm(this.t('overwrite?', this.resumeQuestionsLocale))){
        this.resumeQuestionAnswer.answer = e.answer
      }
    },
    t(key, options){
      return this.$t(`pages.applications.ApplicationResumeEditPage.${key}`, options)
    },
    setTemplate(id){
      if(!!this.resumeQuestionAnswer.answer){ confirm(this.t('overwrite?', this.resumeQuestionsLocale)) }
      this.resumeQuestionAnswer.answer = this.resumeQuestion.resume_question_templates?.find(rqt => String(rqt.id) == String(id))?.template[this.resumeQuestionsLocale]
      this.resumeQuestionAnswer.resume_question_template_id = id
    }
  },
  computed: {
    resumeQuestion(){ return this.resumeQuestionAnswer.resume_question || {}},
    reusableAnswers(){ return this.resumeQuestionAnswer.resume_question?.reusable_answers?.filter(ra => ra.resume_question_template_id == this.resumeQuestionAnswer.resume_question_template_id) || [] },
    templateID: {
      get(){ return this.resumeQuestionAnswer.resume_question_template_id },
      set(val){
        this.setTemplate(val)
      }
    },
    isShownReusableAnswers(){
      return this.resumeQuestion.is_allow_reuse && this.reusableAnswers.length>0
    },
    counterNum(){
      if(this.isAnswerJapanese){
        return this.recruitmentResumeQuestion.num_of_approximate_characters_ja || this.recruitmentResumeQuestion.resume_question.default_num_of_approximate_characters_ja || 150
      } else {
        return this.recruitmentResumeQuestion.num_of_approximate_words_en || this.recruitmentResumeQuestion.resume_question.default_num_of_approximate_words_en || 80
      }
    },
    counterValueFunction(){
      return this.isAnswerJapanese ? null :
        function(text){
          return String(text).split(" ").length
        }
    },
    counterUnit(){
      return this.isAnswerJapanese ? this.t("chars") : this.t("words")
    },
    isAnswerJapanese(){
      if(this.resumeQuestionsLocale=="ja") { return true } // 日本語だったら日本語で判定
      let japaneseCharsLength = String(this.resumeQuestionAnswer.answer).split("").filter(char=>/[^\x00-\x7E]+/.test(char))?.length
      return japaneseCharsLength / this.resumeQuestionAnswer.answer?.length >= 0.5 // 50%くらい日本語だったら日本語と判定
    }
  },
  mounted(){
    // 現在の回答にテンプレートが指定されていなくて，デフォルトテンプレートが指定されていた時
    if(false && !this.resumeQuestionAnswer.resume_question_template_id && !this.resumeQuestionAnswer.answer && !!this.resumeQuestion.default_template_id){ // とりあえず無効化
      this.setTemplate(this.resumeQuestion.default_template_id)
    }
  },
}
</script>

<style>
  .resume-question-form p {
    white-space: normal !important;
  }
</style>