import Vue from "vue";
import App from "./App.vue";
import VueMeta from "vue-meta";
import VueRouter from "vue-router";
import router from "./config/router";
import hfapiConfigure from "./config/hfapi";
import hfapiConfigureFront from "@/config/hfapi"
import vuetify from "@@/plugins/vuetify"; // path to vuetify export
import Vuex from "vuex";
import mixin from "@@/mixins/mixin";
import DatetimePicker from "vuetify-datetime-picker";
import store from "./store";
import i18n from "@/config/i18n";
import MasterSelectField from "@@/components/organisms/shared/MasterSelectField.vue";
import HfMemberSelectField from "@@/components/organisms/shared/HfMemberSelectField.vue";
import SelectFieldCountryRich from "@@/components/organisms/shared/SelectFieldCountryRich.vue";
import ValidationViewer from "@@/components/molecules/ValidationViewer.vue";
import YearMonthPicker from "@@/components/molecules/YearMonthPicker.vue";
import CheckboxDefaultFalse from "@@/components/atoms/checkboxes/CheckboxDefaultFalse.vue";
import extensions from "@@/lib/extensions";


Vue.use(VueMeta);
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(DatetimePicker);
Vue.use(Vuex);
Vue.mixin(mixin);
Vue.component("MasterSelectField", MasterSelectField);
Vue.component("HfMemberSelectField", HfMemberSelectField);
Vue.component("SelectFieldCountryRich", SelectFieldCountryRich);
Vue.component("ValidationViewer", ValidationViewer);
Vue.component("YearMonthPicker", YearMonthPicker);
Vue.component("CheckboxDefaultFalse", CheckboxDefaultFalse);
hfapiConfigure();
hfapiConfigureFront();
extensions();

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
