<template>
  <div>
    <VDataTable
      :items="items"
      :headers="headers"
      :loading="loading"
      :page.sync="paginate.page_index"
      :items-per-page="paginate.rows_per_page"
      :server-items-length="paginate.server_item_length"
      hide-default-footer
    >
    <template v-slot:[`item.id`]="{item}">
      <router-link :to="{ name: 'ApplicationShowPage', params: {id: item.id} }" target="_blank">
        {{item.id}}
      </router-link>
    </template>
    <template v-slot:[`item.user_name`]="{item}">
      <p style="margin-bottom:0;">{{ item.user_name }}</p>
      <router-link :to="{ name: 'UserDetailPage', params: {id: item.user_id} }" target="_blank">
        ユーザー詳細へ
      </router-link>
    </template>
    <template v-slot:[`item.company_name`]="{item}">
      <router-link :to="{ name: 'CompanyEditPage', params: {id: item.company_id} }" target="_blank">
        {{item.company_name}}
      </router-link>
    </template>
    <template v-slot:[`item.recruitment_title`]="{item}">
      <p style="margin-bottom:0;">{{ item.recruitment_title }}</p>
      <router-link :to="{ name: 'RecruitmentShowPage', params: {id: item.recruitment_id} }" target="_blank">
        求人詳細へ
      </router-link>
    </template>
    <template v-slot:[`item.state`]="{item}">
      <ApplicationStateAction :application="item"></ApplicationStateAction>
    </template>
    </VDataTable>
    <VPagination
      v-model="paginate.page_index"
      :length="paginate.page_length"
      :total-visible="7"
  ></VPagination>
  </div>
  
</template>


<script>
import { HfMemberGetApplicationsQueryService } from "hfapi"
import Application from "@/aggregates/application"
import Recruitment from "@/aggregates/recruitment";
import Company from "@/aggregates/company";
import User from "@/aggregates/user";
import ApplicationStateAction from "@@/components/organisms/applications/ApplicationStateAction.vue"

export default {
  metaInfo() {
    return {
      title: `応募一覧`,
    }
  },
  components: {
    ApplicationStateAction,
  },
  data() {
    return {
      applications: [],
      headers: [
        {text: "応募id", value: "id"},
        {text: "応募日", value: "created_at"},
        {text: "ユーザー名", value: "user_name", width: "320"},
        {text: "企業名", value: "company_name", width: "196"},
        {text: "求人タイトル", value: "recruitment_title", width: "320"},
        {text: "求人職種", value: "recruitment_job_categories", width: "320"},
        {text: "現在の状況", value: "state", width: "320"},
        {text: "めも", value: "memo", width: "320"},
        {text: "面接の段階", value: "latest_interview_number", width: "128"},
        {text: "面接の場所", value: "latest_interview_place", width: "320"},
        {text: "担当", value: "hf_member", width: "128"},
      ],
      loading: false,
      paginate: {
        page_index: 1,
        rows_per_page:10,
        server_item_length: 0,
        page_length: 0
      },
    }
  },
  computed: {
    items() {
      return this.applications.map(application => {
        const user = new User(application.user)
        const company = new Company(application.company)
        const recruitment = new Recruitment(application.recruitment)
        return new Application({
          id: application.id,
          created_at: application.createdAtText(),
          user_name: user.display_for_hf,
          user_id: user.id,
          company_name: company.official_name_ja,
          company_id: company.id,
          recruitment_id: recruitment.id,
          recruitment_title: recruitment.title_text,
          recruitment_job_categories: recruitment.jobCategoryText(),
          state: application.state,
          memo: application.memo,
          latest_interview_number: application.latestInterviewNumberText(),
          latest_interview_place: application.latestInterviewPlace(),
          hf_member: application.hf_member?.last_name_ja,
          user: user,
          company: company,
          recruitment: recruitment,
          // link: application.link,
          // application: application
        })
      })
    },
  },
  methods: {
    getApplications() {
      this.loading = true
      let params = new HfMemberGetApplicationsQueryService.Params;
      params.limit = this.paginate.rows_per_page;
      params.offset = this.paginate.rows_per_page * (this.paginate.page_index - 1);

      HfMemberGetApplicationsQueryService.execute(params).then((resp) => {
        this.applications = resp.data.applications.map(a => new Application(a));
        // respからpaginateへ反映させる
        this.paginate.server_item_length = resp.data.total_application_count;
        this.paginate.page_length = Math.ceil(this.paginate.server_item_length / this.paginate.rows_per_page)
        this.loading = false
      });
    },
  },
  created() {
    this.getApplications();
  },
  watch: {
    "paginate.page_index": function () {
      this.getApplications();
    }
  }
}

</script>

<style scoped>

</style>