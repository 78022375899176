<template>
  <div>
    <VForm class="mt-8" ref="QuestionsForm" v-if="initialized">
      <div
        v-for="recruitmentResumeQuestion in recruitmentResumeQuestions.toSorted((a,b) => a.index - b.index)"
        :key="recruitmentResumeQuestion.resume_question_id"
      >
        <ResumeQuestionForm
          :resumeQuestionAnswer="findResumeQuestionAnswer(recruitmentResumeQuestion.resume_question.id)"
          :resumeQuestionsLocale="resumeQuestionsLocale"
          :recruitmentResumeQuestion="recruitmentResumeQuestion"
          ref="form"
        />
      </div>
    </VForm>
  </div>
</template>

<script>
// recruitmentResumeQuestionsとresumeParamsを受け取ってフォームを作成する責務

import ResumeQuestionForm from './ResumeQuestionForm.vue'

export default {
  components:{
    ResumeQuestionForm,
  },
  props: {
    resumeParams: {
      type: Object,
      required: true,
    },
    recruitmentResumeQuestions: {
      type: Array,
      required: true,
    },
    resumeQuestionsLocale: {
      type: String,
    },
  },
  data() {
    return {
      initialized: false
    }
  },
  methods: {
    t(key, options){
      return this.$t(`pages.applications.ApplicationResumeEditPage.${key}`, options)
    },
    validate(){
      if(this.hasCustomResumeQuestion){
        return this.$refs.QuestionsForm?.validate()
      }else{
        return this.$refs.DefaultQuestionsForm?.validate()
      }
    },
    initResumeQuestionAnswer(){
      const resumeQuestionAnswers = this.resumeParams.application.resume.resume_question_answers
      // resume_question_answersをresume_questionsと一致させる
      resumeQuestionAnswers.forEach(rqa => {
        const recruitmentResumeQuestion = this.recruitmentResumeQuestions.find(rrq => rrq.resume_question.id == rqa.resume_question_id)
        if(!recruitmentResumeQuestion){
          rqa._destroy = true
        }
      })
      // まだ答えたことない質問を追加する．
      this.recruitmentResumeQuestions.forEach(rrq => {
        const rqa = resumeQuestionAnswers.find(rqa => rqa.resume_question_id == rrq.resume_question.id)
        if(!rqa){
          const newRqa = this.resumeParams.build("resume_question_answers")
          newRqa.resume_question_id = rrq.resume_question.id
          newRqa.resume_question = rrq.resume_question
          resumeQuestionAnswers.push(newRqa)
        }
      })
      this.resumeParams.application.resume.resume_question_answers = this.resumeParams.application.resume.resume_question_answers.filter(rqa=>rqa.resume_question_id)
      this.initialized = true
    },
    localeChanged(locale) {
      Object.keys(this.$refs).forEach(ref => {
        this.$refs[ref].localeChanged(locale)
      })
    },
    findResumeQuestionAnswer(resume_question_id){
      return this.resumeQuestionAnswers.find(resumeQuestionAnswer=>resumeQuestionAnswer.resume_question_id==resume_question_id)
    }
  },
  computed: {
    hasCustomResumeQuestion(){ return this.resumeQuestionAnswers.length != 0 },
    resumeQuestionAnswers(){ return this.resumeParams.application.resume.resume_question_answers.filter(rqa => !rqa._destroy && !!rqa.resume_question_id).sort((a, b) => a.resume_question.index - b.resume_question.index) },
  },
  mounted(){
    this.initResumeQuestionAnswer()
  }
}
</script>

<style>

</style>
