<template>
  <VAutocomplete
    v-model="selectedId"
    :hide-no-data="!search"
    :items="selectItems"
    :search-input.sync="search"
    item-text="name"
    item-value="id"
    ref="select"
    comment="ここから下は外から受け継ぐもの。(これはただのコメントです。プログラムには影響0)"
    :rules="rules"
    :dense="dense"
    :outlined="outlined"
    :disabled="disabled"
    :label="label"
    :filter="filter()"
  >
    <!-- <template v-slot:no-data>
      <VListItem>
        {{ search }}
      </VListItem>
    </template> -->
  </VAutocomplete>
</template>

<script>
/*
全体として
value.university → ユーザー作成のUniversityを表す
value.university_id → マスターデータのUniversityを表す
という方針。

VAutocompleteに渡している"selectItems"には、
[ マスターデータの大学 ] + ユーザー作成のUniversityを表すUniversityObject
が格納されている。

ユーザー作成のUniversityを表すUniversityObjectは二種類のidを持ち得て、それぞれ、「新規」と「更新」を表す
a. NULL_EXPRESS_NUM
→ 新規でユーザー作成の大学を登録
b. ユーザー作成の大学のid
→ ユーザー作成の大学を更新

以上より、selectedIdが取りうる値は
1. マスターデータの大学id
2. NULL_EXPRESS_NUM
3. ユーザー作成の大学のid
となる。

それぞれ、selectedIdのどれが選択されているかによって、

新規作成時
1. マスターデータの大学id
→ マスターデータの大学を登録
→ value.university_idに格納し、value.university._destroyをtrueへ
2. NULL_EXPRESS_NUM
→ ユーザー作成の大学を登録
→ value.university_idをnullへ、value.universityに値を格納(名前等)
3. ユーザー作成の大学のid
→ とりえない

更新時
1. マスターデータの大学id
→ マスターデータの大学へ更新
→ value.university_idに格納し、value.university._destroyをtrueへ
2. NULL_EXPRESS_NUM
→ 取らせてはいけない
→ 取らないように注意
3. ユーザー作成の大学id
→ ユーザー作成の大学を更新
→ value.university_idをnullへ、value.university.idにちゃんと値を入れてから、value.universityに値を格納(名前等)

あとはフロント側からマスターデータの大学データを変えられないように、
API側で、hf_member_approvedのものは変更不可にしておく。

また、value.universityはcreatedの段階で値が入っている前提

dataのuserCreatedUniversityItemは、searchのところでうまくやってくれるためのもの
*/
const NULL_EXPRESS_NUM = -100
import User from "@/aggregates/user"
export default {
  props: {
    items: {
      type: Array,
      default: ()=>[]
    },
    value: {
      // user_universityをもらう
    },
    rules: {},
    dense: {},
    outlined: {},
    disabled: {},
    label: {},
  },
  data() {
    return {
      search: "",
      selectedId: null,
      userCreatedUniversityItem: null,
      pastId: null, // 更新時用。value.university.idの保存ボックス,
    }
  },
  computed: {
    selectItems(){
      console.log("selectItems updated");
      const copyItems = Array(...this.items)
      if ( !copyItems.find((univ)=> univ.id == this.userCreatedUniversityItem.id ) ){
        // [ マスターデータの大学 ] + ユーザー作成のUniversityを表すUniversityObjectをselectItemsにする
        copyItems.push(this.userCreatedUniversityItem)
      }
      return copyItems
    },
  },
  methods: {
    filter(){
      let count = 0
      return (item, queryText, itemText) => {
        // userCreatedUniversityItemは、一個も答えがない時だけだす。
        if ( item.id == this.userCreatedUniversityItem.id ) { return count == 0 } 
        if (itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1) {
          count += 1
          return true
        } else {
          return false
        }
      }
    },
    setUniversityDestroy(){
      // selectedIdがユーザー作成大学を作ろうor更新しようとしていたら、false
      if ( this.selectedId == NULL_EXPRESS_NUM || this.selectedId == this.pastId ) { this.$set(this.userCreatedUniversityItem, '_destroy', false) }
      // selectedIdがマスターデータを登録しようとしていたら、true
      else { this.$set(this.userCreatedUniversityItem, '_destroy', true) }
    },
    initializeUserCreatedUniversityItem(){
      // ユーザー作成大学を登録済みの時
      if (this.value.university?.is_created_by_user){
        this.userCreatedUniversityItem = this.copy(this.value.university) // 値をコピーしておく
      }
      // ユーザー作成大学が未登録の時(新規 or マスター登録中)
      if ( !this.value.university?.is_created_by_user ) {
        // const blankUser = 
        this.userCreatedUniversityItem = this.copy(new User().user_universities[0].university) // 元を作る
        this.userCreatedUniversityItem.world_ranking = 20000
        this.userCreatedUniversityItem.is_created_by_user = true
        this.userCreatedUniversityItem.id = NULL_EXPRESS_NUM
      }
    },
    setUserCreatedUniversityItem(){
      this.$nextTick(()=>{
        this.setUserCreatedUniversityItemName()
        this.$nextTick(()=>{
          this.$refs.select._computedWatchers.filteredItems.run()
          this.$refs.select._computedWatchers.computedItems.run()
          this.$refs.select.$forceUpdate()
          console.log("$forceUpdate");
        })
      })
    },
    setUserCreatedUniversityItemName(){
      if ( !this.search ) { return }  // 存在しない場合は同期させない(更新時の初期状態対策)
      if ( String(this.search).match(/^[a-zA-Z0-9 ,\(\)-\.'’　]*$/g) ){ //eslint-disable-line
        // 英語のとき
        this.userCreatedUniversityItem.local_name = this.search
        this.userCreatedUniversityItem.english_name = this.search
        this.userCreatedUniversityItem.name = this.search
      } else {
        // 現地語？の時
        this.userCreatedUniversityItem.local_name = this.search
        this.userCreatedUniversityItem.english_name = ""
        this.userCreatedUniversityItem.name = this.search
      }
    },
  },
  created(){
    // console.log("UniversitySelect created");
    this.selectedId = this.value.university_id // 新規だったらnil, 更新ならidが入るので好都合
    this.pastId = this.value.university.id // 新規だったらnil, 更新ならidが入るので好都合
    this.initializeUserCreatedUniversityItem() // 初期値をセット
    // this.setUserCreatedUniversityItemName() // 名前を更新(?)
  },
  watch: {
    "selectedId": function(){
      console.log("アバタ", this.selectedId);
      if ( this.selectedId == NULL_EXPRESS_NUM ) {
        // 新規ユーザー作成大学の時
        console.log("新規ユーザー");
        this.value.university_id = null
        this.$set(this.userCreatedUniversityItem, '_destroy', false)
        this.value.university = this.copy(this.userCreatedUniversityItem)
        this.value.university.id = null
      }
      else if ( this.pastId && this.selectedId == this.pastId && this.value.university?.is_created_by_user ){
        // ユーザー作成大学を更新したい時
        console.log("更新ユーザー");
        this.value.university_id = this.selectedId
        this.$set(this.userCreatedUniversityItem, '_destroy', false)
        this.value.university = this.copy(this.userCreatedUniversityItem)
      } else if (this.selectedId) {
        // 普通のマスターにある大学の時
        // console.log("マスター");
        this.value.university_id = this.selectedId
        this.value.university = null
        this.$set(this.userCreatedUniversityItem, '_destroy', true)
      }
      // console.log("タバタ", this.selectedId);
    },
    "search": {
      handler: function(){
        // console.log(this.search);
        // 1. 一番最初: userCreatedUniversityItemを頑張ってselectの選択肢に出したい
        // → computedItemsが再読み込みされるより前に、userCreatedUniversityItemを更新することで、選択肢に出てくるようにしている
        //   1. this.userCreatedUniversityItem.name = this.search を代入
        //   2. computedItemsが更新される
        //   3. 無事、選択肢としてuserCreatedUniversityItemが現れる
        // 
        // 2. 代入後の編集時:
        //   - 課題1: selectedIdが入った状態で入力をすると、「lazySearchとselectedItem.textが同じ間はfilterをしない」というVAutocompleteのご好意で、ずっとfilterが走らないままになってしまう(searchとuserCreatedUniversityItemを同期させているため)
        //   - 課題2: ならば、selectedIdをnullにしてやろう。とすると、「selectedIdがnullになると、searchを空にする」というVAutocompleteのご好意で、searchが空になってしまう (& 同時にuserCreatedUniversityItem.nameも空にされる)
        //   - 以上の課題に以下の方法で対処した
        //     - for 課題1
        //       1. selectedIdが入っていて、そのselectedIdがuserCreatedUniversityItemだった時、selectedIdをnullにする
        //     - for 課題2
        //       1. selectedIdをnilにする前に, tmpSearchに値を格納しておく
        //       2. selectedIdがnilにされた時に、searchが空にされ、そのさいのwatchで、tmpSearchを戻す
        //       ＊ nextTickにしているのは、地続きで行うと、select.lazySearchに値がなぜか伝搬されなかったため。(そうするとフィルタがかからない)


        // ↓↓↓↓↓「2. 代入後の編集時」のため↓↓↓↓↓
        if ( this.selectedId && this.selectedId == this.userCreatedUniversityItem.id && this.$refs.select.isFocused ) {
          console.log("selectedIdをnilへ");
          console.log(this.search);
          tmpSearch = this.search // for 課題2
          this.selectedId = null // for課題1
        }
        if (!this.search && !this.selectedId && tmpSearch){
          // for課題2
          this.$nextTick(()=>{
            this.search = tmpSearch
            tmpSearch = null
          })
        }
        // ↑↑↑↑↑↑「2. 代入後の編集時」のため↑↑↑↑↑↑

        // ↓↓↓↓↓↓「1. 一番最初」のため↓↓↓↓↓↓
        this.setUserCreatedUniversityItemName()
        // ↓↓↓↓↓↓「1. 一番最初」のため↓↓↓↓↓↓
      }
    },
  }
}
var tmpSearch = ""

</script>

<style>

</style>

