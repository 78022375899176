<template>
  <VDataTable :items="data" :headers="headers" @click:row="showUserDetail" >
    <template v-slot:[`item.application_id`]="{ value }">
      <a @click.stop="$router.push({name: 'ApplicationShowPage', params: { id: value }})">{{value}}</a>
    </template>
    <template v-slot:[`item.visit_at`]="{ value }">
      {{ moment(value).format("YYYY-MM-DD"), }}
    </template>
  </VDataTable>
</template>

<script>
import {HfMemberGetRecruitmentPageViewsQueryService} from "hfapi"

export default {
  props: {
    recruitment_id: { type: String, required: true},
  },
  data(){
    return {
      data: [],
      headers: [
        { text: 'UserID', value: 'user_id' },
        { text: '名前', value: 'user.full_name' },
        { text: '出身国', value: 'user.home_country_name' },
        { text: '回数', value: 'count' },
        { text: '応募', value: 'application_id' },
        { text: '最終閲覧日時', value: 'visit_at' },
      ]
    }
  },
  methods: {
    showUserDetail(e){
      this.$router.push({name: "UserDetailPage", params: { id: e.user_id }})
    }
  },
  created(){
    const params = new HfMemberGetRecruitmentPageViewsQueryService.Params
    Object.assign(params, {recruitment_id: this.recruitment_id})
    HfMemberGetRecruitmentPageViewsQueryService.execute(params).then(resp => {
      this.data = resp.data
    })
  }
}
</script>

<style>

</style>