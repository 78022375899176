<template>
  <VForm class="my-3">
    <VContainer>
      <VTextarea label="アクションめも" v-model="user.user_appraisal.action_memo" outlined rows="7"/>
      <VBtn color="primary" @click="save">保存</VBtn>
      <VBtn @click="addTimestamp" class="ml-3">タイムスタンプ追加</VBtn>
    </VContainer>
  </VForm>
</template>

<script>
import { HfMemberSaveUserActionMemoCommandService } from "hfapi"

export default {
  props: {
    user: {}
  },
  methods: {
    save(){
      const params = new HfMemberSaveUserActionMemoCommandService.Params
      params.user.id = this.user.id
      params.user.user_appraisal.action_memo = this.user.user_appraisal.action_memo
      HfMemberSaveUserActionMemoCommandService.execute(params).then(resp=>{
        console.log(resp);
        this.flash("保存されました", "success")
      })
    },
    addTimestamp(){
      this.user.user_appraisal.action_memo = `---------------------------\n${this.moment().format("YYYY-MM-DD HH:mm")}\n\n\n` + (this.user.user_appraisal.action_memo || "")
    }
  }
}
</script>

<style>

</style>