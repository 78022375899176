<template>
  <div>
    <VTextField label="面談用リンク" v-model="interviewLink" placeholder="以下のリンクから面談の日時を予約してください！ {**ここに入る**}" />
    <p class="warning--text">Gmailの送信画面へ移ります</p>
    <VBtn @click="send" color="primary">面談セッティングメールの送信へ移る</VBtn>
  </div>
</template>

<script>
import { HfMemberGetSetupInterviewWithHfMailQueryService, HfMemberGetGmailUrlCommandService } from 'hfapi/jtj'

export default {
  explanation: "HFとの面談をセッティングするメールを送る",
  props: {
    application: {
      type: Object
    }
  },
  data(){
    return {
      interviewLink: ""
    }
  },
  methods: {
    async send(){
      const mailParams = new HfMemberGetSetupInterviewWithHfMailQueryService.Params
      mailParams.application_id = this.application.id
      mailParams.interview_link = this.interviewLink
      const resp = await HfMemberGetSetupInterviewWithHfMailQueryService.execute(mailParams)
      const gmailUrlParams = new HfMemberGetGmailUrlCommandService.Params
      gmailUrlParams.mail = resp.data
      const gmailUrlResp = await HfMemberGetGmailUrlCommandService.execute(gmailUrlParams)
      window.open(gmailUrlResp.data, '_blank')
    }
  }
}
</script>

<style>

</style>