import { render, staticRenderFns } from "./ResumeQuestionRecruitments.vue?vue&type=template&id=366cb700&scoped=true"
import script from "./ResumeQuestionRecruitments.vue?vue&type=script&lang=js"
export * from "./ResumeQuestionRecruitments.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.10.0_ejs@3.1.10_vue-template-compiler@2.6.11_webpack@5.90.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "366cb700",
  null
  
)

export default component.exports