<template>
  <VContainer>
    <VRow>
      <VCol cols="12">
        <h4>日本語</h4>
        <VTextarea label="日本語" :value="hf_member.meeting_date_str_ja" ref="ja"/>
        <VBtn @click="copy('ja')">コピー</VBtn>
      </VCol>
      <VCol cols="12">
        <h4>英語</h4>
        <VTextarea label="英語" :value="hf_member.meeting_date_str_en" ref="en"/>
        <VBtn @click="copy('en')">コピー</VBtn>
      </VCol>
      <div v-show="false">
        {{hf_member}}
      </div>
    </VRow>
  </VContainer>
</template>

<script>
export default {
   metaInfo() {
    return {
      title: `面談可能日程詳細ページ`,
    }
  },
  methods: {
    copy(refname){
      const element = this.$refs[refname].$el.querySelector("textarea")
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand("copy");
    },
  },
  created(){
    this.$store.dispatch("hf_member/updateFullData")
  }
}
</script>

<style>

</style>