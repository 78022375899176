export const JAPAN_COUNTRY_ID = 109
export const UNIVERSITY_SCHOOL_CATEGORY_ID = 1
export const HIGH_SCHOOL_CATEGORY_ID = 2
export const BACHELOR_ID = 1
export const MASTER_ID = 2
export const DOCTOR_ID = 3
export const JAPANESE_LANGUAGE_ID = 1
export const ENGLISH_LANGUAGE_ID = 2
export const LANGUAGE_SYMBOL_2_ID = { ja: "1", en: "2", af: "3", sq: "4", am: "5", ar: "6", hy: "7", az: "8", eu: "9", be: "10", bn: "11", bs: "12", bg: "13", ca: "14", ceb: "15", "zh-CN": "16", "zh-TW": "17", co: "18", hr: "19", cs: "20", da: "21", nl: "22", eo: "23", et: "24", fi: "25", fr: "26", fy: "27", gl: "28", ka: "29", de: "30", el: "31", gu: "32", ht: "33", ha: "34", haw: "35", he: "36", hi: "37", hmn: "38", hu: "39", is: "40", ig: "41", id: "42", ga: "43", it: "44", jw: "45", kn: "46", kk: "47", km: "48", ko: "49", ku: "50", ky: "51", lo: "52", la: "53", lv: "54", lt: "55", lb: "56", mk: "57", mg: "58", ms: "59", ml: "60", mt: "61", mi: "62", mr: "63", mn: "64", my: "65", ne: "66", no: "67", ny: "68", ps: "69", fa: "70", pl: "71", pt: "72", pa: "73", ro: "74", ru: "75", sm: "76", gd: "77", sr: "78", st: "79", sn: "80", sd: "81", si: "82", sk: "83", sl: "84", so: "85", es: "86", su: "87", sw: "88", sv: "89", tl: "90", tg: "91", ta: "92", te: "93", th: "94", tr: "95", uk: "96", ur: "97", uz: "98", vi: "99", cy: "100", xh: "101", yi: "102", yo: "103", zu: "104" };
export const LANGUAGE_ID_2_SYMBOL = swap(LANGUAGE_SYMBOL_2_ID);
export const STUDY_DEPARTMENT_CATEGORY_ID = {
  "other_arts": 15,
  "other_sciences": 37,
}
export const SKILL_CATEGORY_IDS = {
  "programming": 1,
  "cad": 2,
  "general skills": 3,
  "design": 4,
  "other": 5,
}

function swap(json){
  var ret = {};
  for(var key in json){
    ret[json[key]] = key;
  }
  return ret;
}