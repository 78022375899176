<template>
  <div>
    <VBtn @click="decline">HF判断不合格</VBtn>
  </div>
</template>

<script>
import { HfMemberDeclineApplicationCommandService } from "hfapi"
export default {
  props: {
    application: {
      type: Object
    }
  },
  methods: {
    decline(){
      let params = new HfMemberDeclineApplicationCommandService.Params()
      params.application.id = this.application.id
      HfMemberDeclineApplicationCommandService.execute(params).then(resp=>{
        console.log(resp);
        console.log("保存されました");
      })
    }
  }
}
</script>
