<template>
  <div>
    <MasterSelectField :label="t('country_id')" v-model="company_country_relation.country_id" :items="master('countries')" :rules="[validation.required]"/>
    <MasterSelectField :label="t('company_country_relation_type_id')" v-model="company_country_relation.company_country_relation_type_id" :items="master('company_country_relation_types')" :rules="[validation.required]"/>
  </div>
</template>

<script>
export default {
  props: {
    company_country_relation: {
      type: Object
    }
  },
  methods: {
    t(key) {
      return this.$t(`aggregates.CompanyColumnNames.${key}`)
    }
  },
}
</script>

<style>

</style>