"use strict";
export default class BaseAggregate {
    constructor(arg) {
        this.init();
        if (typeof arg == "string" || typeof arg == "number") {
            // idの時はfetch
            this.fetch(arg);
        }
        if (typeof arg == "object") {
            // objectの時はassignするだけ
            Object.assign(this, arg);
            if (this.after_assign)
                this.after_assign();
        }
    }
    init() {
        Object.assign(this, this.container_hash());
        this.isFetched = false;
    }
    fetch(id) {
        if (!id) {
            id = this.id;
        }
        this.query().execute({ id }).then(resp => {
            Object.assign(this, resp.data);
            this.isFetched = true;
            this.after_assign();
        }).catch(resp => {
            console.log(resp);
        });
    }
    after_assign() {
        // throw new Error("Method not implemented.");
    }
    assign(value) {
        Object.assign(this, value);
        this.isFetched = true;
    }
}
