<template>
  <div>
    <VTextField label="キーワード" v-model="queryParams.full_text_search_query"/>
    <VTextField label="会社名" v-model="localFilter.company_official_name_ja_cont"/>
    <RecruitmentSearchDetailForm v-model="queryParams"/>
    <MasterSelectField label="チャネル" :items="master('company_channels')" v-model="queryParams.q.company_company_channel_id_eq_any" multiple chips deletable-chips/>
    <div>
      <p v-if="loading">
        <VProgressCircular :indeterminate="loading"/> Searching...
      </p>
      <p v-else>
        Result: {{filteredRecruitments.length}}
      </p>
    </div>
    <RecruitmentsList :recruitments="filteredRecruitments" @clickRecruitmentCard="clickRecruitmentCard"/>

  </div>
</template>

<script>
import { HfMemberSearchRecruitmentsQueryService } from "hfapi/jtj"
import { GetMasterDataQueryService } from "hfapi/master"
import RecruitmentsList from "@@/components/organisms/recruitments/RecruitmentsList.vue"
import Recruitment from "@/aggregates/recruitment"
import RecruitmentSearchDetailForm from "@@/components/organisms/recruitments/RecruitmentSearchDetailForm.vue"

export default {
  components: {
    RecruitmentsList,
    RecruitmentSearchDetailForm,
  },
  props: {
    queryParams: {
      type: HfMemberSearchRecruitmentsQueryService.Params,
      default: ()=>{
        return new HfMemberSearchRecruitmentsQueryService.Params()
      }
    }
  },
  data(){
    return {
      masterData: {
        study_department_categories: [],
        countries: [],
      },
      recruitments: [],
      localFilter: {
        company_official_name_ja_cont: null,
      },
      loading: false,
    }
  },
  computed: {
    filteredRecruitments(){
      return this.recruitments.filter((recruitment)=>{
        if ( this.localFilter.company_official_name_ja_cont && !recruitment.company.official_name_ja.includes(this.localFilter.company_official_name_ja_cont) ) { return false }
        return true
      })
    }
  },
  methods: {
    clickRecruitmentCard(recruitment){
      this.$emit('clickRecruitmentCard', recruitment)
    }
  },
  mounted(){
    let params = new GetMasterDataQueryService.Params
    params.target.study_department_categories = true
    params.target.countries = true
    GetMasterDataQueryService.execute(params).then(resp=>{
      this.masterData.countries = resp.data.countries
      this.masterData.study_department_categories = resp.data.study_department_categories
    })
  },
  watch: {
    queryParams: {
      immediate: true,
      deep: true,
      handler: function(){
        if (this.queryParams.q.is_available_from_overseas_true == false) { this.queryParams.q.is_available_from_overseas_true = null; return; }
        if (this.queryParams.q.is_available_by_mid_career_true == false) { this.queryParams.q.is_available_by_mid_career_true = null; return; }
        this.loading = true
        HfMemberSearchRecruitmentsQueryService.execute(this.queryParams).then(resp=>{
          this.loading = false
          console.log(resp.data);
          this.recruitments = resp.data.map(r=>{ return new Recruitment(r) })
        })
      }
    }
  }
}
</script>

<style>

</style>
