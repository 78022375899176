import { Aggregates } from "hfapi";
import moment from "moment";
import { UNIVERSITY_SCHOOL_CATEGORY_ID, HIGH_SCHOOL_CATEGORY_ID, BACHELOR_ID, LANGUAGE_SYMBOL_2_ID } from "@/config/constants.js"

class User extends Aggregates.User {
  mainDepartment() {
    return this.mainUserUniversity()?.study_department_category;
  }
  mainUniversity(){
    return this.mainUserUniversity()?.university;
  }
  mainUserUniversity() {
    return this.user_universities[0]
  }
  departmentsText(){
    return this.user_universities.map(user_university=>{
      return `
      ${user_university.study_department_category?.name}
      `
    }).join("\n")
  }
  livingPlace(){
    return this.living_prefecture.name || this.living_country.name || "不明";
  }
  skillsText(){
    return this.user_skills.map((user_skill)=>{
      return `
      ${user_skill.skill.name}(${user_skill.skill.skill_category.name})
      ${user_skill.description ? user_skill.description : ""}
      `
    }).join(", ")
  }
  hopeWorkingPrefectureNames(){
    return this.user_hope_working_prefectures.map((uhwp) => uhwp?.prefecture?.name).join("\n")
  }

  userCareerText(){
    if (this.hope_last_work_country.name) {
      return `
      最初に働く国: ${this.hope_first_work_country.name}
      最後に働く国: ${this.hope_last_work_country.name}
      グローバルに働きたいか？: ${this.is_hope_work_globaly}
      `;
    }
  }

  userSurveyText(){
    return this.user_survey_answers.map((usa)=>{
      return `${usa.survey.sentence}\n${usa.value_for_hf_member}`;
    }).join("\n")
  }

  japaneseSkillText(){
    return `
    JLPT: ${this.jlpt}
    話す ${this.japaneseSkill()?.speaking_level?.level}. ${this.japaneseSkill()?.speaking_level?.name}\n
    聞く ${this.japaneseSkill()?.listening_level?.level}. ${this.japaneseSkill()?.listening_level?.name}\n
    書く ${this.japaneseSkill()?.writing_level?.level}. ${this.japaneseSkill()?.writing_level?.name}\n
    読む ${this.japaneseSkill()?.reading_level?.level}. ${this.japaneseSkill()?.reading_level?.name}\n
    `
  }
  minJapaneseLevel(){
    return "N" + Math.min(
      this.japaneseSkill()?.speaking_level?.level,
      this.japaneseSkill()?.listening_level?.level,
      this.japaneseSkill()?.writing_level?.level,
      this.japaneseSkill()?.reading_level?.level
    )
  }
  maxJapaneseLevel(){
    return "N" + Math.max(
      this.japaneseSkill()?.speaking_level?.level,
      this.japaneseSkill()?.listening_level?.level,
      this.japaneseSkill()?.writing_level?.level,
      this.japaneseSkill()?.reading_level?.level
    )
  }
  interestJobCategoriesText(){
    return this.user_interesting_job_categories.map(user_interesting_job_category=>{
      return user_interesting_job_category.job_category.name
    }).join(", ");
  }
  interestIndustriesText(){
    return this.user_interesting_industries.map(user_interesting_industry=>{
      user_interesting_industry.industry.name
    }).join(", ");
  }

  graduateAtText(){
    return moment(this.mainUserUniversity()?.graduate_at || this.graduate_date).format("YYYY年MM月");
  }

  jobHistoryText(){
    return this.user_job_histories.map(user_job_history => {
      const job_category_name = user_job_history.job_category?.name || user_job_history.job_category_other
      return `
【${job_category_name}】
仕事内容:
${user_job_history.job_description}
やめた理由:
${user_job_history.quit_reason_other}
`;
    }).join("\n")
  }

  japaneseSkill() {
    return this.user_language_skills.find((user_language_skill) => { return user_language_skill.language_id == LANGUAGE_SYMBOL_2_ID["ja"] })
  }
  englishSkill() {
    return this.user_language_skills.find((user_language_skill) => { return user_language_skill.language_id == LANGUAGE_SYMBOL_2_ID["en"] })
  }
  findOrBuildEnglishSkill(){
    if(!this.englishSkill()){
      this.user_language_skills = this.user_language_skills || []
      this.user_language_skills.push({language_id: LANGUAGE_SYMBOL_2_ID["en"]})
    }
    return this.englishSkill()
  }

  englishExamination() {
    return this.user_language_examinations.find((user_language_examination) => { return user_language_examination.language_examination.language_id == LANGUAGE_SYMBOL_2_ID["en"] })
  }
  findOrBuildEnglishExamination(){
    if(!this.englishExamination()){
      this.user_language_examinations.push({language_examination: {language_id: LANGUAGE_SYMBOL_2_ID["en"]}, result: null })
    }
    return this.englishExamination()
  }

  englishSkillText(){
    return this.englishSkill()?.general_level?.name;
  }
  mainBachelorUserUniversity(){
    return this.user_universities.find((uU) => uU.is_main_bachelor && uU.university_academic_degree_id == BACHELOR_ID);
  }
  latestUserPolymorphicSchool(){
    return this.userPolymorphicSchools().find(uPS => uPS.is_latest && !uPS._destroy)
  }
  removeUserPolymorphicSchool(userPolymorphicSchool){
    userPolymorphicSchool._destroy = true
  }
  blankUserSchool(){
    return Object.assign({}, this.container_hash().user_schools[0])
  }
  blankUserUniversity(){
    return Object.assign({school_category_id: UNIVERSITY_SCHOOL_CATEGORY_ID}, this.container_hash().user_universities[0])
  }
  lastDestroyedUserSchool(){
    return this.user_schools.reverse().find(user_school=>user_school._destroy)
  }
  lastDestroyedUserUniversity(){
    return this.user_universities.reverse().find(user_university=>user_university._destroy)
  }
  buildUserUniversity(){
    let appendedUserPolymorphicSchool = this.lastDestroyedUserUniversity() || this.blankUserUniversity()
    this.appendUserPolymorphicSchool(appendedUserPolymorphicSchool)
  }
  buildUserSchool(){
    let appendedUserPolymorphicSchool = this.lastDestroyedUserSchool() || this.blankUserSchool()
    this.appendUserPolymorphicSchool(appendedUserPolymorphicSchool)
  }
  // changeUserPolymorphicSchoolCategory(userPolymorphicSchool, school_category_id){
  //   this.removeUserPolymorphicSchool(userPolymorphicSchool)
  //   let appendedUserPolymorphicSchool
  //   if(school_category_id == UNIVERSITY_SCHOOL_CATEGORY_ID){
  //     // 新規が大学の時
  //     console.log("lastDestroyedUserUniversity", this.lastDestroyedUserUniversity())
  //     appendedUserPolymorphicSchool = this.lastDestroyedUserUniversity() || this.blankUserUniversity()
  //   } else {
  //     // 新規がその他学校の時
  //     console.log("lastDestroyedUserSchool", this.lastDestroyedUserSchool())
  //     appendedUserPolymorphicSchool = this.lastDestroyedUserSchool() || this.blankUserSchool()
  //     appendedUserPolymorphicSchool.school_category_id = school_category_id
  //   }
  //   console.log(JSON.stringify(appendedUserPolymorphicSchool))
  //   this.appendUserPolymorphicSchool(appendedUserPolymorphicSchool)
  // }
  appendUserPolymorphicSchool(userPolymorphicSchool){
    let haveDestroyedUPS = this.allUserPolymorphicSchools().find(uPS => uPS == userPolymorphicSchool)
    if ( haveDestroyedUPS ){
      // すでに追加済みの時
      haveDestroyedUPS._destroy = false
    } else {
      // すでに追加済みではないとき
      if (userPolymorphicSchool.school_category_id == UNIVERSITY_SCHOOL_CATEGORY_ID) { this.user_universities.push(userPolymorphicSchool) }
      else { this.user_schools.push(userPolymorphicSchool); }
    }
  }
  allUserPolymorphicSchools(){
    const user_universities = this.user_universities?.map((uU)=> Object.assign(uU, { school_category_id: UNIVERSITY_SCHOOL_CATEGORY_ID }) ) || []
    return this.user_schools.concat(user_universities)
  }
  userPolymorphicSchools(){
    return this.allUserPolymorphicSchools().filter((us)=>{return !us._destroy;})
  }
  userPolymorphicOtherSchools(){
    return this.userPolymorphicSchools().filter((us=>{
      return !(us.is_main_bachelor || us.is_latest || us.school_category_id==HIGH_SCHOOL_CATEGORY_ID )
    }))
  }
  userHighSchool(){
    return this.user_schools.find(uS=>uS.school_category_id==HIGH_SCHOOL_CATEGORY_ID)
  }
  userJobHistories(){
    return this.user_job_histories.filter(uJH=>!uJH._destroy)
  }
  fullName(){
    return this.first_name + " " + this.last_name
  }
  isOwnApplication(application_id) {
    if (!this.application_ids) this.application_ids = []
    return this.application_ids.indexOf(Number(application_id)) >= 0
  }
  analyticsDatum(){
return `
first_campaign_name: ${this.user_analytics_datum?.first_campaign_name}
first_campaign_source: ${this.user_analytics_datum?.first_campaign_source}
first_campaign_term: ${this.user_analytics_datum?.first_campaign_term}
first_campaign_medium: ${this.user_analytics_datum?.first_campaign_medium}
first_campaign_content: ${this.user_analytics_datum?.first_campaign_content}
first_campaign_cretaed_at: ${this.user_analytics_datum?.first_campaign_cretaed_at}
first_campaign_landing_page: ${this.user_analytics_datum?.first_landing_page}
sign_up_campaign_name: ${this.user_analytics_datum?.sign_up_campaign_name}
sign_up_campaign_source: ${this.user_analytics_datum?.sign_up_campaign_source}
sign_up_campaign_term: ${this.user_analytics_datum?.sign_up_campaign_term}
sign_up_campaign_medium: ${this.user_analytics_datum?.sign_up_campaign_medium}
sign_up_campaign_content: ${this.user_analytics_datum?.sign_up_campaign_content}
sign_up_campaign_cretaed_at: ${this.user_analytics_datum?.sign_up_campaign_cretaed_at}
sign_up_campaign_landing_page: ${this.user_analytics_datum?.sign_up_landing_page}`
  }
  
  get quitReasons(){
    return this.user_job_histories.map(jh => ({
      companyName: jh.company_name,
      reason: jh.quit_reason_other,
    }))
  }

  get notionUrl(){
    if (!this.notion_id) { return "" }
    return `https://www.notion.so/${this.notion_id.replaceAll("-", "")}`
  }

}

export default User;
