<template>
  <VContainer fluid>
    <VRow>
        <VCol>
          <VExpansionPanels flat v-if="user.id">
            <VExpansionPanel>
              <VExpansionPanelHeader>
                <h4><VIcon>mdi-link-variant</VIcon> 関連リンク一覧</h4>
              </VExpansionPanelHeader>
              <VExpansionPanelContent>
                <div v-for="link in relatedLinks" :key="link.url" >
                  <VListItem  :href="link.url" :target="link.target" v-if="!link.isHidden">
                    <VListItemContent>
                      <VListItemTitle>{{link.title}}</VListItemTitle>
                    </VListItemContent>
                    <VListItemAction>
                      <VIcon v-if="link.target == '_blank'">mdi-open-in-new</VIcon>
                    </VListItemAction>
                  </VListItem>
                </div>
              </VExpansionPanelContent>
            </VExpansionPanel>
          </VExpansionPanels>
        </VCol>
      </VRow>
    <h3>ユーザー詳細ページ</h3>
    <VBtn @click="popChangeNotionForm">Notionページ変更</VBtn>
    <VBtn @click="updateNotionPage" :loading="updatingNotionPage">Notionページ更新</VBtn>
    <VRow>
      <VCol cols="6">
        <UserTable :user="user"/>
      </VCol>
      <VCol cols="6">
        <UserAppraisalForm :user="user"/>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <UserApplicationsList :user="user" />
      </VCol>
    </VRow>

    <!-- Dialog関連色々 -->
    <VDialog v-model="showChangeNotionForm">
      <VCard>
        <VContainer>
          <NotionIdForm :userParams="user" @save="atSaveNotionId" />
        </VContainer>
      </VCard>
    </VDialog>

  </VContainer>
</template>

<script>
import User from "@/aggregates/user.js"
import UserTable from "@@/components/organisms/users/UserTable.vue"
import NotionIdForm from "@@/components/organisms/users/forms/NotionIdForm.vue"
import UserAppraisalForm from "@@/components/organisms/users/UserAppraisalForm.vue"
import UserApplicationsList from "@@/components/organisms/users/UserApplicationsList.vue"
import { HfMemberCreateUserTrelloCardCommandService, HfMemberSyncNotionUserPageCommandService } from "hfapi"

export default {
  
  
  components: {
    UserTable,
    UserAppraisalForm,
    UserApplicationsList,
    NotionIdForm,
  },
  data(){
    return {
      user: new User(this.$route.params.id),
      showChangeNotionForm: false,
      updatingNotionPage: false,
    }
  },

  methods: {
    popChangeNotionForm(){
      this.showChangeNotionForm = true
    },
    atSaveNotionId(){
      this.flash('保存に成功しました', "sucsess")
      this.showChangeNotionForm = false
    },
    createTrelloCard() {
      HfMemberCreateUserTrelloCardCommandService.execute({ user: {id: this.user.id} }).then(resp=>{
        console.log(resp);
        this.flash("Trelloカードが作成できました")
      })
    },
    updateNotionPage(){
      this.updatingNotionPage = true
      HfMemberSyncNotionUserPageCommandService.execute({ user: {id: this.user.id} }).then(resp=>{
        console.log(resp);
        this.flash("更新に成功しました")
        this.updatingNotionPage = false
      })
    }
  },
  computed: {
    relatedLinks(){
      return [
        { title: "ユーザー履歴書", url: `/#/users/${this.user.id}/resume` },
        { title: "ユーザーTrello", url: `https://trello.com/c/${this.user.trello_card_id}` , target: "_blank", isHidden: !this.user.trello_card_id },
        { title: "ユーザーNotion", url: this.user.notionUrl , target: "_blank", isHidden: !this.user.notion_id },
        { title: "退会処理ページ", url: `/#/users/${this.user.id}/leave_from_service` },
        { title: "個人情報上書きページ", url: `/#/users/${this.user.id}/overwrite_personal_data` },
      ]
    }, 
  },
  metaInfo() {
    return {
      title: `${this.user.full_name}-ユーザー詳細ページ`,
    }
  },
  
  mounted(){
  },
  
}
</script>

<style>

</style>