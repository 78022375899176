<template>
  <VContainer>
    <h2>履歴書のための質問を作成できます．</h2>
    <a href="https://www.notion.so/hf-jtj/787a29002ad54c10b02b762c53131faa" target="_blank">＊NotionのWIKIページ</a>
    <div class="btns">
      <VBtn @click="submit" class="success mb-3">全て保存</VBtn>
      <VBtn @click="submitja">日本語だけ保存</VBtn>
    </div>
    <VRow>
      <VCol cols="6">
        <h3>日本語</h3>
        <ResumeQuestionRegisterForm :resumeQuestionParams="resumeQuestionParams" lang="ja" ref="jaForm" :templates="templates" />
      </VCol>
      <VCol cols="6">
        <h3>英語</h3>
        <ResumeQuestionRegisterForm :resumeQuestionParams="resumeQuestionParams" lang="en" ref="enForm" :templates="templates" />
      </VCol>
    </VRow>
    <template v-if="false">
    <h3>回答テンプレート</h3>
    <p>
      ユーザーは回答テンプレートを使って質問に答えられます。
    </p>
    <VRow>
      <VCol v-for="(template, index) in templates" :key="template.id" cols="12">
        <VCard>
          <VContainer>
            <VRow>
              <VCol cols="6">
                <ResumeQuestionTemplateRegisterForm :resumeQuestionTemplate="template" lang="ja"/>
              </VCol>
              <VCol cols="6">
                <ResumeQuestionTemplateRegisterForm :resumeQuestionTemplate="template" lang="en"/>
              </VCol>
            </VRow>
            <VRow>
              <VCol cols="12">
                <VBtn class="error" @click="deleteTemplate(index)">削除</VBtn>
              </VCol>
            </VRow>
          </VContainer>
        </VCard>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <VBtn class="" @click="addTemplate">追加</VBtn>
      </VCol>
    </VRow>
    </template>
  </VContainer>
</template>

<script>
import { HfMemberRegisterResumeQuestionCommandService } from "hfapi"
import ResumeQuestion from "@@/aggregates/resumeQuestion"
import ResumeQuestionRegisterForm from '../../organisms/resumeQuestions/ResumeQuestionRegisterForm.vue'
import ResumeQuestionTemplateRegisterForm from '../../organisms/resumeQuestions/ResumeQuestionTemplateRegisterForm.vue'

export default {
  components: {
    ResumeQuestionRegisterForm,
    ResumeQuestionTemplateRegisterForm,
  },
  props: {
    resumeQuestionId: {
      default: null
    }
  },
  data(){
    return {
      resumeQuestion: new ResumeQuestion(this.resumeQuestionId),
      resumeQuestionParams: new HfMemberRegisterResumeQuestionCommandService.BuildableParams(),
    }
  },
  methods: {
    submit(){
      if ( !this.$refs.jaForm.validate() || !this.$refs.enForm.validate() ) {
        this.flash("入力していない項目があります。", "error")
        return
      }
      this.register()
    },
    submitja(){
      if ( !this.$refs.jaForm.validate() ) {
        this.flash("入力していない項目があります。", "error")
        return
      }
      this.register()
    },
    register(){
      HfMemberRegisterResumeQuestionCommandService.execute(this.resumeQuestionParams).then(resp=>{
        this.resumeQuestion.fetch(resp.data.id)
        this.flash("保存に成功しました", "success")
        this.$emit("register", this.resumeQuestion)
      }).catch(e=>{
        this.flash(e.response.data, "error")
      })
    },
    addTemplate(){
      this.resumeQuestionParams.resume_question.resume_question_templates.push({
        id: null,
        resume_question_id: this.id,
        template_name: { ja: null, en: null },
        template_text: "",
        _destroy: false,
      })
    },
    deleteTemplate(index){
      this.$set(this.resumeQuestionParams.resume_question.resume_question_templates[index], '_destroy', true)
    }
  },
  mounted(){
    this.resumeQuestion.after_assign = ()=>{
      Object.assign(this.resumeQuestionParams.resume_question, this.resumeQuestion)
    }
  },
  computed: {
    templates(){ return this.resumeQuestionParams.resume_question.resume_question_templates.filter(t => !t._destroy) }
  },
}

</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10%;
  right: 5%;
  z-index: 1000;
}

</style>