<template>
  <VContainer>
    <h3>JobTreeJapanの閲覧状況をHfMemberにするために登録をしてください</h3>
    <h5>＊ログインした状態で行ってください。</h5>
    <p>
      GoogleAnalyticsや求人情報の閲覧データにHarmonyForのメンバーのデータが入ると正しいデータが取得できず困ることがあります。ご協力をお願いします。
    </p>
    <VTextField v-model="hf_member_name" label="名前を入れてください"></VTextField>
    <VBtn @click="register">登録する</VBtn>
  </VContainer>
</template>

<script>
import {HfMemberRegisterHfMemberGidCommandService} from "hfapi"

export default {
   metaInfo() {
    return {
      title: `HfMember登録ページ`,
    }
  },
  data() {
    return {
      hf_member_name: ""
    }
  },
  methods: {
    register() {
      HfMemberRegisterHfMemberGidCommandService.execute({
        gid: {
          gid: this.$route.query.gid,
          hf_member_name: this.hf_member_name,
        }
      }).then(()=>{
        this.window.location.href = `${this.VUE_APP_JTJ_HOST}/#/set_gid_as_hf_member`
      }).catch((err)=>{
        console.log(err);
        this.flash(err.response.data, "error");
      })
    }
  },
}
</script>

<style>

</style>
