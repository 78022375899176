"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "university": { "id": null, "local_name": null, "english_name": null, "country_id": null, "prefecture_id": null, "world_ranking": null, "uni_rank_id": null, "is_apporved_by_hf_member": null, "is_created_by_user": null, "local_ranking": null, "description": null, "university_control_type_id": null } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "university": { "id": null, "local_name": null, "english_name": null, "country_id": null, "prefecture_id": null, "world_ranking": null, "uni_rank_id": null, "is_apporved_by_hf_member": null, "is_created_by_user": null, "local_ranking": null, "description": null, "university_control_type_id": null } });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/hf_member__update_university", { params });
    }
};
