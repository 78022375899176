<template>
  <div>
    <VContainer fluid>
    <VChip>* 注意: 日本語と英語のタイトル、内容それぞれ全てが入力されていないと登録ができないのでお気をつけを！</VChip>
    <VRow>
      <VCol cols="6">
        <VCard>
          <VToolbar>
            <VTabs v-model="tab">
              <VTab href="#tab-ja">日本語</VTab>
              <VTab href="#tab-en">English</VTab>          
            </VTabs>
          </VToolbar>
          <VTabsItems v-model="tab" class="ma-5">
            <VTabItem value="tab-ja" >
              <EventForm :eventParams="eventParams" lang="ja" :hfForms="hfForms"></EventForm>
            </VTabItem>
            <VTabItem value="tab-en" >
              <EventForm :eventParams="eventParams" lang="en" :hfForms="hfForms"></EventForm>
            </VTabItem>
          </VTabsItems>
        </VCard>
      </VCol>
      <VCol cols="6">
        <h3>プレビュー</h3>
        <div style="overflow-y: scroll; height: 90vh;">
          <EventPreview :eventParams="eventParams" :lang="tab.split('-')[1]" ></EventPreview>
        </div>
      </VCol>
    </VRow>
    <VRow dense>
      <VCol>
        <VBtn @click="submit" color="primary">Submit</VBtn>
      </VCol>
      <VCol v-if="event.id">
        <VBtn :to="{ name: 'EventShowPage', params: {id: $route.params.id} }" @click="submit" target="_blank" :loading="loading">ページで開く</VBtn>
      </VCol>
    </VRow>
  </VContainer>
  </div>
  
</template>

<script>
import { HfMemberRegisterEventCommandService } from "hfapi"
import { GetFormsQueryService } from "hfapi/hff"
import Event from "@/aggregates/event"
import EventForm from "@@/components/organisms/events/EventForm";
import EventPreview from "@@/components/organisms/events/EventPreview";
import HfForm from "@/aggregates/hf_form"

export default {
   metaInfo() {
    return {
      title: this.event?.title?.ja || "イベント新規作成ページ",
    }
  },
  components: {
    EventForm,
    EventPreview,
  },
  data(){
    return {
      event: new Event(this.$route.params.id),
      eventParams: new HfMemberRegisterEventCommandService.Params(),
      tab: "tab-ja",
      loading: false,
      hfForms: [],
    }
  },
  methods: {
    submit(){
      this.loading = true
      if(!this.eventParams.event.thumbnail?.key){
        this.eventParams.event.thumbnail = null
      }
      HfMemberRegisterEventCommandService.execute(this.eventParams).then(resp=>{
          console.log(resp);
          this.event.assign(resp.data)
          Object.assign(this.eventParams.event, resp.data)
          this.$router.push({ name: "EventEditPage", params: {id: resp.data.id } })
          this.flash("保存に成功しました", "success")
      }).catch(e=>{
        console.log(e.response.data);
        this.flash(e.response.data, "error")
      }).finally(()=>{
        this.loading = false
      })
    },
    async setHfForms(){
      const resp = await GetFormsQueryService.execute()
      this.hfForms = resp.data.map(d => new HfForm(d))
    }
  },
  mounted() {
    this.eventParams.event.thumbnail._destroy = true
    this.setHfForms()
    this.event.after_assign = ()=>{
      Object.assign(this.eventParams.event, this.event)
      this.eventParams.event.thumbnail._destroy = false
    }
  }
}
</script>

<style>

</style>