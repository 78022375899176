<template>
  <VForm ref="form">
    <VChipGroup
      column
      multiple
      active-class="primary"
      v-model="selectedChipLanguageIds"
    >
      <VChip v-for="language in chipLanguageOptions" :key="language.id" :value="language.id">
        {{ language.name }}
      </VChip>
    </VChipGroup>
    <VRow>
      <VCol cols="10">
        <MasterSelectField chips multiple outlined :items="otherLanguageOptions" v-model="selectedOtherLanguageIds" label="Other">
          <template #selection="{ item }">
            <VChip color="primary">{{item.name}}</VChip>
          </template>
        </MasterSelectField>
      </VCol>
    </VRow>
  </VForm>
</template>

<script>
import {
  GetRecruitmentRequiredLanguagesQueryService,
  UserUpdateProfileCommandService,
} from "hfapi";
import { JAPANESE_LANGUAGE_ID, ENGLISH_LANGUAGE_ID } from "@@/config/constants";

const p = new UserUpdateProfileCommandService.Params();
const userLanguageSkill = p.user.user_language_skills[0];

export default {
  props: ["value"],
  data() {
    return {
      chipLanguageIds: [],
      chipLanguageOptions: [],
      optionalLanguage: null,
      selectedChipLanguageIds: [],
      selectedOtherLanguageIds: [],
    };
  },
  methods: {
    setChipLanguageOptions() {
      if ( this.chipLanguageIds.length > 0 ){ return }
      GetRecruitmentRequiredLanguagesQueryService.execute(
        new GetRecruitmentRequiredLanguagesQueryService.Params()
      ).then((resp) => {
        this.chipLanguageIds = resp.data
        this.chipLanguageOptions = this.master('languages').filter((l)=> this.chipLanguageIds.includes(l.id) && l.id != JAPANESE_LANGUAGE_ID && l.id != ENGLISH_LANGUAGE_ID )
      });
    },
    initializeSelected(){
      this.value.user_language_skills.forEach((uLS)=>{
        if ( this.chipLanguageOptions.map((l)=>l.id).includes(uLS.language_id) ){
          this.selectedChipLanguageIds.push(uLS.language_id)
        } else {
          this.selectedOtherLanguageIds.push(uLS.language_id)
        }
      })
    },
    updateUserLanguageSkills(){
      this.value.user_language_skills.forEach( (uLS) => {
        if ( !(uLS.language_id == JAPANESE_LANGUAGE_ID || uLS.language_id == ENGLISH_LANGUAGE_ID) ){
          uLS._destroy = true
        }
      })
      this.selectedLanguageIds.forEach((languageId)=>{
        let selectedULS = this.value.user_language_skills.find( (uLS)=> uLS.language_id == languageId )
        console.log(selectedULS);
       if ( selectedULS ) { selectedULS._destroy = false }
       else { this.value.user_language_skills.push( this.newUserLanguageSkill(languageId) ); console.log(this.newUserLanguageSkill(languageId)); }
      })
    },
    newUserLanguageSkill(languageId){
      let newULS = Object.assign({}, userLanguageSkill);
      newULS.language_id = languageId;
      return newULS
    },
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      return this.$refs.form.validate()
    },
  },
  computed: {
    logULSs(){
      return this.value.user_language_skills.filter((uLS)=> uLS.language_id != null )
    },
    otherLanguageOptions() {
      let excludedIds = this.chipLanguageOptions.map((l)=>l.id)
      excludedIds.push(JAPANESE_LANGUAGE_ID)
      excludedIds.push(ENGLISH_LANGUAGE_ID)
      return this.master('languages').filter((l) => !excludedIds.includes(l.id) )
    },
    selectedLanguageIds(){
      return this.selectedChipLanguageIds.concat(this.selectedOtherLanguageIds)
    },
  },
  watch: {
    "selectedChipLanguageIds": function(){
      this.updateUserLanguageSkills()
    },
    "selectedOtherLanguageIds": function(){
      this.updateUserLanguageSkills()
    },
    "$store.state.master.languages": function(){
      this.setChipLanguageOptions();
    }
  },
  created() {
    this.setChipLanguageOptions();
  },
};
</script>

<style>
</style>