export default {
  title: "【Important things to get a job in Japan / 日本で仕事を得るために大事なこと】",
  body: `*日本語は下にあります

Dear <%=user.first_name%>

This is <%=hf_member.nick_name_en%> who is supporting job-hunting for international students!

Today I tried to find a company from your registration information.
However at the moment I couldn't find companies because of your current Japanese language skills.
Most of the Japanese companies require a certain level of Japanese.
So terribly sorry about this, however I have here for you a guide on ways to get a job in Japan.
Please read this email to the end cause it does get better!

【Japanese language skills are essential if you work in Japan】
When foreigners work in Japan, Japanese language ability is an essential ability.
Many foreigner think that "global companies do not need Japanese language skills", but even Japanese people of global companies can not speak English.
If you can not speak Japanese, the company is concerned with your adaptability to their environment and the extra training that would be required and therefore find it difficult to employ is such skills have not been met. Therefore, if you want to work in Japan, you will need to raise your Japanese level to about N2(Liberal arts) or N3(Sciences).

【If you improve your Japanese ability, it will be easier to work】
There is a reason to say such a tough thing now.
There are many companies that want to work with you if your Japanese level goes up!

In other words, if your Japanese language skills go up, you can get more opportunities and a wider job selection.

Below is a list of jobs that you can apply for when your Japanese language skills goes up!
Please do your best to learn Japanese and here are some articles or ways that may help you to improve your Japanese language skills.

We'll send article how to increase your Japanese level, so stay tuned!

One of the best ways that both rewarding and enjoyable is through making Japanese friends! You can do just that by joining any extra curricular activities. Joining an all Japanese bukatsu or circle may be quite intimidating especially if you are joining alone, so I’d like to recommend this circle where there is a mix of people from all over the world, so you can learn more than just the Japanese language but of different cultures too.


<% recommend_mail.active_remind_mail_recruitments.forEach(function(remind_mail_recruitment, i){ %>
  <%=i+1%>. <%=remind_mail_recruitment.recruitment.company.official_name_en%>
  <%=remind_mail_recruitment.recruitment.company.hf_recommend_sentence.en || remind_mail_recruitment.recruitment.company.appeal_point.en%>
  [Job Description]
  <%=remind_mail_recruitment.recruitment.recruitment_detail.job_description.en%>
  [Job information]
  https://job-tree-japan.com/#/recruitments/<%=remind_mail_recruitment.recruitment_id%>
<% }) %>
==================

I hope that this information is helpful to you.
<%=hf_member.nick_name_en%>

*日本語
<%=user.first_name%>さん

留学生の就職支援をしているJob Tree Japanの<%=hf_member.nick_name_ja%>です！

今日は予告していた「<%=user.first_name%>さんにオススメの仕事リスト」をお届けしようと思ったのですが、今の<%=user.first_name%>さんの日本語力では企業の求める日本語レベルに足りず、オススメできる企業がありませんでした。
期待させてしまったのにオススメができなくてごめんなさい。
しかし、<%=user.first_name%>さんが日本で仕事を得るための方法を書いたので、
落ち込まずにこのメールを最後まで読んでください！

【日本語力は日本で仕事をするなら必須】
留学生が日本で仕事をする場合、日本語力はほぼ必須の能力になります。
多くの留学生が「グローバル企業では日本語力は必要ない。」と考えていますが、グローバル企業の日本人ですら英語を喋れない人ばかりだからです。
企業の人も<%=user.first_name%>さんが日本語を喋れないと、入社後にストレスで辛い想いをすることを心配し、なかなか採用できません。
ですので、日本で仕事をしたいのであれば、日本語力は絶対に必要になります。

【日本語力をあげると仕事をしやすくなる】
今このような厳しいことを言うのには理由があります。
それは、<%=user.first_name%>さんの日本語レベルが上がれば、<%=user.first_name%>さんと一緒に働きたいと思っている会社がたくさんいるからです！

つまり、日本語力が上がれば<%=user.first_name%>さんが仕事を手に入れ、日本で楽しく暮らせる可能性は大きく上がります！
下に<%=user.first_name%>さんの日本語力が上がった時に応募できる仕事のリストを載せます！
ぜひこれを目標に日本語の勉強を頑張ってください！

今後、日本語を学ぶのに必要な情報を送るので楽しみにしていてください！

———  日本語力が上がった時に応募できる仕事リスト  ———
<% recommend_mail.active_remind_mail_recruitments.forEach(function(remind_mail_recruitment, i){ %>
  <%=i+1%>. <%=remind_mail_recruitment.recruitment.company.official_name_ja%>
  <%=remind_mail_recruitment.recruitment.company.hf_recommend_sentence.ja || remind_mail_recruitment.recruitment.company.appeal_point.ja%>
  [仕事内容]
  <%=remind_mail_recruitment.recruitment.recruitment_detail.job_description.ja%>
  [仕事の情報]
  https://job-tree-japan.com/#/recruitments/<%=remind_mail_recruitment.recruitment_id%>
   
<% }) %>


以上となります。
<%=user.first_name%>さんの夢を応援しています！

<%=hf_member.nick_name_ja%>
`,
};