<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="items.length"
    class="elevation-1"
  ></v-data-table>
</template>

<script>
export default {
  props: {
    user: {
      type: Object
    },
    recruitment: {
    },
    company: {
    }
  },
  data() {
    return {
      headers: [
        { text: "カラム名", value: "column_name" },
        { text: "留学生", value: "student_value" },
        { text: "企業", value: "recruitment_value" }
      ]
    }
  },
  computed: {
    items(){
      // return [ { student_value: , recruitment_value:  } ] TODO: マッチしているかを色で判別
      return [
        { column_name: "海外からの応募", student_value: this.user.living_country.name , recruitment_value: this.recruitment.availableOverseasText() },
        { column_name: "年齢制限", student_value: this.user.age , recruitment_value: this.recruitment.age_limit },
        { column_name: "オンライン面接*", student_value: this.user.living_prefecture.name , recruitment_value: this.recruitment.selectionText() }, // TODO
        { column_name: "中途*", student_value: this.user.is_mid_career , recruitment_value: this.recruitment.is_available_by_mid_career ? "中途応募可能" : "応募不可能" },
        { column_name: "学部学科", student_value: this.user.departmentsText() , recruitment_value: this.recruitment.schoolDepartmentText() },
        { column_name: "スキル", student_value: this.user.skillsText() , recruitment_value: this.recruitment.requiredSkillText() },
        { column_name: "関連国", student_value: this.user.home_country.name , recruitment_value: this.recruitment.relationCountriesText() },
        { column_name: "日本語レベル", student_value: this.user.japaneseSkillText() , recruitment_value: this.recruitment.japaneseLevelsText() },
        { column_name: "キャリアパス", student_value: this.user.userCareerText() , recruitment_value: this.recruitment.careerText() },
        { column_name: "研究・業務内容", student_value: this.user.research_description , recruitment_value: this.recruitment.recruitment_detail.job_description_text },
        { column_name: "過去の中途の業務内容", student_value: this.user.jobHistoryText() , recruitment_value: "" },
        { column_name: "卒業時期", student_value: this.user.graduate_latest_school_at , recruitment_value: "" },
        { column_name: "求人についてのメモ", student_value: "" , recruitment_value: this.recruitment.recruitment_internal_datum?.memo },
        { column_name: "企業担当者のメールアドレス", student_value: "" , recruitment_value: this.company.emailsText() },
      ]
    },
  }
}
</script>

<style scoped>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  white-space: pre-line;
}
</style>
