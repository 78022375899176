<template>
  <VCol>
    <div v-for="(us, index) in userPolymorphicOtherSchools" :key="us.id">
      <div v-if="us.school_category_id == UNIVERSITY_SCHOOL_CATEGORY_ID">
        <UniversityForm v-model="userPolymorphicOtherSchools[index]" :hiddenFields="hiddenFields" ref="universityForm"/>
      </div>
      <div v-else>
        <OtherSchoolForm v-model="userPolymorphicOtherSchools[index]" :schoolCategoryId="us.school_category_id" :hiddenFields="hiddenFields" ref="schoolForm"/>
      </div>
      <VBtn color="error" @click="remove(us)">削除</VBtn>
    </div>
    <VBtn @click="appendUniversity">大学追加</VBtn>
    <VBtn @click="appendSchool">学校追加</VBtn>
  </VCol>
</template>

<script>
import { UserUpdateProfileCommandService } from "hfapi";
import { UNIVERSITY_SCHOOL_CATEGORY_ID } from '@@/config/constants'
import UniversityForm from './UniversityForm'
import OtherSchoolForm from './OtherSchoolForm'

export default {
  props: {
    value: {}, // user
    hiddenFields: {}
  },
  components: {
    UniversityForm,
    OtherSchoolForm,
  },
  data() {
    return {
      UNIVERSITY_SCHOOL_CATEGORY_ID: UNIVERSITY_SCHOOL_CATEGORY_ID,
    }
  },
  computed: {
    userPolymorphicOtherSchools(){
      return this.value.userPolymorphicOtherSchools()
    }
  },
  methods: {
    defaultStatesUserUniversity(){
      let p = new UserUpdateProfileCommandService.Params
      return Object.assign(p.user.user_universities[0], {school_category_id: UNIVERSITY_SCHOOL_CATEGORY_ID, is_latest: false, is_main_bachelor: false})
    },
    defaultStatesUserSchool(){
      let p = new UserUpdateProfileCommandService.Params
      return Object.assign({}, p.user.user_schools[0], {is_latest: false})
    },
    validate(){
      return true
    },
    remove(us){
      this.value.removeUserPolymorphicSchool(us)
      this._computedWatchers.userPolymorphicOtherSchools.run()
      this.$forceUpdate()
    },
    appendUniversity(){
      let university = this.defaultStatesUserUniversity()
      this.value.appendUserPolymorphicSchool(university)
    },
    appendSchool(){
      let school = this.defaultStatesUserSchool()
      this.value.appendUserPolymorphicSchool(school)
    },
  },
}
</script>

<style>

</style>