<template>
  <div>
    <VCard>
      <VCardText>
        <VSelect dense :items="items" v-model="reason" label="理由テンプレートを選択"/>
        <VTextarea rows="2" v-model="reason_text" label="理由" disabled/>
        <VBtn color="primary" @click="showSendModal">不合格メールの送信へ移る</VBtn>
      </VCardText>
    </VCard>

    <!-- メールの送信ダイアログ -->
    <VDialog v-model="isShowSendModal">
      <SendMail
        :loading="loadingMail"
        :defaultTo="mail.to"
        :defaultFrom="mail.from"
        :defaultSubject="mail.subject"
        :defaultBody="mail.body"
        :defaultAttachments="mail.attachments"
        @sent="atSent"
        hint="＊送信後、自動でJTJとNotionのApplicationがHF不合格に切り替わります。"
      />
    </VDialog>
  </div>
</template>

<script>
import SendMail from "@@/components/organisms/mails/SendMail"
import { HfMemberGetApplicationFailureMailQueryService, HfMemberDeclineApplicationCommandService } from 'hfapi/jtj'

const reasons = {
  日本語能力で不合格: '理由としては、日本語能力の要件に合わず不合格になりました。',
  対象年度が違う: '理由として、この求人は{year}年卒の学生が対象なため、 \n{name}さんは現在応募出来ません。タイミングになり次第応募ください。',
  海外応募で不合格: 'Job TreeJapan掲載の求人は日本在住の方を対象としています。\n海外在住の方はJob Tree Japan経由では応募することができません。',
  要件に合わないで不合格: '理由としては、今回はご応募が非常に多く、選考基準もかなり高いものとなった中で、相対評価で不合格になりました',
  その他の理由: '理由としては、',
}
export default {
  components: {
    SendMail,
  },
  props: {
    application: {
      type: Object
    }
  },
  explanation: "不合格メールを送る",
  data(){
    return {
      items: Object.keys(reasons),
      reason: "日本語能力で不合格",
      reason_text: "",
      isShowSendModal: false,
      loadingMail: false,
      mail: {
        to: "",
        from: "system@job-tree-japan.com",
        subject: "",
        body: "",
        attachments: [],
      }
    }
  },
  methods: {
    async showSendModal(){
      this.isShowSendModal = true
      this.loadingMail = true
      const mailParams = new HfMemberGetApplicationFailureMailQueryService.Params
      mailParams.application_id = this.application.id
      mailParams.reason = this.reason_text.replace("\n", "<br/>")
      const resp = await HfMemberGetApplicationFailureMailQueryService.execute(mailParams)
      Object.assign(this.mail, resp.data)
      await (this.loadingMail = false)
    },
    atSent(){
      this.isShowSendModal = false
      this.registerHfDecline()
    },
    registerHfDecline(){
      let params = new HfMemberDeclineApplicationCommandService.Params()
      params.application.id = this.application.id
      HfMemberDeclineApplicationCommandService.execute(params).then(()=>{
        this.flash("HF不合格も保存されました", "success");
      }).catch((e)=>{
        this.flash("HF不合格の保存に失敗しました\n"+e.message, "error");
      })
    }
  },
  watch: {
    reason(val){
      this.reason_text = reasons[val]
    }
  },
  created(){
    this.reason_text = Object.values(reasons)[0]
  }
}

</script>

<style>

</style>