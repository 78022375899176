<template lang="">
  <div>
    <h4 class="primary--text">メモ</h4>
    <p>＊ユーザーへは表示されません</p>
    <VTextField label="タイトル(HF向け)" v-model="hfForm.title_for_hf"/>
    <VTextarea label="メモ(HF向け)" v-model="hfForm.memo_for_hf"/>
    <h4 class="primary--text">質問項目</h4>
    <p>＊ユーザーへ表示されます</p>
    <VCard v-for="formQuestion in activeFormQuestions" :key="formQuestion.id || formQuestion.key" class="mb-3">
      <VContainer>
        <FormQuestionForm :formQuestion="formQuestion" :lang="lang"/>
        <div class="mt-3 text-right">
          <VBtn @click="upFormQuestion(formQuestion)" class="mr-1">↑</VBtn>
          <VBtn @click="downFormQuestion(formQuestion)" class="mr-1">↓</VBtn>
          <VBtn @click="removeFormQuestion(formQuestion)" class="mr-1" color="error">削除</VBtn>
        </div>
      </VContainer>
    </VCard>
    <VBtn @click="addFormQuestion">質問を追加</VBtn>
  </div>
</template>
<script>
import FormQuestionForm from "@@/components/organisms/hf_forms/FormQuestionForm.vue"
export default {
  components: {
    FormQuestionForm
  },
  props: {
    hfFormParams: { type: Object },
    lang: { type: String },
  },
  computed: {
    hfForm() {
      return this.hfFormParams.form 
    },
    activeFormQuestions(){
      return this.hfFormParams.form.form_questions.filter(formQuestion=>!formQuestion._destroy).sort((a,b)=>{return a.index-b.index})
    }
  },
  methods: {
    addFormQuestion(){
      const blankFormQuestion = this.hfFormParams.build("form_questions")
      blankFormQuestion.key = this.randHash()
      blankFormQuestion.question_type = "radio_button"
      blankFormQuestion.index = this.activeFormQuestions.length == 0 ? 1 : Math.max(...(this.activeFormQuestions.map(formQuestion=>formQuestion.index)))+1
      this.hfFormParams.form.form_questions.push(blankFormQuestion)
    },
    removeFormQuestion(formQuestion){
      this.$set(formQuestion, "_destroy", true)
    },
    upFormQuestion(formQuestion){
      const index = this.activeFormQuestions.indexOf(formQuestion)
      const upNextFormQuestion = this.activeFormQuestions[index-1]
      if(upNextFormQuestion){
        formQuestion.index = upNextFormQuestion.index
        upNextFormQuestion.index = formQuestion.index + 1
      }
    },
    downFormQuestion(formQuestion){
      const index = this.activeFormQuestions.indexOf(formQuestion)
      const downNextFormQuestion = this.activeFormQuestions[index+1]
      if(downNextFormQuestion){
        formQuestion.index = downNextFormQuestion.index
        downNextFormQuestion.index = formQuestion.index - 1
      }
    },
  },
}
</script>
<style lang="">
  
</style>