<template>
  <div>
    <UsersList :users="users"></UsersList>
  </div>
</template>

<script>
import UsersList from "@@/components/organisms/users/UsersList.vue"
import {HfMemberSearchUsersQueryService} from "hfapi"

export default {
  components: {
    UsersList
  },
  props: {
    _hf_member: {
      type: Object
    }
  },
  data(){
    return {
      users: [],
      params: new HfMemberSearchUsersQueryService.Params()
    }
  },
  watch: {
    _hf_member: {
      immediate: true,
      deep: true,
      handler: function(){
        if ( !this._hf_member.id || this.params.q.hf_member_id_eq_any ) { return }
        this.params.q.hf_member_id_eq_any = [this._hf_member.id]
        this.params.q.only_active = true
        HfMemberSearchUsersQueryService.execute(this.params).then(resp=>{
          this.users = resp.data
        })
      }
    }
  }
}
</script>

<style>

</style>