export default {
  title:
    "Recommended Jobs for <%=user.first_name%> / <%=user.first_name%>さんにお勧めする仕事",
  body: `
*English below

<%=user.first_name%>さん

留学生の就職支援をしているJob Tree Japanの<%=hf_member.nick_name_ja%>です！
先日は、登録ありがとうございました！

ところで、3日前にオススメの企業を紹介させてもらったのですが、届いていたでしょうか？

もう一度、<%=user.first_name%>さんが登録して頂いた情報を元に、オススメ企業をお送りします。
もし興味があれば、ぜひ応募してください。
応募してくれたら、こちらで履歴書のチェックや面接のアドバイスなどをします！

また、個人的な就職活動についての相談に乗ります！
<%= user.first_name %>さんがどのような方で、どのような仕事を望んでいるかを知ることで、より<%= user.first_name %>さんに合った会社を紹介できて、早く理想の会社から内定をもらえるようになります。
Webサイトに掲載していない企業などの紹介などもできます！
ぜひ、一度お話ししましょう！
以下の日付で都合のいい日はありませんか？

【個人的なFace-to-Faceな相談ができる日時】
<%=hf_member.meeting_date_str_ja%>

もし他の日程がよければ、ぜひ教えてください:D

==================
【<%= user.first_name %>さんにおすすめの仕事】
<% recommend_mail.active_remind_mail_recruitments.forEach(function(remind_mail_recruitment, i){ %>
  <%=i+1%>. <%=remind_mail_recruitment.recruitment.company.official_name_ja%>
  <%=remind_mail_recruitment.recruitment.company.hf_recommend_sentence.ja || remind_mail_recruitment.recruitment.company.appeal_point.ja%>
  [仕事内容]
  <%=remind_mail_recruitment.recruitment.recruitment_detail.job_description.ja%>
  [仕事の情報]
  https://job-tree-japan.com/#/recruitments/<%=remind_mail_recruitment.recruitment_id%>
   
<% }) %>
==================


以上となります。
<%=user.first_name%>さんの夢を応援しています！

<%=hf_member.nick_name_ja%>


*English

Dear <%=user.first_name%>

This is <%=hf_member.nick_name_en%> who is supporting job-hunting for international students😊

Did you get my e-mail that I recommended jobs fit for you 3 days ago?

I resend job list fit for you from your registration information again.
If you’re interested in some jobs, please apply for jobs indicate bellow.

To offer you individually specialized support and maximize it, we’d like to know about you more; what is your ideal career path, what kind of company you’d like to work for, etc..
Also we can introduce private job information to you.
Why don’t we have a casual face-to-face chat or talk on a phone on the days indicated below?(Japanese time)

【Let's face-to-face consult】
<%=hf_member.meeting_date_str_en%>

If they are not convenient for you, please tell us when you’ll be available.

==================
【 List of Jobs I'd like to share with you 】
<% recommend_mail.active_remind_mail_recruitments.forEach(function(remind_mail_recruitment, i){ %>
  <%=i+1%>. <%=remind_mail_recruitment.recruitment.company.official_name_en%>
  <%=remind_mail_recruitment.recruitment.company.hf_recommend_sentence.en || remind_mail_recruitment.recruitment.company.appeal_point.en%>
  [Job Description]
  <%=remind_mail_recruitment.recruitment.recruitment_detail.job_description.en%>
  [Job information]
  https://job-tree-japan.com/#/recruitments/<%=remind_mail_recruitment.recruitment_id%>
<% }) %>
==================

I hope that this information is helpful to you.
<%=hf_member.nick_name_en%>

`,
};
