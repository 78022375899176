<template>
  <div>
    <VTextarea ref="textarea" label="HF補足" v-model="application.resume.hf_remark"></VTextarea>
    <VBtn @click="save">保存</VBtn>
  </div>
</template>

<script>
import { HfMemberAddHfRemarkToResumeCommandService } from "hfapi"
export default {
  props: {
    application: {
      type: Object
    }
  },
  methods: {
    save(){
      let params = new HfMemberAddHfRemarkToResumeCommandService.Params()
      params.application.id = this.application.id
      params.application.resume.hf_remark = this.application.resume.hf_remark
      HfMemberAddHfRemarkToResumeCommandService.execute(params).then(resp=>{
        console.log(resp);
        console.log("保存されました");
        this.$emit("save")
      })
    }
  }
}
</script>
