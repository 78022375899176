<template>
  <div>
    <VTextField label="タイトル" v-model="event.title[lang]" outlined :dense="false"/>
    <VueCtkDateTimePicker label="開催日時" v-model="event.start_at" :format="'YYYY-MM-DD HH:mm'" :overlay="true" class="mb-3"/>
    <VueCtkDateTimePicker label="応募締切" v-model="event.deadline" :format="'YYYY-MM-DD HH:mm'" :overlay="true" class="mb-3"/>
    <br>
    <h5>フォーム設定</h5>
    <VRadioGroup v-model="formType">
      <VRadio value="general_gf" label="いつものGoogle Form"></VRadio>
      <VRadio value="peculiar_gf" label="個別のGoogle Form"></VRadio>
      <VRadio value="hff" label="JTJのフォーム"></VRadio>
    </VRadioGroup>
    <NotionUrlField v-model="event.notion_id" />
    <!-- フォーム関連(どれか一つ表示される)↓ -->
    <VTextField v-if="formType=='peculiar_gf'" label="Google Formリンク" v-model="event.apply_url" outlined :dense="false"/>
    <template v-if="formType=='hff'">
      <VSelect label="フォーム" v-model="event.form_id" :items="sortedHfForms" item-text="title_for_hf" item-value="id"></VSelect>
      <VCheckbox label="履歴書の提出は必要ですか？" v-model="event.is_need_resume_file" hint="チェックを入れると応募時に履歴書の提出を求めます"/>
      <VTextarea label="応募後のメッセージ" v-model="event.apply_complete_message[lang]" outlined :dense="false" hint="応募完了後の画面に表示されます"/>
      <VTextField label="Slack Channel Id" v-model="event.slack_notify_channel_id" outlined :dense="false"/>
    </template>
    <!-- フォーム関連(どれか一つ表示される)↑ -->
    <template v-if="event.id && event.thumbnail">
      <h5>アップロード後に提出が必要です</h5>
      <ImageUpload label="images" :dir="`events/${event.id}/`" v-model="event.thumbnail.key" class="mb-4"/>
    </template>
    <template v-else>
      <h5 class="mb-4">画像アップロードするには先に保存してください</h5>
    </template>
    <VTextarea label="内容" v-model="event.body[lang]" outlined rows="13"/>
    <VCheckbox label="公開" v-model="event.is_released"/>
  </div>
</template>

<script>
import { HfMemberRegisterEventCommandService } from "hfapi"
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import ImageUpload from '@@/components/organisms/shared/ImageUpload.vue'
import NotionUrlField from '../../molecules/NotionUrlField.vue';

export default {
  components: {
    VueCtkDateTimePicker,
    ImageUpload,
    NotionUrlField,
  },
  props: {
    eventParams: {
      type: HfMemberRegisterEventCommandService.Params,
    },
    lang: {
      type: String
    },
    hfForms: {
      type: Array
    }
  },
  data() {
    return {
      formType: ""
    }
  },
  computed: {
    event() {
      return this.eventParams.event
    },
    sortedHfForms(){
      return this.hfForms.map((a)=>a).sort((a,b)=>{return new Date(b.updated_at)-new Date(a.updated_at)})
    }
  },
  methods: {
    initializeParams(){
      if(this.event.apply_url){ this.formType = 'peculiar_gf' }
      if(this.event.form_id){ this.formType = 'hff' } // form_idが優先される
    },
  },
  watch: {
    "event.id": function(){
      this.initializeParams()
    }
  },
}
</script>

<style scoped>
.v-text-field--outlined >>> fieldset{
  border-color: rgba(0,0,0,.2);
}
</style>
