<template>
  <div>
    <VBtn @click="dialog = true">{{label}}</VBtn> {{value}}
    <VDialog v-model="dialog">
      <ImageUploadCard :label="label" :dir="dir" @input="input"/>
    </VDialog>
  </div>
</template>

<script>
import ImageUploadCard from '../../molecules/ImageUploadCard.vue'

export default {
  components: { ImageUploadCard },
  props: {
    label: { type: String, default: "Upload" },
    dir: { type: String },
    value: { type: String },
  },
  data(){
    return {
      dialog: false,
      loading: false,
    }
  },
  methods: {
    input(e){
      this.dialog = false,
      this.$emit('input', e)
    }
  }
}
</script>

<style>

</style>