<template>
  <VDialog v-model="showModal" persistent>
    <VCard elevation="2">
      <VCardTitle>
        シート名を入力してください
      </VCardTitle>
      <VCardText>
        <VForm ref="form" lazy-validation>
          <VTextField label="シート名" v-model="params.q.sheet_name" :rules="sheetNameRule" outlined/>
        </VForm>
      </VCardText>
      <VCardActions>
        <VBtn @click="submit()" :disabled="!params.q.sheet_name" color="success" :loading="submitting">
          作成
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { HfMemberCreateRecommendedUsersSpreadSheetCommandService } from "hfapi"

export default {
  props: {
    showModal: {
      type: Boolean,
    },
    searchUserQueryIds: {
      type: Array
    },
    sheet_type: {
      type: String,
    }
  },
  data() {
    return {
      params: new HfMemberCreateRecommendedUsersSpreadSheetCommandService.Params(),
      submitting: false,
      sheetNameRule:[ v => !!v || this.$t('shared.Validations.required') ],
    }
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.submitting = true
        this.params.q.search_user_query_ids = this.searchUserQueryIds
        this.params.sheet_type = this.sheet_type
        HfMemberCreateRecommendedUsersSpreadSheetCommandService.execute(this.params).then( (resp) => {
          this.close()
          this.flash(`作成しました。 ${resp.data.sheet_name} を確認してください。`, "success")
        } ).catch((e)=>{
          this.close()
          this.flash(e.response.data, "error");
        })
      }
    },
    close() {
      this.submitting = false
      this.$refs.form.reset()
      this.$emit('close')
    },
  }
}
</script>

<style>
</style>