import { Aggregates } from "hfapi"
import moment from "moment"
import numberFormatter from "@/lib/numberFormatter";
import { STUDY_DEPARTMENT_CATEGORY_ID } from "@/config/constants.js";
import i18n from "@/config/i18n";

class Recruitment extends Aggregates.Recruitment {
  jobDescriptionText(){
    return t("job_title") + ": " + this.title_text + "\n" + this.recruitment_detail.job_description_text
  }
  numOfHiring(){
    const this_year_annually_datum = this.recruitment_annually_data.find((r) => {
      return r.nendo_year == moment().subtract(3, 'months').year();
    })
    return this_year_annually_datum?.num_of_hiring
  }
  careerText(){
    let text = ""
    this.recruitment_careers.forEach((recruitment_career) => {
      text += recruitment_career.main_text + "\n";
      text += recruitment_career.description_text + "\n";
    });
    return text
  }
  selectionText(){
    return this.recruitment_selections.sort((recruitment_selection1, recruitment_selection2)=>{
      return recruitment_selection1.step_num - recruitment_selection2.step_num
    }).map((recruitment_selection)=>{
      return recruitment_selection.selection_type.name
    }).join(" => ")
  }
  requiredSkillText(is_must=null){
    let text = ""
    this.recruitment_required_skills.filter((r)=>{return r.is_must == is_must || is_must == null})
      .forEach((recruitment_required_skill)=>{
        text += recruitment_required_skill.skill.name + "\n"
        text += recruitment_required_skill.description_text + "\n"
    })
    if (!is_must) text += this.recruitment_detail.required_skills_description_text;
    return text
  }
  workLocationText(){
    return this.recruitment_company_offices.map((recruitment_company_office)=>{
      let office = recruitment_company_office.company_office;
      let text = ""
      if(office?.prefecture?.name) { text += `${office.prefecture.name}: \n` }
      if(office?.address) { text += `${office.address}\n` }
      if(recruitment_company_office.description_text) { text += `＊ ${recruitment_company_office.description_text}` }
      return text
    }).join("\n\n")
  }
  schoolDepartmentText(is_must=null){
    if ( this.is_school_department_category_must == is_must || is_must == null ) {
      // idに含まれているものによってテキストを変える
      const study_department_category_ids = this.target_study_department_categories.map(tSDC=>tSDC.id)
      if ( study_department_category_ids.includes(STUDY_DEPARTMENT_CATEGORY_ID["other_arts"]) && study_department_category_ids.includes(STUDY_DEPARTMENT_CATEGORY_ID["other_sciences"]) ){
        // 理系その他も文系その他も含まれている場合
        return t("all_school_department_categories");
      } else if ( study_department_category_ids.includes(STUDY_DEPARTMENT_CATEGORY_ID["other_arts"]) ){
        // 文系その他だけ含まれている場合
        return t("arts_department_categories")
      } else if ( study_department_category_ids.includes(STUDY_DEPARTMENT_CATEGORY_ID["other_sciences"]) ){
        // 理系その他だけ含まれている場合
        return t("sciences_department_categories")
      } else {
        // その他がない場合
      let text = "";
        this.target_study_department_categories.forEach((target_study_department_category) => {
          text += target_study_department_category.name + "\n";
        });
        return text;
      }
    }
  }
  relationCountriesText(){
    let text = ""
    if (this.recruitment_careers.find(rc=>rc.is_work_globaly)) { text += t("relate_many_countries")+"\n" }
    text += this.recruitment_country_relations.map(recruitment_country_relation => {
      return recruitment_country_relation.country.name;
    }).join(", ")
    return text || t("nothing")
  }
  workTimeText(){
    let text = ""
    if(this.recruitment_detail.is_adopting_flextime) { text += t("flex") + "\n" + t("core_time") + ": "; }
    if(this.recruitment_detail.work_start_at) { text += moment(this.recruitment_detail.work_start_at).format("H:mm") }
    if (this.recruitment_detail.work_start_at||this.recruitment_detail.work_end_at) { text += " ~ " }
    if(this.recruitment_detail.work_end_at) { text += moment(this.recruitment_detail.work_end_at).format("H:mm") }
    return text
  }
  transportMeanText(){
    return this.recruitment_company_offices[0]?.company_office?.company_office_transport_means?.map( company_office_transport_mean=>{
      return company_office_transport_mean.transport_mean.name
    } ).join("\n")
  }
  jobCategoryText(){
    return this.recruitment_job_categories.map(recruitment_job_category=>{
      return recruitment_job_category.job_category?.name;
    }).join(", ");
  }
  mainPrefecture(){
    this.recruitment_company_offices[0]?.company_office?.prefecture?.name;
  }
  // 給料全体表示
  // 学歴: modelMin ~ modelMax 円/月
  // 内訳: min ~ max 円 + 手当諸々
  salaryAllText() {
    // 年収の時は内訳を表示しない
    if (this.recruitment_detail.salary_unit == "year") {
      // mid_careerのみ表示が違うため切り出している("中途"と表示しないため)
      let mid_career_text = ""
      if (this.minSalary("mid_career")) {
        mid_career_text = `${this.basicSalaryText("mid_career")}\n*${t("salary_decided_by_interview")}\n\n`
      }
      return mid_career_text + [
        "professional_school_students",
        "bachelor_students",
        "master_students",
        "doctor_students",
      ].filter(career => this.minSalary(career))
      .map(career => {
        let text = `${t(career)}: ${this.basicSalaryText(career)}` + "\n"
        if (this.recruitment_detail.is_salary_decided_by_interview) {
          text += `*${t("salary_decided_by_interview")}` + "\n"
        }
        return text
      })
      .join("\n")
    }
    else {
      // mid_careerのみ表示が違うため切り出している("中途"と表示しないため)
      let mid_career_text = ""
      if (this.minSalary("mid_career")) {
        mid_career_text = `${this.modelSalaryText("mid_career")}` + "\n" + this.modelSalaryAllowanceBreakdownText("mid_career") + "\n" + `*${t("salary_decided_by_interview")}` + "\n\n"
      }
      return mid_career_text + [
        "professional_school_students",
        "bachelor_students",
        "master_students",
        "doctor_students",
      ].filter(career => this.minSalary(career))
      .map(career => {
        let text = `${t(career)}: ${this.modelSalaryText(career)}` + "\n" + this.modelSalaryAllowanceBreakdownText(career) + "\n"
        if (this.recruitment_detail.is_salary_decided_by_interview) {
          text += `*${t("salary_decided_by_interview")}` + "\n"
        }
        return text
      })
      .join("\n")
    }
  }
  // モデル給料表示
  // min ~ max 円/月
  modelSalaryText(career) {
    const min = toS(this.modelMinSalary(career))
    const max = toS(this.modelMaxSalary(career))
    // 何もなし
    if (!min && !max) { return "" }
    // 面接で決まる場合
    if (this.recruitment_detail.is_salary_decided_by_interview) {
      return min + " ~ " + max + " " + this.salaryUnit()
    }

    if (!min) {
      return max + " " + this.salaryUnit() }
    else if (!max) {
      return min + " " + this.salaryUnit() }
    if (min == max) { return min + " " + this.salaryUnit() }
    else { return min + " ~ " + max + " " + this.salaryUnit() }
  }
  // モデル給料表示(全体)
  modelSalaryForAllText() {
    return numberFormatter(
      Math.min(
        ...[
          this.recruitment_detail.salary_min_for_professional_school_students,
          this.recruitment_detail.salary_min_for_bachelor_students,
          this.recruitment_detail.salary_min_for_master_students,
          this.recruitment_detail.salary_min_for_doctor_students,
          this.recruitment_detail.salary_min_for_mid_career,
        ].filter((value) => {
          return typeof value == "number";
        })
      ) + n(this.recruitment_detail.model_salary_allowance_value) )
      + " ~ " +
      numberFormatter(
      Math.max(
        ...[
          this.recruitment_detail.salary_max_for_professional_school_students,
          this.recruitment_detail.salary_max_for_bachelor_students,
          this.recruitment_detail.salary_max_for_master_students,
          this.recruitment_detail.salary_max_for_doctor_students,
          this.recruitment_detail.salary_max_for_mid_career,
        ].filter((value) => {
          return typeof value == "number";
        })
      ) + n(this.recruitment_detail.model_salary_allowance_value) )
      + " " + this.salaryUnit()
  }
  // モデル給料内訳表示
  // 内訳: min ~ max 円 + 手当諸々
  modelSalaryAllowanceBreakdownText(career) {
    if (this.minSalary(career) == undefined) { return "" }

    if (this.recruitment_detail.model_salary_allowance_value_description_text) {
      return `(${t("basic_salary")}: ${this.basicSalaryText(career)} + ${this.recruitment_detail.model_salary_allowance_value_description_text})`
    } else {
      return `(${t("basic_salary")}: ${this.basicSalaryText(career)})`
    }
  }
  modelMinSalary(career){
    let min = this.recruitment_detail[`salary_min_for_${career}`]
    if (!min) { return null }
    return numberFormatter(min + n(this.recruitment_detail.model_salary_allowance_value))
  }
  modelMaxSalary(career){
    let max = this.recruitment_detail[`salary_max_for_${career}`]
    if (!max) { return null }
    return numberFormatter(max + n(this.recruitment_detail.model_salary_allowance_value))
  }
  // 基本給表示
  // min ~ max 円/月
  basicSalaryText(career){
    const min = this.minSalary(career)
    const max = this.maxSalary(career)
    if (min == undefined && max == undefined) {
      return undefined
    } else if (min == undefined) {
      return max + this.salaryUnit()
    } else if (max == undefined) {
      return min + this.salaryUnit()
    }
    if (min == max) {
      return max + this.salaryUnit()
    } else {
      return min + " ~ " + max + this.salaryUnit()
    }
  }
  // 基本給表示（全体）
  // min ~ max 円/月
  basicSalaryForAllText(){
    const min = this.minSalaryForAll();
    const max = this.maxSalaryForAll();
    if (min == undefined && max == undefined) {
      return undefined
    } else if (min == undefined) {
      return max + this.salaryUnit()
    } else if (max == undefined) {
      return min + this.salaryUnit()
    }
    if (min == max) {
      return max + this.salaryUnit()
    } else {
      return min + " ~ " + max + this.salaryUnit()
    }
  }
  minSalary(career) {
    return numberFormatter(this.recruitment_detail[`salary_min_for_${career}`])
  }
  minSalaryForAll() {
    return numberFormatter(
      Math.min(
        ...[
          this.recruitment_detail.salary_min_for_professional_school_students,
          this.recruitment_detail.salary_min_for_bachelor_students,
          this.recruitment_detail.salary_min_for_master_students,
          this.recruitment_detail.salary_min_for_doctor_students,
          this.recruitment_detail.salary_min_for_mid_career,
        ].filter((value) => {
          return typeof value == "number";
        })
      )
    )
  }
  maxSalary(career) {
    return numberFormatter(this.recruitment_detail[`salary_max_for_${career}`])
  }
  maxSalaryForAll() {
    return numberFormatter(
      Math.max(
        ...[
          this.recruitment_detail.salary_max_for_professional_school_students,
          this.recruitment_detail.salary_max_for_bachelor_students,
          this.recruitment_detail.salary_max_for_master_students,
          this.recruitment_detail.salary_max_for_doctor_students,
          this.recruitment_detail.salary_max_for_mid_career,
        ].filter((value) => {
          return typeof value == "number";
        })
      )
    )
  }
  salaryUnit(){
    return t("yen") + "/" + t(this.recruitment_detail.salary_unit);
  }
  allowancesText(){
    let text = this.recruitment_allowances.map( recruitment_allowance=>{
      return `【${recruitment_allowance.allowance.name}】\n${recruitment_allowance.description_text}`;
    } ).join("\n\n")
    if (this.recruitment_detail.allowances_description_text){
      text = text + "\n\n" + this.recruitment_detail.allowances_description_text;
    }
     return text
  }

  availableOverseasText() {
    // nullだったらさよなら
    if (this.is_available_from_overseas === null || this.is_available_from_overseas === undefined) { return null }
    return this.is_available_from_overseas ? t("available_abroad") : t("unavailable_abroad")
  }
  availableMidCareerText(){
    return this.is_available_by_mid_career ? "応募可能" : "応募不可能";
  }
  needDriverLicenseText(){
    if(this.is_required_driver_license_before_join) { return "入社前に必要" }
    if(this.is_required_driver_license_after_join) { return "入社後に必要" }
    if(this.is_required_driver_license_after_join == false && this.is_required_driver_license_before_join == false )  {return "不要" }
    return "不明"
  }
  japaneseLevelsText(){
    return `JLPT: ${this.required_jlpt}
    話す: ${this.required_japanese_level?.speaking_level?.level}. ${this.required_japanese_level?.speaking_level?.name}
    聞く: ${this.required_japanese_level?.listening_level?.level}. ${this.required_japanese_level?.listening_level?.name}
    書く: ${this.required_japanese_level?.writing_level?.level}. ${this.required_japanese_level?.writing_level?.name}
    読む: ${this.required_japanese_level?.reading_level?.level}. ${this.required_japanese_level?.reading_level?.name}
    `;
  }
  interviewDressText(){
    return this.recruitment_detail?.interview_dress?.name
  }

  japaneseLevelText(is_must=null){
    if (is_must == this.required_japanese_level?.is_must || is_must == null) {
      return this.required_japanese_level?.general_level?.name || this.estimatedJapaneseLevelText()
    }
  }
  estimatedJapaneseLevelText(){
    if (this.required_jlpt == "N1" ){
      return t("lang_level_fluent");
    } else if (this.required_jlpt == "N2" || this.required_jlpt == "N3"){
      return t("lang_level_conversation");
    } else if (this.required_jlpt == "N4" || this.required_jlpt == "N5" || this.required_jlpt == "None"){
      return t("lang_level_basic");
    }
  }
  englishLevelText(is_must=null){
    if (is_must == this.required_english_level?.is_must || is_must == null) {
      return this.required_english_level?.general_level?.name;
    }
  }
  otherLanguageLevelText(is_must=null){
    let otherLanguageLevels = this.required_other_language_levels?.filter((r) => {return r.is_must == is_must || is_must == null});
    return otherLanguageLevels
      ?.filter((recruitment_required_language_level)=>{ return recruitment_required_language_level?.language?.name })
      .map((recruitment_required_language_level)=>{
      return recruitment_required_language_level?.language?.name + ": " + (recruitment_required_language_level?.general_level?.name || "")
    }).join("\n")
  }
}

function t(key, options){
  return i18n.t(`aggregates.Recruitments.${key}`, options);
}
function n(num){
  if (num) { return num }
  else { return 0 }
}
function toS(num) {
  if (num) { return String(num) }
  else { return "" }
}
export default Recruitment
