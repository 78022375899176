<template>
  <div>
    <VImg :src="event.thumbnail_url" class="mb-3"></VImg>
    <h2>{{event.title[lang]}}</h2>
    <div class="mt-5">
      <VueMarkdown :source="event.body[lang]" v-if="event.body[lang]" class="eventdetail-markdown"/>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import { HfMemberRegisterEventCommandService } from "hfapi"

export default {
  components: {
    VueMarkdown
  },
  props: {
    eventParams: {
      type: HfMemberRegisterEventCommandService.Params,
    },
    lang: {
      type: String
    }
  },
  computed: {
    event() {
      return this.eventParams.event
    }
  },
}
</script>

<style>

</style>