import { GetNotionDataQueryService } from "hfapi/hfw"

class BaseNotionModel  {

  constructor(uuid){
    this.uuid = uuid
    this.properties = {}
    this.configure()
    this.retreieveData()
  }

  configure(){
    throw "You must assign member variables such as 'domain', 'table', 'jtj_properties'"
  }

  transformFunctions(){
    throw "You must define 'transformFunctions' to use 'toJTJData' function'"
  }
  
  retreieveData(){
    GetNotionDataQueryService.execute({
      domain: this.domain,
      table: this.table,
      uuid: this.uuid
    }).then(resp=>{
      console.log("resp", resp)
      // JSONのものを置換する
      Object.keys(resp.data).forEach((key)=> {
        try { resp.data[key] = JSON.parse(resp.data[key]) } catch(e) { console.log("") }
      })
      Object.assign(this.properties, resp.data)
      this.fireCallbacks("assigned")
    })
  }

  toJTJData(targetKeys){
    let rtval = {}
    const transformFunctions = this.transformFunctions()
    Object.keys(transformFunctions).forEach(key=>{
      if(targetKeys.isInclude(key)){ rtval[key] = transformFunctions[key].bind(this)() }
    })
    return rtval
  }

  setDataToJTJ(jtjObject, targetKeys){
    return Object.assign(jtjObject, this.toJTJData(targetKeys))
  }

  // on: 発火タイミング: assigned
  // func: 関数
  addCallback(on, func){
    if (!this.callbacks){ this.callbacks = []}
    this.callbacks.push({on, func})
  }

  fireCallbacks(on){
    this.callbacks.forEach(callback=>{
      if(callback.on == on){ callback.func() }
    })
  }


  // private
  translateArray(array, ruleObject){
    if (!Array.isArray(array)) { return []}
    return array.map(key=>{
      return ruleObject[key]
    }).flat().distinct().compact()
  }

  extractJTJIds(array){
    if (!Array.isArray(array)) { return []}
    return array.map(valueStr=>{
      const match = valueStr.match(/\(jtj:(.+)\)/);
      return match ? match[1].split(":").map(idStr=>Number(idStr)) : null
    }).flat().distinct().compact()
  }

  translateArrayWithExtractJTJIds(array, ruleObject){
    const translatedArray = this.translateArray(array, ruleObject)
    const extractedArray = this.extractJTJIds(array)
    console.log(extractedArray);
    return [...translatedArray, ...extractedArray].distinct()
  }
}

export default BaseNotionModel;
