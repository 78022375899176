import BaseNotionModel from "./base_notion_model"

const studeyDepartmentRuleObject = {"機械学科": 16, "電気電子学科": 17, "情報工学科": 18, "理系": [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,], "文系": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], "建築学科": 19, "全学部OK": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,], "不明": null, "材料工学": 20, "化学": 27, "物理": 24, "数学": 25, "経営工学": 21, "高分子材料": 20, "経済": 4, "機械工学": 16, "土木": 19, "理工系": [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27], "プログラミング学科": 22, "自動車整備": 37, "薬学": 33, "獣医": 35, "特になし": null, "美術学科": 14, "デザイン学科": 14, "化学工学": 27, "制御システム工学": 16, "流体・航空宇宙工学系": 16, "機械・構造設計工学": 16, "経営": 4, "商学": 4, "農学": 29, "水産": 31, "農業": 29, "生命": 26, "環境": 5, "材料（半導体）": 20, "生物": 26, "観光": 13, "医学": 34,}
const japaneseLevelRuleObject = {"N1レベル": 1, "N1-N2レベル": 2, "N2レベル": 2, "N3レベル": 3, "N4・N5レベル": 5, "不要": null, "不明": null, "N2-N3レベル": 3, "N4": 4, "ネイティブレベル": 1, "N1上級": 1}
const jobCategoryRuleObject = { "1. 日本国内の営業": 1, "2. グローバル営業": 2, "3. 通訳": 3, "4. 人事": 4, "5. 経理・財務": 5, "6. 教師": 6, "7. 購買・調達": 7, "8. 総務": 8, "9. 企画・マーケティング": 9, "10. 接客・販売": 10, "12. 生産管理": 12, "13. デザイナー": 13, "14. 幹部候補生": 14, "15. システムエンジニア": 15, "16. 知的財産": 16, "17. コンサルタント(経営)": 17, "18. コンサルタント(科学)": 18, "19. 研究開発職": 19, "20. 電気エンジニア": 20, "20. CAD設計エンジニア": 20, "21. エンジニア(化学)": 21, "22. 生産技術": 22, "23. 技術営業": 23, "24. 品質管理": 24, "25. 建築設計": 25, "26. 施工管理": 26, "27. プログラマー": 27, "27. WEB開発エンジニア": 27, "28. WEBデザイナー": 28, "29. AIエンジニア": 29, "AI開発エンジニア": 29, "30. 総合職(理系)": 30, "31. 総合職(文系)": 31, "32. 技能工(機械加工)": 32, "33. 技能工(大工)": 33, "34. 製造": 34, "商社": 1, "35.商社": 2, "36．商品開発": 9, "37.エンジニア（機械・電気電子）": 20, "38．DX推進": 15, "39．エンジニア(機械・電気電子) デザイナー": 20, "40．設計エンジニア": 20, "整備士": 32, "獣医師": null, "エンジニア（情報）": 14, "金型設計": 20, "41.開発系クラウドエンジニア": 15, "42.インフラ系クラウドエンジニア": 15, "43.技術者;クラウドソース系": 15, "44. グローバル職": 2, "博士": 19, "45.旋盤オペレーター": 32, "設計・製造": 20, "海外営業": 2, "弁理士": null, "46.　営業事務": 8, "技術職": null, "47. NCマシニングオペレーター": 32, "コールセンターオペレーター": 8, "設計・開発エンジニア": 20, "日本語教師": 6, }
const prefectureRuleObject = {"岐阜県":21, "愛知県":23, "長野県":20, "東京":13, "広島":34, "岡山":33, "名古屋":23, "京都":26, "岐阜":21, "埼玉県":11, "栃木県":9, "滋賀県":25, "大阪府":27, "静岡県":22, "浜松":22, "神奈川県":14, "三重県":24, "金沢":17, "新潟":15, "千葉":12, "福岡":7, "福岡県":7, "千葉県":12, "不明":null, "兵庫":28, "熊本県":43, "横浜":14, "宮城県":4, "国内外全地域":null, "静岡":22, "香川県":37, "茨城":8, "大阪":27, "北海道":1, "神奈川":14, "世界":null, "群馬":10, "福島":7, "愛媛県":23, "長崎県":20, "東京都":13, "島根県":32, "新潟県":15, "奈良県":29, "在宅(リモート)":null,}
const lastLivingCountryRule = {"ベトナム": 228, "タイ": 208, "中国": 45, "ずっと日本": 109, "不明": null, "どこの国でもいい": null, "香港": 98, "マレーシア": 132, "シンガポール": 190, "インドネシア": 102, "アメリカ": 223, "ヨーロッパ": null, "ブラジル": 31, "フランス": 75, "日本": 109, "支社のある国のどこか": null, "インド": 101, "チェコ": 59, "多分ずっと日本": 109, "もしかしたらカンボジア": 37, "もしかしたらロシア": 177, "母国": null, "フィリピン": 169, "台湾": 205, "メキシコ": 140, "もしかしたら海外": null, "未定": null, "ドイツ": 82, "セルビア": 187, "カナダ": 39, "韓国": 116, "母国に帰っても良い": null, "もしかしたらタイ": 208, "その他": null, "母国に帰る可能性あり": null, "もしかしたら日本": 109,}
const relatedCountryRule = {"中国": 45, "タイ": 208, "シンガポール": 190, "不明": null, "アメリカ": 223, "ブラジル": 31, "フランス": 75, "インドネシア": 102, "チェコ": 59, "インド": 101, "ベトナム": 228, "イタリア": 107, "ドイツ": 82, "支社": null, "ロシア": 177, "カンボジア": 37, "アジア": null, "多分なし": null, "韓国": 116, "ミャンマー": 149, "今のところ決まっていない": null, "メキシコ": 140, "フィリピン": 169, "香港": 98, "なし": null, "いろいろ": null, "マレーシア": 132, "台湾": 205, "イギリス": 222, "オーストラリア": 14, "世界各国": null, "東南アジア": null, "トルコ": 215, "その他": null, "ヨーロッパ": null, "欧米": null,}
const japanId = 109

class NotionRecruitment extends BaseNotionModel {


  configure(){
    this.domain = "jtj"
    this.table = "recruitments"
  }

  transformFunctions(){

    return {

      title(){
        return {
          ja: this.properties.subject_template,
          en: this.properties.subject_template,
        }
      },

      simple_content(){
        return {
          ja: this.properties.japanese_share_template || this.properties.english_share_template,
          en: this.properties.english_share_template || this.properties.japanese_share_template,
        }
      },

      target_study_department_category_ids(){
        return this.translateArrayWithExtractJTJIds(this.properties.target_study_department_categories, studeyDepartmentRuleObject)
      },

      required_jlpt(){
        if (japaneseLevelRuleObject[this.properties.required_japanese_level]==null) { return null }
        return "N" + japaneseLevelRuleObject[this.properties.required_japanese_level]
      },

      required_japanese_level(){
        const level = japaneseLevelRuleObject[this.properties.required_japanese_level]
        return {
          speaking_level_id: level,
          listening_level_id: level,
          writing_level_id: level,
          reading_level_id: level,
          is_must: true,
        }
      },

      job_category_ids(){
        return this.translateArrayWithExtractJTJIds(this.properties.job_categories, jobCategoryRuleObject)
      },

      recruitment_company_offices(){
        return this.translateArrayWithExtractJTJIds(this.properties.working_prefectures, prefectureRuleObject).map(prefecture_id=>{
          return {
            description: {},
            company_office: {
              is_main_office: false,
              prefecture_id: prefecture_id,
            }
          }
        })
      },

      related_country_ids(){
        return this.translateArrayWithExtractJTJIds(this.properties.related_countries, relatedCountryRule)
      },

      recruitment_careers(){
        return this.translateArrayWithExtractJTJIds(this.properties.last_living_countries, lastLivingCountryRule).map(last_living_country_id=>{
          return{
            title: {},
            last_living_country_id,
            first_living_country_id: japanId,
            description: {},
          }
        })
      }

    }

  }
  
}


export default NotionRecruitment;
