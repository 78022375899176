<template>
  <VContainer fluid>
    <VRow>
      <VCol>
        <h3>リマインドメール送信ページ</h3>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="6" style="max-height: 70vh; overflow-y: scroll;">
        <h4>ユーザー情報</h4>
        <UserTable :user="user" />
      </VCol>
      <VCol cols="6" style="max-height: 70vh; overflow-y: scroll;">
        <h4>送信されたおすすめ企業</h4>
        <RecruitmentsList :recruitments="recruitments"/>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <HfMemberSelectField label="担当者" :items="master('hf_members')" v-model="recommendMail.hf_member_id" disabled></HfMemberSelectField>
      </VCol>
    </VRow>

    <VRow v-if="showMailGenerator">
      <VCol>
        <MailGenerator
          @send="send"
          :templateTitle="remindMailTemplate.title"
          :templateBody="remindMailTemplate.body"
          :locals="{recommend_mail: recommendMail, user: user, hf_member: recommendMail.hf_member}"
          :hideAddress="true"
          :sendding="sendding"
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import User from "@/aggregates/user.js"
import RecommendMail from "@/aggregates/recommendMail.js"
import Recruitment from "@/aggregates/recruitment.js"
import UserTable from "@@/components/organisms/users/UserTable"
import RecruitmentsList from "@@/components/organisms/recruitments/RecruitmentsList"
import {HfMemberSearchRecruitmentsQueryService,HfMemberSendRecommendRemindMailCommandService} from "hfapi"
import remindMailTemplate from "@@/components/organisms/recommendMails/mailTemplates/remind_mail.js"
import MailGenerator from "@@/components/molecules/MailGenerator.vue"

export default {
   metaInfo() {
    return {
      title: `レコメンドリマインド設定ページ`,
    }
  },
  components: {
    UserTable,
    RecruitmentsList,
    MailGenerator,
  },
  data(){
    return {
      user: new User(),
      recommendMail: new RecommendMail(this.$route.params.id),
      recruitments: [],
      sendding: false,
      remindMailTemplate: remindMailTemplate,
    }
  },
  computed: {
    showMailGenerator(){
      return this.recommendMail.isFetched && this.user.isFetched
    }
  },
  methods: {
    getRecruitments(){
      const p = new HfMemberSearchRecruitmentsQueryService.Params
      p.q.id_eq_any = this.recommendMail.recruitment_ids
      HfMemberSearchRecruitmentsQueryService.execute(p).then(resp=>{
          this.recruitments = resp.data.map(r=>{ return new Recruitment(r) })
      })
    },
    send(sendedMail) {
      this.sendding = true
      let params = new HfMemberSendRecommendRemindMailCommandService.Params()
      params.recommend_mail_id = this.recommendMail.id
      params.mail.title = sendedMail.title
      params.mail.body = sendedMail.body
      HfMemberSendRecommendRemindMailCommandService.execute(params).then(()=>{
        this.flash('送れました〜', 'success')
        this.$router.push({name: "RecommendMailIndexPage"})
      }).catch(e=>{
        this.flash(e.response.data, "error")
      })
    }
  },
  watch: {
    "recommendMail.isFetched": function(){
      this.user.fetch(this.recommendMail.user_id)
      this.getRecruitments()
    }
  },
}
</script>

<style>

</style>