"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "user": { "id": null, "is_leave_service": null } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "user": { "id": null, "is_leave_service": null } });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/hf_member__user_leave_from_service", { params });
    }
};
