<template>
  <div data-test="recruitment-show-contents-after-sign-up">
    <VRow>
      <VCol cols="12">
        <h2>{{recruitment.title_text}}</h2>
      </VCol>
      <VCol cols="4" md="1"  ref="top" v-if="company.logo_url" style="max-width: 120px" align-self="center">
        <VImg :src="company.logo_url" width="100%"></VImg>
      </VCol>
      <VCol cols="8" md="7">
        <h3>{{recruitment.jobCategoryText()}}</h3>
        <h4>{{[company.official_name, recruitment.mainPrefecture(), employmentStatus].join(" | ")}}</h4>
      </VCol>
    </VRow>
    <VRow justify="center" class="mt-5">
      <VCol>
        <VCarousel v-if="company.images.length != 0 || recruitment.images.length != 0" cycle interval="5000" :show-arrows="false" hide-delimiter-background>
          <VCarouselItem v-for="i in company.images" :key="i.id" >
            <VImg :src="i.url" lazy-src="https://api.job-tree-japan.com/images/noimage.png" contain />
          </VCarouselItem>
          <VCarouselItem v-for="i in recruitment.images" :key="i.id" >
            <VImg :src="i.url" lazy-src="https://api.job-tree-japan.com/images/noimage.png" contain/>
          </VCarouselItem>
        </VCarousel>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12">
        <h3 class="text-md-left primary--text">{{t('about_company')}}</h3>
        <InformationContainer :rowData="aboutCompanyRowData" darken/>
        <h3 class="text-md-left primary--text">{{t('job_description')}}</h3>
        <InformationContainer :rowData="jobDescriptionRowData" darken/>
        <h3 class="text-md-left primary--text">{{t('career')}}</h3>
        <InformationContainer :rowData="careerPathRowData" darken />
        <template v-if="similarRecruitments.length != 0">
          <p>{{t("similar_recruitment")}}</p>
          <ul class="mb-5">
            <li v-for="r in similarRecruitments" :key="r.id" class="mb-3">
              <router-link :to="{ name: 'RecruitmentShowPage', params: {id: r.id} }" target="_blank">
                {{r.recruitment_careers[0].title_text}}
              </router-link>
            </li>
          </ul>
        </template>
        <h3 class="text-md-left primary--text">{{t('salary')}}</h3>
        <InformationContainer :rowData="salaryRowData" darken/>
        <h3 class="text-md-left primary--text">{{t('company_environment')}}</h3>
        <InformationContainer :rowData="companyEnvRowData" darken/>
        <h3 class="text-md-left primary--text">{{t('qualifications')}}</h3>
        <InformationContainer :rowData="qualificationRowData" darken/>
        <template v-if="recruitment.recruitment_detail.training_description_text">
          <h3 class="text-md-left primary--text">{{t('training')}}</h3>
          <p>{{ recruitment.recruitment_detail.training_description_text }}</p>
        </template>
        <h3 class="text-md-left primary--text">{{t('about_application')}}</h3>
        <InformationContainer :rowData="applicationRowData" darken/>
        <h3 class="text-md-left primary--text">{{t('company_abstract')}}</h3>
        <InformationContainer :rowData="companyInsightRowData" darken />
      </VCol>
    </VRow>
  </div>
</template>

<script>
import InformationContainer from "@/components/molecules/InformationContainer"

export default {
  components: {
    InformationContainer,
  },
  props: {
    recruitment: {},
    company: {},
    aboutCompanyRowData: {},
    jobDescriptionRowData: {},
    careerPathRowData: {},
    similarRecruitments: {},
    salaryRowData: {},
    companyEnvRowData: {},
    qualificationRowData: {},
    applicationRowData: {},
    companyInsightRowData: {},
    employmentStatus: {},
  },
  methods: {
    t(key, options){
      return this.$t(`pages.recruitments.RecruitmentShowPage.${key}`, options)
    },
    tc(key, options){
      return this.$t(`aggregates.CompanyColumnNames.${key}`, options)
    },
    tr(key, options){
      return this.$t(`aggregates.RecruitmentColumnNames.${key}`, options)
    },
  },
}
</script>

<style scoped>
  .v-carousel {
    background-color: #dddddd;
    max-height: 200px
  }
  .v-carousel .v-responsive__content>.v-image {
    max-height: 200px
  }
  @media screen and (min-width: 600px) {
    .v-carousel {
      max-height: 300px
    }
    .v-carousel .v-responsive__content>.v-image {
      max-height: 300px
    }
  }
  @media screen and (min-width: 960px) {
    .v-carousel {
      max-height: 400px
    }
    .v-carousel .v-responsive__content>.v-image {
      max-height: 400px
    }
  }
</style>
