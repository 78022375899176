<template>
  <MailGenerator v-if="isSetUpObjects" @send="send"
  ref="mailGenerator"
  :templateTitle="mailTemplate.title"
  :templateBody="mailTemplate.body"
  :locals="{recommend_mail: recommendMail, user: user, hf_member: recommendMail.hf_member}"
  :hideAddress="true"
  :sendding="sendding"
  />
</template>

<script>
import MailGenerator from "@@/components/molecules/MailGenerator.vue"
import User from "@/aggregates/user.js"
import recommendTemplate from "@@/components/organisms/recommendMails/mailTemplates/recommend.js"
import expandVisionTemplate from "@@/components/organisms/recommendMails/mailTemplates/follow_expand_vision.js"
import improveJapaneseTemplate from "@@/components/organisms/recommendMails/mailTemplates/follow_improve_japanese.js"
import notHaveGakushi from "@@/components/organisms/recommendMails/mailTemplates/follow_not_have_gakushi.js"
// import mailTemplates 

export default {
  components: {
    MailGenerator
  },
  props: {
    recommendMail: {
      type: Object
    },
    sendding: {
      type: Boolean
    }
  },
  data(){
    return {
      user: new User(this.recommendMail.user_id),
      templates: {
        "recommend": recommendTemplate,
        "follow_expand_vision": expandVisionTemplate,
        "follow_improve_japanese": improveJapaneseTemplate,
        "follow_not_have_gakushi": notHaveGakushi,
      }
    }
  },
  computed: {
    mailTemplate(){
      return this.templates[this.recommendMail.recommend_mail_type]
    },
    isSetUpObjects(){
      return (this.user.id)
    }
  },
  methods:{
    send(sendedMail){
      this.$emit('send', sendedMail)
    }
  }
}
</script>

<style>

</style>