<template lang="">
  <VContainer>

    <h2>退会処理</h2>
    <p>
      マニュアルは以下にあります <br>
      <a href="https://www.notion.so/hf-jtj/379ad352095c4adcac7ba57a702cb6d7" target="_blank">ユーザーの退会処理/データ削除/メール配信停止周りの現状と実務(Notion)</a>
    </p>
    <VRow>
      <!-- ユーザーの情報 -->
      <VCol cols="12">
        <h4></h4>対象ユーザー
        <p>
          {{ user.summary }}
        </p>
      </VCol>

      <!-- 退会していない時 -->
      <VCol cols="12" class="" v-if="!user.is_leave_service">
        <p>
          ＊Email, Passwordが上書きされユーザーがログインできなくなります。 <br>
          ＊Notionのユーザーステートが上書きされます。<br>
          ＊一斉送信メール設定Spreadsheetの「退会者」にデータが登録されます。
        </p>
        <VBtn color="success" @click="update">退会処理をする</VBtn>
      </VCol>

      <!-- 退会済の時 -->
      <VCol cols="12" v-if="user.is_leave_service">
        ＊こちらのユーザーの退会処理は完了しています。
      </VCol>
    </VRow>

  </VContainer>
</template>
<script>
import { HfMemberUserLeaveFromServiceCommandService } from "hfapi/jtj"
import User from "@/aggregates/user";


export default {
  data(){
    return {
      user: new User(this.$route.params.id),
      params: new HfMemberUserLeaveFromServiceCommandService.BuildableParams()
    }
  },
  mounted(){
    this.params.user.id = this.$route.params.id
    this.params.user.is_leave_service = true
  },
  methods: {
    update(){
      const check = window.confirm("ユーザーがLoginできなくなりますが本当に大丈夫ですか？\n＊マニュアルをよく読んだ上で実行ください。");
      if (check){
        HfMemberUserLeaveFromServiceCommandService.execute(this.params).then((resp)=>{
          this.user.assign(resp.data)
          console.log(resp.data)
          this.flash("ユーザーの退会処理が完了しました", "success")
        }).catch(e=>{
          console.log(e)
          this.flash("エラーが起きました")
        })
      }
    }
  },
}
</script>
<style lang="">
  
</style>