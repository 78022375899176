<template>
  <VForm ref="form">
    <VFileInput
      label="Upload"
      @change="emit"
      :rules="[validation.required]"
    />
  </VForm>
</template>

<script>
export default {
  props: {
    value: {} // 普通にmodelとして
  },
  data(){
    return {
    }
  },
  methods: {
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      return this.$refs.form.validate()
    },
    emit(e){
      console.log(e);
      this.$emit('input', e)
    }
  } 
}
</script>

<style>

</style>