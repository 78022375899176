<template>
  <div>
    <LoginForm :loginData="data" @login="login" />
  </div>
</template>

<script>
import LoginForm from "@@/components/organisms/LoginForm.vue"
export default {
  components: {
    LoginForm
  },
  metaInfo() {
    return {
      title: `ログインページ`,
    }
  },
  data(){
    return {
      data: {
        email: "",
        password: "",
      }
    }
  },
  methods: {
    login(){
      this.$store.dispatch("auth/signIn", this.data).then(()=>{
        this.$store.dispatch("hf_member/update")
        this.redirectOrPush("/")
      })
    }
  },
}
</script>

<style>

</style>