<template>
  <div>
    <VContainer v-if="initializedAggregates">
      <h1 v-if="!recruitment.is_released" style="color: red;">非公開です</h1>
      <VRow>
        <VCol>
          <VBtn class="ma-2" :to="{name: 'RecruitmentEditPage', params: {id: recruitment.id}}">編集ページへ</VBtn>
          <VBtn class="ma-2" :to="{name: 'RecruitmentSimpleEditPage', params: {id: recruitment.id}}">簡易編集ページへ</VBtn>
          <VBtn class="ma-2" :to="{name: 'RecruitmentBuildApplyLinkPage', params: {id: recruitment.id}}">Build Apply Link</VBtn>
          <VBtn class="ma-2" :to="{name: 'RecruitmentCopyPage', params: {id: recruitment.id}}">コピーぺージへ</VBtn>
          <VBtn class="ma-2" :to="{name: 'CompanyEditPage', params: {id: recruitment.company_id}}">企業編集ぺージへ</VBtn>
          <VBtn class="ma-2" @click="window.open(`${VUE_APP_JTJ_HOST}/#/recruitments/${recruitment.id}`)">ユーザー用の求人ぺージへ</VBtn>
          <VBtn class="ma-2" @click="copy('user_detail_field')">ユーザー用の求人ぺージリンクコピー</VBtn>
          <VBtn class="ma-2" @click="showDeleteModal = true" color="error">この求人を削除</VBtn>
          <VBtn class="ma-2" v-show="recruitment.is_simple" @click="changeRecruitmentIsSimple(false)">フルページで表示する</VBtn>
          <VBtn class="ma-2" v-show="!recruitment.is_simple" @click="changeRecruitmentIsSimple(true)">簡易版で表示する</VBtn>
          <ConfirmationDialog :showModal="showDeleteModal" message="本当に削除しますか？" btnText="削除" @confirm="deleteRecruitment()" btnColor="error"></ConfirmationDialog>
          <VTextarea ref="user_detail_field" style="width: 1px; height: 0px;" :value="`${VUE_APP_JTJ_HOST}/#/recruitments/${recruitment.id}`"></VTextarea>
        </VCol>
      </VRow>
      <!-- 内情DB -->
      <VRow>
        <VCol cols="12" md="7">
          <InformationContainer :rowData="recruitmentInternalData1" />
          <h3>閲覧データ</h3>
          <RecruitmentPageViewResultOrg :recruitment_id="String(recruitment.id)"/>
          <VDivider class="my-3" />
          <h3>このページから登録したユーザー</h3>
          <RecruitmentSignUpUsers :recruitment_id="String(recruitment.id)"/>
          <VDivider class="my-3" />
          <h3>過去応募者の情報</h3>
          <InformationContainer :rowData="recruitmentPastApplicationsData" />
        </VCol>
      </VRow>
      <VDivider class="my-5" />
      <!-- 普通のやつ -->
      <VRow>
        <h2>現在の表示形式は{{ recruitment.is_simple ? "簡易版" : "通常版" }}です</h2>
      </VRow>
      <VRow>
        <VCol>
          <RecruitmentShowContentsAfterSignUp
            v-if="!recruitment.is_simple"
            :recruitment="recruitment"
            :company="company"
            :aboutCompanyRowData="aboutCompanyRowData"
            :jobDescriptionRowData="jobDescriptionRowData"
            :careerPathRowData="careerPathRowData"
            :similarRecruitments="similarRecruitments"
            :salaryRowData="salaryRowData"
            :companyEnvRowData="companyEnvRowData"
            :qualificationRowData="qualificationRowData"
            :applicationRowData="applicationRowData"
            :companyInsightRowData="companyInsightRowData"
            :employmentStatus="employmentStatus"
          />
          <RecruitmentShowSimpleContent v-if="recruitment.is_simple" :recruitment=recruitment />
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<script>
import InformationContainer from "@@/components/molecules/InformationContainer"
import ConfirmationDialog from "@@/components/molecules/ConfirmationDialog"
import Recruitment from "@/aggregates/recruitment"
import Company from "@/aggregates/company"
import Application from "@/aggregates/application"
import { UserApplyCommandService, HfMemberSearchApplicationsQueryService, HfMemberDeleteRecruitmentCommandService, SearchRecruitmentsQueryService, HfMemberChangeRecruitmentIsSimpleCommandService } from "hfapi/jtj"
import RecruitmentShowContentsAfterSignUp from '@/components/organisms/recruitments/RecruitmentShowContentsAfterSignUp.vue'
import RecruitmentShowSimpleContent from '@/components/organisms/recruitments/RecruitmentShowSimpleContent.vue'
import RecruitmentPageViewResultOrg from '../../organisms/recruitments/RecruitmentPageViewResultOrg.vue'
import RecruitmentSignUpUsers from '../../organisms/recruitments/RecruitmentSignUpUsers.vue'

export default {
   metaInfo() {
    return {
      title: `${this.recruitment.title.ja}-求人詳細ページ`,
    }
  },
  components: {
    InformationContainer,
    ConfirmationDialog,
    RecruitmentShowContentsAfterSignUp,
    RecruitmentShowSimpleContent,
    RecruitmentPageViewResultOrg,
    RecruitmentSignUpUsers,
  },
  data(){
    return {
     recruitment: new Recruitment(this.$route.params.id),
     company: new Company(),
     applications: [],
     similarRecruitments: [],
     showDeleteModal: false,
    }
  },
  computed: {
    recruitmentInternalData1(){
      let recruitment = this.recruitment
      let company = this.company
      return [
        {
          name: "議事録",
          link: this.company.company_internal_datum.gijiroku_url,
          text: "( 上の文字をクリックで開きます )"
        },
        {
          name: "内情DB",
          tableData: [
            { name: "どんな留学生を求めているか？", text: recruitment.recruitment_internal_datum.demanded_students_description, horizontal: true },
            { name: "なぜ留学生を求めているか？", text: recruitment.recruitment_internal_datum.why_demand_intl_students, horizontal: true },
            { name: "どんな仕事を留学生にしてほしいか？", text: recruitment.recruitment_detail.job_description_text, horizontal: true },
            { name: "選考で気にするにするポイント", text: recruitment.recruitment_detail.selection_valued_point_text, horizontal: true },
            { name: "企業メモ", text: company.company_internal_datum?.memo, horizontal: true },
            { name: "求人メモ", text: recruitment.recruitment_internal_datum?.memo, horizontal: true },
            { name: "履歴書の提出時の注意", text: company.company_internal_datum?.resume_submit_alert, horizontal: true },
            { name: "面接の服装", text: recruitment.interviewDressText(), horizontal: true },
            // { name: "受かった人や落ちた人の情報", text:  },
          ],
        },
      ]
    },
    recruitmentPastApplicationsData(){
      return this.applications.map((application)=>{
        return {
          name: application.user.display_for_hf,
          link: `/#/applications/${application.id}`,
          tableData: [
            { name: "応募日", text: application.createdAtText() },
            { name: "状態", text: application.state },
            { name: "結果の原因", text: application.reason_of_result },
          ]
        }
      })
    },
    aboutCompanyRowData(){
      return [
        { name: this.tc("vision_id"), text: this.company.vision_text },
        { name: this.t("appealing_point_of_company"), text: this.company.appeal_point_text},
        { name: this.t("appealing_point_of_recruitment"), text: this.recruitment.recruitment_detail.appeal_point_text},
        { name: this.t("business_description"), text: this.company.business_description_text },
        { name: this.t("foreign_employees"), text: this.company.numOfForeignersText() },
      ]
    },
    jobDescriptionRowData() {
      return [
        { name: this.t("title"), text: this.recruitment.jobCategoryText() },
        { name: this.t("job_description"), text: this.recruitment.recruitment_detail.job_description_text },
      ]
    },
    careerPathRowData() {
      return this.recruitment.recruitment_careers.map((c) => {
        return { name: c.title_text, text: c.description_text }
      })
    },
    salaryRowData() {
      return [
        { name: "", text: this.recruitment.salaryAllText() },
        { name: this.t("bonus"), tableData: [
          { name: this.t("bonus_paid_months"), text: this.company.bonusPaidMonthText() },
          { name: this.t("bonus_months_worth"), text: (this.company.bonus_months_worth ? this.t("bonus_months_worth_text", {months: this.company.bonus_months_worth}) : "") },
        ] },
        { name: this.t("various_allowances"), text: this.recruitment.allowancesText() },
        { name: this.t("insurance"), text: this.company.insurancesText() },
      ]
    },
    salaryRowDataBeforeSignUp() {
      return [
        { name: "", text: this.recruitment.salaryAllText() },
        { name: this.t("bonus"), tableData: [
          { name: this.t("bonus_paid_months"), text: this.company.bonusPaidMonthText() },
          { name: this.t("bonus_months_worth"), text: (this.company.bonus_months_worth ? this.t("bonus_months_worth_text", {months: this.company.bonus_months_worth}) : "") },
        ] },
      ]
    },
    companyEnvRowData() {
      return [
        { name: this.tc("company_housing_support"), text: this.company.housingSupportText() },
        { name: this.t("ministry_of_health_welfare"), text: this.company.employee_welfare_description_text },
        { name: this.t("attention_to_religion"), text: this.company.religiousConsiderationsText() },
        { name: this.t("commuter_means"), text: this.recruitment.transportMeanText() },
      ]
    },
    qualificationRowData() {
      return [
        { name: this.t("ideal_statue"), text: this.recruitment.recruitment_detail.ideal_statue_text },
        { name: this.t("required_qualifications"), tableData: [
          { name: this.t("department"), text: this.recruitment.schoolDepartmentText(true) },
            { name: this.t("required_skill"), text: this.recruitment.requiredSkillText(true) },
            { name: this.t("japanese_level"), text: this.recruitment.japaneseLevelText(true) },
            { name: this.t("english_level"), text: this.recruitment.englishLevelText(true) },
            { name: this.t("other_language"), text: this.recruitment.otherLanguageLevelText(true) },
          ]
        },
        { name: this.t("preferred_qualifications"), tableData: [
            { name: this.t("department"), text: this.recruitment.schoolDepartmentText(false) },
            { name: this.t("required_skill"), text: this.recruitment.requiredSkillText(false)},
            { name: this.t("japanese_level"), text: this.recruitment.japaneseLevelText(false) },
            { name: this.t("english_level"), text: this.recruitment.englishLevelText(false) },
            { name: this.t("other_language"), text: this.recruitment.otherLanguageLevelText(false) },
          ],
          hint: this.t("preferred_qualifications_hint")
        },
      ]
    },
    applicationRowData() {
      return [
        { name: "", tableData: [
          { name: this.t("selection_way"), text: this.recruitment.selectionText() },
          { name: this.t("number_of_available_position"), text: this.recruitment.numOfHiring(), hint: "We will stop recruiting once we find the ideal candidate" },
          { name: this.t("abroad"), text: this.recruitment.availableOverseasText() },
        ] },
      ]
    },
    companyInsightRowData() {
      return [
        { name: "", tableData: [
          { name: this.t("work_location"), text: this.recruitment.workLocationText() },
          { name: this.t("head_office_address"), text: this.company.mainOfficeAddressText() },
          { name: this.t("working_hours"), text: this.recruitment.workTimeText() },
          { name: this.tc("holiday_description_id"), text: this.company.holiday_description_text },
          { name: this.tc("paid_leave_description_id"), text: this.company.paid_leave_description_text },
          { name: this.t("company_of_the_number_of_people"), text: this.company.numOfEmployees() },
          { name: this.t("foreign_employees"), text: this.company.numOfForeigners() },
          { name: this.t("countries_involved_in_the_work"), text: this.recruitment.relationCountriesText() },
          { name: this.t("overseas_expansion_country"), text: this.company.relationCountriesText("overseas_expansion_country") },
          { name: this.t("branch"), text: this.company.relationCountriesText("branch") },
          { name: this.t("client"), text: this.company.relationCountriesText("client") },
          { name: this.t("capital"), text: this.company.capitalText() },
          { name: this.t("earnings"), text: this.company.salesText() },
        ] },
      ]
    },
    initializedAggregates(){
      return this.company.isFetched && this.recruitment.isFetched
    },
    employmentStatus(){
      if ( Number(this.company.id)==1180 ){ return "" }
      if ( Number(this.recruitment.id)==515||Number(this.recruitment.id)==535 ){ return this.t("internship") }
      return this.t("full_time")
    }
  },
  methods: {
    changeRecruitmentIsSimple(is_simple){
      const params = new HfMemberChangeRecruitmentIsSimpleCommandService.Params
      params.id = this.recruitment.id
      params.is_simple = is_simple

      HfMemberChangeRecruitmentIsSimpleCommandService.execute(params).then(() => {
        this.flash("変更されました．")
        this.recruitment.fetch()
      }).catch(() => {
        this.falsh("変更に失敗しました．\n管理者に連絡して下さい．")
      })
    },
    apply(){
      let params = new UserApplyCommandService.Params
      params.application.recruitment_id = this.recruitment.id
      UserApplyCommandService.execute(params).then( resp => {
        this.$router.push({ name: 'ApplicationResumeEditPage', params: { id: resp.data.id }})
      } )
    },
    t(key, options){
      return this.$t(`pages.recruitments.RecruitmentShowPage.${key}`, options)
    },
    tc(key, options){
      return this.$t(`aggregates.CompanyColumnNames.${key}`, options)
    },
    tr(key, options){
      return this.$t(`aggregates.RecruitmentColumnNames.${key}`, options)
    },
    copy(refname){
      const element = this.$refs[refname].$el.querySelector("textarea")
      element.select();
      element.setSelectionRange(0, 99999);
      document.execCommand("copy");
      this.flash("copyされました")
    },
    deleteRecruitment() {
      this.showDeleteModal = false
      let params = new HfMemberDeleteRecruitmentCommandService.Params
      params.recruitment.id = this.recruitment.id
      HfMemberDeleteRecruitmentCommandService.execute(params).then(resp=>{
        console.log(resp)
      })
    }
  },
  created(){
    this.recruitment.after_assign = () => {
      this.company.fetch(this.recruitment.company_id)
      SearchRecruitmentsQueryService.execute({ q: { job_categories_id_eq_any: this.recruitment.job_category_ids, company_id_eq_any: [this.recruitment.company_id]}}).then(resp=>{
        this.similarRecruitments = resp.data.filter(r => {return r.id != this.recruitment.id})
      })
    },
    HfMemberSearchApplicationsQueryService.execute({q: { recruitment_id_eq_any: [this.$route.params.id] }}).then(resp=>{
      this.applications = resp.data.map(a=>new Application(a)).sort((application1, application2)=>{ return new Date(application2.created_at) - new Date(application1.created_at) })
    })
  }
}
</script>

<style scoped>
</style>
