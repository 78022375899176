<template>
  <VForm ref="form">
    <h3 class="mt-4 mb-n4">{{t('writing_level', {lang_name: t('japanese')} ) }}</h3>
    <VRadioGroup v-model="japaneseSkill.writing_level_id" :rules="[validation.required]">
      <VRadio
        v-for="writingLevel in writingLevels"
        :key="writingLevel.id"
        :value="writingLevel.id"
        :label="writingLevel.name"
      />
    </VRadioGroup>

    <h3 class="mt-4 mb-n4">{{t('reading_level', {lang_name: t('japanese')} ) }}</h3>
    <VRadioGroup v-model="japaneseSkill.reading_level_id" :rules="[validation.required]">
      <VRadio
        v-for="readingLevel in readingLevels"
        :key="readingLevel.id"
        :value="readingLevel.id"
        :label="readingLevel.name"
      />
    </VRadioGroup>

    <h3 class="mt-4 mb-n4">{{ t( 'listening_level', {lang_name: t('japanese')} ) }}</h3>
    <VRadioGroup v-model="japaneseSkill.listening_level_id" :rules="[validation.required]">
      <VRadio
        v-for="listeningLevel in listeningLevels"
        :key="listeningLevel.id"
        :value="listeningLevel.id"
        :label="listeningLevel.name"
      />
    </VRadioGroup>

    <h3 class="mt-4 mb-n4">{{t('speaking_level', {lang_name: t('japanese')} ) }}</h3>
    <VRadioGroup v-model="japaneseSkill.speaking_level_id" :rules="[validation.required]">
      <VRadio
        v-for="speakingLevel in speakingLevels"
        :key="speakingLevel.id"
        :value="speakingLevel.id"
        :label="speakingLevel.name"
      />
    </VRadioGroup>
  </VForm>
</template>

<script>
import { GetMasterDataQueryService } from "hfapi/master";

export default {
  props: ["value"],
  data() {
    return {
      selectedLevels: {},
      writingLevels: [],
      readingLevels: [],
      speakingLevels: [],
      listeningLevels: [],
    };
  },
  computed: {
    japaneseSkill(){
      return this.value.japaneseSkill()
    }
  },
  methods: {
    getLevels() {
      let params = new GetMasterDataQueryService.Params();

      params.target.writing_levels = true;
      params.target.reading_levels = true;
      params.target.speaking_levels = true;
      params.target.listening_levels = true;

      GetMasterDataQueryService.execute(params).then((resp) => {
        this.writingLevels = resp.data.writing_levels;
        this.readingLevels = resp.data.reading_levels;
        this.speakingLevels = resp.data.speaking_levels;
        this.listeningLevels = resp.data.listening_levels;
      });
    },
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      return this.$refs.form.validate()
    },
  },
  created() {
    this.getLevels();
    if(!this.value.japaneseSkill()){
      let uls = {language_id: 1, writing_level_id: null,reading_level_id: null, listening_level_id: null, speaking_level_id: null}
      this.value.user_language_skills.push(uls)
    }
  },
};
</script>

<style>
</style>