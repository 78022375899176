<template>
  <VDialog v-model="showModal" @click:outside="closeDialog()" @keydown.esc="closeDialog()">
    <VCard elevation="2">
      <VCardTitle>ログインし直してください</VCardTitle>
      <VCardText>
        <p><strong class="error--text">(＊注意)</strong>ログインが完了しても直前の操作は完了しません。完了するには操作し直してください。</p>
        <p>例：フォームを入力して保存ボタンを押した場合→ログイン後再度保存ボタンを押す</p>
        <LoginForm :loginData="data" @login="login" />
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import LoginForm from "@@/components/organisms/LoginForm.vue"

export default {
  components: {
    LoginForm
  },
  props: {
    showModal: {
      type: Boolean,
    },
  },
  data(){
    return {
      data: {
        email: "",
        password: "",
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    login(){
      this.$store.dispatch("auth/signIn", this.data).then(()=>{
        this.$store.dispatch("hf_member/update", () => {
          this.closeDialog()
        })
      })
    }
  }
}
</script>
