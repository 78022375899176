"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "article": { "id": null, "title": { "ja": null, "en": null }, "content": { "ja": null, "en": null }, "after_signup_content": { "ja": null, "en": null }, "publish_at": null, "is_hidden": null, "call_to_action": { "ja": null, "en": null } } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "article": { "id": null, "title": { "ja": null, "en": null }, "content": { "ja": null, "en": null }, "after_signup_content": { "ja": null, "en": null }, "publish_at": null, "is_hidden": null, "call_to_action": { "ja": null, "en": null } } });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/hf_member__register_article", { params });
    }
};
