"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "q": { "id_eq": null, "country_id_eq": null, "local_name_cont": null, "english_name_cont": null } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "q": { "id_eq": null, "country_id_eq": null, "local_name_cont": null, "english_name_cont": null } });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.get("/jtj/queries/get_universities", { params });
    }
};
