import Vue from 'vue'
import Vuex from 'vuex'
import PersistedState from 'vuex-persistedstate'
import { LANGUAGE_SYMBOL_2_ID } from "@/config/constants.js";

import auth from './modules/auth'
import user from './modules/user'
import flash from './modules/flash'
import master from './modules/master'
import gac from './modules/gac'


Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    auth, user, flash, master, gac,
  },
  plugins: [PersistedState({
    key: 'jtj',
    paths: ['auth', 'page_language_id', 'gac'],
    storage: window.localStorage
  })],
  state: {
    page_language_id: ""
  },
  mutations: {
    setPageLang(state, symbolOrId){
      if(Number(symbolOrId)){
        // Idの時
        state.page_language_id = symbolOrId;
      } else {
        // symbolの時
        state.page_language_id = LANGUAGE_SYMBOL_2_ID[symbolOrId];      
      }
    }
  }
})

export default store