var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VChipGroup',_vm._l((_vm.selectedResumeQuestions),function(rq){return _c('VChip',{key:rq.id,domProps:{"textContent":_vm._s(rq.title_for_hf_member)}})}),1),_c('VDialog',{attrs:{"max-width":"80vw"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('VChip',_vm._g(_vm._b({attrs:{"color":"primary"}},'VChip',attrs,false),on),[_c('VIcon',[_vm._v("mdi-pencil")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('VCard',[_c('VCardTitle',[_vm._v("クリックして確認・編集することができます．")]),_c('VCardActions',[_c('VTextField',{staticClass:"ma-2",attrs:{"label":"Search","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('VBtn',{staticClass:"ma-2",on:{"click":_vm.create}},[_c('VIcon',[_vm._v("mdi-plus")]),_vm._v("質問を追加する")],1),_c('VBtn',{on:{"click":function($event){return _vm.$emit('onResumeQuestionRegister')}}},[_c('VIcon',[_vm._v("mdi-sync")]),_vm._v("一覧を更新する")],1)],1),_c('VDataTable',{attrs:{"items":_vm.resumeQuestions,"headers":_vm.headers,"item-key":"id","return-value":"id","search":_vm.search,"custom-sort":_vm.sort},on:{"click:row":function($event){return _vm.routerWithBlank({ name: 'ResumeQuestionEditPage', params: { id: $event.id }})}},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
var item = ref.item;
return [_c('VCheckbox',{attrs:{"input-value":_vm.isSelected(item)},on:{"click":function($event){$event.stopPropagation();return _vm.toggle(item)}}})]}},{key:"item.recruitment_resume_question.is_display_in_resume",fn:function(ref){
var item = ref.item;
return [(_vm.isSelected(item))?_c('VCheckbox',{on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.findRecruitmentResumeQuestionByResumeQuestionId(item.id).is_display_in_resume),callback:function ($$v) {_vm.$set(_vm.findRecruitmentResumeQuestionByResumeQuestionId(item.id), "is_display_in_resume", $$v)},expression:"findRecruitmentResumeQuestionByResumeQuestionId(item.id).is_display_in_resume"}}):_vm._e()]}},{key:"item.recruitment_resume_question.num_of_approximate_characters_ja",fn:function(ref){
var item = ref.item;
return [(_vm.isSelected(item))?_c('VTextField',{attrs:{"type":"number"},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.findRecruitmentResumeQuestionByResumeQuestionId(item.id).num_of_approximate_characters_ja),callback:function ($$v) {_vm.$set(_vm.findRecruitmentResumeQuestionByResumeQuestionId(item.id), "num_of_approximate_characters_ja", $$v)},expression:"findRecruitmentResumeQuestionByResumeQuestionId(item.id).num_of_approximate_characters_ja"}}):_vm._e()]}},{key:"item.recruitment_resume_question.num_of_approximate_words_en",fn:function(ref){
var item = ref.item;
return [(_vm.isSelected(item))?_c('VTextField',{attrs:{"type":"number"},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.findRecruitmentResumeQuestionByResumeQuestionId(item.id).num_of_approximate_words_en),callback:function ($$v) {_vm.$set(_vm.findRecruitmentResumeQuestionByResumeQuestionId(item.id), "num_of_approximate_words_en", $$v)},expression:"findRecruitmentResumeQuestionByResumeQuestionId(item.id).num_of_approximate_words_en"}}):_vm._e()]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title.ja)),_c('br'),_vm._v(" "+_vm._s(item.purpose.ja)),_c('br')]}},{key:"item.up_down",fn:function(ref){
var item = ref.item;
return [_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.up(item)}}},[_c('VIcon',[_vm._v("mdi-arrow-up")])],1),_c('VBtn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.down(item)}}},[_c('VIcon',[_vm._v("mdi-arrow-down")])],1)]}},{key:"item.using_frequency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("★".repeat(item.using_frequency_rating_number))+" ")]}}],null,true)})],1)],1),(_vm.isShowResumeQuestionForm)?_c('VDialog',{attrs:{"max-width":"1200px"},model:{value:(_vm.isShowResumeQuestionForm),callback:function ($$v) {_vm.isShowResumeQuestionForm=$$v},expression:"isShowResumeQuestionForm"}},[_c('VCard',[_c('ResumeQuestionRegister',{ref:"ResumeQuestionRegister",on:{"register":_vm.onResumeQuestionRegister}})],1)],1):_vm._e(),_c('CheckboxDefaultFalse',{attrs:{"label":_vm.t('is_japanese_only_for_resume_questions')},model:{value:(_vm.recruitment.is_japanese_only_for_resume_questions),callback:function ($$v) {_vm.$set(_vm.recruitment, "is_japanese_only_for_resume_questions", $$v)},expression:"recruitment.is_japanese_only_for_resume_questions"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }