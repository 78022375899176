<template>
  <VForm ref="form">
    <h3 class="mb-n4">{{t('have_jlpt')}}</h3>
    <VSwitch v-model="hasJlpt" :label="t('yes')"/>
    <VChipGroup v-model="value.jlpt" active-class="primary--text" :rules="rules">
      <VChip
        v-for="jlptLevel in jlptLevels"
        :key="jlptLevel"
        :value="jlptLevel"
        :disabled="!hasJlpt"
      >
        {{ jlptLevel }}
      </VChip>
    </VChipGroup>
    <ValidationViewer v-model="value.jlpt" :rules="rules"/>
  </VForm>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      jlptLevels: [ "N1", "N2", "N3", "N4", "N5" ],
      rules: [
        (value) => !this.hasJlpt || !!value || this.$t("shared.Validations.required")
      ]
    };
  },
  methods: {
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      return this.$refs.form.validate()
    },
  },
  computed: {
    selected(){ return this.jlptLevels.findIndex(l => l == this.value.jlpt) },
    hasJlpt: {
      get(){
        console.log(this.value.jlpt);
        return this.value.jlpt != "None"
      },
      set(hasJlpt){
        if (hasJlpt) { this.value.jlpt = null }
        else { this.value.jlpt = "None" }
      }
    }
  },
  created(){
    this.value.jlpt = this.value.jlpt || "None"
  }
};
</script>

<style>
</style>