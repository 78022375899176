"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "target": { "countries": null, "study_faculty_categories": null, "study_department_categories": null, "languages": null, "prefectures": null, "job_categories": null, "industries": null, "industry_sectors": null, "university_control_types": null, "university_academic_degrees": null, "university_student_categories": null, "school_categories": null, "general_language_levels": null, "speaking_levels": null, "listening_levels": null, "reading_levels": null, "writing_levels": null, "language_examinations": null, "transport_means": null, "company_country_relation_types": null, "company_cultures": null, "dresses": null, "allowances": null, "insurances": null, "housing_supports": null, "religious_considerations": null, "personalities": null, "selection_types": null, "skill_categories": null, "skills": null, "skill_levels": null, "job_history_quit_reasons": null, "recruit_intl_students_reasons": null, "hf_members": null, "company_channels": null, "academic_major_categories": null, "academic_majors": null } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "target": { "countries": null, "study_faculty_categories": null, "study_department_categories": null, "languages": null, "prefectures": null, "job_categories": null, "industries": null, "industry_sectors": null, "university_control_types": null, "university_academic_degrees": null, "university_student_categories": null, "school_categories": null, "general_language_levels": null, "speaking_levels": null, "listening_levels": null, "reading_levels": null, "writing_levels": null, "language_examinations": null, "transport_means": null, "company_country_relation_types": null, "company_cultures": null, "dresses": null, "allowances": null, "insurances": null, "housing_supports": null, "religious_considerations": null, "personalities": null, "selection_types": null, "skill_categories": null, "skills": null, "skill_levels": null, "job_history_quit_reasons": null, "recruit_intl_students_reasons": null, "hf_members": null, "company_channels": null, "academic_major_categories": null, "academic_majors": null } });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.get("/master/queries/get_master_data", { params });
    }
};
