<template>
  <v-app>
    <div id="app">
      <TheSnackbar/>
      <TheHeader/>
        <v-main>
          <!-- Provides the application the proper gutter -->
          <v-container fluid>
            <!-- If using vue-router -->
              <router-view />
          </v-container>
        <SessionExpirationDialog :showModal="$store.state.auth.showExpirationDialog" @close="$store.commit('auth/closeExpirationDialog')"/>
      </v-main>
      <TheFooter />
    </div>
  </v-app>
</template>

<script>
import TheHeader from './components/organisms/TheHeader';
import TheFooter from './components/organisms/TheFooter';
import TheSnackbar from './components/organisms/TheSnackbar';
import SessionExpirationDialog from './components/organisms/SessionExpirationDialog'

export default {
  components: {
    TheHeader,
    TheFooter,
    TheSnackbar,
    SessionExpirationDialog,
  },
  created(){
    this.$i18n.locale = this.currentLocale
  }
}
</script>

<style lang="scss">
  #app {
    background-color: #F6F5F5;
    p {
      white-space: pre-line;
    }
  }
</style>