<template>
  <VForm ref="form">
    <VTextField :label="t('company_name')" v-model="value.company_name" :rules="[validation.required]"/>
    <MasterSelectField :items="master('job_categories').concat([{name: 'Other', id: null}])" v-model="value.job_category_id" :label="t('job_category_id')" />
    <VTextField :label="`${t('job_category_id')}(${t('job_category_other')})`" v-model="value.job_category_other" v-if="value.job_category_id == null"/>
    <VTextarea :label="t('job_description')" v-model="value.job_description"/>
    <SteppedDatePicker :label="t('enter_date')" v-model="value.enter_date" :rules="[validation.required]"/>
    <SteppedDatePicker :label="t('leave_date')" v-model="value.leave_date"/>
    <VCheckbox label="会社名をそのまま出力" v-model="value.is_used_as_plain_text_in_resume"></VCheckbox>
  </VForm>
</template>

<script>
import SteppedDatePicker from "@@/components/molecules/SteppedDatePicker";

export default {
  components: {
    SteppedDatePicker,
  },
  props: {
    value: {} // user_job_hisotry
  },
  data(){
    return {
      id: 1
    }
  },
  computed: {

  },
  methods: {
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      return this.$refs.form.validate()
    }
  }
}
</script>

<style>

</style>