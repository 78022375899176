import Application from "../../lib/aggregates/application";
import Article from "../../lib/aggregates/article";
import Company from "../../lib/aggregates/company";
import Course from "../../lib/aggregates/course";
import Event from "../../lib/aggregates/event";
import EventApplication from "../../lib/aggregates/event_application";
import HfMember from "../../lib/aggregates/hf_member";
import Lesson from "../../lib/aggregates/lesson";
import MailTemplate from "../../lib/aggregates/mail_template";
import RecommendMail from "../../lib/aggregates/recommend_mail";
import Recruitment from "../../lib/aggregates/recruitment";
import ResumeQuestion from "../../lib/aggregates/resume_question";
import User from "../../lib/aggregates/user";
const Aggregates = {
    Application,
    Article,
    Company,
    Course,
    Event,
    EventApplication,
    HfMember,
    Lesson,
    MailTemplate,
    RecommendMail,
    Recruitment,
    ResumeQuestion,
    User,
};
export default Aggregates;
