<template>
  <div>
    <MasterSelectField :label="t('target_study_department_categories_id_eq_any')" :items="master('study_department_categories')" v-model="value.q.target_study_department_categories_id_eq_any" multiple chips deletable-chips/>
    <!-- <MasterSelectField :label="t('company_office_prefectures_id_eq_any')" :items="master('prefectures')" v-model="value.q.company_office_prefectures_id_eq_any" multiple chips deletable-chips/> -->
    <MasterSelectField :label="t('related_countries_id_eq_any')" :items="master('countries')" v-model="value.q.related_countries_id_eq_any" multiple chips deletable-chips/>
    <VAutocomplete     :label="t('required_jlpt_gteq')" :items="master('general_language_levels')" item-value="level" item-text="name" v-model="value.q.required_jlpt_gteq"/>
    <!-- <VCheckbox         :label="t('is_available_from_overseas_true')" v-model="value.q.is_available_from_overseas_true" :false-value="1"/> -->
    <MasterSelectField :label="t('job_categories_id_eq_any')" :items="master('job_categories')" v-model="value.q.job_categories_id_eq_any" multiple chips deletable-chips/>
    <MasterSelectField :label="t('recruitment_careers_first_living_country_id_eq_any')" :items="master('countries')" v-model="value.q.recruitment_careers_first_living_country_id_eq_any" multiple chips deletable-chips/>
    <MasterSelectField :label="t('recruitment_careers_last_living_country_id_eq_any')" :items="master('countries')" v-model="value.q.recruitment_careers_last_living_country_id_eq_any" multiple chips deletable-chips/>
    <VCheckbox         :label="t('recruitment_careers_is_work_globaly_true')" v-model="value.q.recruitment_careers_is_work_globaly_true" :false-value="1"/>
    <VCheckbox         label="公開中" v-model="value.q.is_released_true" :false-value="null"/>
    <MasterSelectField :label="t('required_skills_id_eq_any')" :items="master('skills')" v-model="value.q.required_skills_id_eq_any" multiple chips deletable-chips/>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      // type: SearchRecruitmentsQueryService.Params.q
    },
  },
  methods: {
    t(key, options){
      return this.$t(`pages.recruitments.RecruitmentsSearchPage.${key}`, options)
    },
  },
  watch: {
    value: {
      deep: true,
      handler: function(){
        this.$emit('input', this.value)
      }
    }
  },
  created(){  }
}
</script>

<style>

</style>
