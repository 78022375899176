import generateParamsUrl from "./generateParamsUrl"

export default function generateUTMUrl(url,campaign,source,medium,content,term,extendParams) {

  if (!url) { return "" }
  if (url == "" || source == "" || medium == "" || campaign == "") {
    throw new Error("campaign name, source, and medium are required");
  }

  return generateParamsUrl(
    url,
    {
      utm_source: source,
      utm_medium: medium,
      utm_term: term,
      utm_content: content,
      utm_campaign: campaign,
      ...extendParams
    }
  )

}