import { Aggregates } from "hfapi";
import moment from "moment"
class Application extends Aggregates.Application {
  hfMember(){
    return this.application_hf_members[0].hf_member
  }
  recruitmentExpression(){
    return `${this.company?.official_name_ja}: ${this.recruitment?.job_categories[0]?.name}`
  }
  createdAtText(){
    return moment(this.created_at).format("YYYY-MM-DD")
  }
  latestInterviewNumberText(){
    if (!this.latest_interview) {
      return ""
    }
    return `${this.latest_interview.interview_number}次面接`
  }
  latestInterviewPlace(){
    if (!this.latest_interview) {return ""}
    if (this.latest_interview.is_online) {
      return this.latest_interview.place + "\n" + this.latest_interview.online_link
    }
    return this.latest_interview.place + "\n" + this.latest_interview.google_map_url
  }

  setBehavior(behavior){
    this.behavior = JSON.stringify(behavior)
  }

  getBehavior(){
    if(this.behavior){
      return JSON.parse(this.behavior)
    }
  }

  appendBehavior(log){
    let behavior = this.getBehavior() || []
    behavior.push(log)
    this.setBehavior(behavior)
  }
}

export default Application;
