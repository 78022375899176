<template>
    <VSimpleTable>
    <template v-slot:default>
      <thead>
        <tr>
          <th>User情報</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><strong> メールアドレス</strong></td>
          <td> {{ user.email }} </td>
        </tr>
        <tr>
          <td><strong> ユーザー提出履歴書</strong></td>
          <td v-if="user.resume_drive_file_url"><a :href="user.resume_drive_file_url">Google Drive</a></td>
          <td v-else>なし</td>
        </tr>
        <tr>
          <td><strong> Summary </strong></td>
          <td> <p>{{ user.summary }}</p></td>
        </tr>
        <tr>
          <td><strong> ID </strong></td>
          <td> {{ user.id }} </td>
        </tr>
        <tr>
          <td><strong> 登録日 </strong></td>
          <td> {{ user.created_at }} </td>
        </tr>
        <tr>
          <td><strong> 名前 </strong></td>
          <td> {{ user.full_name }} </td>
        </tr>
        <tr>
          <td><strong> 電話番号</strong></td>
          <td> {{ user.phone_num }} </td>
        </tr>
        <tr>
          <td><strong> 出身国</strong></td>
          <td> {{ user.home_country.name }} </td>
        </tr>
        <tr>
          <td><strong> 都道府県</strong></td>
          <td> {{ user.livingPlace() }} </td>
        </tr>
        <tr>
          <td><strong> 年齢 </strong></td>
          <td> {{ user.age }} </td>
        </tr>
        <tr>
          <td><strong>大学</strong></td>
          <td>
            <div v-for="user_university in user.user_universities" :key="user_university.id">
              <UniversityDetailCard :userUniversity="user_university" class="my-3" />
            </div>
          </td>
        </tr>
        <tr>
          <td><strong>その他の学校</strong></td>
          <td>
            <div v-for="user_school in user.user_schools" :key="user_school.id">
              <h4>{{user_school.school_name}} ( {{ user_school.school_category.name }} ) </h4>
              <p>
                <strong>国</strong>: <span v-if="user_school.country">{{ user_school.country.name }}</span><br/>
                <strong>卒業</strong>: {{ moment(user_school.graduate_at).format("YYYY年MM月") }}
              </p>
            </div>
          </td>
        </tr>
        <tr v-if="user.user_academic_majors">
          <td><strong> 専攻 </strong></td>
          <td>
            <VChipGroup column>
              <VChip v-for="uam in user.user_academic_majors" :key="uam.id" v-text="uam.academic_major.name"/>
            </VChipGroup>
          </td>
        </tr>
        <tr>
          <td><strong> 日本語レベル </strong></td>
          <td><p>{{ user.japaneseSkillText() }}</p></td>
        </tr>
        <tr>
          <td><strong> 英語レベル </strong></td>
          <td><p>{{ user.englishSkillText() }}</p></td>
        </tr>
        <tr>
          <td><strong> 希望業種	</strong></td>
          <td> {{ user.interestIndustriesText() }} </td>
        </tr>
        <tr>
          <td><strong> 希望職種	</strong></td>
          <td> {{ user.interestJobCategoriesText() }} </td>
        </tr>
        <tr>
          <td><strong> 希望の住まい	</strong></td>
          <td> {{ user.hopeWorkingPrefectureNames() }} </td>
        </tr>
        <tr>
          <td><strong> 希望のキャリアパス	</strong></td>
          <td><p>{{ user.userCareerText() }}</p></td>
        </tr>
        <tr>
          <td><strong> 希望の傾向	</strong></td>
          <td>
            <p>
              5段階: <br>
              ◎, ○, -, △, × <br><br>
              {{ user.userSurveyText() }}
            </p>
          </td>
        </tr>
        <tr>
          <td><strong> スキル	</strong></td>
          <td><p>{{ user.skillsText() }}</p></td>
        </tr>
        <tr>
          <td><strong> 研究内容	</strong></td>
          <td> {{ user.research_description }} </td>
        </tr>
        <tr>
          <td><strong> 職歴	</strong></td>
          <td> <p>{{ user.jobHistoryText() }}</p> </td>
        </tr>
        <tr>
          <td><strong> チャネル情報	</strong></td>
          <td> <p>{{ user.analyticsDatum() }}</p> </td>
        </tr>
        <tr v-if="user.resume_face_image">
          <td><strong>顔写真ファイル</strong></td>
          <td><a download :href="user.resume_face_image">ダウンロード</a></td>
        </tr>
      </tbody>
    </template>
  </VSimpleTable>

</template>

<script>
import UniversityDetailCard from './UniversityDetailCard.vue'
export default {
  components: { UniversityDetailCard },
  props: {
    user: {
      type: Object
    }
  }
}
</script>

<style scoped>
/* p {
  white-space: pre-line;
} */
</style>
