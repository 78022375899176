<template>
  <div>
    <OtherSchoolForm v-model="userHighSchool" :schoolCategoryId="HIGH_SCHOOL_CATEGORY_ID" :hiddenFields="hiddenFields" :notRequiredFields="['enroll_at']" ref="form"/>
  </div>
</template>

<script>
import { UserUpdateProfileCommandService } from "hfapi";
import { HIGH_SCHOOL_CATEGORY_ID } from "@@/config/constants.js"
import OtherSchoolForm from './OtherSchoolForm'

export default {
  components: {
    OtherSchoolForm,
  },
  props: {
    value: {},
    hiddenFields: {
      type: Array,
      default: ()=>[]
    }
  },
  data(){
    return {
      HIGH_SCHOOL_CATEGORY_ID: HIGH_SCHOOL_CATEGORY_ID
    }
  },
  computed: {
    userHighSchool() {
      return this.value.userHighSchool()
    }
  },
  methods: {
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      return this.$refs.form.validate()
    },
    defaultUserHighSchool(){
      const userHighSchool = (new UserUpdateProfileCommandService.Params).user.user_schools[0]
      userHighSchool.school_category_id = HIGH_SCHOOL_CATEGORY_ID
      userHighSchool.is_latest = false
      return userHighSchool
    }
  },
  created(){
    if ( !this.value.userHighSchool() ){
      this.value.user_schools.push(this.defaultUserHighSchool())
    }
  }
};
</script>

<style>
</style>