<template>
  <ResumeQuestionsForm :resumeParams="resumeParams" :recruitmentResumeQuestions="recruitmentResumeQuestions" :resumeQuestionsLocale="resumeQuestionsLocale" />
</template>

<script>
// 責務としてはResumeQuestionsFormへのWrapper的な存在。

import ResumeQuestionsForm from '@/components/organisms/applications/ResumeQuestionsForm.vue'
import { UserSaveResumeForApplicationCommandService } from 'hfapi/jtj'
export default {
  components: { ResumeQuestionsForm },
  props: {
    resumeQuestions: {
      type: Array,
      required: true,
    },
    resumeQuestionsLocale: {
      type: String,
      required: true
    },
    recruitmentResumeQuestions: {
      type: Array,
      required: true
    }
  },
  data(){
    return {
      resumeParams: new UserSaveResumeForApplicationCommandService.BuildableParams,
    }
  },
  methods: {
    initRecruitmentResumeQuestions(){
      this.recruitmentResumeQuestions.forEach((rrq)=>{
        rrq.resume_question = JSON.parse(JSON.stringify(this.resumeQuestions.find(rq=>rq.id == rrq.resume_question_id)))
      })
    },
    initResumeQuestionAnswer(resumeQuestions){
      Object.assign(this.resumeParams, new UserSaveResumeForApplicationCommandService.BuildableParams)
      const resumeQuestionAnswers = this.resumeParams.application.resume.resume_question_answers
      resumeQuestions.forEach(rq => {
        const rqa = resumeQuestionAnswers.find(rqa => rqa.resume_question_id == rq.id)
        if(!rqa){
          const newRqa = this.resumeParams.build("resume_question_answers")
          newRqa.resume_question_id = rq.id
          newRqa.resume_question = rq
          resumeQuestionAnswers.push(newRqa)
        }
      })
    }
  },
  watch: {
    resumeQuestions(val){
      this.initResumeQuestionAnswer(val)
      this.initRecruitmentResumeQuestions()
    }
  },
}
</script>

<style>

</style>