<template>
  <VContainer>
    <h2>HfFormを登録できます。</h2>
    <VRow>
      <VCol cols="6">
        <h3>日本語</h3>
        <HfFormRegisterForm :hfFormParams="hfFormParams" lang="ja"/>
      </VCol>
      <VCol cols="6">
        <h3>英語</h3>
        <HfFormRegisterForm :hfFormParams="hfFormParams" lang="en"/>
      </VCol>
    </VRow>
    <VBtn @click="submit" class="primary">Submit</VBtn>
  </VContainer>
</template>

<script>
import { RegisterFormCommandService } from "hfapi/hff"
import HfForm from "@/aggregates/hf_form"
import HfFormRegisterForm from "@@/components/organisms/hf_forms/HfFormRegisterForm"

export default {
   metaInfo() {
    return {
      title: `HfForm新規登録ページ`,
    }
  },
  components: {
    HfFormRegisterForm,
  },
  data() {
    return {
      hf_form_local: new HfForm(this.$route.params.id),
      hfFormParams: new RegisterFormCommandService.BuildableParams(),
    }
  },
  methods: {
    submit() {
      RegisterFormCommandService.execute(this.hfFormParams).then(resp=>{
          this.flash("保存に成功しました", "success")
          if(this.$route.name=="HfFormEditPage"){
            this.$router.go() // 再リロード
          } else {
            this.$router.push({ name: "HfFormEditPage", params: {id: resp.data.id } })
          }
      }).catch(e=>{
        console.log(e.response.data);
        this.flash(e.response.data, "error")
      })
    },
    removeIdForCopy(){
      this.hfFormParams.form.id = null
      this.hfFormParams.form.form_questions.forEach(form_question=>form_question.id=null)
    }
  },
  mounted(){
    this.hf_form_local.after_assign = () => {
      Object.assign(this.hfFormParams.form, this.hf_form_local)
      if (this.$route.name == "HfFormCopyPage") {
        this.removeIdForCopy()
      }
    }
  }
}
</script>

<style>

</style>