<template>
  <VCard @click="$emit('click', $event)">
    <VContainer>
      <!-- 一行目(ロゴ&企業名) -->
      <h3 v-if="!recruitment.is_released" style="color: red;">非公開です</h3>
      <VRow>
        <!-- 企業名 -->
        <VCol cols="8">
          <h4>{{ recruitment.company.official_name_ja }}</h4>
        </VCol>
      </VRow>
      <!-- 二行目(タイトル) -->
      <VRow>
        <VCol cols="12">
          <h3> {{recruitment.title_text}} </h3>
        </VCol>
      </VRow>
      <!-- 三行目(仕事の役割) -->
      <VRow>
        <VCol cols="4">
          <div> 仕事の役割 </div>
        </VCol>
        <VCol cols="8">
          <h3> {{recruitment.jobCategoryText() }} </h3>
        </VCol>
      </VRow>
      <!-- 四行目(日本語レベル) -->
      <VRow>
        <VCol cols="4">
          <div> 日本語レベル </div>
        </VCol>
        <VCol cols="8">
          <p>{{recruitment.japaneseLevelsText() }}</p>
        </VCol>
      </VRow>
      <!-- 五行目(対象の学部学科) -->
      <VRow>
        <VCol cols="4">
          <div> 対象の学部学科 </div>
        </VCol>
        <VCol cols="8">
          <h3> {{recruitment.schoolDepartmentText() }} </h3>
        </VCol>
      </VRow>
      <!-- 五行目(関係のある国) -->
      <VRow>
        <VCol cols="4">
          <div> 関係のある国 </div>
        </VCol>
        <VCol cols="8">
          <h3> {{recruitment.relationCountriesText() }} </h3>
        </VCol>
      </VRow>
    </VContainer>
  </VCard>
</template>

<script>
export default {
  props: {
    recruitment: {
      type: Object
    }
  },
}
</script>

<style scoped>
</style>
