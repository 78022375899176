<template>
  <div>
    <MasterSelectField :label="t('transport_mean_id')" v-model="company_office_transport_mean.transport_mean_id" :items="master('transport_means')" :rules="[validation.required]"/>
    <VTextField :label="t('closest_stop_name_ja')" v-model="company_office_transport_mean.closest_stop_name_ja"/>
    <VTextField :label="t('closest_stop_name_en')" v-model="company_office_transport_mean.closest_stop_name_en"/>
    <VTextField :label="t('walking_minutes_from_closest_stop')" v-model="company_office_transport_mean.walking_minutes_from_closest_stop" type="number"/>
    <VTextField :label="t('description_id')" v-model="company_office_transport_mean.description[lang]"/>
  </div>
</template>

<script>
export default {
  props: {
    company_office_transport_mean: {
      type: Object
    },
    lang: {
      type: String
    }
  },
  methods: {
    t(key) {
      return this.$t(`aggregates.CompanyColumnNames.${key}`)
    }
  },
}
</script>

<style>

</style>