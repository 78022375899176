<template>
  <div>
    <VTextField v-model="baseUrl" label="BaseUrl" outlined />
    <VTextField v-model="campaignName" label="CampaignName" outlined />
    <VTextField v-model="campaignSource" label="CampaignSource" outlined />
    <VTextField v-model="campaignMedium" label="CampaignMedium" outlined />
    <VTextField v-model="campaignContent" label="CampaignContent" outlined />
    <VTextField v-model="campaignTerm" label="CampaignTerm" outlined />
    <VSelect v-model="lang" :items="['en', 'ja']" label="Language" outlined />

    <VCard>
      <VCardTitle>Share the generated campaign URL</VCardTitle>
      <VCardSubtitle>Use this URL in any promotional channels you want to be associated with this custom campaign.</VCardSubtitle>
      <VCardText>
        <VTextarea v-model="utmUrl" label="Generated URL" outlined style="text-color: black;" readonly/>
        <VTextField v-model="bitlyLinkComputed" label="Shortened URL" outlined ref="bitlyLinkField" readonly/>
        <VBtn @click="setBitlyLink" color="success">Shorten Link</VBtn>
        <VBtn @click="copyBitlyLink" class="ml-2"><VIcon>mdi-copy</VIcon>Copy Shorten Link</VBtn>
      </VCardText>
    </VCard>
    
  </div>
</template>

<script>
import generateUTMUrl from "@@/lib/generateUTMUrl"
import {ServiceCreateBitlyLinkCommandService} from "hfapi"

export default {
  props: {
    defaultBaseUrl: { default: "", type: String },
    defaultCampaignName: { default: "", type: String },
    defaultCampaignSource: { default: "", type: String },
    defaultCampaignMedium: { default: "", type: String },
    defaultCampaignContent: { default: "", type: String },
    defaultCampaignTerm: { default: "", type: String },
    defaultLang: { default: "", type: String },
  },
  data(){
    return {
      baseUrl: "",
      campaignName: "",
      campaignSource: "",
      campaignMedium: "",
      campaignContent: "",
      campaignTerm: "",
      lang: "",
      bitlyLink: "",
    }
  },
  computed: {
    utmUrl: {
      get(){
        return generateUTMUrl(
          this.baseUrl,
          this.campaignName,
          this.campaignSource,
          this.campaignMedium,
          this.campaignContent,
          this.campaignTerm,
          {
            lang: this.lang
          }
        )
      },
      set(){
      }
    },
    bitlyLinkComputed: {
      get(){
        return this.bitlyLink
      },
      set(){
      }
    }
  },
  methods: {
    setBitlyLink(){
      ServiceCreateBitlyLinkCommandService.execute({url: this.utmUrl}).then((resp)=>{
        this.bitlyLink = resp.data.link
      })
    },
    copyBitlyLink(){
      const bitlyLinkFieldElement = this.$refs.bitlyLinkField.$el.querySelector('input')
      bitlyLinkFieldElement.select()
      bitlyLinkFieldElement.setSelectionRange(0, 99999);
      document.execCommand('copy')
      this.flash("Copied")
    }
  },
  mounted(){
      this.baseUrl = this.defaultBaseUrl
      this.campaignName = this.defaultCampaignName
      this.campaignSource = this.defaultCampaignSource
      this.campaignMedium = this.defaultCampaignMedium
      this.campaignContent = this.defaultCampaignContent
      this.campaignTerm = this.defaultCampaignTerm
      this.lang = this.defaultLang
  }
}
</script>