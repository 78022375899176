<template>
  <VContainer fluid>
    <VRow>
      <VCol cols="6">
        <UserResumeEditProfileForm :user_id="$route.params.id" @save="saved"></UserResumeEditProfileForm>
      </VCol>
      <VCol cols="6">
        <ResumePDFViewer :user_id="$route.params.id" :fileName="resumeFileName" ref="resumePDFViewer"></ResumePDFViewer>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import UserResumeEditProfileForm from "@@/components/organisms/users/UserResumeEditProfileForm.vue"
import ResumePDFViewer from "@@/components/organisms/applications/ResumePDFViewer.vue"
import User from "@/aggregates/user"

export default {
   metaInfo() {
    return {
      title: `${this.user.full_name}-ユーザー履歴書ページ`,
    }
  },
  components: {
    UserResumeEditProfileForm,
    ResumePDFViewer,
  },
  data() {
    return {
      user: new User(this.$route.params.id),
    }
  },
  computed: {
    resumeFileName(){
      return `【履歴書】${this.user.fullName()}`
    },
  },
  methods: {
    saved(){
      this.$refs.resumePDFViewer.updateResume()
      this.flash('保存されました', "success")
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
}
</script>

<style>

</style>