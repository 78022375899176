<template>
  <div>
    <VContainer fluid>
      <VRow>
        <VCol cols="12" md="8" offset-md="2">
          <h1 class="my-5 text-center"> {{ article.title[lang] }} </h1>
          <ArticlePreview :articleParams="articleParams" :lang="lang" v-if="articleParams" class="articledetail-markdown"/>
        </VCol>
      </VRow>
    </VContainer>
    <div class="btns">
      <VBtn :href="`${VUE_APP_JTJ_HOST}/#/articles/${article.id}`" target="_blank" color="#33C8B2" class="my-1">JTJのページを開く</VBtn>
      <VBtn @click="$router.push({name: 'ArticleEditPage', params: { id: article.id }})" class="my-1">編集する</VBtn>
      <VSwitch v-model="showAfterSignup" label="ログイン後" class="my-1"></VSwitch>
    </div>
  </div>
</template>

<script>
import Article from "@/aggregates/article"
import ArticlePreview from "@@/components/organisms/articles/ArticlePreview";
import { HfMemberRegisterArticleCommandService } from "hfapi"

export default {
  metaInfo() {
    return {
      title: `${this.article.title.ja}-記事詳細ページ`,
    }
  },
  components: {
    ArticlePreview,
  },
  data(){
    return {
      article: new Article(this.$route.params.id),
      showAfterSignup: true,
    }
  },
  computed: {
    articleParams(){
      let params = new HfMemberRegisterArticleCommandService.Params()
      params.article = this.article
      return params
    },
    lang(){
      return this.currentLocale
    }
  }
}
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10%;
  right: 5%;
}
</style>