import User from '@/aggregates/user'
import { UserGetOwnDataQueryService, UserChangePageLanguageCommandService } from "hfapi";
import { LANGUAGE_ID_2_SYMBOL, LANGUAGE_SYMBOL_2_ID } from "@/config/constants.js"

export default {
  namespaced: true,
  state() {
    return new User()
  } ,
  mutations: {
    update(state, user){
      state.assign(user)
    },
    destroy (state) {
      state.assign(new User())
    },
    setPageLang(state, symbol){
      state.page_language_id = LANGUAGE_SYMBOL_2_ID[symbol];
    }
  },
  actions: {
    update({ commit }, callback) {
      UserGetOwnDataQueryService.execute({}).then(resp => {
        commit( "update", new User(resp.data) )
      }).catch(error=>{
        console.error(error.response);
        commit('destroy')
        commit('auth/destroy', null, { root: true })
      }).finally(() => {
        if (callback) { callback() }
      })
    },
    destroy({ commit }) {
      commit('destroy')
    },
    setPageLang({commit, state}, payload) {
      const symbol = payload.symbol
      const callback = payload.callback
      const page_language_id = LANGUAGE_SYMBOL_2_ID[symbol];
      if ( state.id ) {
        // ログイン中
        UserChangePageLanguageCommandService.execute({user: {page_language_id: page_language_id}}).then(resp=>{
          commit("setPageLang", LANGUAGE_ID_2_SYMBOL[String(resp.data.page_language_id)]);
        }).finally(()=>{
          if (callback) { callback() }
        })
      } else {
        // 非ログイン
        commit("setPageLang", symbol);
        if ( callback ) { callback() }
      }
    }
  },
}
