<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu2"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="value"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="value"
          :label="label"
          readonly
          v-bind="attrs"
          v-on="on"
        ></v-text-field>
      </template>
      <v-time-picker
        v-if="menu2"
        full-width
        @click:minute="$refs.menu.save(value)"
        @input="$emit('input', $event)"
      ></v-time-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: {
    },
    label: {
      type: String
    }
  },
  data () {
    return {
      menu2: false,
    }
  },
}
</script>

<style>

</style>