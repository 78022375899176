export default {
  title: "Recommended Jobs for <%=user.first_name%> / <%=user.first_name%>さんにお勧めする仕事",
  body: `
*English below
<%=user.first_name%>さん

Job Tree Japanの<%=hf_member.nick_name_ja%>です。
<%=user.first_name%>さんにおすすめな企業があるので連絡しました。
<%=user.first_name%>さんの<%=user.mainDepartment()?.name_ja ? user.mainDepartment()?.name_ja + "の" : ""%>知識が生かせる仕事を中心に紹介します。

<% if (recommend_mail.is_including_meeting_schedule){ %>
  「企業や仕事の情報をもっと知りたい」「応募の仕方がわからない」など困っていることはオンラインで話しましょう！
30分ほど時間が取れる日を下のリストから３つ教えてください！

【日程候補】
<%=hf_member.meeting_date_str_ja%>
<% } %>
==================
【ご紹介したい仕事リスト】
<% recommend_mail.active_remind_mail_recruitments.forEach(function(remind_mail_recruitment, i){ %>
  <%=i+1%>. <%=remind_mail_recruitment.recruitment.company.official_name_ja%>
  <%=remind_mail_recruitment.recruitment.company.hf_recommend_sentence.ja || remind_mail_recruitment.recruitment.company.appeal_point.ja%>
  [仕事内容]
  <%=remind_mail_recruitment.recruitment.recruitment_detail.job_description.ja%>
  [仕事の情報]
  https://job-tree-japan.com/#/recruitments/<%=remind_mail_recruitment.recruitment_id%>
   
<% }) %>
==================

気になる企業があったらぜひ応募してください。
自分で決めることが難しい場合は、メールもしくはオンライン相談できます。
いつでも質問お待ちしています！

<%=hf_member.nick_name_ja%>

*English

Dear <%=user.first_name%>,

This is <%=hf_member.nick_name_en%> at Job Tree Japan.
I contacted you because I have a job to recommend!
I will introduce jobs that you can utilize the knowledge related to your studies.
<% if (recommend_mail.is_including_meeting_schedule){ %>
If you're having trouble like:"learning more about companies and jobs" , "not knowing how to apply", etc. Let's talk online!
Let me know three of your preferable dates and time from the options below.
[Candidate Date]
<%=hf_member.meeting_date_str_en%>

<% } %>
==================
【 List of Jobs I'd like to share with you 】
<% recommend_mail.active_remind_mail_recruitments.forEach(function(remind_mail_recruitment, i){ %>
  <%=i+1%>. <%=remind_mail_recruitment.recruitment.company.official_name_en%>
  <%=remind_mail_recruitment.recruitment.company.hf_recommend_sentence.en || remind_mail_recruitment.recruitment.company.appeal_point.en%>
  [Job Description]
  <%=remind_mail_recruitment.recruitment.recruitment_detail.job_description.en%>
  [Job information]
  https://job-tree-japan.com/#/recruitments/<%=remind_mail_recruitment.recruitment_id%>
<% }) %>
==================

If you have a company that interests you, please apply!
If you find it difficult to decide for yourself, you can email or consult online.

We are always looking forward to your questions!
Thank you for your time!

Regards,
<%=hf_member.nick_name_en%>

`
}