const defaultState = {
  message: "",
  type: "info", // typeはsuccess, info, warning, error の4つ
  isShown: false,
  lastUpdatedAt: new Date()
}


export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(defaultState)),
  mutations: {
    set(state, arg) {
      state.type = arg.type
      state.message = arg.message
      state.isShown = false
      state.lastUpdatedAt = new Date()
      setTimeout(function(){
        state.isShown = true
      }, 100)
    },
    close(state){
      state.isShown = false
    }
  },
  actions: {
    show({ state, commit }, newState){
      const diff = new Date().getTime() - new Date(state.lastUpdatedAt).getTime()
      if( diff > 1000 ){
        // 通常時
        commit("set", newState)
      } else {
        // 連続発火時は1.2秒間隔を空ける
        setTimeout(function(){commit("set", newState)}, 1200)
      }
    }
  }
}
