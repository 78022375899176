import Query from "../../lib/queries_services/aggregates__form";
import BaseAggregate from "../../../base_aggregate";
class Form extends BaseAggregate {
    container_hash() {
        return { "title_for_hf": null, "memo_for_hf": null, "id": null, "form_questions": [{ "id": null, "index": null, "title_texts": null, "description_texts": null, "option_texts": null, "question_type": null, "required": false }] };
    }
    query() {
        return Query;
    }
}
export default Form;
