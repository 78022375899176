<template>
  <div>
    <VSelect v-model="selectedRegions" :items="regions" label="地域" multiple></VSelect>
    <MasterSelectField label="母国" :items="master('countries')" multiple chips deletable-chips @input="$emit('input', $event)" :value="value" />
  </div>
</template>

<script>
export default {
  name: "SelectFieldCountryRich",
  props: {
    value: {
    },
  },
  data() {
    return {
      selectedRegions: [],
      regions: ["europe", "asia", "other"],
      europe_ids: [2, 3, 6, 12, 15, 16, 21, 22, 29, 34, 55, 58, 59, 60, 69, 72, 74, 75, 82, 84, 85, 86, 96, 99, 100, 105, 107, 120, 125, 126, 129, 135, 142, 143, 145, 153, 160, 171, 172, 176, 177, 183, 187, 191, 192, 197, 202, 203, 215, 220, 222,],
      asia_ids: [19, 26, 33, 37, 45, 98, 101, 102, 116, 119, 128, 132, 133, 144, 149, 152, 162, 169, 190, 198, 205, 209, 228,],
      other_ids: [1, 4, 5, 7, 8, 9, 10, 11, 13, 14, 17, 18, 20, 23, 24, 25, 27, 28, 30, 31, 32, 35, 36, 38, 39, 40, 41, 42, 43, 44, 46, 47, 48, 49, 50, 51, 52, 53, 54, 56, 57, 61, 62, 63, 64, 65, 66, 67, 68, 70, 71, 73, 76, 77, 78, 79, 80, 81, 83, 87, 88, 89, 90, 91, 92, 93, 94, 95, 97, 103, 104, 106, 108, 109, 110, 111, 112, 113, 114, 115, 117, 118, 121, 122, 123, 124, 127, 130, 131, 134, 136, 137, 138, 139, 140, 141, 146, 147, 148, 150, 151, 154, 155, 156, 157, 158, 159, 161, 163, 164, 165, 166, 167, 168, 170, 173, 174, 175, 178, 179, 180, 181, 182, 184, 185, 186, 188, 189, 193, 194, 195, 196, 199, 200, 201, 204, 206, 207, 208, 210, 211, 212, 213, 214, 216, 217, 218, 219, 221, 223, 224, 225, 226, 227, 229, 230, 231, 232,],
    }
  },
  watch: {
    selectedRegions(){
      let selected = []
      if (this.selectedRegions.isInclude("europe")) selected = selected.concat(this.europe_ids)
      if (this.selectedRegions.isInclude("asia")) selected = selected.concat(this.asia_ids)
      if (this.selectedRegions.isInclude("other")) selected = selected.concat(this.other_ids)
      this.$emit("input", selected)
    }
  },
}
</script>

<style>

</style>