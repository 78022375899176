<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable
      @change="$emit('change', $event)"
    >
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  model: {
    prop: "date",
    event: "change",
  },
  props: {
    label: {
      type: String
    },
    date: {
      type: [String, Date]
    },
  },
  data(){
    return {
      menu: false,
    }
  },
  watch: {
    date(){
      if (this.date) { this.date = new Date(this.date).toISOString().substr(0, 10) }
    }
  }
}
</script>

<style>

</style>