import { hfapiAxios } from "hfapi"
import store from "../store"
import moment from "moment"

const host = process.env.VUE_APP_HFAPI_HOST;

export default function () {
  hfapiAxios.defaults.baseURL = host

  hfapiAxios.interceptors.request.use(request => {
    // const csrf_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    request.headers = {
      "X-Requested-With": "XMLHttpRequest",
      // "X-CSRF-Token": csrf_token,
      "access-token": store.state.auth.token,
      "client": store.state.auth.client,
      "uid": store.state.auth.uid,
      "token-type": store.state.auth.tokenType,
      "page-language-id": store.state.page_language_id
    }
    if (store.state.auth.expiry == "" || moment.unix(store.state.auth.expiry).isBefore()) { //トークンの有効期限が切れていたら
      store.commit("auth/destroy");
    }
    return request
  })

  hfapiAxios.interceptors.response.use(response => {
    if(!response){ return response }
    store.dispatch('auth/updateWithToken', response.headers)
    return response
  })

}