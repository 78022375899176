import Query from "../../lib/queries_services/aggregates__hf_member";
import BaseAggregate from "../../../base_aggregate";
class HfMember extends BaseAggregate {
    container_hash() {
        return { "id": null, "email": null, "phone_num": null, "first_name_ja": null, "last_name_ja": null, "first_name_en": null, "last_name_en": null, "nick_name_ja": null, "nick_name_en": null, "is_manager_of_applications": false, "trello_id": null, "notion_id": null, "slack_id": null, "available_times_google_calendar_id": null };
    }
    query() {
        return Query;
    }
}
export default HfMember;
