<template>
  <div class="recruitment-show-simple-content" data-test="recruitment-show-contents-simple" >
    <!-- PCの時 -->
    <div v-if="$vuetify.breakpoint.mdAndUp" style="margin-right: 250px;">
      <VCarousel v-if="images.length != 0" cycle interval="5000" :show-arrows="false" hide-delimiter-background height="400">
        <VCarouselItem v-for="i in images" :key="i.id" height="400">
            <VImg :src="i.url" lazy-src="https://api.job-tree-japan.com/images/noimage.png" contain/>
        </VCarouselItem>
      </VCarousel>
    </div>
    <!-- Mobileの時 -->
    <div v-if="$vuetify.breakpoint.smAndDown">
      <VCarousel v-if="images.length != 0" cycle interval="5000" :show-arrows="false" hide-delimiter-background height="200">
        <VCarouselItem v-for="i in images" :key="i.id" height="200">
            <VImg :src="i.url" lazy-src="https://api.job-tree-japan.com/images/noimage.png" contain/>
        </VCarouselItem>
      </VCarousel>
    </div>
    <h1>{{recruitment.title_text}}</h1>
    <VueMarkdown :source="recruitment.simple_content_text"/>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
export default {
  components: {
    VueMarkdown
  },
  props: {
    recruitment: {rquired: true}
  },
  computed: {
    images(){
      return this.recruitment.images.filter(i => i.url)
    }
  }
}
</script>

<style>
  .recruitment-show-simple-content p {
    white-space: normal !important;
  }
</style>
