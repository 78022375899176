<template>
  <div>
    <VDataTable
    :headers="headers"
    :items="items"
    >
    <template v-slot:item.recruitment="{item}">
      <a :href="`https://admin.job-tree-japan.com/#/recruitments/${item.recruitment_id}`" target="_blank">
        {{ item.recruitment_name }}
      </a>
    </template>
    <template v-slot:item.hf_member="{item}">
      <ApplicationChangeHfMemberForm :application="item.application" class="mb-5"/>
    </template>
    <template v-slot:item.link="{ item }">
      <VBtn @click="$router.push({name: 'ApplicationShowPage', params: { id: item.id }})">詳細へ</VBtn>
    </template>
    </VDataTable>
  </div>
</template>

<script>
import ApplicationChangeHfMemberForm from "@@/components/organisms/applications/ApplicationChangeHfMemberForm.vue"
export default {
  components: {
    ApplicationChangeHfMemberForm,
  },
  props: {
    applications: {
      type: Array
    }
  },
  data(){
    return {
      headers: [
        {text: "応募日", value: "created_at",},
        {text: "応募id", value: "id",},
        {text: "企業名", value: "company_name",},
        {text: "求人", value: "recruitment",},
        {text: "現在の状況", value: "state",},
        // {text: "めも", value: "memo",},
        {text: "担当", value: "hf_member",},
        {text: "詳細", value: "link",},
      ]
    }
  },
  computed: {
    items(){
      return this.applications.map(application=>{
        return {
          id: application.id,
          created_at: this.moment(application.created_at).format("YYYY-MM-DD"),
          company_name: application.company?.official_name_ja,
          recruitment_name: application.recruitment?.title_text,
          recruitment_id: application.recruitment?.id,
          recruitment: application.recruitment,
          state: application.state,
          memo: application.memo,
          hf_member: application.hf_member,
          link: application.link,
          application: application
        }
      })
    }
  }
}
</script>

<style>

</style>