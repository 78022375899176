<template>
  <VContainer>
    <h1>HfMember一覧</h1>
    <VBtn :to="{name: 'HfMemberRegisterPage'}" color="success" class="mb-3">新規作成</VBtn>
    <VCard>
      <VTextField v-model="search" label="search" outlined dense class="ml-4 pa-4"/>
      <VCardText>
        <VDataTable :items="hfMembers" :headers="headers" :search="search" :custom-sort="sort">
          <template v-slot:[`item.id`]="{ item }">
            <a :href= "`/#/hf_members/${item.id}/edit`"> {{ item.id }}</a>
          </template>
        </VDataTable>
      </VCardText>
    </VCard>
  </VContainer>
</template>

<script>
import { HfMemberGetHfMembersQueryService } from "hfapi"
import HfMember from "@/aggregates/hf_member"

export default {
  data(){
    return {
      hfMembers: [],
      headers: [
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Email', value: 'email', sortable: true },
        { text: "苗字", value: 'first_name_ja', sortable: true },
        { text: '名前', value: 'last_name_ja', sortable: true}
      ],
      search: "",
    }
  },
  metaInfo() {
    return {
      title: `HfMember一覧`,
    }
  },
  methods: {
    sort(items){
      return items.sort((a, b) => {
        return a.id - b.id
      })
    }
  },
  async created(){
    const resp = await HfMemberGetHfMembersQueryService.execute({ all: true })
    this.hfMembers = resp.data.map(d => new HfMember(d))
  },
}

</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10%;
  right: 5%;
  z-index: 1000;
}

</style>
