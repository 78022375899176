import Vue from "vue";
import Router from "vue-router";

// ログイン
import LogInPage from "@@/components/pages/LogInPage";
import LogOutPage from "@@/components/pages/LogOutPage";
// menu周り
import TopPage from "@@/components/pages/TopPage";
// applications周り
import ApplicationShowPage from "@@/components/pages/applications/ApplicationShowPage";
import ApplicationBehaviorShowPage from "@@/components/pages/applications/ApplicationBehaviorShowPage";
import ApplicationsIndexPage from "@@/components/pages/applications/ApplicationsIndexPage";
import ResumeCorrectPage from "@@/components/pages/applications/ResumeCorrectPage";

// recommend_mails周り
import RecommendMailIndexPage from "@@/components/pages/recommendMails/RecommendMailIndexPage";
import RecommendMailSetPage from "@@/components/pages/recommendMails/RecommendMailSetPage";
import RecommendMailSetRemindPage from "@@/components/pages/recommendMails/RecommendMailSetRemindPage";

// user周り
import UserDetailPage from "@@/components/pages/users/UserDetailPage";
import UserResumePage from "@@/components/pages/users/UserResumePage";
// 退会処理
import UserLeaveFromServicePage from "@@/components/pages/users/UserLeaveFromServicePage"
import UserOverwritePersonalDataPage from "@@/components/pages/users/UserOverwritePersonalDataPage"

// 企業情報登録周り
import CompanyRegisterPage from "@@/components/pages/companies/CompanyRegisterPage";
import RecruitmentRegisterPage from "@@/components/pages/recruitments/RecruitmentRegisterPage";
import RecruitmentSimpleRegisterPage from "@@/components/pages/recruitments/RecruitmentSimpleRegisterPage";

// 企業情報一覧
import CompanySearchPage from "@@/components/pages/companies/CompanySearchPage";

// 企業情報周り
import RecruitmentSearchPage from "@@/components/pages/recruitments/RecruitmentSearchPage";
import RecruitmentShowPage from "@@/components/pages/recruitments/RecruitmentShowPage";
import RecruitmentBuildApplyLinkPage from "@@/components/pages/recruitments/RecruitmentBuildApplyLinkPage";

// 履歴書質問
import ResumeQuestionRegisterPage from "@@/components/pages/resumeQuestions/ResumeQuestionRegisterPage";
import ResumeQuestionIndexPage from "@@/components/pages/resumeQuestions/ResumeQuestionIndexPage";


// 記事周り
import ArticlesIndexPage from "@@/components/pages/articles/ArticlesIndexPage"
import ArticleRegisterPage from "@@/components/pages/articles/ArticleRegisterPage"
import ArticleShowPage from "@@/components/pages/articles/ArticleShowPage"
import ArticleEditPage from "@@/components/pages/articles/ArticleEditPage"

// イベント周り
import EventsIndexPage from "@@/components/pages/events/EventsIndexPage"
import EventRegisterPage from "@@/components/pages/events/EventRegisterPage"
import EventShowPage from "@@/components/pages/events/EventShowPage"

// HfMember登録
import HfMemberRegisterPage from "@@/components/pages/hf_members/HfMemberRegisterPage";
import HfMembersIndexPage from "@@/components/pages/hf_members/HfMembersIndexPage";

// フォーム登録
import HfFormRegisterPage from "@@/components/pages/forms/HfFormRegisterPage";
import HfFormsIndexPage from "@@/components/pages/forms/HfFormsIndexPage";

// マスター管理
import MasterIndexPage from "@@/components/pages/masters/MasterIndexPage"
import MasterUniversitiesIndexPage from "@@/components/pages/masters/MasterUniversitiesIndexPage";
import MasterTableIndexPage from "@@/components/pages/masters/MasterTableIndexPage"
import MasterUniversityRegisterPage from "@@/components/pages/masters/MasterUniversityRegisterPage";
import MasterTableRegisterPage from "@@/components/pages/masters/MasterTableRegisterPage";

// その他
import AvailableDateCopyPage from "@@/components/pages/hf_members/AvailableDateCopyPage.vue"
import SearchRecommendedUsersPage from "@@/components/pages/hf_members/SearchRecommendedUsersPage.vue"
import RegisterGidPage from "@@/components/pages/RegisterGidPage";

Vue.use(Router);

export default new Router({
  routes: [
    { path: "/", name: "TopPage", component: TopPage, meta: { auth: "log_in" } },
    { path: "/log_in", name: "LogInPage", component: LogInPage, meta: { auth: "not_log_in" } },
    { path: "/log_out", name: "LogOutPage", component: LogOutPage, meta: { auth: "public" } },

    { path: "/applications", name: "ApplicationsIndexPage", component: ApplicationsIndexPage, meta: { auth: "log_in" } },
    { path: "/applications/:id", name: "ApplicationShowPage", component: ApplicationShowPage, meta: { auth: "log_in" } },
    { path: "/applications/:id/behavior", name: "ApplicationBehaviorShowPage", component: ApplicationBehaviorShowPage, meta: { auth: "log_in" } },
    { path: "/applications/:id/resume/correct", name: "ResumeCorrectPage", component: ResumeCorrectPage, meta: { auth: "log_in" } },

    { path: "/recommend_mails", name: "RecommendMailIndexPage", component: RecommendMailIndexPage, meta: { auth: "log_in" } },
    { path: "/recommend_mails/:id/set", name: "RecommendMailSetPage", component: RecommendMailSetPage, meta: { auth: "log_in" } },
    { path: "/recommend_mails/:id/set_remind", name: "RecommendMailSetRemindPage", component: RecommendMailSetRemindPage, meta: { auth: "log_in" } },

    { path: "/users/:id", name: "UserDetailPage", component: UserDetailPage, meta: { auth: "log_in" } },
    { path: "/users/:id/resume", name: "UserResumePage", component: UserResumePage, meta: { auth: "log_in" } },
    { path: "/users/:id/leave_from_service", name: "UserLeaveFromServicePage", component: UserLeaveFromServicePage, meta: { auth: "log_in" } },
    { path: "/users/:id/overwrite_personal_data", name: "UserOverwritePersonalDataPage", component: UserOverwritePersonalDataPage, meta: { auth: "log_in" } },

    { path: "/recruitments", name: "RecruitmentSearchPage", component: RecruitmentSearchPage, meta: { reuse: false } },
    { path: "/recruitments/new", name: "RecruitmentRegisterPage", component: RecruitmentRegisterPage },
    { path: "/recruitments/simple_new", name: "RecruitmentSimpleRegisterPage", component: RecruitmentSimpleRegisterPage },
    { path: "/recruitments/:id", name: "RecruitmentShowPage", component: RecruitmentShowPage },
    { path: "/recruitments/:id/simple_edit", name: "RecruitmentSimpleEditPage", component: RecruitmentSimpleRegisterPage },
    { path: "/recruitments/:id/edit", name: "RecruitmentEditPage", component: RecruitmentRegisterPage, meta: { reuse: false } },
    { path: "/recruitments/:id/copy", name: "RecruitmentCopyPage", component: RecruitmentRegisterPage, meta: { reuse: false } },
    { path: "/recruitments/:id/build_apply_link", name: "RecruitmentBuildApplyLinkPage", component: RecruitmentBuildApplyLinkPage, meta: { reuse: false } },

    { path: "/resume_questions/", name: "ResumeQuestionIndexPage", component: ResumeQuestionIndexPage },
    { path: "/resume_questions/new", name: "ResumeQuestionRegisterPage", component: ResumeQuestionRegisterPage },
    { path: "/resume_questions/:id/edit", name: "ResumeQuestionEditPage", component: ResumeQuestionRegisterPage, meta: { reuse: false } },

    { path: "/companies/:id/edit", name: "CompanyEditPage", component: CompanyRegisterPage, meta: { reuse: false } },
    { path: "/companies/new", name: "CompanyRegisterPage", component: CompanyRegisterPage },
    { path: "/companies/search", name: "CompanySearchPage", component: CompanySearchPage },

    { path: "/articles", name: "ArticlesIndexPage", component: ArticlesIndexPage, meta: { auth: "log_in" } },
    { path: "/articles/new", name: "ArticleRegisterPage", component: ArticleRegisterPage, meta: { auth: "log_in" } },
    { path: "/articles/:id/edit", name: "ArticleEditPage", component: ArticleEditPage, meta: { auth: "log_in" } },
    { path: "/articles/:id", name: "ArticleShowPage", component: ArticleShowPage, meta: { auth: "log_in" } },

    { path: "/events", name: "EventsIndexPage", component: EventsIndexPage, meta: { auth: "log_in" } },
    { path: "/events/new", name: "EventRegisterPage", component: EventRegisterPage, meta: { auth: "log_in" } },
    { path: "/events/:id/edit", name: "EventEditPage", component: EventRegisterPage, meta: { auth: "log_in" } },
    { path: "/events/:id", name: "EventShowPage", component: EventShowPage, meta: { auth: "log_in" } },

    { path: "/available_date_copy_page", name: "AvailableDateCopyPage", component: AvailableDateCopyPage },
    { path: "/search_recommended_users", name: "SearchRecommendedUsersPage", component: SearchRecommendedUsersPage },
    { path: "/search_recommended_users_for_sales", name: "SearchRecommendedUsersPage", component: SearchRecommendedUsersPage },
    { path: "/register_gid", name: "RegisterGidPage", component: RegisterGidPage },

    { path: "/hf_members/new", name: "HfMemberRegisterPage", component: HfMemberRegisterPage },
    { path: "/hf_members/:id/edit", name: "HfMemberEditPage", component: HfMemberRegisterPage },
    { path: "/hf_members", name: "HfMemberPage", component: HfMembersIndexPage},

    { path: "/hf_forms/new", name: "HfFormRegisterPage", component: HfFormRegisterPage },
    { path: "/hf_forms/:id/edit", name: "HfFormEditPage", component: HfFormRegisterPage },
    { path: "/hf_forms/:id/copy", name: "HfFormCopyPage", component: HfFormRegisterPage },
    { path: "/hf_forms", name: "HfFormsIndexPage", component: HfFormsIndexPage},

    {path: "/masters", name: "MasterIndexPage", component: MasterIndexPage },
    {path: "/masters/universities", name: "MasterUniversitiesIndexPage", component: MasterUniversitiesIndexPage},
    {path: "/masters/:table_name", name: "MasterTableIndexPage", component: MasterTableIndexPage},
    {path: "/masters/universities/:id/edit", name: "MasterUniversityEditPage", component: MasterUniversityRegisterPage},
    {path: "/masters/universities/new", name: "MasterUniversityCreatePage", component: MasterUniversityRegisterPage},
    {path: "/masters/:table_name/:id/edit", name: "MasterTableEditPage", component: MasterTableRegisterPage},
    {path: "/masters/:table_name/new", name: "MasterTableCreatePage", component: MasterTableRegisterPage},
  ],

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition != null) { return savedPosition }
    else { return { x: 0, y: -100 } }
  }
});
