<template>
  <VForm ref="form">
    <MasterSelectField
      :rules="[validation.required]"
      v-if="!hiddenFields.includes('country_id')"
      :items="master('countries')"
      dense
      :label="t('school_country')"
      outlined
      v-model="value.country_id"
    />
    <MasterSelectField
      :rules="[validation.required]"
      v-if="!hiddenFields.includes('school_category_id')"
      :items="master('school_categories')"
      dense
      label="学校種"
      outlined
      v-model="value.school_category_id"
    />
    <VTextField
      :rules="[validation.required]"
      v-if="!hiddenFields.includes('school_name')"
      dense
      outlined
      item-value="id"
      item-text="name"
      v-model="value.school_name"
      :label="t('school_name')"
    />
    <SteppedDatePicker
      :rules="notRequiredFields.includes('enroll_at') ? [] : [validation.required]"
      v-if="!hiddenFields.includes('enroll_at')"
      v-model="value.enroll_at"
      :label="t('enroll_at')"
    />
    <SteppedDatePicker
      :rules="[validation.required]"
      v-if="!hiddenFields.includes('graduate_at')"
      v-model="value.graduate_at"
      :label="t('graduate_at')"
    />
  </VForm>
</template>

<script>
import { UserUpdateProfileCommandService } from "hfapi";
import MasterSelectField from "@@/components/organisms/shared/MasterSelectField.vue";
import SteppedDatePicker from "@@/components/molecules/SteppedDatePicker";
import { JAPAN_COUNTRY_ID } from "@@/config/constants.js"

let p = new UserUpdateProfileCommandService.Params;
export default {
  props: {
    value: {
      default: () => Object.assign({}, p.user.user_schools[0]),
    },
    schoolCategoryId: {},
    hiddenFields: {
      type: Array,
      default: ()=>[]
    },
    notRequiredFields: {
      type: Array,
      default: ()=>[]
    }
  },
  components: {
    MasterSelectField,
    SteppedDatePicker,
  },
  methods: {
    t(key, options){
      return this.$t(`pages.users.UpdateProfilePage.${key}`, options)
    },
    validate(){
      return this.$refs.form.validate()
    },
  },
  created(){
    console.log("create");
    this.value.school_category_id = this.schoolCategoryId
    this.value.country_id = this.value.country_id || JAPAN_COUNTRY_ID
  }
};
</script>

<style>
</style>