import { hfapiAxios } from "hfapi"
import Axios from 'axios'
const axios = new Axios.create

export default {
  namespaced: true,
  state: {
    client: '',
    uid: '',
    token: '',
    tokenType: '',
    expiry: '',
    showExpirationDialog: false,
  },
  mutations: {
    create(state, headers) {
      state.client = headers["client"]
      state.token = headers["access-token"]
      state.uid = headers["uid"]
      state.tokenType = headers["token-type"]
      state.expiry = headers["expiry"]
    },
    destroy (state) {
      state.client = ''
      state.uid = ''
      state.token = ''
      state.tokenType = ''
      state.expiry = ''
    },
    updateWithToken(state, headers) {
      state.token = headers["access-token"]
      state.expiry = headers["expiry"]
    },
    openExpirationDialog(state) {
      state.showExpirationDialog = true
    },
    closeExpirationDialog(state) {
      state.showExpirationDialog = false
    },
  },
  actions: {
    signIn({ commit }, hf_member) {
      return hfapiAxios.post('/jtj/hf_member/sign_in', hf_member).then(resp => {
        commit('create', resp.headers)
      })
    },
    signOut({ commit, state, dispatch }, callback) {
      console.log(state)
      // hfapiAxiosでやるとinterceptorのせいで無限ループにハマる可能性がある
      return axios.delete(`${process.env.VUE_APP_HFAPI_HOST}/jtj/hf_member/sign_out`,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          // "X-CSRF-Token": csrf_token,
          "access-token": state.token,
          client: state.client,
          uid: state.uid,
          "token-type": state.tokenType,
        }
      }).finally(() => {
        commit('destroy') //とりあえず削除すればログアウトはできるので
        dispatch('hf_member/destroy', null, { root: true })
        if (callback) { callback() }
      })
    },
    updateWithToken({ commit }, headers) {
      if (headers['access-token']) { // undefined または 空文字列のときは弾く
        commit('updateWithToken', headers)
      }
    }
  },
  getters: {
    isSignIn: (state) => {
      return !!state.token
    },
  }
}
