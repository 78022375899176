<template>
  <div>
    <template v-if="resumeQuestionId">
    <h3>この質問を使った求人リスト</h3>
    <VList>
      <VListItem v-for="recruitment in recruitments" :key="recruitment.id" @click="routerWithBlank({ name: 'RecruitmentShowPage', params: { id: recruitment.id }})">
        <VListItemTitle>{{recruitment.title_text}}</VListItemTitle>
      </VListItem>
    </VList>
    </template>
  </div>
</template>

<script>
import { HfMemberSearchRecruitmentsQueryService } from "hfapi/jtj"

export default {
  props: {
    resumeQuestionId: {},
  },
  data(){
    return {
      recruitments: []
    }
  },
  methods:{
    getRecruitments(){
      if (!this.resumeQuestionId) return
      HfMemberSearchRecruitmentsQueryService.execute({q: {resume_questions_id_eq_any: [this.resumeQuestionId]}}).then((resp)=>{
        this.recruitments = resp.data
      })
    }
  },
  mounted(){
    this.getRecruitments()
  }
}

</script>

<style scoped>
</style>