<template>
  <VCard>
    <VCardSubtitle>state: {{application.state}}</VCardSubtitle>
    <VCardText>
      <VSelect dense :items="Object.values(state_components)" item-text="explanation" return-object v-model="component" label="action"/>
      <component :is="component" :application="application" v-if="component"/>
    </VCardText>
  </VCard>
</template>

<script>
import ActionSendRequestResumeMail from "./Actions/ActionSendRequestResumeMail"
// import ActionRegisterSendResumeDate from "./Actions/ActionRegisterSendResumeDate"
// import ActionRegisterInterviewResult from "./Actions/ActionRegisterInterviewResult"
// import ActionRegisterNextInterviewOrPassed from "./Actions/ActionRegisterNextInterviewOrPassed"
// import ActionRegisterResumeResult from "./Actions/ActionRegisterResumeResult"
// import ActionRegisterSchedulingInterview from "./Actions/ActionRegisterSchedulingInterview"
// import ActionRegisterStudentDecision from "./Actions/ActionRegisterStudentDecision"
import ActionSendFailureMail from "./Actions/ActionSendFailureMail"
import ActionSendSetupInterviewWithHfMail from "./Actions/ActionSendSetupInterviewWithHfMail"
import ActionSendAskQuestionsMail from "./Actions/ActionSendAskQuestionsMail"
import ActionStandbyRequestMail from "./Actions/ActionStandbyRequestMail"
export default {
  props: {
    application: {
      type: Object,
    }
  },
  data(){
    return {
      state_components: {
        "wf_making_resume": ActionSendRequestResumeMail,
        // "wf_sending_resume_to_company": ActionRegisterSendResumeDate,
        // "wf_resume_result": ActionRegisterResumeResult,
        // "wf_scheduling_interview": ActionRegisterSchedulingInterview,
        // "wf_doing_interview": ActionRegisterSchedulingInterview,
        // "wf_interview_result": ActionRegisterInterviewResult,
        // "wf_next_interview_or_passed": ActionRegisterNextInterviewOrPassed,
        // "wf_student_decision": ActionRegisterStudentDecision,
        "resume_failure": ActionSendFailureMail,
        "none0": ActionSendSetupInterviewWithHfMail,
        "none1": ActionSendAskQuestionsMail,
        "none2": ActionStandbyRequestMail,
      },
      component: null
    }
  },
  watch: {
    "application.state": function(){
      this.component = this.state_components[this.application.state]
    }
  },
  created(){
    this.component = this.state_components[this.application.state]
  }
};

</script>
