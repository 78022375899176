<template>
  <div>
    <ApplicationStateAction :application="application"/>
    <VContainer fluid>

      <VRow>
        <VCol>
          <VExpansionPanels flat v-if="recruitment.id">
            <VExpansionPanel>
              <VExpansionPanelHeader>
                <h4><VIcon>mdi-link-variant</VIcon> 関連リンク一覧</h4>
              </VExpansionPanelHeader>
              <VExpansionPanelContent>
                <div v-for="link in relatedLinks" :key="link.url" >
                  <VListItem  :href="link.url" :target="link.target" v-if="!link.isHidden">
                    <VListItemContent>
                      <VListItemTitle>{{link.title}}</VListItemTitle>
                    </VListItemContent>
                    <VListItemAction>
                      <VIcon v-if="link.target == '_blank'">mdi-open-in-new</VIcon>
                    </VListItemAction>
                  </VListItem>
                </div>
              </VExpansionPanelContent>
            </VExpansionPanel>
          </VExpansionPanels>
        </VCol>
      </VRow>
      <VRow>
        <VCol cols="6">
          <!-- 企業側 -->
          <h2>{{ company.official_name_ja }}</h2>
          <h3>仕事内容</h3>
          <p>  {{ recruitment.job_description_text }} </p>
          <h3>事業内容</h3>
          <p>{{ company.business_description_text }}</p>
          <VBtn v-if="user.resume_drive_file_url" :href="user.resume_drive_file_url" class="ma-3" color="primary">ユーザー提出履歴書があります</VBtn>
          <CompareTable :recruitment="recruitment" :user="user" :company="company"/>
        </VCol>
        <VCol cols=6>
          <!-- 留学生側 -->
          <VExpansionPanels class="mb-3">
            <VExpansionPanel>
              <VExpansionPanelHeader>
                <h4> <VIcon>mdi-wrench</VIcon> 履歴書ツール</h4>
              </VExpansionPanelHeader>
              <VExpansionPanelContent>
                  <VListItem>
                    <VBtn @click="$router.push({name: 'ResumeCorrectPage'})" class="primary my-3"><VIcon>mdi-pencil</VIcon>履歴書を編集する</VBtn>
                  </VListItem>
                  <VListItem v-if="false">
                      <VBtn v-if="!application.resume_google_docs_link" @click="createGoogleDocs" :loading="generatingGoogleDocs"><VIcon>mdi-file-document-edit-outline</VIcon>GoogleDocs作成</VBtn>
                      <VBtn v-else @click="window.open(application.resume_google_docs_link, '_blank')"><VIcon>mdi-open-in-new</VIcon>GoogleDocsへ</VBtn>
                  </VListItem>
                  <VListItem>
                      <VBtn v-if="!as_user" @click="getResumeAsUser"><VIcon>mdi-reload</VIcon>ユーザー表示の履歴書を取得</VBtn>
                      <VBtn v-if="as_user" @click="getResumeAsAdmin"><VIcon>mdi-reload</VIcon>Admin表示の履歴書を取得</VBtn>
                  </VListItem>
              </VExpansionPanelContent>
            </VExpansionPanel>
          </VExpansionPanels>
          <ResumePDFViewer :application_id="this.$route.params.id" :fileName="resumeFileName" :as_user="as_user" :user_id="this.user.id" ref="resumePDFViewer"/>
          <div>
            <h5>虚偽がないことの同意</h5>
            <p>{{application.no_lie_agreement ? "同意済み": "同意なし"}}</p>
            <h5>健康状態</h5>
            <p>{{user.health_description}}</p>
            <h5>扶養家族の人数</h5>
            <p>{{user.num_of_dependents_family}}</p>
            <h5>キャリアパスを選んだ理由</h5>
            <p>{{application.resume.why_select_career}}</p>
            <h5>辞めた理由</h5>
            <p v-for="r in user.quitReasons" :key="r.companyName">{{r.companyName}}: {{r.reason}}</p>
            <h5>履歴書質問</h5>
            <div v-for="a in forHfAnswers" :key="a.id">
              <h5>・{{ a.resume_question.title_text }}</h5>
              <p>{{ a.answer }}</p>
            </div>
          </div>
          <AddHfRemarkForm :application="application" v-if="application.state=='wf_making_resume' || application.state=='wf_sending_resume_to_company'" @save="updateResume"/>
        </VCol>
      </VRow>
      <VRow>
        <VCol>
          <h3>担当者変更</h3>
          <ApplicationChangeHfMemberForm :application="application"/>
        </VCol>
      </VRow>
      <VRow v-if="!application.sent_resume_to_company_at">
        <VCol>
          <h3>HF判断不合格</h3>
          <p class="red--text">ボタンを押した時点でHF判断不合格になるので注意してください</p>
          <ApplicationHfDeclineForm :application="application"/>
        </VCol>
      </VRow>
      <VRow v-if="application.state=='wf_sending_resume_to_company'">
        <VCol>
          <h3>履歴書の送信</h3>
          <ApplicationSendResumeForm :application="application" :company="company" :user="user" :recruitment="recruitment"/>
        </VCol>
      </VRow>
    </VContainer>
  </div>
</template>

<script>
import ApplicationStateAction from "@@/components/organisms/applications/ApplicationStateAction"
import CompareTable from "@@/components/organisms/applications/CompareTable"
import ResumePDFViewer from "@@/components/organisms/applications/ResumePDFViewer"
import AddHfRemarkForm from "@@/components/organisms/applications/AddHfRemarkForm"
import ApplicationHfDeclineForm from "@@/components/organisms/applications/ApplicationHfDeclineForm"
import ApplicationChangeHfMemberForm from "@@/components/organisms/applications/ApplicationChangeHfMemberForm"
import ApplicationSendResumeForm from "@@/components/organisms/applications/ApplicationSendResumeForm"
import User from "@/aggregates/user"
import Recruitment from "@/aggregates/recruitment"
import Application from "@/aggregates/application"
import Company from "@/aggregates/company"
import {HfMemberCreateResumeGoogleDocsCommandService} from "hfapi"

export default {

  metaInfo() {
    return {
      title: `${this.user.full_name}-応募編集ページ`,
    }
  },
  components:{
    ApplicationStateAction,
    CompareTable,
    ResumePDFViewer,
    AddHfRemarkForm,
    ApplicationHfDeclineForm,
    ApplicationChangeHfMemberForm,
    ApplicationSendResumeForm,
  },
  data(){
    return {
      application: new Application(this.$route.params.id),
      user: new User(),
      recruitment: new Recruitment(),
      company: new Company(),
      generatingGoogleDocs: false,
      as_user: false,
    }
  },
  computed: {
    resumeFileName(){
      return `【履歴書】${this.user.fullName()}`
    },
    relatedLinks(){
      return [
        { title: "求人詳細ページ", url: `/#/recruitments/${this.recruitment.id}`, target: "_blank" },
        { title: "ユーザー詳細ページ", url: `/#/users/${this.user.id}` },
        { title: "ユーザーTrello", url: `https://trello.com/c/${this.user.trello_card_id}` , target: "_blank" },
        { title: "応募Trello", url: `https://trello.com/c/${this.application.trello_card_id}` , target: "_blank" },
        { title: "求人編集ページ", url: `/#/recruitments/${this.recruitment.id}/edit`},
        { title: "企業編集ページ", url: `/#/companies/${this.company.id}/edit`},
        { title: "履歴書GoogleDocs", url: this.application.resume_google_docs_link, target: "_blank", isHidden: !this.application.resume_google_docs_link },
        { title: "応募分析用ページ", url: `/#/applications/${this.application.id}/behavior`},
      ]
    },
    forHfAnswers() {
      const resumeQuestionAnswers = this.application.resume.resume_question_answers
      const answersForHf = resumeQuestionAnswers.filter(rqa => !rqa.is_display_in_resume)
      return answersForHf
    }
  },
  methods: {
    updateResume(){
      console.log(this.$refs.resumePDFViewer)
      this.$refs.resumePDFViewer.updateResume()
    },
    createGoogleDocs(){
      this.generatingGoogleDocs = true
      HfMemberCreateResumeGoogleDocsCommandService.execute({application: {id: this.application.id}}).then(resp=>{
        this.application.assign(resp.data)
      }).catch((e)=>{
        this.flash(e.message, "error")
      }).finally(()=>{
        this.generatingGoogleDocs = false
      })
    },
    getResumeAsUser(){
      this.as_user = true
      this.$nextTick(()=>{
        this.updateResume()
      })
    },
    getResumeAsAdmin(){
      this.as_user = false
      this.$nextTick(()=>{
        this.updateResume()
      })
    },
  },
  mounted(){
    this.application.after_assign = ()=>{
      this.user.fetch(this.application.user_id)
      this.recruitment.after_assign = () => {
        this.company.fetch(this.recruitment.company_id)
      }
      this.recruitment.fetch(this.application.recruitment_id)
    }
  }
}
</script>

<style>

</style>