<template>
  <div>
    <VBtn @click="dialog = true">{{label}}</VBtn>
    <VDialog v-model="dialog">
      <VCard>
        <VCardSubtitle>登録済みの画像は，行をクリックするとプレビューができます</VCardSubtitle>
        <VDataTable :items="items" :headers="headers" @click:row="preview">
          <template v-slot:[`item.delete`]="{item}">
            <VBtn small @click.stop="destroy(item)">delete</VBtn>
          </template>
        </VDataTable>
        <VCardActions>
          <VBtn class="float-right" color="primary" @click="uploadDialog=true">add</VBtn>
          <VBtn class="float-right" @click="dialog=false">Close</VBtn>
        </VCardActions>
      </VCard>
      <VDialog v-model="uploadDialog">
        <ImageUploadCard :label="label" :dir="dir" @input="add" />
      </VDialog>
      <VDialog v-model="previewData.dialog" width="380">
        <VCard>
          <VImg :src="previewData.url"/>
        </VCard>
      </VDialog>
    </VDialog>
  </div>
</template>

<script>
import ImageUploadCard from '../../molecules/ImageUploadCard.vue'

export default {
  components: { ImageUploadCard },
  props: {
    label: { type: String, default: "Upload" },
    dir: { type: String },
    value: { type: Array },
  },
  data(){
    return {
      dialog: false,
      uploadDialog: false,
      previewData: {
        dialog: false,
        url: ""
      },
      headers: [
        { value: 'key', text: 'key' },
        { value: 'delete', text: 'delete' },
      ]
    }
  },
  methods: {
    preview(item){
      this.previewData.dialog = true
      this.previewData.url = item.url
    },
    add(key){
      this.uploadDialog = false
      this.value.push({ key, _destroy: false})
      this.$emit('input', this.value)

    },
    destroy(item){
      this.$set(item, "_destroy", true)
    }
  },
  computed: {
    items(){ return this.value ? this.value.filter(i => !i._destroy) : [] }
  }
}
</script>

<style>

</style>