<template>
  <div>
    <!-- 基本的に、idやtemplateが全て入ってからrenderする -->
    <!-- v-if="locals.recruitment.id" とかでrenderingを止めておく -->
    <VTextField label="宛先アドレス" v-model="sendedMail.address" :disabled="disableAddress" v-if="!hideAddress"></VTextField>
    <VTextField label="件名" v-model="sendedMail.title"></VTextField>
    <VTextarea label="本文" v-model="sendedMail.body" :rows="bodyRows||30"></VTextarea>
    <VBtn @click="$emit('send', sendedMail)" :loading="sendding" x-large class="primary">送信</VBtn>
  </div>
</template>

<script>
import ejs from "ejs"
import moment from "moment"

export default {
  props: {
    templateTitle: {
      type: String
    },
    templateBody: {
      type: String
    },
    locals: {
      type: Object
    },
    address: {
      type: String
    },
    disableAddress: {
      type: Boolean
    },
    hideAddress: {
      type: Boolean
    },
    bodyRows: {
      type: Number,
      default: ()=> {null}
    },
    sendding: {
      type: Boolean
    }
  },
  data(){
    return {
      sendedMail: {
        address: "",
        title: "",
        body: "",
      }
    }
  },
  mounted(){
    this.sendedMail.address = this.address
    this.locals.moment = moment
    this.sendedMail.title = ejs.render(this.templateTitle, this.locals)
    this.sendedMail.body = ejs.render(this.templateBody, this.locals)
  }
}
</script>

<style>

</style>