export default {
  title:
    "【Important things to get a job in Japan / 日本で仕事を得るために大事なこと】",
  body: `*English below

<%=user.first_name%>さん

留学生の就職支援をしているJob Tree Japanの<%=hf_member.nick_name_ja%>です！

現時点で、<%=user.first_name%>さんが登録して頂いた情報を元に、企業を探してみたのですが、現時点で希望の企業が見つかりませんでした...

理由としては、<%= user.mainDepartment().name_ja %>学科系に関連した仕事は、得ることが難しいからです。
元々採用人数自体が少ないことと、他の優秀な日本人学生や留学生が同じ業種を狙っているので日本人でも難しい業種になります。

しかし、<%=user.first_name%>さんはとても優秀なので、他の業種でも活躍できることができると思います。
下に<%=user.first_name%>さんの日本語のスキルと、<%=user.mainDepartment().name_ja%>を活かせる他の業種の仕事リストを載せました！
希望の仕事とは違うかもしれませんが、一度下記の企業もチェックしてみてください＾＾
これらの企業は<%=user.first_name%>さんが来てくれることを望んでいます！
一度、色々な業界を見て、<%=user.first_name%>さんの可能性を広げてもらえたらと思います。

不安なことや気になることがありましたら、いつでもご連絡ください！
<%=user.first_name%>さんの力になれるように頑張ります😀
<% recommend_mail.active_remind_mail_recruitments.forEach(function(remind_mail_recruitment, i){ %>
  <%=i+1%>. <%=remind_mail_recruitment.recruitment.company.official_name_ja%>
  <%=remind_mail_recruitment.recruitment.company.hf_recommend_sentence.ja || remind_mail_recruitment.recruitment.company.appeal_point.ja%>
  [仕事内容]
  <%=remind_mail_recruitment.recruitment.recruitment_detail.job_description.ja%>
  [仕事の情報]
  https://job-tree-japan.com/#/recruitments/<%=remind_mail_recruitment.recruitment_id%>
<% }) %>

以上となります。
<%=user.first_name%>さんの夢を応援しています！
<%=hf_member.nick_name_ja%>

*English

Dear <%=user.first_name%>

This is <%=hf_member.nick_name_en%> who is supporting job-hunting for international students!

At the moment, I tried to find a company from your registration information, but at the moment I couldn't find companies you hope ...

The reason is difficult to get a job that an industry and job type related to <%=user.mainDepartment().name_en%>.
It is a difficult to get job that you hope even for Japanese people, because the number of people employed is less and other excellent Japanese students and foreign students also aim at the same jobs.

However, you seem to be excellent.
So I think you can be active in other industries.
Below is a list of jobs that you can make use of Japanese skills and knowledge of <%=user.mainDepartment().name_en%>.
It may not be the jobs you wanted, but please check the following companies once:D
These companies want you to come!
I hope you see the various industries once and expand the possibilities of you.

If you worry about job-hunting, please contact me anytime!
I will do my best to help you 😀
<% recommend_mail.active_remind_mail_recruitments.forEach(function(remind_mail_recruitment, i){ %>
  <%=i+1%>. <%=remind_mail_recruitment.recruitment.company.official_name_en%>
  <%=remind_mail_recruitment.recruitment.company.hf_recommend_sentence.en || remind_mail_recruitment.recruitment.company.appeal_point.en%>
  [Job Description]
  <%=remind_mail_recruitment.recruitment.recruitment_detail.job_description.en%>
  [Job information]
  https://job-tree-japan.com/#/recruitments/<%=remind_mail_recruitment.recruitment_id%>
<% }) %>
I hope that this information is helpful to you.
<%=hf_member.nick_name_en%>
`,
};