import i18n from "@/config/i18n";
export default function numberFormatter(num, digits) {
  if (!num) { return null }
  if (i18n.locale == "ja") {
    return numberFormatterJa(num);
  } else {
    return numberFormatterEn(num, digits);
  }
}

function numberFormatterEn(num, digits) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

function numberFormatterJa(num) {
  const keta = ["", "千", "万", "億", "兆"];

  let jaNum = "";
  let count = 0;

  num = String(num).replace(",", "");

  // 千
  if (num.length <= 3) {
    return num;
  } else {
    const n = num.slice(-3) - 0 + "";
    jaNum = [n !== "0" ? `${n}${keta[count]}` : "", jaNum].join("");
    num = ((num / 1000) | 0) + "";
    count++;
  }
  // 万
  if (num.length > 1) {
    const n = num.slice(-1) - 0 + "";
    jaNum = [n !== "0" ? `${n}${keta[count]}` : "", jaNum].join("");
    num = ((num / 10) | 0) + "";
    count++;
  }
  // それ以降
  while (num.length > 4) {
    const n = num.slice(-4) - 0 + "";
    jaNum = [n !== "0" ? `${n}${keta[count]}` : "", jaNum].join("");
    num = ((num / 10000) | 0) + "";
    count++;
  }
  jaNum = [num !== "0" ? `${num}${keta[count]}` : "", jaNum].join("");
  return jaNum;
}
