<template>
  <VContainer>
    <h1>履歴書用質問</h1>
    <VBtn :to="{name: 'ResumeQuestionRegisterPage'}" color="success" class="mb-3">新規作成</VBtn>
    <VCard>
      <VCardTitle>クリックして編集できます．<VTextField v-model="search" label="search" outlined dense class="ml-4"/></VCardTitle>
      
      <VCardText>
        <VDataTable :items="resumeQuestions" :headers="headers" :search="search" :custom-sort="sort" @click:row="$router.push({ name: 'ResumeQuestionEditPage', params: { id: $event.id }})">
          <template v-slot:[`item.using_frequency_rating_number`]="{ value }">
            {{ "★".repeat(value) }}
          </template>
          <template v-slot:[`item.disabled`]="{value}">
            <VIcon v-if="value">mdi-check</VIcon>
          </template>
        </VDataTable>
      </VCardText>
    </VCard>
  </VContainer>
</template>

<script>
import { HfMemberGetResumeQuestionsQueryService } from "hfapi"
import ResumeQuestion from "@@/aggregates/resumeQuestion"

export default {
  data(){
    return {
      resumeQuestions: [],
      headers: [
        { text: 'ID', value: 'id'},
        { text: 'タイトル', value: 'title_for_hf_member', sortable: false },
        { text: "利用頻度", value: 'using_frequency_rating_number', sortable: false },
        { text: '非表示', value: 'disabled', sortable: false}
      ],
      search: "",
    }
  },
  metaInfo() {
    return {
      title: `履歴書用質問ページ`,
    }
  },
  methods: {
    sort(items){
      return items.sort((a, b) => {
        return a.id - b.id
      }).sort((a, b) => {
        return b.using_frequency_rating_number - a.using_frequency_rating_number
      })
    }
  },
  async created(){
    const resp = await HfMemberGetResumeQuestionsQueryService.execute({ all: true })
    this.resumeQuestions = resp.data.map(d => new ResumeQuestion(d))
  },
}

</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10%;
  right: 5%;
  z-index: 1000;
}

</style>