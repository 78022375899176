<template>
  <VForm ref="form">

    <h4 class="mt-8">基本情報</h4>
    <VAutocomplete :label="t('company_id')" v-model="recruitment.company_id" :items="companies" item-text="official_name_ja" item-value="id" :rules="[ validation.required ]"/>
    <VTextField :label="t('title_id')" v-model="recruitment.title[lang]" />
    <NotionUrlField v-model="recruitment.notion_id" :rules="[ validation.required ]" />
    <VTextField :label="t('job_category_name_of_company_id')" v-model="recruitment.recruitment_detail.job_category_name_of_company[lang]" />
    <MasterSelectField :label="t('job_category_id')" v-model="recruitment.job_category_ids" multiple deletable-chips chips :items="master('job_categories')" />
    <VTextarea :label="t('job_description_id')" v-model="recruitment.recruitment_detail.job_description[lang]" />
    <VTextarea :label="t('appeal_point_id')" v-model="recruitment.recruitment_detail.appeal_point[lang]" />

    <h4 class="mt-8">求人イメージ画像</h4>
    <template v-if="recruitment.id != null">
      <h5>アップロード後に提出が必要です</h5>
      <ImagesUpload label="images" :dir="`companies/${recruitment.company_id}/recruitments/${recruitment.id}/`" v-model="recruitment.images"/>
    </template>
    <template v-else>
      一度保存する必要があります
    </template>

    <h4 class="mt-8">{{t("recruitment_careers")}}</h4>
    <VCard v-for="recruitment_career in recruitment.recruitment_careers" :key="recruitment_career.id" class="mb-2">
      <VContainer>
        <VRow v-if="!recruitment_career._destroy">
          <VCol cols="12">
            <VTextarea :label="t('title_id')" v-model="recruitment_career.title[lang]"/>
            <MasterSelectField :label="t('first_living_country_id')" v-model="recruitment_career.first_living_country_id" :items="master('countries')"/>
            <MasterSelectField :label="t('last_living_country_id')" v-model="recruitment_career.last_living_country_id" :items="master('countries')"/>
            <CheckboxDefaultFalse :label="t('is_work_globaly')" v-model="recruitment_career.is_work_globaly"/>
            <VTextField :label="t('years_in_first_country')" v-model="recruitment_career.years_in_first_country" type="number"/>
            <VTextarea :label="t('description_id')" v-model="recruitment_career.description[lang]"/>
          </VCol>
          <VCol>
            <VBtn class="error" @click="remove(recruitment.recruitment_careers, recruitment_career)"><VIcon>mdi-trash</VIcon>削除</VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VBtn @click="add('recruitment_careers')">キャリアパス追加</VBtn>

    <h4 class="mt-8">{{t("ideal_statue_id")}}</h4>
    <VTextarea :label="t('ideal_statue_id')" v-model="recruitment.recruitment_detail.ideal_statue[lang]"/>

    <h4 class="mt-8">{{t("recruitment_required_skills")}}</h4>
    <VCard v-for="recruitment_required_skill in recruitment.recruitment_required_skills" :key="recruitment_required_skill.id" class="mb-2">
      <VContainer>
        <VRow v-if="!recruitment_required_skill._destroy">
          <VCol cols="12">
            <MasterSelectField :label="t('skill_id')" v-model="recruitment_required_skill.skill_id" :items="master('skills')" :rules="[ validation.required ]"/>
            <VTextarea :label="t('description_id')" v-model="recruitment_required_skill.description[lang]"/>
            <CheckboxDefaultFalse :label="t('is_must')" v-model="recruitment_required_skill.is_must"/>
          </VCol>
          <VCol cols="2">
            <VBtn class="error" @click="remove(recruitment.recruitment_required_skills, recruitment_required_skill)"><VIcon>mdi-trash</VIcon>削除</VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VBtn @click="add('recruitment_required_skills')">必要なスキル追加</VBtn>
    <h4 class="mt-8">その他の必要なスキル</h4>
    <VTextarea label="その他必要なスキル" v-model="recruitment.recruitment_detail.required_skills_description[lang]"></VTextarea>

    <h4 class="mt-8">履歴書作成用質問</h4>
    <RecruitmentResumeQuestionForm
      :recruitmentParams="recruitmentParams"
      :resumeQuestions="resumeQuestions"
      @update:recruitmentParams="$emit('update:recruitmentParams', $event)"
      @onResumeQuestionRegister="$emit('onResumeQuestionRegister')"
    />

    <CheckboxDefaultFalse :label="t('is_japanese_only_for_resume_questions')" v-model="recruitment.is_japanese_only_for_resume_questions"/>

    <h4 class="mt-8">{{t("recruitment_target_study_department_categories")}}</h4>
    <MasterSelectField :label="t('study_department_category_id')" v-model="recruitment.target_study_department_category_ids" :items="master('study_department_categories')" :hint="t('recruitment_target_study_department_categories_hint')" multiple deletable-chips chips persistent-hint/>

    <h4 class="mt-8">{{t("required_japanese_level")}}</h4>
    <VSelect :label="t('required_jlpt')" v-model="recruitment.required_jlpt" :items="['N1', 'N2', 'N3', 'N4', 'N5']"/>
    <MasterSelectField :label="t('speaking_level_id')" v-model="recruitment.required_japanese_level.speaking_level_id" :items="master('speaking_levels')"/>
    <MasterSelectField :label="t('listening_level_id')" v-model="recruitment.required_japanese_level.listening_level_id" :items="master('listening_levels')"/>
    <MasterSelectField :label="t('writing_level_id')" v-model="recruitment.required_japanese_level.writing_level_id" :items="master('writing_levels')"/>
    <MasterSelectField :label="t('reading_level_id')" v-model="recruitment.required_japanese_level.reading_level_id" :items="master('reading_levels')"/>
    <CheckboxDefaultFalse :label="t('is_must')" v-model="recruitment.required_japanese_level.is_must"/>

    <h4 class="mt-8">{{t("required_english_level")}}</h4>
    <MasterSelectField :label="t('general_level_id')" v-model="recruitment.required_english_level.general_level_id" :items="master('general_language_levels')"/>
    <CheckboxDefaultFalse :label="t('is_must')" v-model="recruitment.required_english_level.is_must"/>

    <h4 class="mt-8">その他の必要言語</h4>
    <VCard v-for="recruitment_required_language_level in recruitment.required_other_language_levels" :key="recruitment_required_language_level.id" class="mb-2">
      <VContainer>
        <VRow v-if="!recruitment_required_language_level._destroy">
          <VCol cols="12">
            <MasterSelectField :label="t('language_id')" v-model="recruitment_required_language_level.language_id" :items="master('languages')" :rules="[ validation.required ]"/>
            <MasterSelectField :label="t('general_level_id')" v-model="recruitment_required_language_level.general_level_id" :items="master('general_language_levels')"/>
            <CheckboxDefaultFalse :label="t('is_must')" v-model="recruitment_required_language_level.is_must"/>
          </VCol>
          <VCol>
            <VBtn class="error" @click="remove(recruitment.required_other_language_levels, recruitment_required_language_level)"><VIcon>mdi-trash</VIcon>削除</VBtn> </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VBtn @click="add('required_other_language_levels')">その他の必要言語追加</VBtn>

    <h4 class="mt-8">選考方法について</h4>
    <VCard v-for="recruitment_selection in recruitment.recruitment_selections" :key="recruitment_selection.id" class="mb-2">
      <VContainer>
        <VRow v-if="!recruitment_selection._destroy">
          <VCol cols="12">
            <MasterSelectField :label="t('selection_type_id')" v-model="recruitment_selection.selection_type_id" :items="master('selection_types')" :rules="[ validation.required ]"/>
            <CheckboxDefaultFalse :label="t('is_online')" v-model="recruitment_selection.is_online"/>
            <VTextField :label="t('place_id')" v-model="recruitment_selection.place[lang]"/>
            <VTextField :label="t('step_num')" v-model="recruitment_selection.step_num" type="number" :rules="[ validation.required ]"/>
            <MasterSelectField :label="t('language_id')" v-model="recruitment_selection.selection_language_ids" :items="master('languages')" multiple deletable-chips chips/>
          </VCol>
          <VCol>
            <VBtn class="error" @click="remove(recruitment.recruitment_selections, recruitment_selection)"><VIcon>mdi-trash</VIcon>削除</VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VBtn @click="add('recruitment_selections')">選考追加</VBtn>

    <h4 class="mt-8">海外応募の可否</h4>
    <CheckboxDefaultFalse :label="t('is_available_from_overseas')" v-model="recruitment.is_available_from_overseas"/>

    <h4 class="mt-8">給料について</h4>
    <VSelect :label="t('salary_unit')" v-model="recruitment.recruitment_detail.salary_unit" :items="[{text: '月収', value: 'month'}, {text: '年収', value: 'year'}]"/>
    <CheckboxDefaultFalse :label="t('is_salary_decided_by_interview')" v-model="recruitment.recruitment_detail.is_salary_decided_by_interview"/>
    <VTextField :label="t('salary_min_for_professional_school_students')" v-model="recruitment.recruitment_detail.salary_min_for_professional_school_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_max_for_professional_school_students')" v-model="recruitment.recruitment_detail.salary_max_for_professional_school_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_min_for_bachelor_students')" v-model="recruitment.recruitment_detail.salary_min_for_bachelor_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_max_for_bachelor_students')" v-model="recruitment.recruitment_detail.salary_max_for_bachelor_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_min_for_master_students')" v-model="recruitment.recruitment_detail.salary_min_for_master_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_max_for_master_students')" v-model="recruitment.recruitment_detail.salary_max_for_master_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_min_for_doctor_students')" v-model="recruitment.recruitment_detail.salary_min_for_doctor_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_max_for_doctor_students')" v-model="recruitment.recruitment_detail.salary_max_for_doctor_students" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_min_for_mid_career')" v-model="recruitment.recruitment_detail.salary_min_for_mid_career" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('salary_max_for_mid_career')" v-model="recruitment.recruitment_detail.salary_max_for_mid_career" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextField :label="t('model_salary_allowance_value')" v-model="recruitment.recruitment_detail.model_salary_allowance_value" type="number" hint="単位は円で。'100000' と数字だけで入力"/>
    <VTextarea :label="t('model_salary_allowance_value_description_id')" v-model="recruitment.recruitment_detail.model_salary_allowance_value_description[lang]" hint="住宅手当: 30,000円 + 通訳手当: 20,000円"/>
    <CheckboxDefaultFalse :label="t('has_bonus')" v-model="recruitment.recruitment_detail.has_bonus"/>

    <h4 class="mt-8">手当について</h4>
    <p class="text-caption text--secondary">{{t("allowances_hint")}}</p>
    <VCard v-for="recruitment_allowance in recruitment.recruitment_allowances" :key="recruitment_allowance.id" class="mb-2">
      <VContainer>
        <VRow v-if="!recruitment_allowance._destroy">
          <VCol cols="12">
            <MasterSelectField :label="t('allowances')" v-model="recruitment_allowance.allowance_id" :items="master('allowances')"/>
            <VTextarea :label="t('description_id')" v-model="recruitment_allowance.description[lang]"/>
          </VCol>
          <VCol>
            <VBtn class="error" @click="remove(recruitment.recruitment_allowances, recruitment_allowance)"><VIcon>mdi-trash</VIcon>削除</VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VBtn @click="add('recruitment_allowances')">手当て追加</VBtn>

    <h4 class="mt-8">その他の手当について</h4>
    <VTextarea label="その他の手当て" v-model="recruitment.recruitment_detail.allowances_description[lang]"/>


    <h4 class="mt-8">就業時間について</h4>
    <CheckboxDefaultFalse :label="t('is_adopting_flextime')" v-model="recruitment.recruitment_detail.is_adopting_flextime"/>
    <TimePickerInMenu :label="t('work_start_at')" v-model="recruitment.recruitment_detail.work_start_at"/>
    <TimePickerInMenu :label="t('work_end_at')" v-model="recruitment.recruitment_detail.work_end_at"/>
    <VTextField :label="t('work_break_time_id')" v-model="recruitment.recruitment_detail.work_break_time[lang]" hint="休憩時間が何時から何時か？などを書いてください。例: 12:00 ~ 13:00"/>
    <VTextarea :label="t('work_hours')" v-model="recruitment.recruitment_detail.work_hours" type="number"/>
    <VTextarea :label="t('work_time_description_id')" v-model="recruitment.recruitment_detail.work_time_description[lang]"/>

    <h4 class="mt-8">勤務地について</h4>
    <RecruitmentCompanyOfficeForm :recruitmentParams="recruitmentParams" :lang="lang" />

    <h4 class="mt-8">社内研修について</h4>
    <CheckboxDefaultFalse :label="t('has_training')" v-model="recruitment.recruitment_detail.has_training"/>
    <VTextarea :label="t('training_description_id')" v-model="recruitment.recruitment_detail.training_description[lang]"/>

    <h4 class="mt-8">その他</h4>
    <VTextField :label="t('peculiar_apply_url')" v-model="recruitment.peculiar_apply_url" hint="ここを入力するとJTJから履歴書が作れなくなります" persistent-hint/>
    <CheckboxDefaultFalse :label="t('is_released')" v-model="recruitment.is_released"/>
    <CheckboxDefaultFalse label="トップページへ表示" v-model="recruitment.is_shown_on_top"/>
  </VForm>
</template>

<script>
import { HfMemberRegisterRecruitmentCommandService } from "hfapi/jtj"
import RecruitmentResumeQuestionForm from './RecruitmentResumeQuestionForm.vue'
import ImagesUpload from '@@/components/organisms/shared/ImagesUpload.vue'
import TimePickerInMenu from "@@/components/molecules/TimePickerInMenu.vue"
import NotionUrlField from '../../molecules/NotionUrlField.vue'
import RecruitmentCompanyOfficeForm from './RecruitmentRegisterForms/RecruitmentCompanyOfficeForm.vue'

export default {
  components: {
    TimePickerInMenu,
    ImagesUpload,
    NotionUrlField,
    RecruitmentCompanyOfficeForm,
    RecruitmentResumeQuestionForm
  },
  props: {
    recruitmentParams: {
      type: [HfMemberRegisterRecruitmentCommandService.Params, HfMemberRegisterRecruitmentCommandService.BuildableParams],
    },
    lang: {
      type: String,
    },
    companies: {
      type: Array,
      required: true,
    },
    resumeQuestions: {
      type: Array,
      required: true,
    }
  },
  data(){
    return {
      recruitment: this.recruitmentParams.recruitment,
      importance_items: [ "no", "want", "must" ],
    }
  },
  methods: {
    add(key){
      let pushed = this.recruitmentParams.build(key)
      this.recruitment[key].push(pushed)
    },
    remove(ary, object){
      if (object.id && this.recruitmentParams.recruitment.id){
        this.$set(object, "_destroy", true)
      } else {
        ary.remove(object)
      }
    },
    t(key){
      return this.$t(`aggregates.RecruitmentColumnNames.${key}`)
    },
    validate(){
      return this.$refs.form.validate()
    }
  },
}


</script>

<style>

</style>
