<template>
  <VForm ref="form">
    <h4 class="primary--text">HF用タイトル</h4>
    <VTextField label="タイトル(HF用)" v-model="resumeQuestion.title_for_hf_member" :rules="[validation.required]" />

    <h4 class="primary--text">ユーザーへの表示</h4>
    <VTextField label="タイトル(ユーザー向け)" v-model="resumeQuestion.title[lang]" :rules="[validation.required]"/>
    <VTextarea label="記入目的(ユーザー向け)" v-model="resumeQuestion.purpose[lang]" hint="＊ユーザーに表示されます．Markdownを利用できます．"/>
    <VTextarea label="補足説明" v-model="resumeQuestion.explanation[lang]" hint="＊ユーザーに表示されます．Markdownを利用できます．"/>
    <h4 class="primary--text">目安文字数</h4>
    <VTextField label="文字数(日本語)" v-model="resumeQuestion.default_num_of_approximate_characters_ja" type="number"/>
    <VTextField label="Word数(英語)" v-model="resumeQuestion.default_num_of_approximate_words_en" type="number"/>


    <template v-if=" false && templateItems.length>0"> <!-- とりあえず無効化 -->
      <VAutocomplete label="デフォルトのテンプレート" v-model="resumeQuestion.default_template_id" :items="templateItems" item-value="id" :item-text="d => `${d.template_name_text}: ${d.template_text}`" style="text-overflow: ellipsis;" />
      <p>
        <strong>選択したテンプレートのテキスト</strong> <br>
        {{ selectedTemplateText || "なし" }}
      </p>
    </template>

    <h4 class="primary--text">使い回し設定</h4>
    <CheckboxDefaultFalse label="回答の使い回しを許可する" v-model="resumeQuestion.is_allow_reuse"/>


    <h4 class="primary--text">履歴書に表示をするか？(デフォルト)</h4>
    <p>＊表示非表示は求人ごとに選択が可能です。ここではデフォルト値を選べます。</p>
    <CheckboxDefaultFalse label="履歴書に表示する(デフォルト)" v-model="resumeQuestion.default_is_display_in_resume"/>


    <h4 class="primary--text">使用頻度</h4>
    <p>＊質問選択時の並び替え用です</p>
    <VRadioGroup row mandatory :rules="[validation.required]" v-model="resumeQuestion.using_frequency">
      <VRadio value="usually" label="よく使う" />
      <VRadio value="often" label="たまに使う" />
      <VRadio value="rarely" label="ある求人の時のみ使う" />
    </VRadioGroup>


    <h4 class="primary--text">質問の削除</h4>
    <p>＊求人に質問を登録するページに表示されなくなります。すでに登録済みの場合はそのままです。</p>
    <CheckboxDefaultFalse label="削除する" v-model="resumeQuestion.disabled"/>
  </VForm>
</template>

<script>
import { HfMemberRegisterResumeQuestionCommandService } from "hfapi"

export default {
  props: {
    resumeQuestionParams: {
      type: HfMemberRegisterResumeQuestionCommandService.BuildableParams,
    },
    lang: {
      type: String,
    },
    templates: {
      type: Array,
      default: () => [],
    }
  },
  data(){
    return {
      resumeQuestion: this.resumeQuestionParams.resume_question,
    }
  },
  methods: {
    t(key){
      return this.$t(`aggregates.ResumeQuestionColumnNames.${key}`)
    },
    validate(){
      return this.$refs.form.validate()
    }
  },
  computed: {
    templateItems(){ return this.templates.filter(t => !!t.id) },
    selectedTemplateText(){ return this.templates.find(t => t.id == this.resumeQuestion.default_template_id)?.template_text }
  },
}


</script>

<style>

</style>