<template>
  <VContainer>
    あああああああああああ
    <a @click="$router.push({name: 'ApplicationShowPage', params: { id: $route.params.id } })">＜ 履歴書Previewへ</a>
    <VCard class="mt-5">
      <VContainer>
        <h2 class="mb-2 accent--text">履歴書編集</h2>
        <VTabs v-model="tabIndex">

          <VTab>
            履歴書の内容
          </VTab>
          <VTab>
            プロフィール
          </VTab>
          <VTab>
            顔写真
          </VTab>

          <VTabItem>
            <ApplicationResumeEditForm :application="application" @save="saved"/>
          </VTabItem>
          <VTabItem>
            <UserResumeEditProfileForm :user_id="application.user_id" @save="saved"/>
          </VTabItem>
          <VTabItem>
            <ResumeFaceImageUpload :user_id="application.user_id" @uploaded="flash('保存に成功しました')"/>
          </VTabItem>

        </VTabs>
      </VContainer>
    </VCard>
  </VContainer>
</template>

<script>
import ApplicationResumeEditForm from "@@/components/organisms/applications/ApplicationResumeEditForm";
import UserResumeEditProfileForm from "@@/components/organisms/users/UserResumeEditProfileForm";
import ResumeFaceImageUpload from "@@/components/organisms/users/ResumeFaceImageUpload";
import Application from "@/aggregates/application"

export default {
  metaInfo() {
    return {
      title: `ユーザー履歴書編集ページ`,
    }
  },
  components: {
    ApplicationResumeEditForm,
    UserResumeEditProfileForm,
    ResumeFaceImageUpload,
  },
  data(){
    return {
      tabIndex: 0,
      application: new Application(this.$route.params.id),
    }
  },
  methods: {
    saved(){
      this.flash('保存されました', "success")
    }
  }
}
</script>

<style>

</style>