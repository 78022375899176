export default function generateParamsUrl(urlStr, keyValueObject) {

  if(typeof urlStr != "string") { throw "url must be string: "+urlStr }

  urlStr = urlStr.replace("/#", "#")
  console.log(urlStr)

  const url = new URL(urlStr)
  const urlParams = new URLSearchParams(url.search);
  Object.keys(keyValueObject).forEach(key=>{
    if (!keyValueObject[key]) { return }
    urlParams.append(key, keyValueObject[key])
  })
  url.search = urlParams.toString()
  return url.toString()
  
}