"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "user": { "id": null, "email": null, "first_name": null, "last_name": null, "middle_name": null, "first_name_kana": null, "last_name_kana": null, "middle_name_kana": null, "phone_num": null, "address": null, "past_email": null } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "user": { "id": null, "email": null, "first_name": null, "last_name": null, "middle_name": null, "first_name_kana": null, "last_name_kana": null, "middle_name_kana": null, "phone_num": null, "address": null, "past_email": null } });
    }
    build(key) { return {}[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/hf_member__overwrite_user_personal_data", { params });
    }
};
