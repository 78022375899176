<template>
<div>
  <VContainer>
    <h2 class="mb-5">Job Tree Japan Events</h2>
    <div v-for="event in futureEvents" :key="event.id" class="mb-5">
      <EventCard :event="event" @click="$router.push({name: 'EventShowPage', params: {id: event.id}})" :lang="lang"></EventCard>
    </div>

    <h3 v-if="pastEvents.length" class="mb-5">Past Events</h3>
    <div v-for="event in pastEvents" :key="event.id" class="mb-5">
      <EventCard :event="event" @click="$router.push({name: 'EventShowPage', params: {id: event.id}})" :lang="lang"></EventCard>
    </div>
  </VContainer>
  <div class="btns">
    <VBtn class="my-1" @click="$router.push({name: 'EventRegisterPage'})">新規作成</VBtn>
  </div>
</div>
</template>

<script>
import {GetEventsQueryService} from "hfapi"
import Event from "@/aggregates/event"
import EventCard from "@@/components/organisms/events/EventCard.vue"

export default {
   metaInfo() {
    return {
      title: `イベント一覧ページ`,
    }
  },
  components: {
    EventCard,
  },
  data(){
    return {
      events: [],
    }
  },
  computed: {
    pastEvents(){
      return this.events.filter( e=> this.moment(e.start_at).isBefore() )
    },
    futureEvents(){
      return this.events.filter( e=> this.moment(e.start_at).isAfter() )
    },
    lang(){
      return this.currentLocale
    },
  },
  created(){
    GetEventsQueryService.execute().then(resp=>{
      this.events = resp.data.map(e=>new Event(e))
    })
  },
}
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 10%;
  right: 5%;
}
</style>