<template>
  <div>
    <VContainer v-if="should_check_remind_mails.length>0">
      <h3 class="primary--text mb-3">リマインドチェック</h3>
      <RecommendMailsShouldCheck :recommendMails="should_check_remind_mails" />
    </VContainer>
    <VContainer v-if="should_send_recommend_mails.length>0">
      <h3 class="primary--text mb-3">新規ユーザー</h3>
      <RecommendMailsShouldSend :recommendMails="should_send_recommend_mails" />
    </VContainer>
    <h3 v-if="should_send_recommend_mails.length==0 && should_check_remind_mails.length==0"> 本日のレコメンドメールは以上です。お疲れ様でした。 </h3>
  </div>
</template>

<script>
import {HfMemberGetRecommendMailsQueryService} from "hfapi"
import RecommendMailsShouldCheck from "@@/components/organisms/recommendMails/RecommendMailsShouldCheck"
import RecommendMailsShouldSend from "@@/components/organisms/recommendMails/RecommendMailsShouldSend"

export default {
   metaInfo() {
    return {
      title: `レコメンド一覧`,
    }
  },
  components: {
    RecommendMailsShouldCheck,
    RecommendMailsShouldSend,
  },
  data(){
    return {
      should_send_recommend_mails: [],
      should_check_remind_mails: []
    }
  },
  mounted(){
    console.log("aaaaaaa");
    HfMemberGetRecommendMailsQueryService.execute().then(resp=>{
    console.log("afffffffff");
      console.log(resp);
      this.should_send_recommend_mails = resp.data.should_send_recommend_mails || []
      this.should_check_remind_mails = resp.data.should_check_remind_mails || []
    }).catch(resp=>{
      console.log(resp);
    })
  }
}
</script>

<style>

</style>