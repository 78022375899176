var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-list"},[_c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","single-expand":true,"show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.display_for_hf",fn:function(ref){
var item = ref.item;
return [_c('h4',[_c('a',{on:{"click":function($event){return _vm.$router.push({name: 'UserDetailPage', params: { id: item.id }})}}},[_vm._v(_vm._s(item.display_for_hf)+" "),_c('VIcon',[_vm._v("mdi-link")])],1)])]}},{key:"item.action_memo",fn:function(ref){
var item = ref.item;
return [_c('ActionMemoForm',{attrs:{"user":item.user}})]}},{key:"item.applicationText",fn:function(ref){
var item = ref.item;
return _vm._l((item.applications),function(application){return _c('VContainer',{key:item.id+'__'+application.id},[_c('h4',[_c('a',{on:{"click":function($event){return _vm.$router.push({name: 'ApplicationShowPage', params: {id: application.id}})}}},[_vm._v(_vm._s(application.recruitmentExpression())+" "),_c('VIcon',[_vm._v("mdi-link")])],1)]),_c('p',[_vm._v(" "+_vm._s(application.state_ja)+" ")])])})}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(_vm.expanded[0] == item)?_c('td',{attrs:{"colspan":headers.length}},[_c('VContainer',[_c('p',[_vm._v(" "+_vm._s(item.user.user_appraisal.memo)+" ")]),_c('VBtn',{on:{"click":function($event){_vm.expanded=[]}}},[_vm._v("閉じる")])],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }