<template lang="">
  <div>
    <VBtn @click="$router.push({name: 'MasterTableCreatePage', params: { table_name: table_name } })">新規作成</VBtn>
    <VBtn @click="downloadCsv()">CSVダウンロード</VBtn>
    <VDataTable :headers="headers" :items="items" :items-per-page="-1">
      <template v-slot:item.actions="{ item }">
        <VBtn @click="$router.push({name: 'MasterTableEditPage', params: { table_name: table_name, id: item.id } })">編集</VBtn>
      </template>
    </VDataTable>
  </div>
</template>
<script>

import csvDownload from "@@/lib/csvDownload"

export default {
  computed: {
    table_name() {
      return this.$route.params.table_name
    },
    master_data(){
      return this.master(this.table_name)
    },
    keys(){
      if (!this.master_data[0]) { return [] }
      return Object.keys(this.master_data[0])
    },
    headers(){
      return this.keys.map(key=>{
        return { text: key, value: key }
      }).concat([{ text: "Actions", value: "actions" }])
    },
    items(){
      return this.master_data
    },
    twoDArray(){
      let array = []
      array.push(this.keys)
      this.master_data.forEach(data => {
        array.push(this.keys.map(key=>data[key]))
      });
      return array
    }
  },
  methods: {
    downloadCsv(){
      csvDownload(this.twoDArray , this.table_name)
    }
  },
}
</script>
<style lang="">
  
</style>