<template>
  <div>
    <VRow align="center" justify="space-between" v-if="!!user_id">
      <VCol>
        <VSelect v-model="resume_type" :items="resume_type_items" label="履歴書タイプ"/>
      </VCol>
    </VRow>
    <template v-if="loading && !failed">
      <VProgressCircular :indeterminate="loading"/> Building resume...
    </template>
    <template v-else-if="failed">
      <p class="error--text">履歴書の生成に失敗しました．システム担当まで確認してください．</p>
    </template>
    <template v-else>
      <VBtn @click="download" color="accent" small class="ma-1">Download</VBtn>
      <VBtn @click="downloadPhoto" color="accent" small class="ma-1">ユーザーの顔写真をダウンロード</VBtn>
      <PDFViewer :data="resumePDFData"></PDFViewer>
    </template>
  </div>
</template>

<script>
import { HfMemberGetApplicationResumePdfQueryService, HfMemberGetUserResumePdfQueryService } from "hfapi"
import PDFViewer from "@/components/molecules/PDFViewer"
import User from "@/aggregates/user";
import mime from 'mime';

export default {
  props: {
    application_id: { type: [Number, String] },
    user_id: { type: [Number, String] },
    fileName: { type: String },
  },
  components: {
    PDFViewer
  },
  data() {
    return {
      resumePDFData: "",
      loading: true,
      failed: false,
      resume_type: "hf_member_resume",
    }
  },
  computed: {
    resume_type_items(){
      if(this.application_id){
        return [
          { text: "HfMember向け", value: "hf_member_resume" },
          { text: "HfMember向け(フルデータ)", value: "hf_member_resume_full_data" },
          { text: "営業向け(フィルター)", value: "hf_member_resume_filtered" },
          { text: "ユーザー向け", value: "user_resume" },
        ]
      } else if(this.user_id){
        return [
          { text: "営業向け(フィルター)", value: "hf_member_resume_filtered" },
          { text: "ユーザー向け", value: "user_resume" },
        ]
      }
      return []
    }
  },
  methods: {
    updateResume(){
      this.loading = true
      if (this.application_id) {
        HfMemberGetApplicationResumePdfQueryService.execute({application_id: this.application_id, resume_type: this.resume_type}).then(resp=>{
          this.loading = false
          this.resumePDFData = resp.data.resume_pdf_data
        }).catch(() => {
          this.failed = true
        })
      }
      else if (this.user_id) {
        HfMemberGetUserResumePdfQueryService.execute({user_id: this.user_id, resume_type: this.resume_type}).then(resp=>{
          this.loading = false
          this.resumePDFData = resp.data.resume_pdf_data
        }).catch(() => {
          this.failed = true
        })
      }
    },
    changeFiltered(){
      this.updateResume()
    },
    download(){
      this.downloadBase64(this.resumePDFData, "application/pdf")
    },
    downloadPhoto(){
      User.fetch(this.user_id).then(user => {
        const base64 = user.resume_face_image.split("base64,")[1]
        const type = user.resume_face_image.split(/;base64,|data:/)[1]
        this.downloadBase64(base64, type)
      })
    },
    downloadBase64(base64, type){
      const decoded_array = new Uint8Array(Array.prototype.map.call(atob(base64), c => c.charCodeAt()));
      const decoded = new Blob([decoded_array], {type});
      const url = window.URL.createObjectURL(decoded);
      const a = document.createElement("a");
      a.href = url;
      a.download = this.fileName + mime.getExtension(type);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  },
  mounted(){
    this.filtered = !!this.$route.query.filtered
    this.updateResume()
  },
  watch: {
    resume_type: function(){
      this.updateResume()
    }
  },
}
</script>

<style>

</style>
