<template>
  <VContainer>
    <h2>HfMemberを登録できます。</h2>
    <VRow>
      <VCol cols="12">
        <HfMemberRegisterForm :hfMemberParams="hfMemberParams"/>
      </VCol>
    </VRow>
    <VBtn @click="submit">Submit</VBtn>
  </VContainer>
</template>

<script>
import { HfMemberRegisterHfMemberCommandService } from "hfapi"
import HfMember from "@/aggregates/hf_member"
import HfMemberRegisterForm from "@@/components/organisms/hf_members/HfMemberRegisterForm"

export default {
   metaInfo() {
    return {
      title: `HfMember新規登録ページ`,
    }
  },
  components: {
    HfMemberRegisterForm,
  },
  data() {
    return {
      hf_member_local: new HfMember(this.$route.params.id),
      hfMemberParams: new HfMemberRegisterHfMemberCommandService.Params(),
    }
  },
  methods: {
    submit() {
      HfMemberRegisterHfMemberCommandService.execute(this.hfMemberParams).then(() =>{
          this.flash("保存に成功しました", "success")
      }).catch(e=>{
        console.log(e.response.data);
        this.flash(e.response.data.message, "error")
      })
    }
  },
  mounted(){
    this.hf_member_local.after_assign = () => {
      Object.assign(this.hfMemberParams.hf_member, this.hf_member_local)
    }
  }
}
</script>

<style>

</style>