<script>
export default {
  inheritAttrs: false,
  name: "ValidationViewer",
  render(h){
    const children = Object.keys(this.$slots).map(slot =>
      h("template", { slot }, this.$slots[slot])
    );
    return h("VSelect", {
      props: {
        ...this.$attrs,
        ...this.$props,
      },
      on: this.$listeners,
      scopedSlots: this.$scopedSlots
    }, children);
  }
}
</script>

<style scoped>
.v-input >>> .v-input__slot{
  /* background-color: red; */
  display: none;
}
</style>