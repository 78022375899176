import { GetIsHfMemberByGidQueryService } from "hfapi"
import store from "@/store";

const SIGN_UP_STATE = {
  hf_member: "hf_member",
  signup_user: "signup_user",
  not_signup_user: "not_signup_user",
}
const LOGIN_STATE = {
  hf_member: "hf_member",
  login: "login",
  not_login: "not_login",
}
const SIGN_UP_STATE_PRIORITY = {
  hf_member: 1,
  signup_user: 2,
  not_signup_user: 3,
}

function calculateSignUpState(oldSignUpState, currentLoginState){
  oldSignUpState = oldSignUpState || "not_signup_user";
  console.log("oldSignUpState", oldSignUpState, "currentLoginState", currentLoginState);
  const loginStateToSignUpState = {
    "hf_member": SIGN_UP_STATE.hf_member,
    "login": SIGN_UP_STATE.signup_user,
    "not_login": SIGN_UP_STATE.not_signup_user
  }
  const oldSignUpStatePriority = SIGN_UP_STATE_PRIORITY[oldSignUpState]
  const newSignUpStatePriority = SIGN_UP_STATE_PRIORITY[loginStateToSignUpState[currentLoginState]]
  if(newSignUpStatePriority < oldSignUpStatePriority){
    return loginStateToSignUpState[currentLoginState];
  } else {
    return oldSignUpState
  }
}

export default {
  namespaced: true,
  state: {
    isHfMember: false,
    firstCampaign: {
      name: "",
      source: "",
      term: "",
      medium: "",
      content: "",
      landingPage: "",
      createdAt: null,
    },
    latestCampaign: {
      name: "",
      source: "",
      term: "",
      medium: "",
      content: "",
      landingPage: "",
      updatedAt: null,
    },
    signUpState: "", // hf_member, signup_user, not_signup_user → 過去全てにおいて一度でも登録されたら基本的には巻き戻らない。
  },
  actions: {
    checkAndSetIsHfMember({ commit }, gid, callback){
      GetIsHfMemberByGidQueryService.execute({ gid: gid }).then(resp=>{
        console.log(resp.data);
        commit("setIsHfMember", resp.data);
      }).finally(()=>{
        if (callback) { callback() }
      });
    },
    updateSignUpState({commit, getters}){
      commit("setSignUpState", getters.signUpState);
    }
  },
  mutations: {
    setFirstCampaign(state, location) {
      // 初めての場合
      if (state.firstCampaign.name) { return } //すでにあったらいらない
      const urlParams = new URLSearchParams(location.search);
      state.firstCampaign.name = urlParams.get("utm_campaign") || "none"
      state.firstCampaign.source = urlParams.get("utm_source") || "none"
      state.firstCampaign.term = urlParams.get("utm_term") || "none"
      state.firstCampaign.medium = urlParams.get("utm_medium") || "none"
      state.firstCampaign.content = urlParams.get("utm_content") || "none"
      state.firstCampaign.landingPage = location.href;
      state.firstCampaign.createdAt = new Date()
    },
    setLatestCampaign(state, location){
      if (state.latestCampaign.updatedAt && (new Date() - new Date(state.latestCampaign.updatedAt))/(60*1000) < 30 ){
        // 30分以内に更新されていた場合継続させたいので。
        state.latestCampaign.updatedAt = new Date();
        return
      }
      const urlParams = new URLSearchParams(location.search);
      state.latestCampaign.name = urlParams.get("utm_campaign") || "none";
      state.latestCampaign.source = urlParams.get("utm_source") || "none";
      state.latestCampaign.term = urlParams.get("utm_term") || "none";
      state.latestCampaign.medium = urlParams.get("utm_medium") || "none";
      state.latestCampaign.content = urlParams.get("utm_content") || "none";
      state.latestCampaign.landingPage = location.href;
      state.latestCampaign.updatedAt = new Date();
    },
    setIsHfMember(state, isHfMember){
      state.isHfMember = isHfMember
      state.signUpState = SIGN_UP_STATE.hf_member
    },
    setSignUpState(state, newSignUpState){
      const oldSignUpStatePriority = SIGN_UP_STATE_PRIORITY[state.signUpState];
      const newSignUpStatePriority = SIGN_UP_STATE_PRIORITY[newSignUpState];
      if (newSignUpStatePriority < oldSignUpStatePriority) {
        state.signUpState = newSignUpState
      }
    }
  },
  getters: {
    currentLoginState(state) {
      console.log("getters/currentLoginState");
      if (state.isHfMember) {
        return LOGIN_STATE.hf_member
      } else if (store.getters["auth/isSignIn"]) {
        return LOGIN_STATE.login
      } else {
        return LOGIN_STATE.not_login
      }
    },
    signUpState(state, getters){
      console.log("getters/signUpState");
      return calculateSignUpState(state.signUpState, getters.currentLoginState);
    }
  },
};
