"use strict";
import axios from "../../../axios";
import deepSeal from "../../../lib/deep_seal";
class Params {
    constructor() {
        Object.assign(this, { "application": { "id": null, "resume": { "self_pr": null, "skill_pr": null, "why_select_company": null, "why_select_career": null, "why_good_at_job": null, "about_special_skill": null, "resume_question_answers": [{ "id": null, "resume_question_id": null, "resume_question_template_id": null, "answer": null, "_destroy": null }] } } });
        deepSeal(this);
    }
}
class BuildableParams {
    constructor() {
        Object.assign(this, { "application": { "id": null, "resume": { "self_pr": null, "skill_pr": null, "why_select_company": null, "why_select_career": null, "why_good_at_job": null, "about_special_skill": null, "resume_question_answers": [] } } });
    }
    build(key) { return { "resume_question_answers": { "id": null, "resume_question_id": null, "resume_question_template_id": null, "answer": null, "_destroy": null } }[key]; }
}
export default {
    Params: Params,
    BuildableParams: BuildableParams,
    execute: function (params) {
        return axios.patch("/jtj/commands/user__save_resume_for_application", { params });
    }
};
