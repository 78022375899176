<template>
  <div>
    <VTextField :label="t('first_name')" v-model="company_employee.first_name"/>
    <VTextField :label="t('last_name')" v-model="company_employee.last_name" :rules="[validation.required]"/>
    <VTextField :label="t('first_name_kana')" v-model="company_employee.first_name_kana"/>
    <VTextField :label="t('last_name_kana')" v-model="company_employee.last_name_kana"/>
    <VTextField :label="t('position')" v-model="company_employee.position"/>
    <VTextField :label="t('phone_num')" v-model="company_employee.phone_num"/>
    <VTextField :label="t('email')" v-model="company_employee.email" :rules="[validation.required]"/>
    <CheckboxDefaultFalse :label="t('is_main_person')" v-model="company_employee.is_main_person" :checked="company_employee.is_main_person || false" :rules="[validation.required]"/>
  </div>
</template>

<script>
export default {
  props: {
    company_employee: {
      type: Object
    }
  },
  methods: {
    t(key) {
      return this.$t(`aggregates.CompanyColumnNames.${key}`)
    }
  },
}
</script>

<style>

</style>