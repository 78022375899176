<template lang="">
  <div>
    <VCard v-for="recruitment_career in recruitment_careers" :key="recruitment_career.id" class="mb-2">
      <VContainer>
        <VRow v-if="!recruitment_career._destroy">
          <VCol cols="12">
            <VTextarea v-if="isShowField('title_id')" :label="t('title_id')" v-model="recruitment_career.title[lang]"/>
            <MasterSelectField v-if="isShowField('first_living_country_id')" :label="t('first_living_country_id')" v-model="recruitment_career.first_living_country_id" :items="master('countries')"/>
            <MasterSelectField v-if="isShowField('last_living_country_id')" :label="t('last_living_country_id')" v-model="recruitment_career.last_living_country_id" :items="master('countries')"/>
            <CheckboxDefaultFalse v-if="isShowField('is_work_globaly')" :label="t('is_work_globaly')" v-model="recruitment_career.is_work_globaly"/>
            <VTextField v-if="isShowField('years_in_first_country')" :label="t('years_in_first_country')" v-model="recruitment_career.years_in_first_country" type="number"/>
            <VTextarea v-if="isShowField('description_id')" :label="t('description_id')" v-model="recruitment_career.description[lang]"/>
          </VCol>
          <VCol>
            <VBtn class="error" @click="remove(recruitment_career)"><VIcon>mdi-trash</VIcon>削除</VBtn>
          </VCol>
        </VRow>
      </VContainer>
    </VCard>
    <VBtn @click="add('recruitment_careers')">キャリアパス追加</VBtn>
  </div>
</template>
<script>
import {HfMemberRegisterSimpleRecruitmentCommandService} from "hfapi/jtj"

export default {
  props: {
    hiddenFields: {type: Array, default:()=>[]},
    recruitmentParams: { type: Object }
  },
  computed: {
    recruitment_careers(){
      return this.recruitmentParams.recruitment.recruitment_careers
    }
  },
  methods: {
    isShowField(fieldName){
      return !this.hiddenFields.isInclude(fieldName)
    },
    t(key){
      return this.$t(`aggregates.RecruitmentColumnNames.${key}`)
    },
    add(){
      let pushed = (new HfMemberRegisterSimpleRecruitmentCommandService.BuildableParams).build("recruitment_careers")
      this.recruitment_careers.push(pushed)
    },
    remove(object){
      this.$set(object, "_destroy", true)
    },
  },
}
</script>
<style lang="">
  
</style>